const Home = () => import(/* webpackChunkName: "Home" */ "../views/Home");
const Persons = () => import(/* webpackChunkName: "Persons" */ "../views/Persons");
const Auth = () => import(/* webpackChunkName: "Auth" */ "../views/Auth");
const Person = () => import(/* webpackChunkName: "Person" */ "../views/Person");
const Messages = () => import(/* webpackChunkName: "Messages" */ "../views/Messages");
const Notifications = () => import(/* webpackChunkName: "Notifications" */"../views/Notifications");
const Redirect = () => import(/* webpackChunkName: "Redirect" */ "../views/Redirect");
const MyName = () => import("../views/MyName");
const Stats = () => import("../views/Stats");
const CelebrityProgram = () => import(/* webpackChunkName: "CelebrityProgram" */ "../views/CelebrityProgram");
const ErrorPage = () => import("../views/404");
const Static = () => import(/* webpackChunkName: "Static" */ "../views/Static");
const ReferralPage = () => import(/* webpackChunkName: "Referral" */ "../views/Referral");
const RecommendedPeople = () => import(/* webpackChunkName: "RecommendedPeople" */ "../views/RecommendedPeople");
const CheckSocial = () => import(/* webpackChunkName: "CheckSocial" */ "../views/CheckSocial");
const SearchPeople = () => import(/* webpackChunkName: "SearchPeople" */ "../views/SearchPeople");
const Settings = () => import(/* webpackChunkName: "Settings" */ "../views/Settings");
const Analyze = () => import(/* webpackChunkName: "Analyze" */ "../views/Analyze");
const Welcome = () => import(/* webpackChunkName: "Welcome" */ "../views/Welcome");
const WelcomeWant = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/want/want");
const WelcomeStart = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/start");
const WelcomeName = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/name");
const WelcomePersonalInfo = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/personal-info");
const WelcomeAnfas = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/anfas");
const WelcomeProfileExist = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/profile-already-exist");
const WelcomeProfile = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/profile");
const WelcomeTest = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/test");
const WelcomePhotoTest = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/photo-test");
const WelcomeTestComplete = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/test-complete");
const WelcomeAnalyze = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/analyze");
const WelcomeProfPay = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/prof-pay");
const WelcomeBusinessCardPay = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/business-card-pay");
const WelcomeBusinessCardInfo = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/business-card-info");
const WelcomePsychotypePay = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/psychotype-pay");
const WelcomeCheckPay = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/pay-check");
const WelcomeAnalyzeReady = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/analyze-ready");
const WelcomeProfessionVote = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/profession-vote");
const WelcomeTalentsReady = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/talents-ready");
const WelcomeTalents = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/talents");
const WelcomeRecommendations = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/recommendations");
const WelcomePeopleRecommendationsReady = () =>
    import(/* webpackChunkName: "Welcome" */ "@/components/welcome/people-recommendations-ready");
const WelcomePeopleRecommendations = () =>
    import/* webpackChunkName: "Welcome" */ ("@/components/welcome/people-recommendations");
const WelcomeProfessions = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/professions");
const WelcomeSubscribe = () => import(/* webpackChunkName: "Welcome" */ "@/components/welcome/subscribe");
const PhoneForm = () => import(/* webpackChunkName: "PhoneForm" */ "@/components/auth/auth-form/phone-form");
const RedirectFromSubdomain = () => import(/* webpackChunkName: "SubdomainRedirect" */ "@/views/redirectFromSubdomain.vue");
const PromocodesPage = () => import(/* webpackChunkName: "Promocodes" */ "@/views/Promocodes.vue");
const MidjourneyKeysPage = () => import(/* webpackChunkName: "MidjourneyKeys" */ "@/views/MidjourneyKeys.vue");
const PersonAnalyzePage = () => import(/* webpackChunkName: "PersonAnalyze" */ "@/views/PersonAnalyze.vue");
const CreateAssistantPage = () => import(/* webpackChunkName: "Assistants" */ "@/views/Assistants/Create.vue");
const MyAssistantsPage = () => import(/* webpackChunkName: "Assistants" */ "@/views/Assistants/My.vue");
const AssistantsPage = () => import(/* webpackChunkName: "Assistants" */ "@/views/Assistants/Assistants.vue");
const SharePhoto = () => import(/* webpackChunkName: "PhotoShare" */ "@/views/SharePhoto.vue");
const ChatGPTPage = () => import(/* webpackChunkName: "AILanding" */ "@/views/ai/chat-gpt.vue");
const FaceSwapPage = () => import(/* webpackChunkName: "AILanding" */ "@/views/ai/face-swap.vue");
const MidjourneyPage = () => import(/* webpackChunkName: "AILanding" */ "@/views/ai/midjourney.vue");
const StableDiffusionPage = () => import(/* webpackChunkName: "AILanding" */ "@/views/ai/stable-diffusion.vue");
const PikaPage = () => import(/* webpackChunkName: "AILanding" */ "@/views/ai/pika.vue");

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            layout: "landing",
            requiresAuth: false,
        },
    },
    {
        path: "/ai/chat-gpt",
        name: "ChatGPTLanding",
        component: ChatGPTPage,
        meta: {
            layout: "AI",
            requiresAuth: false,
        },
    },
    {
        path: "/ai/face-swap",
        name: "FaceSwapLanding",
        component: FaceSwapPage,
        meta: {
            layout: "AI",
            requiresAuth: false,
        },
    },
    {
        path: "/ai/midjourney",
        name: "MidjourneyLanding",
        component: MidjourneyPage,
        meta: {
            layout: "AI",
            requiresAuth: false,
        },
    },
    {
        path: "/ai/stable-diffusion",
        name: "StableDiffusionLanding",
        component: StableDiffusionPage,
        meta: {
            layout: "AI",
            requiresAuth: false,
        },
    },
    {
        path: "/ai/pika",
        name: "PikaLanding",
        component: PikaPage,
        meta: {
            layout: "AI",
            requiresAuth: false,
        },
    },
    {
        path: "/redirect-from-subdomain",
        name: "RedirectFromSubdomain",
        component: RedirectFromSubdomain,
        meta: {
            layout: "main",
            requiresAuth: false,
        },
    },
    {
        path: "/share/photo/:id",
        name: "SharePhoto",
        component: SharePhoto,
        meta: {
            layout: "login",
            requiresAuth: false,
        },
    },
    {
        path: "/welcome/",
        name: "Welcome",
        component: Welcome,
        meta: {
            layout: "main",
        },
        children: [
            {
                path: "login",
                name: "Login",
                component: Auth,
                meta: {
                    layout: "welcome",
                    requiresAuth: false,
                },
            },
            {
                path: "phone",
                name: "Phone",
                component: PhoneForm,
                meta: {
                    layout: "welcome",
                    requiresAuth: false,
                },
            },
            {
                path: "want",
                name: "Welcome-want",
                component: WelcomeWant,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "start",
                name: "Welcome-start",
                component: WelcomeStart,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "name",
                name: "Welcome-name",
                component: WelcomeName,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "personal-info",
                name: "Welcome-personal-info",
                component: WelcomePersonalInfo,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "anfas",
                name: "Welcome-anfas",
                component: WelcomeAnfas,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "profile-already-exist",
                name: "Welcome-profile-exist",
                component: WelcomeProfileExist,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "test",
                name: "Welcome-test",
                component: WelcomeTest,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "photo-test",
                name: "Welcome-photo-test",
                component: WelcomePhotoTest,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "analyze",
                name: "Welcome-analyze",
                component: WelcomeAnalyze,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "profile",
                name: "Welcome-profile",
                component: WelcomeProfile,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "test-complete",
                name: "Welcome-test-complete",
                component: WelcomeTestComplete,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "prof-pay",
                name: "Welcome-prof-pay",
                component: WelcomeProfPay,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "business-card-pay",
                name: "Welcome-business-card-pay",
                component: WelcomeBusinessCardPay,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "business-card-info",
                name: "Welcome-business-card-info",
                component: WelcomeBusinessCardInfo,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "psychotype-pay",
                name: "Welcome-psychotype-pay",
                component: WelcomePsychotypePay,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "pay-check",
                name: "Welcome-pay-check",
                component: WelcomeCheckPay,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "analyze-ready",
                name: "Welcome-analyze-ready",
                component: WelcomeAnalyzeReady,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "profession-vote",
                name: "Welcome-profession-vote",
                component: WelcomeProfessionVote,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "talents-ready",
                name: "Welcome-talents-ready",
                component: WelcomeTalentsReady,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "talents",
                name: "Welcome-talents",
                component: WelcomeTalents,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "recommendations",
                name: "Welcome-recommendations",
                component: WelcomeRecommendations,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "people-recommendations-ready",
                name: "Welcome-people-recommendations-ready",
                component: WelcomePeopleRecommendationsReady,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "people-recommendations",
                name: "Welcome-people-recommendations",
                component: WelcomePeopleRecommendations,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "professions",
                name: "Welcome-professions",
                component: WelcomeProfessions,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
            {
                path: "subscribe",
                name: "Welcome-subscribe",
                component: WelcomeSubscribe,
                meta: {
                    layout: "welcome",
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: "/promocodes",
        name: "Promocodes",
        component: PromocodesPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/midjourney-keys",
        name: "MidjourneyKeys",
        component: MidjourneyKeysPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/assistants",
        name: "Assistants",
        component: AssistantsPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/assistants/create",
        name: "CreateAssistant",
        component: CreateAssistantPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/assistants/edit/:uuid",
        name: "EditAssistant",
        component: CreateAssistantPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/assistants/my",
        name: "MyAssistants",
        component: MyAssistantsPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/settings/:type?",
        name: "settings",
        component: Settings,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Настройки",
        },
    },
    {
        path: "/search/:name?",
        name: "search",
        component: SearchPeople,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Поиск",
        },
    },
    {
        path: "/persons/:personType?",
        name: "persons",
        component: Persons,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Пользователи",
        },
    },
    {
        path: "/person/:uuid/:type?",
        name: "person",
        component: Person,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/messages/:chatId?/:type?",
        name: "messages",
        component: Messages,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Мессенджер",
        },
    },
    {
        path: "/messages/:chatId/themes/assistants/:assistantId",
        name: "messages-assistants",
        component: Messages,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Мессенджер",
        },
    },
    {
        path: "/messages/:chatId?/themes/:theme_id",
        name: "messages-theme",
        component: Messages,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Мессенджер",
        },
    },
    {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Уведомления",
        },
    },
    {
        path: "/redirect",
        name: "redirect-page",
        component: Redirect,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/recommended-people/",
        name: "recommended-people",
        component: RecommendedPeople,
        meta: {
            layout: "main",
            requiresAuth: true,
            headerTitle: "Рекомендации людей",
        },
    },
    {
        path: "/analyze",
        name: "merlin-analyze",
        component: Analyze,
        meta: {
            layout: "main",
            requiresAuth: true,
        },
    },
    {
        path: "/person-analyze/:uuid",
        name: "person-analyze",
        component: PersonAnalyzePage,
        meta: {
            layout: "analyze",
            requiresAuth: false,
        },
    },
    {
        path: "/:uuid",
        name: "public_person",
        component: Person,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/settings/change-my-name",
        name: "change-name",
        component: MyName,
        meta: {
            layout: "main",
            requiresAuth: true,
        },
    },
    {
        path: "/stats/dsHYhsdYhsdhDSH",
        name: "stats",
        component: Stats,
        meta: {
            layout: "login",
            requiresAuth: true,
        },
    },
    {
        path: "/special/celebrity-program",
        name: "celebrityProgram",
        component: CelebrityProgram,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/docs/:page",
        name: "Static",
        component: Static,
        meta: {
            layout: "main",
            requiresAuth: false,
        },
    },
    {
        path: "/:referralType/:user",
        name: "referral-program",
        component: ReferralPage,
        meta: {
            layout: "main",
        },
    },
    {
        path: "/connect/:client/:type",
        name: "check-social",
        component: CheckSocial,
        meta: {
            layout: "login",
        },
    },
    {
        path: "/404",
        name: "error-404",
        component: ErrorPage,
        meta: {
            layout: "error",
        },
    },
    {
        path: "*",
        redirect: "/404",
    },
];
export default routes;

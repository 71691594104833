/* eslint-disable */
export default {
    "newbie": "No name",
    "logout": "Logout",
    days: "day",
    "onBoardingMixin": {
        "title-1": "Definition of psychotype",
        "title-2": "Profitting",
        "description-1":
            "After Merlin AI determines your psychotype, you and all guests of your page will see it. Merlin will also begin to recommend you to other users of the social network,",
        "description-2": "Are you sure you want to get certified?",
    },
    "errors": {
        "unknown-error": "Unknown error",
    },
    "cookies": {
        "prefix-text": "We collect ",
        "text": "Cookies",
        "btn-text": "OK",
    },
    "DescriptionPopup": {
        "learn-about": "You read about person:",
        "light-background": "Light background",
        "dark-background": "Dark Background",
        "start-learn-profession": "Start learning the right profession today:",
        "start-learn-profession-btn-text": "Start",
    },
    MerlinDating: {
        "title":
            "Would you like a step-by-step algorithm for getting to know {name}'s personality?",
        "subtitle": "Download our app.",
    },
    "FooterLinks": {
        about: "About the project",
        contacts: "Contact",
    },
    "FriendsFilter": {
        "gender": {
            all: "All",
            male: "Men",
            female: "Women",
        },
        age: {
            any: "Any",
            interval_1: "18 to 24 years old",
            interval_2: "25 to 34 years old",
            interval_3: "35 to 44 years old",
            interval_4: "45 and older",
        },
        typeList: [
            {
                id: "ALL",
                name: "Any",
            },
            {
                id: "ADMINISTRATOR",
                name: "Administrator",
            },
            {
                id: "ACTIVIST",
                name: "Activist",
            },
            {
                id: "ALGORITHMIST",
                name: "Algorithmist",
            },
            {
                id: "ARTIST",
                name: "Artist",
            },
            {
                id: "MASTERMIND",
                name: "Mastermind",
            },
            {
                id: "HEDONIST",
                name: "Hedonist",
            },
            {
                id: "GENERATOR",
                name: "Generator",
            },
            {
                id: "DIPLOMAT_INVADER",
                name: "Diplomat Invader",
            },
            {
                id: "IDEOLOGIST",
                name: "Idean",
            },
            {
                id: "IDEOLOGICAL_MASTERMIND",
                name: "Mastermind",
            },
            {
                id: "IDEOLOGICAL_LEADER",
                name: "Thought Leader",
            },
            {
                id: "IMAGE_MAKER",
                name: "Image Maker",
            },
            {
                id: "ENGINEER",
                name: "Engineer",
            },
            {
                id: "INITIATOR",
                name: "Initiator",
            },
            {
                id: "INSPECTOR_PERFECTIONIST",
                name: "Perfectionist Inspector",
            },
            {
                id: "INTEGRATOR",
                name: "Integrator",
            },
            {
                id: "RESEARCHER",
                name: "Researcher",
            },
            {
                id: "RESEARCHER_ANALYST",
                name: "Research Analyst",
            },
            {
                id: "COMMUNICATOR",
                name: "Communicator",
            },
            {
                id: "CONNECTOR",
                name: "Connector",
            },
            {
                id: "CONTROLLER",
                name: "Controller",
            },
            {
                id: "CRITIC",
                name: "Critic",
            },
            {
                id: "LEGIONARY",
                name: "Legionnaire",
            },
            {
                id: "LEADER",
                name: "Leader",
            },
            {
                id: "LOBBYIST",
                name: "Lobbyist",
            },
            {
                id: "MASS_MEDIA_ENTERTAINER",
                name: "Massovik Entertainer",
            },
            {
                id: "MASTER_IMPLEMENTOR",
                name: "Master Implementer",
            },
            {
                id: "MATERIALIST",
                name: "Materialist",
            },
            {
                id: "MAECENAS",
                name: "Patron",
            },
            {
                id: "DREAMER",
                name: "Dreamer",
            },
            {
                id: "MORALIST",
                name: "Moralist",
            },
            {
                id: "MENTOR",
                name: "Mentor",
            },
            {
                id: "ASSERTIVE_INVADER",
                name: "Assertive Invader",
            },
            {
                id: "INFORMAL_LEADER_MANIPULATOR",
                name: "Manipulator Informal Leader",
            },
            {
                id: "INNOVATOR",
                name: "Innovator",
            },
            {
                id: "INNOVATOR_ENTHUSIAST",
                name: "Innovator Enthusiast",
            },
            {
                id: "OPTIMIZER",
                name: "Optimizer",
            },
            {
                id: "OPTIMIST",
                name: "Optimist",
            },
            {
                id: "ORGANIZER",
                name: "Organizer",
            },
            {
                id: "EDUCATOR",
                name: "Teacher",
            },
            {
                id: "PERFECTIONIST",
                name: "Perfectionist",
            },
            {
                id: "POLITICIAN",
                name: "Politician",
            },
            {
                id: "MEDIATOR_DIPLOMAT",
                name: "Diplomat Mediator",
            },
            {
                id: "PRACTITIONER",
                name: "Practitioner",
            },
            {
                id: "PRACTITIONER_CONSERVATIVE",
                name: "Conservative Practitioner",
            },
            {
                id: "PRACTITIONER_MANAGER",
                name: "Managing Practitioner",
            },
            {
                id: "ENTREPRENEUR_INNOVATOR",
                name: "Innovative Entrepreneur",
            },
            {
                id: "ADHERENT",
                name: "Follower",
            },
            {
                id: "FORECASTER",
                name: "Forecaster",
            },
            {
                id: "PREACHER_MENTOR",
                name: "Preacher Mentor",
            },
            {
                id: "RATIONALIZATOR",
                name: "Innovator",
            },
            {
                id: "IMPLEMENTATOR",
                name: "Realizer",
            },
            {
                id: "ROMANTIC",
                name: "Romantic",
            },
            {
                id: "EMINENCE_GRISE",
                name: "Red Cardinal",
            },
            {
                id: "SYSTEMATIZER",
                name: "Systemizer",
            },
            {
                id: "SKEPTIC",
                name: "Skeptic",
            },
            {
                id: "SOCIALIZER",
                name: "Socializer",
            },
            {
                id: "STABILIZER",
                name: "Stabilizer",
            },
            {
                id: "TECHIE",
                name: "Technician",
            },
            {
                id: "HANDYMAN",
                name: "Craftsman",
            },
            {
                id: "MANAGER",
                name: "Manager",
            },
            {
                id: "KEEPER",
                name: "Keeper",
            },
            {
                id: "EXPERIMENTER",
                name: "Experimenter",
            },
            {
                id: "ENTHUSIAST",
                name: "Enthusiast",
            },
        ],
        searchSortList: [
            {
                id: "DATE",
                name: "Date added",
            },
            {
                id: "name",
                name: "Name",
            },
        ],
        searchList: [
            {
                id: "DATE",
                name: "Date added",
            },
            {
                id: "name",
                name: "Name",
            },
            {
                id: "COMPATIBILITY",
                name: "Compatibility",
            },
            {
                id: "SIMILARITY",
                name: "Similarities",
            },
            {
                id: "BUSINESS",
                name: "Compatibility Business",
            },
            {
                id: "INTERACTION_BUSINESS",
                name: "Business Interaction",
            },
            {
                id: "INTERACTION_INTERPERSONAL",
                name: "Interpersonal interaction",
            },
        ],
        "any": "Any",
        "all": "All",
        "by-age-title": "By age:",
        "by-person-type-title": "Personality type:",
        "by-person-category-title": "Personality category:",
        "sort-by-title": "Sort by:",
        "sort-by-default": "Date added",
        "sort-by-gender-title": "By gender:",
        "reset-text": "Reset filter",
    },
    "InviteFriends": {
        "btn-text": "Add a friend",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "This user didn't upload photos!",
        "feedback": "Write to support",
        "referral-program": "Referral Program",
        "bonus-program": "Bonus Program",
        "legal-info-title": "Legal Information",
        "contacts-and-requisites": "Contacts and details",
        "about": "About company",
        "service-pay": "Payment for services",
        "term-of-use": "User Agreement",
        "privacy-policy": "Privacy Policy",
    },
    "RightAsideSocial": {
        "title": "Share",
        "vk": "VK.com",
        "ok": "ok.ru",
    },
    "loading": {
        "network-error":
            "Check the Internet connection <br/> and reload the page",
    },
    "MainHeader": {
        "back-to-profile": "Back to Profile",
        "createAccount": "Create account",
        "support": "Customer Service",
        "logout": "Logout",
    },
    "MobileMenu": {
        support: "SUPPORT",
    },
    "NotificationsModule": {
        "all": "All notifications",
        "partner": "You have been asked to become a partner",
        "deposit": "Deposit",
        "bonus": "You get a bonus",
        "reward": "Reward within the referral program",
        "referral": "{name} became your referral<br/>in Merlin Dating",
        "free-period":
            "Your referral {name} activated a trial subscription period {message} at Merlin Dating",
        "cancel-subscribe":
            "Your referral {name} unsubscribed {message} from Merlin Dating",
        "pay-subscribe":
            "You get reward. Your referral {name} paid my subscription {message} to Merlin Dating",
        "subscribe-error":
            "couldn't renew my subscription {0} in Merlin Dating for some reason",
        "mapp_celebrity_compare": "Merlin Dating compatibility buying reward",
        "bonus_coach_partner": "Bonus for your partner's action",
        "person_compare": "Someone checked with you",
        "compatibility": "compatibility",
        "similarity": "similarity",
        "business": "compatibility for business partnership",
        "person_indicator": "Somebody recognized",
        "interaction_business": "type of business interaction",
        "interaction_interpersonal": "type of interpersonal interaction",
        "mma_champion": "champion potential",
        "mma_potential": "martial arts potential",
        "aggression_common": "your general aggression",
        "aggression_emotional": "your emotional aggression",
        "aggression_social": "your social aggression",
        "aggression_intellectual": "your intellectual aggression",
        "aggression_physical": "your physical aggression",
        "analyse_item_unlock":
            "Someone read your description paragraph in the category",
        "professionaltype": "Profotype",
        "neurotype": "neurotype",
        "leadertype": "leadership",
        "character": "character",
        "relations": "relationships and family",
        "motivation": "motivation and energy",
        "selfAssessmen": "your self-esteem and confidence",
        "professional": "profession and self-actualization",
        "person_review_created": "Personality review is ready",
        "new_in_top":
            "Your compatibility top updated: +{{ notification.message }}",
        "phone_confirm": "You received a bonus {count} $",
        "upload_photos_request": "Someone is asking you to upload pictures",
        "upload_photos_completed": "{user} uploaded a photo of himself",
        "coach_partner_request": "{name} invited you to become a partner",
        "coach_partner_request_accept": "Accept",
        "coach_partner_request_decline": "Reject",
        "analyse_item_unlock_reward":
            "You received a bonus {{ notification.message }} $",
        "new_in_top_show": "Click to view",
        "phone_confirm_reward": "For confirming the phone number",
        "loading": "Downloading...",
        "no-more": "No more notifications found",
        "no-notifications": "You have no new notifications.",
        "show-all": "Show all",
    },
    "OfflineIndicator": {
        title: "No Internet connection.",
        retry: "Please try again later...",
    },
    "OnboardingDescriptionPopup": {
        text: "It says why you might be interested in doing this and what qualities you have that can help.",
        error: "You did not vote for all professions:",
    },
    "Search": {
        "findFriends": "Find friends",
        "found": "Users found:",
        "limit": "Enter at least 3 characters",
        "not-found": "Nothing found",
        "show-all": "SHOW ALL",
    },
    "SearchPeople": {
        "full-name": "Enter your first or last name",
        "all": "All",
        "friends": "Friends",
        "celebrity": "Celebrities",
        "fans": "Fans",
        "type-is-not-available": "Personality type not yet discovered",
        "limit": "Enter at least 3 characters",
        "empty-filter":
            "No people were found according to the specified parameters",
    },
    "Select": {
        nothing: "No match on request",
        choose: "Select",
    },
    "ServiceMenu": {
        "with-friends": "Compare with your friends",
        "about-me": "Learn more about yourself",
        "about-another": "Learn more about:",
        "aggression-another": "The level of aggression at:",
        "aggression": "Find out the level<br/> of aggression",
        "person-aggression": "The level of aggression in:",
    },
    "UserNav": {
        "my-page": "My page",
        "friends": "Friends",
        "compare-with-me": "Compared to me",
        "celebrities": "Celebrities",
        "messages": "Messages",
        "analyze": "Analysis Merlin AI",
        "recommendations": "Recommendations of people",
        "settings": "Settings",
        "manager": "Managing personalities",
    },
    "Auth": {
        "back": "Back",
        "another-phone": "Enter another phone number",
        "prev-step": "Go back",
        "auth": "Authorization",
        "confirm-phone":
            "We need to make sure that you are a real person. Please enter your phone number:",
        "retry-after":
            "You have recently requested a call, so you can request a new one in {{smsTimerCountdown}} seconds.",
        "not-found": "Nothing found",
        "country-code": "Country code",
        "phone": "Phone number",
        "agree_1":
            "By entering your phone number, you confirm that you are familiar with the ",
        "agree_2": "terms of use",
        "agree_3": "privacy policy",
        "telegram":
            "To complete your registration, please share your contact - it will be used if you lose access to Telegram.",
        "call": "Incoming call",
        "message": "Incoming message",
        "blocked":
            "Your number was recently blocked, you can start over in {{smsTimerCountdown}} seconds.",
        "retry-error":
            "If for some reason you cannot be reached or your text message is not received, you can request the code again in {{smsTimerCountdown}} seconds.",
        "telegramTitle":
            "Request from bot received. Authorization in progress.",
        "telegramTitle2":
            "Merlin will send you a telegram message within a minute.",
        "enter-call-code":
            "Enter the last 4&nbsp;digits of the incoming number below.",
        "enter-message-code": "Enter the code from the text message",
        "code": "confirm code:",
        "confirm": "confirm",
        "request": "request...",
        "enter": "enter",
        "loading": "loading...",
        "requestError":
            "Error sending call request, try again in a few seconds.",
        "phoneError":
            "Enter the correct phone number in international format. Example for Russia: +79174246633",
        "codeExpired":
            "You have not had time to enter the last four digits within 60 seconds. Please start the confirmation procedure again.",
        "codeError": "Incorrect digits, no attempts left: {attempts} out of 3",
    },
    "PhoneForm": {
        "back": "Back",
        "another-phone": "Enter another phone number",
        "prev-step": "Go back",
        "auth": "Authorization",
        "confirm-phone":
            "We need to make sure that you are a real person. Please enter your phone number:",
        "retry-after":
            "You have recently requested a call, so you can request a new one in {{smsTimerCountdown}} seconds.",
        "not-found": "Nothing found",
        "country-code": "Country code",
        "phone": "Phone number",
        "agree_1":
            "By entering your phone number, you confirm that you are familiar with the ",
        "agree_2": "terms of use",
        "agree_3": "privacy policy",
        "telegram":
            "To complete your registration, please share your contact - it will be used if you lose access to Telegram.",
        "call": "Incoming call",
        "message": "Incoming message",
        "blocked":
            "Your number was recently blocked, you can start over in {{smsTimerCountdown}} seconds.",
        "retry-error":
            "If for some reason you cannot be reached or your text message is not received, you can request the code again in {{smsTimerCountdown}} seconds.",
        "telegramTitle":
            "Request from bot received. Authorization in progress.",
        "telegramTitle2":
            "Merlin will send you a telegram message within a minute.",
        "enter-call-code":
            "Enter the last 4&nbsp;digits of the incoming number below.",
        "enter-message-code": "Enter the code from the text message",
        "code": "confirm code:",
        "confirm": "confirm",
        "request": "request...",
        "enter": "enter",
        "loading": "loading...",
        "requestError":
            "Error sending call request, try again in a few seconds.",
        "phoneError":
            "Enter the correct phone number in international format. Example for Russia: +79174246633",
        "codeExpired":
            "You have not had time to enter the last four digits within 60 seconds. Please start the confirmation procedure again.",
        "codeError": "Incorrect digits, no attempts left: {attempts} out of 3",
    },
    "choose-want": {
        "choose-1-1": "Money",
        "choose-1-2": "the profession",
        "choose-1-3": "self-actualization",
        "choose-2-1": "Energy",
        "choose-2-2": "motivation",
        "choose-2-3": "sense",
        "choose-3-1": "Relationships",
        "choose-3-2": "family",
        "choose-3-3": "colleagues",
        "choose-4-1": "Confidence",
        "choose-4-2": "self-assessment",
        "choose-4-3": "serenity",
    },
    "CelebrityProgram": {
        "compare":
            "Check your celebrity compatibility and if you make it to the top, you can meet in person",
        "desc": "This program enables people and celebrities to:",
        "get-know": "Get to know",
        "collaborate": "Collaborate",
        "talk": "Talk",
        "date": "Going on a date",
        "all": "All the celebrities in the program",
    },
    "MessagesHistory": {
        "accessError": "You cannot write to this user. If you think this is a mistake, please contact customer support.",
        "no-messages": "No messages",
        
    },
    "MessageHistoryHead": {
        "delete-chat": "Delete chat",
        "new-chat": "New chat",
        "history": "History",
        "disable-notifications": "Turn off notifications",
        "enable-notifications": "Enable notifications",
        "attempts-left": "{0} requests left",
    },
    "ChatMessage": {
        "myMessageTitle": "You",
        "upscale": "Upscale {0}",
        "seconds": " sec.",
        "generated-by-stable-diffusion": "Image generated via Stable Diffusion",
        "generated-by-midjorney": "Image generated by Midjorney",
        "generated-by-midjorney-error":
            "The image could not be created. Don't worry, we are already aware of the problem and will solve it soon. Try again a little later",
        "midjorney-parameters-error": "Unknown parameters:",
        "midjourney-queue":
            "Stand by, you're {0} in the queue to create a picture",
        "midjorney-stress-error":
            "Server load is too high. Try again in 1 minute",
        "midjorney-moderator-error":
            "I'm sorry, but your picture request violates the rules. I do not create pictures that:\n" +
            "- May offend or mislead someone.\n" +
            "- Contain offensive words or violence.\n" +
            "- Depict someone without clothes or in inappropriate situations.\n" +
            "- May be considered disrespectful in different cultures.",
        "midjorney-single-image-error":
            "Generation from the transmitted image only is not possible, add a text query and try again",
        "midjorney-unknown-error":
            "Oops, I think something went wrong with your image request. \n" +
            "\n" +
            "Examples of private errors:\n" +
            "1️⃣ Using --version 5.2 parameter only with --style: raw. \n" +
            "2️⃣ Correctness of entered parameters. Such parameters as -ar, 21:9, -style, raw, -q2, -seed, 302280510.\n" +
            "3️⃣ Correctness of the specified aspect ratio. Must be W:W, such as 16:9, not 'da'.\n" +
            "\n" +
            "These are just examples of what might be wrong. Please check your query and try again.",
        "optimization-midjorney": "Producing image optimization",
        "gpt-error-try-again": "Something went wrong. Try again.",
        "generated-by-pika": "The video was generated through Pika",
        "pika-generating": "Generating video...",
        "pika-queue": "Stand by, you're {0} in the queue to generate the video",
        "pika-unknown-error":
            "Oops, I think something went wrong with your image request. Please try again.",
        "download": "Download",  
    },
    "AddMessageForm": {
        "inputPlaceholder": "Enter message...",
    },
    "Chat": {
        "loading": "Loading Dialogue...",
        "write": "prints...",
        "online": "Online",
        "offline": "Was online",
        "delete-chat": "Delete chat",
        "new-chat": "New chat",
        "history": "History",
        "disable-notifications": "Turn off notifications",
        "enable-notifications": "Enable notifications",
        "partner": "You made a business proposal",
        "partner-for-you": "You have received a business proposal",
        "target": "You suggested closing the target",
        "target-for-you": "You have been asked to close the target",
        "AIPlaceholder": "Enter the string to generate the image",
        "edit": "Edit",
        "copy": "Copy",
        "delete": "Delete",
        "read": "Read",
        "notRead": "Not read",
        "empty-error": "The message cannot be empty",
        "length-error-min":
            "The message must not be shorter than {min} characters",
        "length-error-max": "Message length not more than {max} characters",
        "target-message": "You propose to close the target",
        "partner-message": "You make a business proposal",
        "stable-diffusion-message":
            "Image generation mode via Stable Diffusion",
        "midjorney-message": "Image generation mode via Midjorney",
        "editing": "Editing",
        "confirmTitle": "confirm action",
        "confirmDeleteMessageText": "Do you really want to delete the message?",
        "confirmDeleteAllMessagesText":
            "Do you really want to delete all correspondence?",
        "manyMessage5seconds":
            "Too many messages in a short time, wait 5 seconds.",
        "manyMessage60seconds":
            "No more than 30 messages per minute, please wait about 60 seconds.",
        "userDisable":
            "The user has restricted the ability to receive messages.",
        "sendError": "Message sending error, try again.",
        "gpt-message-to-long": "The message is too long",
    },
    "Dialogs": {
        "search": "Search for friends",
        "open": "Open chat",
        "nothing": "You have no active chats",
        "new-chat": "New chat",
        "delete": {
            "title": "Confirm Deletion",
            "desc": "After deleting a chat room, all messages in it will be deleted",
            "btn": "Delete chat",
        },
        "attempts-left": "{0} requests left",
    },
    Messages: {
        loading: "Loading chat...",
        "chooseAI": "Select the neural network you want to use",
        chooseChat: "Choose a chat to view messages",
    },
    ChatMenu: {
        title: "Menu",
        stableDiffusionTitle: "Stable Diffusion",
        stableDiffusionDescription:
            "Generate photorealistic images based on any text",
        midjorneyTitle: "Midjorney",
        midjorneyDescription:
            "Generate photorealistic images based on any text",
        enable: "Enable",
        disable: "Disable",
    },
    PayoutModal: {
        balance: "Your balance is:",
        title: "Withdraw funds:",
        sum: "Output amount:",
        sumRequiredError: "Specify withdrawal amount",
        sumMinError:
            "Sorry, we cannot accept the application. The minimum amount for withdrawal is {min} rubles. Continue as actively and soon you will be able to withdraw the money you have earned.",
        sumMaxError: "Maximum amount to withdraw {max}",
        success:
            "Application for withdrawal of funds has been accepted! The manager will contact you shortly",
        newFormat:
            "We have switched to a subscription format, you can spend your current balance on comparisons with friends or celebrities. And to use all the chips, subscribe to one or more sections",
        deposits: "Replenishments",
        payments: "Purchases",
        bonuses: "Bonuses",
        table: {
            "date": "Date",
            "sum": "Sum",
            "description": "Description",
            "remainder": "Remainder",
            loading: "Loading...",
            noMore: "No more entries",
            no: "No transactions found",
        },
    },
    purchaseDescriptions: {
        category: {
            deposit: "Account replenishment",
            analyse_item_unlock: "Purchase Description in Category",
            bonus_analyse_item_unlock:
                "Someone unlocked your description item in a category",
            referral_reward: "Reward from the referral program",
            merlinapp_referral_created:
                "Reward for a referral in a mobile application",
            person_compare: "You checked",
            bonus_person_compare: "Someone checked you",
            person_compare_photos: "Analyze by photos on",
            person_indicator: "Indicator open:",
            bonus_person_indicator: "Someone opened your indicator:",
            phone_confirm: "Phone Confirmation Bonus",
            bonus_coach_partner: "Coach Partner Program Bonus",
            payout: "Withdraw",
            refund: "Refund",
        },
        subcategory: {
            professionaltype: "professional type",
            neurotype: "neurotype",
            leadertype: "leadership",
            character: "character",
            relations: "relationships and family",
            motivation: "motivation and energy",
            selfAssessmen: "self-esteem and confidence",
            professional: "profession and self-realization",
            compatibility: "compatibility",
            similarity: "similarity",
            business: "compatibility for business partnership",
            interaction_business: "business interaction type",
            interaction_interpersonal: "interpersonal interaction type",
            mma_champion: "champion potential",
            mma_potential: "martial arts potential",
            aggression_common: "general aggression",
            aggression_physical: "physical aggression",
            aggression_emotional: "emotional aggression",
            aggression_social: "social aggression",
            aggression_intellectual: "intellectual aggression",
        },
    },
    serviceTypes: {
        COMPATIBILITY: "Relationship Compatibility",
        COMPATIBILITY_PLAN: "Relationships and family",
        SIMILARITY: "The similarity of characters",
        SIMILARITY_PLAN: "Self-esteem and confidence",
        BUSINESS: "Business Compatibility",
        BUSINESS_PLAN: "Profession and purpose",
        INTERACTION_BUSINESS: "Business relationship",
        INTERACTION_BUSINESS_MY: "How do I conduct business",
        INTERACTION_BUSINESS_OTHER: "How he behaves in business",
        INTERACTION_BUSINESS_PLAN: "Profession and purpose",
        INTERACTION_INTERPERSONAL: "Relationships with people",
        INTERACTION_INTERPERSONAL_MY: "How do I deal with people",
        INTERACTION_INTERPERSONAL_OTHER: "How he behaves with people",
        INTERACTION_INTERPERSONAL_PLAN: "Relationships and Family",
        AGGRESSION_COMMON: "General Aggression",
        AGGRESSION_COMMON_PLAN: "Relationships and Family",
        AGGRESSION_PHYSICAL: "Physical Aggression",
        AGGRESSION_PHYSICAL_PLAN: "Profession and Purpose",
        AGGRESSION_EMOTIONAL: "Emotional Aggression",
        AGGRESSION_EMOTIONAL_PLAN: "Profession and Purpose",
        AGGRESSION_SOCIAL: "Social Aggression",
        AGGRESSION_SOCIAL_PLAN: "Profession and Purpose",
        AGGRESSION_INTELLECTUAL: "Intellectual Aggression",
        AGGRESSION_INTELLECTUAL_PLAN: "Relationships and family",
        INTELLIGENCE_LEVEL: "Intelligence level",
    },
    buyActions: {
        "compareUnlimited": "Number of comparisons is unlimited",
        "compareLimit": "Remaining comparisons: {limit}",
        "indicatorUnlimited": "The number of checks is unlimited",
        "indicatorLimit": "Tries left: {limit}",
        compareCelebrity: "celebrity",
        compareUser: "by user",
        indicatorCelebrity: "celebrities",
        indicatorUser: "user",
        compareConfirmTitle: "Confirm Compare",
        compareConfirmText: `Are you sure you want to compare yourself to {userType} {name} in category "{activeType}"?`,
        compareConfirmBtn: "Confirm Purchase",
        indicatorConfirmTitle: "Confirm purchase",
        indicatorConfirmText:
            'Do you really want to open the "{activeType}" category at {userType} {name}?',
        indicatorConfirmBtn: "Confirm purchase",
    },
    "compare-result-dialog": {
        more: "Learn more about the person",
    },
    "ConfirmDialog": {
        price: "Cost:",
        timer: "Prior to verification as part of the subscription:",
        cancel: "Cancel",
        apply: "Confirm",
    },
    "LegalLinksMobile": {
        "referral": "Referral program",
        "legal-title": "Legal Information",
        "requisites": "Contacts and details",
        "about": "About company",
        "payments": "Payment for services",
        "term-of-use": "User Agreement",
        "privacy-policy": "Privacy Policy",
        "payout": "Bonus Program",
        "support": "Write to support",
        "logout": "Log out",
    },
    "AnotherPerson": {
        "rating": "Your grades",
        "person-rate": "Evaluate the man",
        "compare-with-me": "Compare with yourself",
        "more": "Learn more",
        "aggression": "Level of aggression",
    },
    "AnotherPersonActions": {
        "sendMessage": "SEND MESSAGE",
        "sendTarget": "OFFER TO CLOSE TARGET",
        "sendBusiness": "BUSINESS PROPOSAL",
        "targetConfirmTitle": "note",
        "targetConfirmText":
            "In order to take this action, you must first add it as a friend.",
        "businessConfirmText":
            'In order to make the person a business offer, you need to subscribe to the "Profession and Destination" category',
        "add": "Add to friends",
        "subscribe": "subscribe",
    },
    "AnotherPersonInfo": {
        "getType": "Identify my psycho-type",
        "typeHidden": "Personality type not yet discovered",
        "web": "Website",
        "webUndefined": "Not specified",
        "selfEsteem": "What boosts self-esteem",
        "selfEsteemUndefined": "Not specified",
        "occupation": "Status",
        "occupationUndefined": "Not specified",
        "personalInfo": "Personal information",
        "age_1": "year",
        "age_2": "year",
        "age_3": "years",
        "height": "cm",
        "weight": "kg",
        "noChildren": "No children",
        "children_1": "child",
        "children_2": "children",
        "children_3": "children",
    },
    "AnotherPersonBusinessCard": {
        "merlin": "From Merlin",
        "aboutMe": "{name} about myself",
        "blocked": "Blocked",
        "unlock": "Unlock",
        "more": "Read more",
        "mrln_strengths": "Strengths",
        "mrln_talents": "Talent",
        "mrln_self_esteem": "What boosts self-esteem",
        "mrln_motivation": "What boosts motivation",
        "mrln_tasks": "The tasks it performs successfully",
        "mrln_activities": "Spheres of activity to which he is prone",
        "self_esteem": "What boosts self-esteem",
        "user_like": "What likes",
        "user_good": "What's good",
        "user_usually": "What do you usually",
        "user_work": "Who does he want to work",
        "user_goal": "Wants to earn",
        "user_skill": "Wants to master",
        "career": "EXPERIENCE",
        "education": "EDUCATION",
        self_esteemUndefined: "Nothing",
        user_likeUndefined: "Nothing",
        user_goodUndefined: "Nothing",
        user_usuallyUndefined: "Nothing",
        user_workUndefined: "Nobody",
        user_goalUndefined: "5$ per hour",
        user_skillUndefined: "left side of the bed",
        fromNow: "as of now",
        categoryProfession: "Profession and Destination",
        categorySelfEsteem: "self-esteem and confidence",
        categoryMotivation: "Motivation and effectiveness",
        needSubscribe: "Subscription required",
        needSubscribeText:
            'To unlock this item, a subscription to the category "{category}" is required.',
        subscribe: "Subscribe",
    },
    "AvailableSocial": {
        "notSelected": "NOTHING IS SPECIFIED",
    },
    "AvatarGallery": {
        retry: "Try again",
        new: "Upload a new avatar",
    },
    "ChangeName": {
        "placeholder": "Enter your first and last name",
        "error": "First and last names must contain more than 2 characters",
        "saving": "Saving...",
        "save": "Save",
    },
    "ChangePhotos": {
        "another": "Download Other",
        "anotherAnfasDrag": "Drag another <br/> photo in full-face here",
        "anotherProfileDrag": "Drag another <br/> photo to the profile",
        "didntFit": "The photo didn't fit",
        "limit": "The download limit for <br/> today is exhausted",
        "example": "Example reference photo",
        anfas: {
            title: "Only the neural network algorithm will see your photos. Photos are not available to third parties.",
            desktopText: "Drag your photo in full-face here.",
            mobileText: "Upload your photo in full-face here.",
            dragText: "Drag your photo in full-face here",
        },
        profile: {
            desktopText: "Drag your profile photo here",
            mobileText: "Upload your profile photo here",
            dragText: "Drag your profile photo here",
        },
        avatar: {
            title: "Upload your avatar, it's visible to everyone who visits your page.",
            text: "Upload your avatar",
            dragText: "Drag your avatar here.",
            help: "I suggest you upload a photo where you can be seen better. It's a photo other users will see. I won't be able to promote you with other people's photos.",
        },
    },
    "CompareWithPhoto": {
        "back": "Back to the personality description",
        "another-type": "Select another type of comparison",
        "choose-in-list": "Choose from a list of friends",
        "loading": "Downloading...",
        "no": "No one else was found :)",
        "noFriendsShort": "You have no friends",
        "upload-friend-photo": "upload a photo of a friend",
        "searchFriends": "searchFriends",
        "noFriends":
            "have no friends yet. You can invite friends and get bonuses if they join",
        "invite":
            "Sorry, this person is not on your friends list yet. You can invite him and get a bonus if he joins",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Photo uploaded",
        "choose-main": "To make the main",
        "confirmDeleteTitle": "Confirm deletion",
        "confirmDeleteText": "Do you really want to delete the photo?",
        "confirmDeleteBtn": "Delete",
    },
    "PersonCompareMobile": {
        compare: {
            myProfile: "Compare with your friends",
            withUser: "Compare yourself to <span>{user}</span>",
        },
        indicator: {
            myProfile: "Learn more about yourself",
            withUser: "Learn more about:<br/> {user}",
        },
    },
    "PersonDescription": {
        "back": "Back",
        "choose-type": "Select the direction you are interested in",
        "need-subscribe": "Subscription required",
        "need-subscribe-text":
            "In order to read the description of the selected section, it is necessary to subscribe to a category",
        "btn": "Sign up",
    },
    "PersonInfo": {
        "age": "Age",
        "age_1": "years old",
        "age_2": "years old",
        "age_3": "years old",
        "no": "Not specified",
        "children": "Children",
        "children_1": "child",
        "children_2": "children",
        "children_3": "children",
        "height": "Growth",
        "weight": "Weight",
        "subscriptions": "Subscriptions",
        "photo-error":
            "If you have a few of your own photos - the business card will look more attractive        ",
    },
    "PersonMobileBox": {
        history: "History",
    },
    "PersonMobileBusinessCard": {
        merlin: "From Merlin",
        user: "From {user}",
        career: "Career",
        education: "Education",
    },
    "PersonMobileBusinessCardPreview": {
        "title":
            "What would my business card look like <br/>for another person",
        "send": "SEND A LINK TO MY PAGE",
    },
    "PersonMobileHeader": {
        "noName": "Name not specified",
        "changeName": "Set name",
        "my-type": "Identify my psycho-type",
        "type-not-available": "Personality type not yet discovered",
    },
    "PersonalRecommendations": {
        title: "Personal recommendations",
    },
    "PersonalRecommendationsDialog": {
        title: "Personalized recommendations for you",
        canOpen: "You can open",
        number: "Recommendation No",
        openAfter: "You can open through: ",
    },
    "PhotoExamples": {
        title: "Examples of reference photos",
        text: "The closer the photo is to the standard, the more accurately the neural network will tell about you. <br/> Photos will be available only to you.",
        anfas: "Photo in full-face",
        profile: "Profile picture",
        btn: "Upload your photos",
    },
    "AboutSettingsForm": {
        "user_like": "What do you like to do?",
        "user_good": "What you are good at?",
        "user_usually": "What do you usually do?",
        "user_work": "What do you want to work as?",
        "user_goal": "How much do you want to earn?",
        "user_skill": "What do you want to learn?",
        "saved": "Saved",
    },
    "CareerSettingsForm": {
        "company-name": "Company name",
        "search-title": "Perhaps you meant:",
        "company-name-error": "Enter company name",
        "company-position": "Position",
        "company-position-error": "Enter position",
        "company-start": "Getting Started",
        "company-start-error": "Enter the start date",
        "company-end": "End of job",
        "company-end-now":
            "If you still work for the company, leave this field blank",
        "saved": "Saved",
        "save": "Save",
        "andContinue": " and continue",
        "delete": "Delete job",
        "add": "Add job",
    },
    "EducationSettingsForm": {
        "country": "Country:",
        "selectCountry": "Select country",
        "alreadySelectCountry": "First select the country",
        "alreadySelectCity": "First select the city",
        "city": "City: ",
        "searchCity": "Search by City",
        "no-cities": "No cities were found for your query",
        "education-place": "Name of school",
        "education-place-nothing": "No school was found for your query",
        "education-palce-error": "Enter the name of the school",
        "education-position": "Specialty",
        "education-position-error": "Enter your field of study",
        "education-start": "Year of start of training",
        "education-start-error": "Enter the year of the start of training",
        "education-end": "Year of graduation",
        "education-end-now":
            "If you still work for the company, leave this field blank",
        "delete": "Delete education location",
        "add": "Add a education location",
        "saved": "Saved",
        "save": "Save",
        "andContinue": " and continue",
    },
    "Settings": {
        "information": "Main",
        "added-info": "Information",
        "about": "Interests",
        "career": "Career",
        "education": "Education",
        "photos": "Photos",
        "name": "Name",
        "person-name": "Enter the name of the person",
        "name-error": "Name must be shorter than 60 characters",
        "address": "Page address",
        "address-error":
            'The page address can only contain latin letters, numbers and "_" and "-" signs and cannot be shorter than 3 or more than 30 characters',
        "address-length-error":
            "The address must not be longer than {min} characters",
        "birthday": "Date of birth",
        "birthday-error": "Select date of birth",
        "marital": "Marital status",
        "maritalList": [
            "Single",
            "Married",
            "Not married",
            "Married",
            "Divorced",
        ],
        "children": "Children",
        "children-error": "Enter an integer or leave the field blank",
        "growth": "Growth",
        "weight": "Weight",
        "growth-error": "Enter your height",
        "weight-error": "Enter your weight",
        "gender": "Gender",
        "genderList": [
            {
                id: "M",
                name: "Male",
            },
            {
                id: "F",
                name: "Female",
            },
        ],
        "eye-color": "Eye color",
        "saved": "Saved by",
        "select": "Select from list",
        "height": "height",
        "maxHeightError": "Maximum allowed value is 200",
        "maxWeightError": "Maximum allowed value - 200",
        "heightUnit": "cm",
        "weightUnit": "kg",
        "height-error": "Enter your height",
        "delete-account": "Delete account",
        "exit": "Logout",
        "nameTimeError": "Name can be changed once in 24 hours",
    },
    "InfoSettingsForm": {
        "web": "Website",
        "selfEsteem": "What boosts your self-esteem?",
        "address-incorrect":
            "Enter the correct link, in the format: https://merlinface.com",
        "self-esteem-error":
            "No more than 50 characters, you have entered {{ selfEsteem.length }}",
        "social-status": "Your social status:",
        "socialList": [
            "Schoolboy",
            "Student",
            "Unemployed",
            "Self-employed",
            "Company employee",
            "(Co)owner of the company",
        ],
        "country": "Country:",
        "city": "City:",
        "selectCountry": "Select country",
        "searchCities": "Search by city",
        "saved": "Saved",
    },
    "settings": {},
    "SocialRating": {
        "start": "Your grades",
        "end": "{name} grades",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "The analysis of recommended people is done. This can take up to 24 hours.",
        "category_1": "profession&nbsp;purpose",
        "category_2": "Relationships&nbsp;family",
        "category_3": "Motivation&nbsp;effectiveness",
        "category_4": "self-esteem&nbsp;confidence.",
    },

    Person: {
        "you": "Вы",
        "user": "User",
        compare: {
            "fromMe": "Compare with friends",
            "fromUnnamedUser":
                "Compare yourself to <span>an unnamed person</span>",
            "fromNamedUser": "Compare yourself to <span>{user}</span>",
        },
        indicator: {
            "fromMe": "Learn more about yourself",
            "fromUnnamedUser": "Learn more about: <span>UnnamedUser</span>",
            "fromNamedUser": "Learn more about: <span>{user}</span>",
        },
        aggression: {
            "fromMe": "Find out your level of aggression",
            "fromUnnamedUser": "Aggression Level of: <span>Unnamed User</span>",
            "fromNamedUser": "Aggression level of: <span>{user}</span>",
        },
    },
    "PersonList": {
        "celebrities": "Celebrities",
        "fans": "Fans",
        "friends": "All friends",
        "friends-requests": "Friend requests",
        "select-all": "Select all your friends",
        "no-with-params":
            "No celebrities were found according to the specified parameters",
        "no-friends": "You have no friends yet.",
        "no-requests": "You don't have any friend requests yet.",
        "no-celebrities":
            "No celebrities were found according to the specified parameters",
        search: {
            "celebrity": "Celebrity Search",
            "friends": "Search by friends",
            "requests": "Search for friend requests",
            "users": "Search by person",
        },
    },
    "PersonShortCard": {
        typeLocked: "Personality type not yet unlocked",
        sendMessage: "Send message",
        compare: "Compare",
        hide: "hide",
        removeFromFriends: "Remove from friends",
        acceptRequest: "Add",
        declineRequest: "Reject",
        confirmDeleteFriendTitle: "Confirm action",
        confirmDeleteFriendText: `Are you sure you want to delete the identity "{user}" from your friends?`,
        confirmDeleteFriendBtn: "Delete",
    },
    "Promocode": {
        "btn": "I have a promo code",
        "enter": "Enter promocode",
    },
    "RecommendedPeople": {
        "no-persons": "Merlin has not yet picked up people for you",
        "money-target": "My earning goal",
        "next": "Next",
        toProfile: "TO PROFILE",
        "type-undefined": "Undefined",
        "age_1": "year",
        "age_2": "years",
        "age_3": "years",
        "status": "Social status",
        "no-status": "No status",
        "no-goal": "Not specified",
        "city": "City",
        "spheres": "Areas of activity",
        "no-spheres": "Not specified",
        "no-city": "Not specified",
        "noAge": "No age specified",
        "loading": "Loading...",
        category_1: "Profession and &nbsp;purpose",
        category_2: "Relationships &&nbsp;family",
        category_3: "Motivation &&nbsp;efficiency",
        category_4: "Self-esteem &&nbsp;confidence",
        "next-recommendation": "Next person",
    },
    "SubscriptionsCategoriesItem": {
        "active": "subscription is active",
    },
    "SubscriptionsContent": {
        "active": "Subscription active until {date}",
        category_1: "Profession and Destination, FREE",
        category_2: "Relationships & Family, FREE",
        category_3: "Motivation and Performance, FREE",
        category_4: "Self-Esteem and Confidence, FREE",
        cancelTitle: "unsubscribe.",
        cancelText:
            'Are you confirming that you want to cancel {period} subscription in "{category}"?',
        cancelBtn: "cancel subscription",
        mounthly: "monthly",
        annual: "annual",
    },
    SubscriptionsTableForm: {
        payBtn: "Go to payment",
        withBalance: "Payment will be debited from the balance on the site",
    },
    SubscriptionsTableInfo: {
        discount: "Your discount: {discount}&nbsp;₽",
        chooseAnnual:
            "Choose an annual subscription<br/>and save {discount}&nbsp;₽",
        active: "Active subscription",
    },
    Subscriptions: {
        title: "Select the subscription category you are interested in",
        canceled: "Subscription canceled successfully!",
        succeeded: "Successfully subscribed!",
        choosePlan: "Choose a plan:",
    },
    "about": {
        "title": "Welcome to the project merlinface.com!",
        "text-1": "General provisions",
        "text-2":
            "Our team has created a Neural Network, through which you can get text and graphical diagnosis of attention, memory, emotional-volitional sphere, behavior, as well as many individualized recommendations that are based on these indicators.",
        "text-3":
            "Qualified specialists also work on the diagnostics of Users: neuropsychologists, cognitive-behavioral therapists and specialists in other fields.",
        "text-4": "Useful insights!",
        "vk": "Our VKontakte group:",
    },
    "cookie": {
        "title": "Cookie policy",
        "text-1":
            "Cookies are small pieces of text sent by the server and stored on the User's computer. Cookies perform many functions, for example, save the settings created by the User, allow to move between pages after authorization and generally simplify the work with the site.",
        "text-2": "We use cookies to:",
        "text-3":
            "1. Identities — cookies allow you to recognize your device and your Account if you enter your username and password and not ask for them every time you go to another page.",
        "text-4":
            "2. Statistics — cookies allow you to collect data on browsing certain pages.",
        "text-5":
            "You can prevent your browser from accepting cookies, but this will significantly limit your ability to use the Platform.",
    },
    "payments": {
        "title": "Payment and receipt of order",
        "text-1": "Payment by bank card",
        "text-2":
            "The Robokassa processing center — an official service provider of VISA and MasterCard — accepts payments on the site.",
        "text-3":
            "For online payment you can use bank cards of MIR, Visa, Visa Electron, MasterCard and Maestro. If your card is subscribed to 3D-Secure, your payment will be authorized using a one-time password.",
        "text-4":
            "The entry and processing of sensitive payment data is performed on the side of the processing center. Payment data is transmitted to the bank in encrypted form through secure channels. No one, not even the seller, can get the bank card details entered by the client, which guarantees full security of his money and personal data.",
        "text-5":
            "After successful payment, an electronic receipt is sent to the payer's email, confirming the payment and containing its unique identifier.",
        "text-6":
            "In the case of a refund, the money is returned to the same card from which the payment was made.",
        "text-7":
            "All questions related to the payment process can be asked by Robokassa 24/7 support specialists by phone +7 499 110 57 25 or by writing to support@robokassa.ru",
        "text-8": "Payment with Yandex.Money, Webmoney, Qiwi",
        "text-9":
            "You can pay for your order directly on the website merlinface.com through the e-wallets Yandex.Money, WebMoney, QIWI. Payment security is provided by Robokassa processing center.",
        "text-10":
            "In the case of a refund, the money is returned to the same e-wallet from which the payment was made.",
        "text-11":
            "If you have any questions about payment via e-wallet, you can contact the round-the-clock support service of Robokassa by phone +7 499 110 57 25",
        "text-12": "List of information services for purchase:",
        "text-13":
            '1. The "Profession and Self-Actualization" service. Providing a description of the User on the topic of self-realization and recommendations for suitable professions. Cost from 150 to 500 rubles.',
        "text-14":
            '2. The service "Self-assessment". Providing a description of the user and recommendations on the topic of self-esteem. Cost from 150 to 200 rubles.',
        "text-15":
            '3. The service Motivation and Energy". Providing a description of the User and recommendations on the topic of self-esteem. Cost from 150 to 500 rubles.',
        "text-16":
            '4. The service "Relationship and Family". Providing a description of the User and recommendations on the topic of relationships with other people. Cost from 100 to 400 rubles.',
        "text-17":
            '5. The service "Compatibility in relationships" shows the level in percent. Cost 50 rubles.',
        "text-18":
            '6. The service "Similarity of characters" shows the level in percent. The cost is 25 rubles.',
        "text-19":
            '7. The service "Business compatibility" shows the level in percent. Cost 100 rubles.',
        "text-20":
            '8. The service "How does it behave in business" shows the level as a percentage. Cost 50 rubles.',
        "text-21":
            '9. The service "How does he behave with people" shows the level as a percentage. Cost 50 rubles.',
        "text-22":
            '10. The service "Potential in martial arts" shows the level in percent. The cost is 50 rubles.',
        "text-23":
            '11. The service "Champion potential" shows the level as a percentage. The cost is 50 rubles.',
        "text-24":
            "The amount for one-time purchases when comparing with each User of the platform or opening the description block of interest for each User is deducted from the balance within the system.",
        "text-25":
            "After successful payment, the service opens automatically on the user's page at merlinface.com. The service is provided in perpetuity.",
        "text-26":
            'The return of funds is regulated by the Law of the Russian Federation from 07.02.1992 № 2300-I "On Protection of Consumer Rights"',
    },
    "privacy-policy": {
        "title": "",
        "text-1": "General provisions",
        "text-2":
            'This Privacy Policy is compiled in accordance with the requirements of the Federal Law of 27.07.2006. No. 152-FZ "On Personal Data" and defines the procedure of personal data processing and measures to ensure personal data security taken by "Merlin AI" LLC (hereinafter - the Administration).',
        "text-3":
            "1.1. The Administration sets as its most important goal and condition of its activities the observance of human and civil rights and freedoms in the processing of personal data, including the protection of the rights to privacy, personal and family secrets.",
        "text-4":
            '1.2. This Administration\'s policy regarding the processing of personal data (the "Policy") applies to all information that the Administration may receive about visitors to the https://merlinface.com website and the Merlin Dating mobile app',
        "text-5": "2. Basic concepts used in the Policy",
        "text-6":
            "Blocking of personal data – temporary termination of personal data processing (except when processing is necessary to clarify personal data);",
        "text-7":
            "Website – a set of graphic and informational materials, as well as computer programs and databases, making them available on the Internet at https://merlinface.com and the mobile application Merlin Dating.",
        "text-8":
            "Information system of personal data – a set of personal data contained in databases of personal data, and providing their processing information technology and technical means;",
        "text-9":
            "Depersonalization of personal data – actions, as a result of which it is impossible to determine without the use of additional information what personal data belongs to a particular user or other subject of personal data;",
        "text-10":
            "Processing of personal data – any action (operation) or a set of actions (operations) performed with or without the use of automation with personal data, including collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), anonymization, blocking, removal, destruction of personal data;",
        "text-11":
            "Administration – a legal entity or an individual, independently or jointly with others, organizing and (or) carry out the processing of personal data, as well as determining the processing of personal data, the composition of personal data to be processed, actions (operations) performed with personal data;",
        "text-12":
            "Personal Data – any information relating directly or indirectly to an identified or identifiable User of the https://merlinface.com website and the Merlin Dating mobile app",
        "text-13":
            "User - any visitor to https://merlinface.com and the Merlin Dating mobile app",
        "text-14":
            "Provision of personal data – actions aimed at disclosure of personal data to a certain person or a certain circle of persons;",
        "text-15":
            "Dissemination of personal data – any action aimed at disclosure of personal data to an indefinite range of persons (transfer of personal data) or to familiarize with personal data to an unlimited range of persons, including the publication of personal data in the media, placing in information and telecommunications networks or providing access to personal data in any other way;",
        "text-16":
            "Trans-border transfer of personal data – transfer of personal data to a foreign country to a foreign authority, a foreign individual or a foreign legal entity;",
        "text-17":
            "Destruction of personal data – any action resulting in the destruction of personal data irretrievably with the inability to further restore the content of personal data in the information system of personal data and (or) the destruction of the material media personal data.",
        "text-18":
            "Payment – remuneration of the Administration, the money paid by the User in favor of the Administration for the provision of a non-exclusive license.",
        "text-19":
            "3. The Administration may process the following Personal Data of the User:",
        "text-20": "3.1. Surname, first name, patronymic;",
        "text-21": "3.2. Email address;",
        "text-22": "3.3. Phone numbers;",
        "text-23": "3.4. Year, month, date and place of birth;",
        "text-24": "3.5. Photos;",
        "text-25":
            "3.6. Information about education, profession, specialty and qualifications, details of educational documents;",
        "text-26":
            "3.7. The Site also collects and processes impersonal visitor data (including cookies) using Internet statistical services (Yandex Metrika and Google Analytics, etc.).",
        "text-27":
            "3.8. The Site may automatically or with the User's participation collect additional information, including IP address, browser version, country, language, affiliate tags, address of the page from which the User has accessed the Site, and other technical data that can be used to identify the User without additional measures.",
        "text-28":
            "3.9. Permission may be requested from the User to provide access to the accounts of third-party Services, including but not limited to public file storage, instant messaging, social networks, and so forth, in order to use various Services of the Site. In this case, the Site may receive additional personal information from third parties, including, but not limited to, gender, location, user avatar (userpic) and so on. All information available through a third-party service provider is processed and stored in accordance with the User Agreement and Privacy Policy.",
        "text-29": "4. Purposes of Personal Data Processing",
        "text-30":
            "4.1. The purpose of processing of Personal Data of the User is to create the User's Account and Personal Cabinet for working with the Website and Mobile Application; informing the User by sending e-mails or messages; providing customer service and technical support; giving the User access to the services, information and/or materials contained on the Website and in the Application.",
        "text-31":
            "4.2. Also the Administration has the right to send the User notifications about new products and services, special offers and various events. The User may always refuse to receive information messages by sending an email to the Administration at info@merlinface.com with the note «Refuse notifications about new products and services and special offers».",
        "text-32":
            "4.3. Depending on how the User interacts with the Site, individual marketing messages about products and services may be sent to the User, as well as information about the interaction with the Site, which allows you to adjust advertising campaigns, for example, not to show ads in social networks to those who are already a User.",
        "text-33":
            "4.4. Anonymized User data collected through Internet statistical services is used to collect information about the actions of Users on the site, to improve the quality of the site and its content.",
        "text-34":
            "4.5. When the User performs actions in myAlpari for security purposes and to prevent fraudulent actions, the following activities are logged: date and time of registration and authorizations, date and time of subscription and purchase of other types of services provided, date and time of sending messages, date and time of deleting the profile page.",
        "text-35": "5. Legal basis for processing of Personal Data",
        "text-36":
            "5.1. The Administration processes the User's Personal Data only if the User fills them out and/or sends them independently through the special forms located on the Site and its mobile platform. By filling out the relevant forms and/or sending their personal data to the Administration, the User expresses its consent to this Policy.",
        "text-37":
            "5.2. The Administration processes anonymized data about the User if it is allowed in the User's browser settings (cookie saving and the use of JavaScript technology are enabled).",
        "text-38":
            "5.3. User may voluntarily provide Personal Data when connecting to and using third-party services integrated into the Site.",
        "text-39":
            "6. Procedure for collection, storage, transfer and other processing of Personal Data",
        "text-40":
            "6.1. Security of personal data, which are processed by the Administration, is provided by implementing legal, organizational and technical measures necessary to comply in full with the requirements of applicable law in the field of protection of personal data.",
        "text-41":
            "6.2. The Administration shall take all necessary technical and organizational precautions to protect the User's personal data from unauthorized or accidental access, deletion or modification, blocking, copying, disclosure or other unauthorized actions of third parties. In case of loss or disclosure of the User's personal data, the Administration shall notify the User about the loss or disclosure of its personal data.",
        "text-42":
            "6.3. The User may be asked for Personal Data and other information by third parties, for example, when it is necessary to make a payment, or to add additional features using third-party services integrated with the Site. The user voluntarily submits personal data and other information. All personal data requested by third parties must be processed and stored in accordance with the User Agreement and the Privacy Policy of these third parties.",
        "text-43":
            "6.4. The Administration may transfer Personal Data to providers who provide services on behalf of the Administration. For example, the Administration may engage third parties to provide customer support, manage advertisements on third-party resources, send marketing and other messages on behalf of the Administration, or assist in data storage. Such third parties are prohibited from using Personal Data of Users for advertising purposes.",
        "text-44":
            "6.5. If there are any inaccuracies in the Personal Data, the User may update them on their own, by sending a notice to the Administration e-mail law@merlinface.com with the subject «Updating of Personal Data».",
        "text-45":
            "6.6. The period of processing of Personal Data is unlimited. Users may withdraw their consent to the processing of Personal Data at any time by sending a notice to the Administration via email to law@merlinface.com, marked «Withdrawal of consent to the processing of personal data». For technical reasons, the information may not be deleted immediately, but with a delay. You should also take into account that it is possible to save some information to the extent necessary to comply with legal obligations, dispute resolution, fraud prevention and protection of legitimate interests of the Administration.",
        "text-46":
            "6.7. Photos of third parties uploaded by the User are not stored and are not used by the service to personalize data or to identify the subject of Personal Data.",
        "text-47":
            "6.8. The Site is an operator of Personal Data only in respect of the data it receives directly from the User by the Site and uses only the information uploaded by the User for searching purposes.",
        "text-48":
            "6.9. The use of User Information and uploaded photos is done by transferring the photo to the Server. The Merlin algorithm dots the face to determine the parameters and expression of the features. User photos are not stored on the Server and are not used by the Service and/or third parties. Parameters of size and expression of features are stored on the Server without transfer to third parties.",
        "text-49":
            "6.10 The service accumulates and uses data on the size and expression of features in order to:",
        "text-50":
            "compare parameters of two people's traits to identify compatibility, similarity and other indicators according to the author's methodology in the Merlin algorithm;",
        "text-51":
            "to select compatible personalities for the User according to the author's methodology, embedded in the Merlin algorithm;",
        "text-52":
            "search for personality by trait parameters within the database of the Service;",
        "text-53":
            "Maintain compatibility results for future Users of the Site and App.",
        "text-54": "7. Payment policy",
        "text-55":
            "7.1. Payment for the Subscription, according to which the User uses the services of the Site, is made through the use of one of the payment systems, such as, but not limited to: Visa, MasterCard, Maestro, MIR, Yandex.Money, Webmoney, Qiwi, ApplePay, GooglePay, PayPal. These payment systems collect and store financial information in accordance with their User Agreement and Privacy Policy.",
        "text-56":
            "7.2. Administration does not keep full details of the card and does not process payments, receiving from the payment system only a notification of a successful payment.",
        "text-57":
            "7.3. The Administration may collect additional information related to the payment made by the User, including, but not limited to, transaction number, transaction time, type and expiration date of the card used for payment, and the last four digits of the card number, cardholder name, country and city in which the card was charged.",
        "text-58": "8. Cross-border transfer of personal data",
        "text-59":
            "8.1. Before transborder transfer of Personal Data, the Administration must ensure that the foreign country, to the territory of which the transfer of Personal Data is supposed to be carried out, provides reliable protection of the rights of subjects of Personal Data.",
        "text-60":
            "8.2. Trans-border transfer of Personal Data to foreign countries that do not meet the above requirements may be carried out only if the subject of Personal Data has consented in writing to the cross-border transfer of his Personal Data and / or performance of the agreement, to which the subject of Personal Data is a party.",
        "text-62": "9. Liability of the parties.",
        "text-63":
            '9.1.  If the User processes personal data of third parties, the User is solely responsible for compliance with the appropriate measures to protect personal data in accordance with the regulations on personal data (General Data Protection Regulation, GDPR), the Federal Law № 152-FZ of 27.07.2006 "On Personal Data" and other laws and regulations, including obtaining appropriate permits, placement on the Site of the User necessary documents and information.',
        "text-64":
            "9.2. In case of failure to fulfill its obligations, the Administration assumes responsibility for any losses, but not more than to the extent of the cost paid by the User under the current tariff for the Subscription, incurred by the User as a result of unauthorized use of their personal data, in accordance with Russian law, except in cases where the personal data:",
        "text-65":
            "a) have been made available to the authorities of the relevant jurisdiction;",
        "text-66":
            "b) were disclosed by a third party after they were passed by the Administration with the User's consent;",
        "text-67": "c) became public before they were lost or disclosed;",
        "text-68":
            "d) were received from a third party prior to their submission to the Administration;",
        "text-69": "e) have been disclosed with the User's consent;",
        "text-70": "f) were disclosed as a result of force majeure;",
        "text-71":
            "g) were disclosed as a result of a justified claim to the User about a violation of third-party rights and/or the User Agreement.",
        "text-72": "10. Concluding Provisions",
        "text-73":
            "10.1. The user can get any clarification on questions of interest regarding the processing of his personal data, by contacting the Administration via e-mail law@merlinface.com.",
        "text-74":
            "10.2. This document will reflect any changes in the Administration's personal data processing policy. The policy is valid indefinitely until replaced by a new version.",
        "text-75":
            "10.3. The current version of the Policy is freely available on the Internet at: https://merlinface.com/docs/privacy-policy",
    },
    "subscriptions": {
        "choose-type": "Select the subscription category you are interested in",
        "cancel": "The subscription has been successfully cancelled!",
        "success": "Subscription successful!",
        "choose-plan": "Choose a plan:",
        "junior":
            "The Junior subscription includes Gold benefits in all 4 categories: <br/>Profession and Self-Actualization, Self-Esteem and Confidence, Motivation and Energy, Relationships and Family",
        "before-charges": "before charges",
        "to-support": "contact support",
        "left": "remainder",
        "status": "Subscription status",
        "failed-to-renew": "failed to renew the subscription",
        "auto-renew-active": "auto renewal actively",
        "active-to": "active until",
    },
    "RetakeOnboarding": {
        DEFAULT: "Define my psychotype",
        PROFESSION_ORIENTATION: "Pass Professional Typing",
    },
    ConnectSocial: {
        title: "Connect social networks",
        enabled: "Connected",
        enable: "Connect",
        enterEmail: "Enter your email",
        codeSend: "A code was sent to your email. Enter it in this field.",
        youGetCode: "You will receive an email with a confirmation code",
        retryEmail:
            "If for some reason the email did not come, you can send the code again <span> in {timer} sec...</span>",
        codeError: "The code does not fit",
        retrySend: "SEND THE CODE AGAIN",
    },
    FriendActions: {
        requestSend: "Friend request sent",
        cancel: "Cancel",
        incomingRequest: "Incoming friend request, accept?",
        accept: "Accept",
        decline: "Reject",
        add: "Add",
        sendMessage: "Write a message",
        addToFriends: "Add as a friend",
        partnerRequestSend: "The partnership application has been sent!",
        makePartner: "Make partner",
    },
    RecommendedPeopleCtrl: {
        title: "Recommendations of people",
    },
    AppLoading: {
        title: "Check your Internet connection<br/>and reload the page",
    },
    ChooseFriendPhotos: {
        "analyze": "Analyze",
    },
    "ServiceCollapse": {
        "openNow": "Open now",
        confirmTitle: "Opening Recommendation",
        confirmText:
            "In order to open a recommendation right now, you need to subscribe to the PLATINUM rate.",
        confirmBtn: "Subscribe",
    },
    LandingHeader: {
        advantages: "What you get",
        steps: "How it works",
        mission: "Our mission",
        enter: "Log in",
    },
    "LandingBanner": {
        "title": "<span>Merlinface —</span> fast access to ChatGPT 4.0, YandexGPT, Dalle-3, FaceSwap, Stable diffusion, and Pika in one window",
        "list": [
            "Create captivating and unique texts",
            "Completely original images",
            "Animations that will attract your audience's attention",
        ],
        "description": "Use neural networks to start earning on social media today",
        "btn": "Try it",
    },
    "LandingAbout": {
        "title": "<span>Merlinface —</span> a platform for starting to earn with AI",
        "list": [
            {
                "1": "Find content ideas for your social networks",
                "2": "Create storytelling that will reveal you in a new way to your audience"
            },
            {
                "3": "Neural networks will help save on staff salaries",
                "4": "Create funnels for social networks"
            },
            {
                "5": "You will be able to lead multiple projects at the same time and not burn out",
                "6": "",
                "7": "Create your own new photos without a photographer and expenses on clothing"
            }
        ],
    },
    "LandingAdvantages": {
        "title": "What will you get?",
        "showVideo": "Watch video",
        "list": [
            {
                "id": 9,
                "active": true,
                "code": "gpt",
                "title": "The answer to any question from a neural network",
                "description":
                    "Our database contains over 1000 different professions. Merlin AI will help you choose the few in which you are most likely to succeed",
                big: true
            },
            {
                "id": 10,
                "active": false,
                "code": "image",
                "title": "The ability to create images based on text",
                "description":
                    "Our database contains over 1000 different professions. Merlin AI will help you choose the few in which you are most likely to succeed",
                big: true
            },
            {
                "id": 1,
                "active": false,
                "code": "professional",
                "title": "Professional Typing",
                "description":
                    "Our database contains over 1000 different professions. Merlin AI will help you choose 3 in which you will succeed.",
            },
            {
                "id": 2,
                "active": false,
                "code": "psychotype",
                "title": "Psychological Typing",
                "description":
                    "You will receive a list of all your strengths and weaknesses, as well as how to develop them.",
            },
            {
                "id": 3,
                "active": false,
                "code": "person-description",
                "title": "Description of Your Character and Behavioral Traits",
                "description":
                    "Every third user of our site finds their passion.",
            },
            {
                "id": 5,
                "active": false,
                "code": "recommendations",
                "title": "Personal Recommendations",
                "description":
                    "Merlin AI has prepared about 150 individual recommendations for you that will allow you to achieve success quickly",
            },
            {
                "id": 6,
                "active": false,
                "code": "person-recommendations",
                "title": "Recommendations from Other Platform Participants",
                "description":
                    "Merlin AI will regularly select motivators or individuals with high business compatibility for you among whom you can find a business partner, friend, or relationship partner",
            },
            {
                "id": 7,
                "active": false,
                "code": "messages",
                "title": "Links for Training in Recommended Professions\n",
                "description":
                    "You will be able to communicate with other users who are also interested in finding a partner for business, relationships, or motivation",
            },
            {
                "id": 8,
                "active": false,
                "code": "celebrity",
                "title": "Access to Celebrity Database",
                "description":
                    "You can have the opportunity to meet them in person and start collaboration, as well as compare compatibility.",
            },
        ],
    },
    "LandingMission": {
        "title": "Our <span>Mission</span>",
        "description":
            "To provide bloggers and entrepreneurs with a powerful tool for increasing earnings and reducing costs through cutting-edge neurotechnology.",
        "memberCount": "People are already in the service",
        "btn": "Join",
    },
    "LandingSteps": {
        "title": "How It Works?",
        "steps": [
            {
                "number": 1,
                "title": "Press the 'Start' button below",
            },
            {
                "number": 2,
                "title": "Confirm your phone number",
            },
            {
                "number": 3,
                "title": "Enter the chat and start communicating with the neural network",
            },
            {
                "number": 4,
                "title": "Solve real tasks and complete assignments right in the chat"
            },
            {
                "number": 5,
                "title": "Receive a list of professions suitable for you from artificial intelligence",
            },
        ],
        "result": {
            "title": "You Get Results",
            "list": [
                "Access to popular neural networks in one place",
                "The ability to get answers to any questions from Merlin AI",
                "The ability to create images based on any text"
            ]
        },
        "btn": "Start",
    },
    LandingReviews: {
        title: "What they say about Merlinface:",
    },
    MerlinLimit: {
        title: "The question limit has been reached",
        desc: "To continue communicating with Merlin AI, switch to any paid tariff",
        btn: "View tariffs",
    },
    SubscriptionsData: {
        PROFESSION: {
            TITLES: [
                {
                    id: "PROF_OPTION_1",
                    name: "Your personal descriptions for self-realization",
                },
                {
                    id: "PROF_OPTION_2",
                    name: "Personal recommendations for self-realization",
                },
                {
                    id: "PROF_OPTION_3",
                    name: [
                        "Available indicators:",
                        "Business Compatibility",
                        "Business relationship",
                        "Physical Aggression",
                        "Emotional Aggression",
                    ],
                },
                {
                    id: "PROF_OPTION_4",
                    name: "Celebrity Business Match Test",
                },
                {
                    id: "PROF_OPTION_5",
                    name: "Recruitment of business partners with Merlinface",
                },
            ],
            FREE: [
                {
                    id: "PROF_OPTION_1",
                    value: "30% open",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "1 per week",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "1 of the specified per day",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "—",
                },
                {
                    id: "PROF_OPTION_5",
                    value: ["1 per week", "1 is picked for me", "to 1 you get"],
                },
            ],
            GOLD: [
                {
                    id: "PROF_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "1 in three days",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "1 of specified per hour",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "1 per month",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "1 in three days",
                        "1 is picked for me",
                        "to 3 you get",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "1 per week",
                },
            ],
            PLATINUM: [
                {
                    id: "PROF_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "PROF_OPTION_2",
                    value: ["1 per day"],
                },
                {
                    id: "PROF_OPTION_3",
                    value: "unlimited",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "1 per week",
                },
                {
                    id: "PROF_OPTION_5",
                    value: ["1 per day", "1 gets picked", "5 hits you"],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "1 per week",
                },
            ],
        },
        SELF_ESTEEM: {
            TITLES: [
                {
                    id: "ESTEEM_OPTION_1",
                    name: "Individual Descriptions to Boost Self-Esteem",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    name: "Personal recommendations to improve self-esteem",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    name: [
                        "Available indicators",
                        "The Similarity of Characters",
                        "Business relationship",
                        "Relationships with People",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_5",
                    name: "Selection of people for experience exchange",
                },
            ],
            FREE: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "30% open",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "1 per week",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "1 of the specified per day",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "—",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: ["1 per week", "1 is picked for me", "to 1 you get"],
                },
            ],
            GOLD: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "1 in three days",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "1 of specified per hour",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "1 per month",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "1 in three days",
                        "1 is picked for me",
                        "to 3 you get",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "1 per week",
                },
            ],
            PLATINUM: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: ["1 per day"],
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "unlimited",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "1 per week",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "1 per day",
                        "1 is picked for me",
                        "by 5 you get there",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "1 per week",
                },
            ],
        },
        MOTIVATION: {
            TITLES: [
                {
                    id: "MOTIVATION_OPTION_1",
                    name: "Your personal descriptions to increase motivation",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    name: "Personal recommendations to increase motivation",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    name: "Selection of people-motivators",
                },
            ],
            FREE: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "30% open",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "1 per week",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "1 per month",
                        "1 gets picked for me",
                        "1 hits you",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "1 in three days",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "1 in three days",
                        "1 is picked for me",
                        "to 1 you get",
                    ],
                },
            ],
            PLATINUM: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: ["1 per day"],
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: ["1 per day", "1 gets picked", "1 hits you"],
                },
            ],
        },
        RELATIONS: {
            TITLES: [
                {
                    id: "RELATIONS_OPTION_1",
                    name: "Your Relationship Features",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    name: "Individual recommendations for a better relationship",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    name: [
                        "Available indicators:",
                        "Relationship Compatibility",
                        "Relations with people",
                        "General Aggression",
                        "Physical Aggression",
                        "Emotional Aggression",
                        "Social Aggression",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_4",
                    name: "Celebrity Compatibility Comparison",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    name: "Selection of partners for relationships",
                },
            ],
            FREE: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "30% open",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "1 per week",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "1 of the specified per day",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "—",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: ["1 per week", "1 is picked for me", "to 1 you get"],
                },
            ],
            GOLD: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "1 in three days",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "1 of specified per hour",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "1 per month",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "1 in three days",
                        "1 is picked for me",
                        "to 3 you get",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "1 per week",
                },
            ],
            PLATINUM: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "fully open",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "1 per day",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "unlimited",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "1 per week",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "1 per day",
                        "1 is picked for me",
                        "by 5 you get there",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "1 per week",
                },
            ],
        },
    },
    TariffJunior: {
        "title-1": "JUNIOR is active",
        "title-2": "Junior",
        desc: "The Junior subscription includes the benefits of the Gold plan in all 4 categories: <br/>Profession and self-realization, self-esteem and confidence, motivation and energy, relationships and family",
        cancelSubscription: "Cancel Subscription",
        confirmation: {
            title: "Unsubscribe",
            desc: "Are you confirming that you want to cancel your JUNIOR subscription? All benefits will be available until the end of the paid period",
            btn: "Unsubscribe",
        },
    },
    SubscriptionPeriodLabel: {
        MONTH: "per month",
        ANNUAL: "per year",
    },
    SubscriptionPeriodOptions: {
        MONTH: "monthly",
        ANNUAL: "annual",
    },
    Tips: {
        close: "Close tooltip",
        prev: "Back",
        next: "Next",
        disable: "Disable hints",
        categories: {
            PROFESSIONAL: "Profession and purpose",
            RELATIONS: "Relationships and family",
            MOTIVATION: "Motivation and efficiency",
            SELFASSESSMENT: "Self-esteem and confidence",
        },
        list: [
            {
                name: "auth",
                element: ".auth-form",
                title: "Sign up with phone number",
                subtitle:
                    "This is necessary so that only you have access to the data, and you do not need to come up with a password.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "avatar",
                element: "[data-tip='avatar']",
                title: "Set your avatar",
                subtitle:
                    "Without an avatar, the algorithm will not recommend you to other site users.",
                timeout: 2000,
                next: "recommendations",
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "recommendations",
                element: "[data-tip='recommendations']",
                title: "",
                subtitle:
                    "Here you will find recommendations that will help you in different purposes.",
                timeout: 2000,
                next: "people-recommendations",
                prev: "avatar",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "people-recommendations",
                element: "[data-tip='people-recommendations']",
                title: "",
                subtitle:
                    "Here you will find people recommended to you in different categories",
                timeout: 2000,
                next: "search",
                prev: "recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "sphere",
                element: "[data-tip='sphere']",
                title: "There are 4 main areas in which people often have problems",
                subtitle:
                    "I have already analyzed your photos and created a personal description. It remains to click on the category of interest.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "inside-sphere",
                element: "[data-tip='sphere']",
                title: "Open and Closed Sphere Categories",
                subtitle:
                    "The yellow color shows those that are already open for reading. The dark ones can be opened by subscribing to the categories of interest.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "search",
                element: "[data-tip='search']",
                title: "",
                subtitle:
                    "Through the search box, you can find any Russian celebrity and people by name or surname.",
                timeout: 2000,
                next: "notifications",
                prev: "people-recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "notifications",
                element: "[data-tip='notifications']",
                title: "Bell Notification Button",
                subtitle:
                    "It displays messages about various bonuses for active actions within the social network, notifications if someone has checked compatibility with you.",
                timeout: 2000,
                next: "subscriptions",
                prev: "search",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "subscriptions",
                element: "[data-tip='subscriptions']",
                title: "",
                subtitle:
                    "Here you will find a list of possible subscriptions and your active subscriptions.",
                timeout: 2000,
                next: false,
                prev: "notifications",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "multi-compare",
                element: "[data-tip='multi-compare']",
                title: "Select Categories",
                subtitle:
                    "You can select several categories in which you want to compare with your friends. Only those comparisons are available for which you have activated the PLATINUM plan.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
        ],
    },
    WelcomeLayout: {
        ctrl: {
            "step-1": "Test with questions",
            "step-2": "Photo test",
            "step-3": "Photo test",
            "step-4": "Professions test",
        },
        "back-to-main": "Back to main",
        "back-to-main-confirm-title": "Confirm action",
        "back-to-main-confirm-desc":
            "Are you sure you want to go back to the main page? All progress will be permanently lost.",
        "back-to-main-confirm-apply": "Back to main",
    },

    WelcomeWant: {
        title: "Congratulations, the first step has been taken.",
        description:
            "My name is Merlin and I will help you get what you really need. <br/>Please specify what you want to get:",
    },
    WelcomeTest: {
        "title-1": "Test time 15 minutes",
        "title-2": "We have identified 12 areas that suit you!",
        "title-3":
            "YOU GREAT! There are 2 stages left before new knowledge about yourself",
        "subtitle-1": "Now a little psychological test on ",
        "subtitle-2": "Let's keep trimming. Answer another one",
        "subtitle-3":
            "Last {count} questions to determine your personality type. <br/> To determine areas that suit you",
        "question-destination-1": "question",
        "question-destination-2": "question",
        "question-destination-3": "questions",
        "choose-title": "Choose the option that best describes you:",
        "next-question": "Next question",
        "quote-1": "In every question you will find yourself",
        "quote-2":
            "To have the power to choose what you like and not give up. Otherwise, it's better to die. (c) Albert Camus",
        "quote-3":
            "A perfect person looks for everything in himself, an insignificant person - in others. (c) Confucius",
    },
    WelcomePhotoTest: {
        title: "1 stage left until the result",
        subtitle: "PSYCHOLOGICAL TEST to determine your strengths",
        "choose-title": "Which face do you like best?",
        "next-btn": "Next Photos",
    },
    WelcomeCheckPay: {
        "title-success": "Payment was successful",
        "title-error": "Payment error",
        "description-success":
            "You will be redirected to the next step in 5 seconds...",
        "description-error": "You must make a payment to continue",
        "btn-success": "continue",
        "btn-error": "Retry",
    },
    WelcomeAnalyze: {
        title: "Analyzing Answers",
    },
    WelcomePhotos: {
        title: "IMPROVE YOUR RESULTS",
        subtitle:
            "Your answers are analyzed by a neural network algorithm.<br/> Upload your photos to get a more accurate selection of professions.",
        "upload-photos-title":
            "Upload your photos for a more accurate job match",
        "next-btn": "Next",
        "skip-btn": "Skip",
    },
    WelcomeAnalyzeReady: {
        title: "I managed to remove 170 inappropriate professions.",
        subtitle: "In 10 minutes we will choose the best professions for you.",
        desc: "Don't waste time on areas that are not suitable.<br/> The test will help speed up the process of employment<br/> 90% of people who passed the test found their favorite job and realized themselves",
        quote: "Any destination requires implementation. (c) Aristarkh Romashin",
        "start-btn": "Start",
    },
    WelcomeProfessionVote: {
        "title-1": "",
        "title-2": "Great! {count} {decl} left out of 1150",
        "title-3": "Great! We're close",
        "title-4": "Click on a new profession and we'll continue",
        "subtitle-1":
            "In this circle, I have identified areas of activity in which you could maximize yourself.<br/>I left {count} {decl} out of 1150. Help me choose the best one for you.<br/> <br/>Click on the section highlighted in yellow",
        "subtitle-2": "Click on the section highlighted in yellow",
        "subtitle-3": "{count} {decl} left of 1150",
        "subtitle-4": "Only {count} {decl} left out of 1150",
        "profession-declination-1": "profession",
        "profession-declination-2": "professions",
        "profession-declination-3": "profession",
    },
    ProfessionVotePopup: {
        "help-1":
            "Great. I remember your answer. Now fill in the next few professions and click Calculate.",
        "help-2":
            "If you don't know what the profession is, just click on the name.",
        "help-3":
            "Here it is written why you might be interested in doing this and what qualities of yours can help you in this.",
        "help-4":
            "This is an example of a profession where you could excel. Please rate how interesting it is for you. <br/><br/>Evaluate thoughtfully, I will use your answers to select the best profession <br/ ><br/>Vote for the first profession",
        "error": "Please vote for the following professions:",
        "next-btn": "Continue",
        "calc-btn": "Calculate",
    },
    OnboardDescriptionPopupRadio: {
        list: [
            {
                id: 1,
                text: "Never interested",
                showSub: false,
            },
            {
                id: 2,
                text: "Never tried it, but I'm curious",
            },
            {
                id: 3,
                text: "I'm currently studying this area",
            },
            {
                id: 4,
                text: "I tried, it didn't work",
            },
            {
                id: 5,
                text: "I'm actively doing this and I'm doing well",
            },
            {
                id: 6,
                text: "I'm an expert on this with a lot of experience",
            },
        ],
    },
    WelcomeProfPay: {
        "title-1": "Out of 1150 professions, 3 suit you",
        "title-2": "Select payment method",
        "desc-title":
            "{count} people found their favorite job and increased their income",
        "desc": "87% of those who pass this test recommend it to their loved ones <br/>The test is used by top managers of 38 leading companies in the country <br/><br/>Get personal advice on how to succeed in the profession",
        "open-btn": "Open for 499 rubles",
        "motivation":
            "You've already invested 15 minutes, the last step is left!",
        "recreate-text": {
            "btn": "Take the test again",
            "confirm-title": "Restart the test?",
            "confirm-desc":
                "If you think that you did not pass the test correctly, you can take it again again. Attention: the progress of the passed test will be permanently deleted.",
            "confirm-btn": "Take the test again",
        },
        partners: {
            "title": "Approved by our partners:",
            "list": [
                "Ministry of Education of the Russian Federation",
                "Innovation Promotion Fund",
                "B20 Indonesia 2022",
                "Skolkovo",
                "Merlin AI - official partner Telegram",
            ],
        },
        paymentMethods: {
            all: "Show all payment methods",
            phone: {
                title: "1. By phone number",
                copy: "copy",
                copied: "copied",
                "copy-number": "Copy number",
            },
            link: {
                title: "2. By Link",
                btn: "Go to link",
            },
            kassa: {
                title: "3. Through Robokassa",
                btn: "Pay",
            },
            qr: {
                title: "4. By QR code",
                btn: "Select",
            },
        },
        "after-pay": "After payment, check the box so we can check the payment",
        "after-pay-label": "Paid 499 rubles",
        "after-pay-btn": "Continue",
    },
    WelcomeProfessions: {
        "title": "We've identified the areas you're most likely to<br/>",
        "title-1":
            "Great!<br/>We have identified professions that suit you best",
        "title-2": "Great!<br/>Now",
        "title-3": "Great!<br/>Now",
        "subtitle-1":
            "Click on a profession that interests you <b class='gold'>more</b> others",
        "subtitle-2":
            "The more connections - the more suitable the sphere<br><br>Click on any sphere and you will see suitable professions",
        "title-helper": "professions from the realm",
        "these": "These",
        "merlin-help":
            "I'll help you learn them. There are some great people on Merlinface who are already good at it. I'll introduce you to them. <br/><br/>But I have one more thing right now...",
        "more-btn": "Learn",
        "next-btn": "Continue",
    },
    WelcomeBusinessCardInfo: {
        "info-title": "Fill in some information about yourself",
        "edu-title": "Fill in your education information",
        "career-title": "Fill in your job information",
        "subtitle":
            "This information will be seen by other users when they visit your page. This may include your potential business partner",
        "all-fields-error": "All fields are required",
        "edu-fields-error": "Add at least one study location",
        "career-fields-error": "Add at least one job",
    },
    WelcomeBusinessCardPay: {
        loading: "Loading...",
    },
    WelcomeStart: {
        "title": "Fill out your profile carefully!",
        "subtitle":
            "The more accurate the information, the more accurate the result",
    },
    WelcomePersonInfo: {
        title: "Very nice, ",
        subtitle: "Fill in some information about yourself:",
        age: "age",
        "age-placeholder": "How old are you?",
        "age-error": "Enter an age between 14 and 99",
        "choose-country": "Choose a country",
        "choose-city": "Choose a city",
        "country-placeholder": "Search by country",
        "city-placeholder": "Search by city",
        "city-no-options":
            "Enter the name of the city to search in the selected country",
        "social-title": "Your social status:",
        "social-error": "Select social status",
        "next-btn": "Continue",
    },
    WelcomeTestComplete: {
        title: "I Determined Your Psychotype",
        "your-type": "We have {count} {dec} on our site with your psychotype",
        "your-partners":
            "{count} {decl} with high business compatibility with you.",
        "celebrity":
            "{count} celebrities and successful people of your personality type.",
        "next-btn": "Continue",
        typeDescriptions: {
            "Mediator":
                "Your natural talents make you a great mediator. You are able to resolve conflicts and find common ground with different people. Your ability to listen and understand the needs of others makes you a valuable member of any team. Your attention to detail and desire for harmony leads to strong and productive relationships.",
            "Communicator":
                "You have a unique gift for communication. Your skills enable you to communicate effectively, express your thoughts and ideas while respecting the views of others. Your ability to make communication easy and enjoyable helps you meet professional challenges.",
            "Connector":
                "You are a great connector, able to connect people and ideas. Your social adjustment skills and attention to others allow you to be at the centre of professional networks. Your organisational skills help you build successful teams and projects.",
            "Hedonist":
                "You find joy and enjoyment in professional life. Your skills enable you to enjoy your work and achieve excellent results. You value comfort, harmony and find ways to make your work process as enjoyable and efficient as possible.",
            "Diplomat Invader":
                "You have a unique gift for communication and the ability to influence people. Your talents allow you to find common ground in all environments and to get things done, which makes you extraordinarily effective in the professional sphere. You are achievement and success-oriented, and you aspire to lead and make a positive impact. Equally importantly, you can negotiate with competence and tact, which enables you to meet the most complex challenges.",
            "Lobbyist":
                "You have unique skills of persuasion and influence. Your abilities allow you to make contacts easily, to win over those around you and achieve your goals. You are able to manage processes, build strategies and lobby interests. Your ability to influence others makes you an asset in any industry. Your initiative and organisational skills allow you to work successfully in difficult environments and overcome obstacles.",
            "Politician":
                "Your ability to lead and influence allows you to be successful in the political arena. You are able to make important decisions, resolve conflicts and find common ground with people of different views. Your active approach to life, desire for change and ability to involve others in your ideas make you an effective politician. Your sociability and self-confidence allow you to achieve your goals and make meaningful contributions to public processes.",
            "Materialist":
                "You value material prosperity and are able to work for results. Your pragmatism and sense of purpose keep you afloat at all times. You are capable of managing resources, planning and executing your plans effectively. Your organisational, optimisation and decision-making skills make you indispensable to any team or project.",
            "Conservative":
                "Your talents and skills fit perfectly with your role as a conservator. You value stability, order and tradition, and can bring these values into any professional field. You have the ability to organise and systematise while maintaining attention to detail. Your efforts and persistence in achieving goals create solid and stable structures.",
            "Adherent":
                "You are ideally suited to the role of commitment builder. Your skills and talents enable you to be loyal and reliable in achieving common goals. Your diligence, responsiveness and teamwork skills make you an indispensable contributor to any project.",
            "Guardian":
                "Your ability to look after others and maintain order makes you an ideal custodian. Your skills enable you to retain values and resources, while your responsibility and discipline help you provide stability and reliability in any situation.",
            "The mass-minded":
                "With your talents and skills, you are uniquely suited to the role of an entertainer. You have a unique ability to uplift, organise and engage people with interesting ideas. Your creativity and enthusiasm help you adapt quickly to new situations and tackle challenges with a high level of motivation. Your energy and empathy make you a favourite member of any team.",
            "Organiser":
                "You have natural organisational and planning skills. Your communication and resource management skills enable you to coordinate people and tasks effectively. You know how to organise work so that it is not only productive but also enjoyable for everyone involved. Your leadership skills and ability to maintain a team spirit make you a vital element in any project.",
            "Optimist":
                "Your optimism and positive outlook on life give you an advantage in any professional field. Your ability to see the bright side of any situation and your ability to cheer up others allow you to quickly find solutions and cope with difficulties. Your enthusiasm and buoyancy bring positive energy to the team, increasing overall performance.",
            "Activist":
                "Your energy, engagement and ability to influence people makes you the perfect activist. Not only are you able to actively participate in the process, but also to involve others in it. Your communication and organisational skills help you successfully promote your ideas and achieve your goals. Your enthusiasm and self-confidence inspire others and allow you to achieve great results.",
            "Inspirationalist":
                "Your ability to see the world in a broader context and your creative approach to problem solving make you an ideal inspirational thinker. Your ideas can ignite a spark in people's hearts, encouraging them to do new things. Your intuition and keen sense of opportunity enable you to anticipate trends and create innovative solutions.",
            "Romantic":
                "Your sensitivity and deep perception of the world make you a true romantic. You have the unique ability to see the beauty in the ordinary and translate your emotions into your work. Your ability to inspire and inspire others can be the key to new ideas and perspectives.",
            "Philanthropist":
                "You have a natural ability to perceive beauty and harmony and the ability to support and develop creative ideas. Your desire to help others, support their creativity and respect for the arts makes you the ideal patron of the arts.",
            "Dreamer":
                "Your ability to generate ideas, to tap deeply into emotions and to see the big picture makes you the perfect dreamer. You are not afraid to dream and let your ideas fly. Your inspiration and imagination allow you to see prospects and new possibilities wherever you direct your efforts.",
            "Idea Leader":
                "Your ability to generate and implement new ideas, combined with your ability to inspire and motivate others, makes you an ideal thought leader. You are able to see beyond the usual and open up new horizons for yourself and your team. Your forward-thinking, creative approach and a relentless pursuit of novelty become a driving force for those who follow you.",
            "Image-maker":
                "Your creative vision and keen sense of style allow you to create and maintain images that influence people. You have a unique ability to express ideas and values through external image, creating powerful and memorable experiences. Your sense of beauty, ability to adapt to new trends and creative approach to visual style make you an integral part of any team.",
            "Idealist":
                "Your ability to generate and develop original ideas makes you the perfect idea man. Your creative approach to problem-solving, out-of-the-box thinking and ability to see opportunities where others see obstacles help you create innovative solutions and put them into practice. Your ideas inspire others and drive change.",
            "Initiator":
                "Your energy and drive for change make you an ideal instigator. You have a unique ability to launch new projects, integrate different ideas and lead people towards a common goal. Your ability to start something new in spite of obstacles and your openness to new ideas make you indispensable in any project, especially those that require innovative approaches.",
            "Preacher":
                "Your conviction, your sensitivity to the nuances of others' emotional state and your sincere desire to make the world a better place make you an ideal preacher. Your passion for helping others and your ability to connect deeply with their feelings allow you to interact effectively with people and have a significant impact on them. You see into the heart of things and can communicate your thoughts and ideas clearly.",
            "Moralist":
                "You have a deep ethical awareness and a desire for rightness and justice, which makes you an ideal moralist. Your understanding of human nature, respect for values and principles, and your desire to improve the world around you make you indispensable to any team or organisation.",
            "Educator":
                "Your passion for learning and your deep understanding of human nature make you an ideal educator. You have the skills to get to the heart of problems and offer solutions, as well as the ability to help others discover and develop their abilities. Your ability to be patient, considerate and sensitive ensures your students' respect and trust.",
            "Emotional leader":
                "Your emotional sensitivity, strong influence on others and ability to make close connections make you the ideal emotional leader. Your ability to capture and reflect the emotions of those around you, and to support and motivate your team, can greatly increase their productivity and satisfaction levels.",
            "Enthusiast":
                "Your energy, openness and optimism allow you to inspire and motivate those around you. You easily bring enthusiasm and positive emotion to any work you do. Your ability to see a bright future and share that vision with others makes you an integral member of any team.",
            "Mentor":
                "Your sensitivity to the needs of others and your ability to approach people makes you an ideal mentor. Your patience, empathy and ability to pass on your knowledge and experience help you train and inspire others to achieve their goals.",
            "Artist":
                "Your creativity, emotional expression and unique world view make you an ideal artist. Your ability to express yourself and think creatively, as well as your ability to see the beauty around you and communicate it to others, allow you to create unique and influential works.",
            "Master":
                "Your detailed orientation, practical thinking and problem-solving skills make you the perfect craftsman. You scrutinise every detail, trying to achieve maximum quality in your work. Your ability to work with your hands, improve and repair things is very valuable in many professions.",
            "Optimiser":
                'Your drive for efficiency, practicality and ability to find rational solutions make you the perfect optimizer. You are always looking for ways to improve processes, to make work easier and more efficient. Your ability to see the "big picture", to analyse complex systems and optimise their operation is your key asset.',
            "Technician":
                "Your technical awareness, ability to think analytically, and ability to understand complex systems make you the ideal technician. You have an ability to work with technology, understand how it works and use it to solve practical problems. Your ability to learn quickly and adapt to new technologies is a great advantage.",
            "Smart":
                "Your manual dexterity, practicality and creative approach to problem solving make you the ideal handyman. You have the ability to create, repair and improve things with your own hands. Your attention to detail, ability to work with materials and tools, and your passion for creating something new and useful are your strengths.",
            "Pushy grabber":
                'Your determination, ability to make quick decisions and impressive leadership make you the ideal "assertive invader". You can see opportunities where others see obstacles, and you are always ready to take on challenges. You motivate and inspire your team to achieve goals, even when it requires great effort and risk.',
            "Leader":
                "Your courage, self-confidence and ability to mobilise people makes you the ideal leader. You are always confident in your decisions and know how to inspire confidence in your team. Your natural leadership, courage and ability to take responsibility in difficult situations are your key qualities.",
            "Legionnaire":
                "Your tenacity, teamwork skills and physical stamina make you the perfect legionnaire. Your drive, coping skills and will to win help you achieve your goals. Your desire to always be at the forefront and your ability to stand your ground, even in the most difficult circumstances, make you stand out from the rest.",
            "Inspector":
                "Your skills of accurate and detailed analysis, love of order and systematisation, and resilience and predictability make you ideal for roles requiring close supervision and examination. Your ability to think critically and enforce standards and procedures leads to high quality work.",
            "Controller":
                "Your ability to remain calm under stress, your attention to detail and penchant for perfectionism enable you to successfully oversee and manage complex processes. You are adept at ensuring that rules and standards are adhered to, which contributes to the efficiency and safety of your projects.",
            "Stabiliser":
                "Your resilience to change, reliability and commitment to purpose ensure stability and consistency in any team or project. Your ability to establish and maintain order and your desire for stability and predictability make you an invaluable member of a team in any field.",
            "Engineer":
                "Your systems and analytical thinking skills, creative approach to problem solving and love of detail make you an ideal candidate for engineering and technical roles. Your ability to work accurately with detail, your ability to understand and improve complex systems and your ability to adapt to new technologies ensure your success in the technical field.",
            "Manager":
                "Your organisational, planning and management skills, as well as your ability to communicate and delegate effectively, make you the ideal manager. You are able to coordinate teams, manage resources and successfully achieve goals, even in the face of uncertainty.",
            "Practitioner":
                "Your hands-on approach to work, your ability to see and seize real opportunities, and your penchant for concrete and measurable results indicate that you are a true practitioner. Your ability to bring ideas to life, take practical steps and act realistically are your key assets.",
            "Implementer":
                "Your ability to execute plans effectively, your commitment to purpose and your hands-on approach to work make you an ideal implementer. You have a unique skill for bringing ideas to life and achieving concrete results, which is important in any organisation.",
            "Administrator":
                "Your organisational skills, attention to detail and ability to coordinate multi-tasking make you an excellent administrator. Your ability to manage resources, establish and maintain order and your ability to ensure efficient processes are the foundation of your professional success.",
            "Grey Cardinal":
                "Your ability to think strategically, your penchant for analysis and your ability to calculate all possible scenarios make you an ideal candidate for the role of 'grey cardinal'. You are able to work behind the scenes, influencing the decision-making process while remaining invisible.",
            "Forecaster":
                "Your attention to detail, analytical thinking and ability to predict trends make you an excellent forecaster. Your ability to see the big picture, analyse information and anticipate future events or trends is the basis for successful strategic planning.",
            "Sceptic":
                "Your ability to think critically, analyse and be sceptical makes you a good sceptic. You are always ready to question established views and ideas, analysing them from different angles and identifying their weaknesses.",
            "Critic":
                "Your subtle ability to critically evaluate and analyse information, as well as your desire for quality and accuracy, makes you an ideal critic. You are able to spot shortcomings and suggest improvements, which helps in the constant pursuit of excellence and development.",
            "Innovator Enthusiast":
                "Your tireless energy, drive for new things and enduring enthusiasm for discovery set you apart as an enthusiastic innovator. You are always looking for new ideas, open to experimentation and willing to take risks to implement something new and breakthrough. This gives you the ability to overcome obstacles, rethink conventional boundaries and go beyond the boundaries of the conventional. You bring liveliness and innovation to any team you're involved in, and your ideas are often the source of innovation.",
            "Researcher":
                "Your curiosity, your interest in self-improvement and your ability to analyse situations deeply make you a true researcher. Your thirst for knowledge and your ability to ask the right questions lead to a deeper understanding of situations and problems. You have a knack for finding connections where others fail to see them, giving you the ability to develop new strategies and approaches. Your research skills and your desire to get to the heart of things are the basis for development and innovation.",
            "Generator":
                "Your creativity, ability to generate ideas quickly and drive for constant renewal and progression make you a great generator. Your ideas often become a catalyst for change, and your drive for creativity helps maintain an innovative culture within your team. You have a unique ability to spot opportunities ignored by others, and this ability to generate ideas can be a catalyst for growth and development in any organisation or project.",
            "Analyst":
                "Your talent for analysing and breaking things down makes you an indispensable analyst. You always see how each element affects the big picture, and your ability to objectively assess information and data allows you to offer insightful and informed insights. Your analytical skills help organisations make data-driven decisions and develop effective strategies.",
            "Systematiser":
                "Your penchant for order, structure and systematisation makes you an excellent systematiser. Your ability to put information in order, make connections and create clear and logical systems gives you the ability to transform chaos into structure. This helps organisations improve their processes, increase efficiency and boost productivity.",
            "Perfectionist":
                "Your commitment to flawlessness, thoroughness and focus on detail make you the perfect perfectionist. You don't stop until the job is done as well as possible. Your high standards and relentless pursuit of quality affect all your projects, which brings tangible results in the form of high-quality work.",
            "Innovator":
                "Your ability to analyse and optimise makes you a great innovator. You always find ways to make things more efficient and effective. Your passion for optimisation and your skill at turning the complex into the simple helps organisations to increase efficiency and improve the quality of their services or products.",
            "Entrepreneurial innovator":
                "Your ability to see the big picture, to find opportunities where others don't, and your openness to new ideas define you as an entrepreneurial innovator. You are not afraid to take risks, and your ambition and energy help you translate your ideas into reality. Your entrepreneurial mindset can be a catalyst for growth and innovation in any organisation or project.",
            "Algorithmist":
                "Your ability to process information, create algorithms and organise knowledge makes you uniquely algorithmic. You bring logic and structure to any process, allowing organisations to work more efficiently and predictably. Your algorithm-oriented thinking leads to increased productivity and quality of work.",
            "Integrator":
                "Your ability to connect people, ideas and resources defines you as an integrator. You are able to see how different elements interact and influence each other, which allows you to build stronger and more effective teams. Your commitment to integration helps organisations create synergies and improve their performance.",
            "Experimenter":
                "Your penchant for hypothesis testing, assumption testing and continuous improvement makes you a skilled experimenter. You are not afraid of making mistakes and see them as a learning opportunity. Your ability to experiment and your patience with uncertainty can help organisations evolve and adapt in the face of constant change.",
        },
    },
    WelcomeSubscribe: {
        title: "I will promote you, help you achieve success and earn money in the chosen areas",
        subtitle:
            "Before you go to your profile - there is a great offer for you right now<br/><br/> What it includes:",
        pay: "Get access to everything for {price} RUB/month",
        "to-profile": "Go to profile",
        list: [
            {
                id: 1,
                icon: "people-recommendations",
                text: "Every day you will be recommended by 5 users from the site with whom you have excellent business compatibility.",
            },
            {
                id: 2,
                icon: "text-recommendations",
                text: "Get a deep analysis of your psychotype and its decoding",
            },
            {
                id: 3,
                icon: "brain",
                text: "Get a description of your personality and behavior.",
            },
            {
                id: 4,
                icon: "environment",
                text: "More than 10 recommendations for your psychotype to develop in this business",
            },
            {
                id: 5,
                icon: "stars",
                text: "You will have access to a celebrity database and you can have the opportunity to meet them in person or start a collaboration or learn about their character.",
            },
            {
                id: 6,
                icon: "photo",
                text: "You will be able to check any person on the site and off site for business compatibility.",
            },
        ],
    },
};

/* eslint-disable */

export default {
    "newbie": "Аты жоқ",
    "logout": "Шығу",
    days: "күн",
    "onBoardingMixin": {
        "title-1": "Психотиптің анықтамасы",
        "title-2": "Табыс",
        "description-1":
            "Merlin AI сіздің психотипіңізді анықтағаннан кейін, сіз және сіздің парақшаңыздың барлық қонақтары оны көресіз. Мерлин де сізді әлеуметтік желінің басқа пайдаланушыларына ұсына бастайды,",
        "description-2": "Сіз сертификат алғыңыз келетініне сенімдісіз бе?",
    },
    "errors": {
        "unknown-error": "Белгісіз қате",
        error: "Произошла ошибка",
        unknownError: "Белгісіз қате",
        attention: "Назар аударыңыз",
        wrongPhotoTitle: "Фото сәйкес келмеді",
        noAccess: "Қатынау мүмкін емес",
        alreadyFriendsTitle: "Сіздер уже доссыздар!",
        info: "Ақпарат",
        reEnter: "Өз аккаунтыңызға қайта кіріңіз немесе бізбен хабарласыңыз.",
        notEnoughMoneyTitle: "Қаражат жеткіліксіз",
        compareNotAllowedTitle: "Салыстыруға рұқсат етілмейді",
        retry: "Қате орын алды, әрекетті кейінірек қайталаңыз.",
        noAccessText:
            "Сіз өзіңізге рұқсат етілмеген функцияны пайдаланбақ болып отырсыз.",
        personDataNotReceived:
            "Жеке деректер алынғанда қате орын алды, бетті қайта жүктеу қажет болуы мүмкін.",
        wrongProfilePhoto:
            "Профиль үшін фото сай келмейді, басқа фото жүктеңіз. Үлгі варианттарына назар аударыңыз.",
        wrongPhotoToTheRight:
            "Басыңызды оңға тым көп бұрғансыз, қайталап көріңіз.",
        wrongPhotoToTheLeft:
            "Фото сәйкес келмеді, басыңызды солға тым көп бұрғансыз, қайталап көріңіз.",
        wrongPhotoToTheBottom:
            "Фото сәйкес келмеді, басыңызды тым төмен иіп алғансыз, қайталап көріңіз.",
        wrongPhotoFewPersons:
            "Фотосуретте бірнеше адамның беттері бар, тек қажетті бетті қалдырып, фотосуретті қиып алыңыз",
        wrongPhotoToTheUp:
            "Фотосурет сәйкес келмейді, басыңызды тым жоғары көтеріпсіз, қайталап көріңіз.",
        wrongPhotoSame:
            "Сіз еңбектеу және профиль үшін бірдей фотосуреттерді жүктедіңіз, басқа фотосурет жүктеңіз",
        wrongPhotoFeatures:
            "Сіздің фотосуреттеріңізден бет бітімдерін алу мүмкін болмады. Осы екеуін ауыстырыңыз.",
        wrongPhotoYouAreNot:
            "Жаңа фотосуретте Сіздің бетіңіз табылмады. Өз бетіңізбен фото жүктеңіз",
        wrongAnfasPhoto:
            "Еңбектеу фотосуреті сәйкес келмейді, басқа фотосурет жүктеңіз. Үлгі варианттарына назар аударыңыз.",
        wrongCelebrityPhoto:
            "Таңдаған тұлғаның фотосуреттерін талдау кезінде қате пайда болды. Пайдаланушы дұрыс фотосуреттерді жүктегеннен кейін салыстыру және сипаттама қолжетімді болады",
        photoNotUploaded:
            "Фотосуретті жүктеу мүмкін болмады, кейінірек қайталап көріңіз...",
        myPhotoNotUploaded:
            "Сіздің фотосуреттеріңізді жүктеу мүмкін болмады. Өтінемін, бетті жаңартыңыз.",
        paymentsListNotReceived:
            "Төлемдер тізімін алу мүмкін болмады, кейінірек қайталап көріңіз.",
        incorrectPaymentData:
            "Төлем деректері дұрыс емес. Оның ұстында, кейінірек қайталап көріңіз.",
        paymentGateWayNotRespond:
            "Төлем шлюзы жауап бермейді. Өтінемін, кейінірек қайталап көріңіз.",
        friendStatusNotReceived:
            "Дос статусын алу мүмкін болмады, кейінірек қайталап көріңіз.",
        pageError:
            "Осы бетпен байланысты белгісіз проблема пайда болды, бетті жаңартып, қайтадан әрекет жасап көріңіз.",
        friendRequestError:
            "Достық сұрауын жасай алмайсыз, кейінірек қайталап көріңіз.",
        alreadyFriends:
            'Егер сіз "Достарға қосу" батырмасын көрсеңіз, бетті жаңартыңыз.',
        alreadySendFriendRequest:
            "Сіз бұрын сұрау жібердіңіз. Досыңыздың сұрауды қабылдауды күтіңіз.",
        friendRequestDecline:
            "Өкінішке орай, Сіздің достық сұрауыңыз қабылданбады. Сіз соңғы әрекеттен кейін 24 сағат өткен соң, қайтадан сұрау жібере аласыз.",
        notificationsNotReceived:
            "Сіздің хабарландырулар тізіміңізді алу мүмкін болмады, кейінірек қайталап көріңіз.",
        servicePriceNotReceived:
            "Қызмет бағасын алу мүмкін болмады. Бетті жаңартып, әрекетті қайталаңыз.",
        needAuth: "Осы әрекетті жасау үшін авторизация қажет",
        notEnoughMoney:
            "Өтініш, шотыңызды кез келген ыңғайлы тәсілмен толтырыңыз.",
        personListNotReceived:
            "Тұлғалар тізімін алу кезінде қате орын алды, қайталап көріңіз.",
        friendListNotReceived:
            "Достар тізімін алу кезінде қате орын алды, қайталап көріңіз.",
        badName:
            "Таңдалған ат сәйкес келмеді, басқаларын көріп немесе кейінірек қайталап көріңіз.",
        nameLimit: "Атты өзгерту тек 24 сағат сайын ғана мүмкін.",
        friendsCount:
            "Достар санын алу кезінде қате орын алды. Сұрауды кейінірек қайталаңыз.",
        friendRequestsCount:
            "Достық сұраулар санын алу кезінде қате орын алды. Сұрауды кейінірек қайталаңыз.",
        compareNotAllowed:
            "Өзіңізді басқа тұлғалармен салыстыру үшін, өз суреттеріңізді жүктеуіңіз керек",
        photoUploadPersonIsNotFound:
            "Сіз фотосурет орнатпақ болып отырған бет табылмады",
        photoUploadPersonIsNotYours:
            "Сіз фотосурет орнатып жатқан бет сізге тиесілі емес.",
        yourFaceIsNotFound:
            "Жаңа фотосуретте сіздің бет-әлпетініз табылмады, өтініш, бет-әлпетіңіз бар фотосуретті жүктеңіз.",
        partnerStatusNotReceived: "Серіктестік статусын алу мүмкін емес.",
        uuidError:
            "Қолданушының дұрыс емес ID-і берілді, әкімшіге хабарласыңыз.",
        alreadyPartner: "Пайдаланушы алдағыда сіздің серіктесіңіз.",
        waitingResponse:
            "Өтініш бұрын жіберілген, серіктесіңіздің шешімін күтіңіз.",
        partnerRequestDeclined:
            "Сіздің сұрауыңыз қабылданбады, сұрауыңызды соңғы сұраудан кейін 24 сағат өткен соң қайталауға болады.",
        partnerResponseAlreadySend:
            "Өтініш табылмады. Мүмкін сіз оны бұрын қабылдадыңыз.",
        partnerResponseNotSend:
            "Қате мәліметтер жіберілді. Техникалық қолдауға жүгініңіз",
        createDescriptionError:
            "Сіздің жеке басыңызды сипаттауды құру мүмкін емес. Техникалық қолдауға жүгініңіз",
        firstUploadAnfas: "Алдымен бет-әлпет фотосын жүктеу қажет",
        descriptionNotReady:
            "Merlin бет-әлпеттің сызбанұсқаларын бет-әлпет фотосынан оқып шығғанша, профильге фотосурет жүктеуге болмайды. Бір минуттан кейін қайталаңыз.",
        REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Мақсатты жабу туралы ұсынысты күніне бір реттен артық жіберуге болмайды",
        BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Кәсіпкерлік ұсыныстарды күніне бір реттен артық жіберуге болмайды",
        AVATAR_LIMIT_REACHED:
            "Сіз тәуліктік фотосурет жүктеу шегіне жеттіңіз. Ертең қайтадан көріңіз.",
    },
    "cookies": {
        "prefix-text": "Біз жинаёмыз ",
        "text": "Cookies",
        "btn-text": "OK",
    },

    "DescriptionPopup": {
        "learn-about": "Ол адам туралы оқыңыз:",
        "light-background": "Жарық фон",
        "dark-background": "Қара Фон",
        "start-learn-profession": "Бүгін қажетті мамандықты өгренуге бастаңыз:",
        "start-learn-profession-btn-text": "Бастау",
    },
    MerlinDating: {
        "title":
            "{name} ж personality  танысу үшін қадаммен-қадам алгоритм қалайсыз ба?",
        "subtitle": "Біздің қолданбамызды жүктеп алыңыз.",
    },
    "FooterLinks": {
        about: "Жоба туралы",
        contacts: "Байланыс",
    },
    "FriendsFilter": {
        "gender": {
            all: "Барлығы",
            male: "Ер адамдар",
            female: "Әйелдер",
        },
        age: {
            any: "Кез келген",
            interval_1: "18 жастан 24 жасқа дейін",
            interval_2: "25 жас жастан 34 жасқа дейін",
            interval_3: "35 жас жастан 44 жасқа дейін",
            interval_4: "45 жас және одан асырақ",
        },

        typeList: [
            {
                id: "ALL",
                name: "Кез келген",
            },
            {
                id: "ADMINISTRATOR",
                name: "Әкімші",
            },
            {
                id: "ACTIVIST",
                name: "Активист",
            },
            {
                id: "ALGORITHMIST",
                name: "Алгоритмист",
            },

            {
                id: "ARTIST",
                name: "Өнеркәсі",
            },
            {
                id: "MASTERMIND",
                name: "Бастамашы ақылды",
            },
            {
                id: "HEDONIST",
                name: "Гедонист",
            },
            {
                id: "GENERATOR",
                name: "Генератор",
            },
            {
                id: "DIPLOMAT_INVADER",
                name: "Дипломатты Басқыншы",
            },
            {
                id: "IDEOLOGIST",
                name: "Идеан",
            },
            {
                id: "IDEOLOGICAL_MASTERMIND",
                name: "Бастамашы ақылды",
            },
            {
                id: "IDEOLOGICAL_LEADER",
                name: "Ойлама Басшы",
            },
            {
                id: "IMAGE_MAKER",
                name: "Сурет жасаушы",
            },
            {
                id: "ENGINEER",
                name: "Инженер",
            },
            {
                id: "INITIATOR",
                name: "Баслаушы",
            },
            {
                id: "INSPECTOR_PERFECTIONIST",
                name: "Толықтаушы инспектор",
            },
            {
                id: "INTEGRATOR",
                name: "Интегратор",
            },
            {
                id: "RESEARCHER",
                name: "Жаттығушы",
            },
            {
                id: "RESEARCHER_ANALYST",
                name: "Жаттығушы аналитик",
            },
            {
                id: "RESEARCHER_ANALYST",
                name: "Байланысшы",
            },

            {
                id: "CONNECTOR",
                name: "Байланыш",
            },
            {
                id: "CONTROLLER",
                name: "Контроллер",
            },
            {
                id: "CRITIC",
                name: "Сынапшы",
            },
            {
                id: "LEGIONARY",
                name: "Легионер",
            },
            {
                id: "LEADER",
                name: "Лидер",
            },
            {
                id: "LOBBYIST",
                name: "Лоббист",
            },
            {
                id: "MASS_MEDIA_ENTERTAINER",
                name: "Массовик тамашалаушы",
            },
            {
                id: "MASTER_IMPLEMENTOR",
                name: "Жобаны есепші",
            },
            {
                id: "MATERIALIST",
                name: "Материалист",
            },
            {
                id: "MAECENAS",
                name: "Меценат",
            },
            {
                id: "DREAMER",
                name: "Армандар",
            },
            {
                id: "MORALIST",
                name: "Моралист",
            },
            {
                id: "MENTOR",
                name: "Ментор",
            },
            {
                id: "ASSERTIVE_INVADER",
                name: "Баскымметтік завоеватель",
            },
            {
                id: "INFORMAL_LEADER_MANIPULATOR",
                name: "Манипулятор жасалған лидер",
            },

            {
                id: "INNOVATOR",
                name: "Жаңғыртушы",
            },
            {
                id: "INNOVATOR_ENTHUSIAST",
                name: "Жаңғыртушы таңғамашы",
            },
            {
                id: "OPTIMIZER",
                name: "Оптималдағычы",
            },
            {
                id: "OPTIMIST",
                name: "Оптимист",
            },
            {
                id: "ORGANIZER",
                name: "Ұйымдатқыш",
            },
            {
                id: "EDUCATOR",
                name: "Мұғалім",
            },
            {
                id: "PERFECTIONIST",
                name: "Толықтаушы",
            },
            {
                id: "POLITICIAN",
                name: "Саясаткер",
            },
            {
                id: "MEDIATOR_DIPLOMAT",
                name: "Дипломаттік байлаушы",
            },
            {
                id: "PRACTITIONER",
                name: "Амалдаушы",
            },
            {
                id: "PRACTITIONER_CONSERVATIVE",
                name: "Консервативті амалдаушы",
            },
            {
                id: "PRACTITIONER_MANAGER",
                name: "Басқару амалдаушы",
            },
            {
                id: "ENTREPRENEUR_INNOVATOR",
                name: "Жаңғыртушы жатакшы",
            },
            {
                id: "ADHERENT",
                name: "Күшіргуші",
            },
            {
                id: "FORECASTER",
                name: "Болашақты байқаушы",
            },

            {
                id: "PREACHER_MENTOR",
                name: "Проповедник Ментор",
            },
            {
                id: "RATIONALIZATOR",
                name: "Инноватор",
            },
            {
                id: "IMPLEMENTATOR",
                name: "Реализатор",
            },
            {
                id: "ROMANTIC",
                name: "Романтик",
            },
            {
                id: "EMINENCE_GRISE",
                name: "Қызыл Кардинал",
            },
            {
                id: "SYSTEMATIZER",
                name: "Систематизатор",
            },
            {
                id: "SKEPTIC",
                name: "Скептик",
            },
            {
                id: "SOCIALIZER",
                name: "Социализатор",
            },
            {
                id: "STABILIZER",
                name: "Стабилизатор",
            },
            {
                id: "TECHIE",
                name: "Техник",
            },
            {
                id: "HANDYMAN",
                name: "Қолқыныш",
            },
            {
                id: "MANAGER",
                name: "Менеджер",
            },
            {
                id: "KEEPER",
                name: "Сақтаушы",
            },
            {
                id: "EXPERIMENTER",
                name: "Эксперименттерген",
            },
            {
                id: "ENTHUSIAST",
                name: "Энтусиаст",
            },
        ],

        searchSortList: [
            {
                id: "DATE",
                name: "Қосылған күн",
            },
            {
                id: "NAME",
                name: "Аты",
            },
        ],

        searchList: [
            {
                id: "DATE",
                name: "Қосылған күн",
            },
            {
                id: "NAME",
                name: "Аты",
            },

            {
                id: "COMPATIBILITY",
                name: "Қатысушилік",
            },
            {
                id: "SIMILARITY",
                name: "Ұқсастықтар",
            },
            {
                id: "BUSINESS",
                name: "Бизнес қатысушылығы",
            },
            {
                id: "INTERACTION_BUSINESS",
                name: "Бизнес қатынас",
            },
            {
                id: "INTERACTION_INTERPERSONAL",
                name: "Өзара қатынас",
            },
        ],

        "any": "Кез келген",
        "all": "Барлығы",
        "by-age-title": "Жасы бойынша:",
        "by-person-type-title": "Тұлға түрі бойынша:",
        "by-person-category-title": "Тұлға санаты бойынша:",
        "sort-by-title": "Сұрыптау тәртібі:",
        "sort-by-default": "Қосу күні бойынша",
        "sort-by-gender-title": "Жынысы бойынша:",
        "reset-text": "Сұзғышты қайтару",
    },

    "InviteFriends": {
        "btn-text": "Дос добавлу",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "Бұл пайдаланушы фотосуретті жүктемеді!",
        "feedback": "Әрі қарай жазу",
        "referral-program": "Қатынаушылық бағдарламасы",
        "bonus-program": "Бонустық бағдарлама",
        "legal-info-title": "Заңдық ақпарат",
        "contacts-and-requisites": "Байланыс деректері және реквизиттары",
        "about": "Компани туралы",
        "service-pay": "Қызметке төлем жасау",
        "term-of-use": "Пайдаланушы шарты",
        "privacy-policy": "Құпиядылық саясаты",
    },
    "RightAsideSocial": {
        "title": "Бөлісу",
        "vk": "VK.com",
        "ok": "ok.ru",
    },
    "loading": {
        "network-error":
            "Интернет байланысын тексеру <br/> және парақты қайта жүктеу",
    },
    "MainHeader": {
        "back-to-profile": "Профильге оралу",
        "createAccount": "Тіркеу жасау",
        "support": "Тұтынушы қызметі",
        "logout": "Шығу",
    },
    "MobileMenu": {
        support: "ҚОЛДАУ",
    },

    "NotificationsModule": {
        "all": "Барлық хабарландырулар",
        "partner": "Сізді серіктес болуға шақырылдыңыз",
        "deposit": "Депозит",
        "bonus": "Сізге бонус берілді",
        "reward": "Сілтеме бағдарламасы арқылы сыйлық",
        "referral": "{name} Merlin Dating-де сіздің сілтемеңіз болды<br/>",
        "free-period":
            "Сіздің сілтемеңіз {name} Merlin Dating-де сынақ мерзімдерге жатады {message}",
        "cancel-subscribe":
            "Сіздің сілтемеңіз {name} жазылымды {message} Merlin Dating-ден болдырмады",
        "pay-subscribe":
            "Сіз марапат аласыз. Сіздің сілтемеңіз {name} жазылымына {message} төледі Merlin Dating",
        "subscribe-error":
            "бірқатар себептермен жазылымды {0} жаңартуға болмады Merlin Dating",
        "mapp_celebrity_compare":
            "Merlin Dating ұйымдастыру покупкасына қарсы бауырлар",

        "bonus_coach_partner": "Сіздің серіктестігіңіздің әрекеті үшін бонус",
        "person_compare": "Біреу сізмен салыскан жасады",
        "compatibility": "сәйкестік",
        "similarity": "ұқсастық",
        "business": "бизнес серіктестігі үшін сәйкестік",
        "person_indicator": "Біреу белгіленді",
        "interaction_business": "бизнес ыңғайлылығының түрі",
        "interaction_interpersonal": "жеке мекенжай меншігінің түрі",
        "mma_champion": "чемпионлық потенциясы",
        "mma_potential": "спорттық көсбассыздардың потенциясы",
        "aggression_common": "сіздің жалпы агрессияңыз",
        "aggression_emotional": "сіздің эмоциялық агрессияңыз",
        "aggression_social": "әлеуметтік агрессияңыз",
        "aggression_intellectual": "интеллектуалды агрессияңыз",
        "aggression_physical": "физикалық агрессияңыз",
        "analyse_item_unlock":
            "Біреу санатқа қатысты сіздің сипатты жазбаңызды оқыды",
        "professionaltype": "Профотип",
        "neurotype": "нейротип",
        "leadertype": "басшылық",
        "character": "қасиет",
        "relations": "қатынастар және отбасы",
        "motivation": "мотивация және энергия",
        "selfAssessmen": "өзіңіздің қадір-қасиетіңіз және білісіңіз",
        "professional": "мамандық және өзін-өзі жетілу",
        "person_review_created": "Туыстық қаралы жаңа пікір дайын",
        "new_in_top":
            "Сіздің сәйкестік топта жаңадан +{{ notification.message }}",
        "phone_confirm": "Сіз бонусты қабылдадыңыз {count} $",
        "upload_photos_request": "Біреу сурет жүктеп салуыңызды сұрады",
        "upload_photos_completed": "{user} оның өзінің фотосын жүктеді",
        "coach_partner_request": "{name} сізді серіктес болуға шақырады",
        "coach_partner_request_accept": "Қабылдау",
        "coach_partner_request_decline": "Қабылдамау",
        "analyse_item_unlock_reward":
            "Сіз бонусты қабылдадыңыз {{ notification.message }} $",
        "new_in_top_show": "Көру үшін басыңыз",
        "phone_confirm_reward": "Телефон нөмірін растау үшін",
        "loading": "Жүктелуде...",
        "no-more": "Басқа хабарландырулар табылмады",
        "no-notifications": "Сізде жаңа хабарландырулар жоқ.",
        "show-all": "Барлығын көрсету",
    },
    "OfflineIndicator": {
        title: "Интернет байланысы жоқ.",
        retry: "Әрі бір рет көріп шығыңыз...",
    },

    "OnboardingDescriptionPopup": {
        text: "Осына қызықтырып отырған себебенізді және осы іске қандай сипаттарыңыздың көмектесуі мүмкін екенін айтады.",
        error: "Сіз барлық кәсіптерге дауыс бермедіңіз:",
    },
    "Search": {
        "findFriends": "Дос табу",
        "found": "Қолданушылар табылды:",
        "limit": "Кем дегенде 3 таңба енгізіңіз",
        "not-found": "Ештеңе табылмады",
        "show-all": "БАРЛЫҒЫН КӨРСЕТУ",
    },
    "SearchPeople": {
        "full-name": "Атыңызды немесе тегіңізді енгізіңіз",
        "all": "Барлығы",
        "friends": "Досыздар",
        "celebrity": "Атақты адамдар",
        "fans": "Ұланушылар",
        "type-is-not-available": "Түлкілердің түрі әлі анықталмаған",
        "limit": "Кем дегенде 3 таңба енгізіңіз",
        "empty-filter": "Көрсетілген параметрлерге лайық адамдар табылмады",
    },
    "Select": {
        nothing: "Сұранымбой жоқ",
        choose: "Таңдау",
    },

    "ServiceMenu": {
        "with-friends": "Достарыңызбен салыстырыңыз",
        "about-me": "Өзіңіз туралы көбірек білу",
        "about-another": "Төмендегі туралы көбірек білу:",
        "aggression": "Хабдарлық деңгейін анықтаңыз<br/>",
        "aggression-another": "Хабдарға деңгейі:",
        "person-aggression": "Хабдарлық деңгейі:",
    },
    "UserNav": {
        "my-page": "Менің бетім",
        "friends": "Достар",
        "compare-with-me": "Менмен салыстыру",
        "celebrities": "Әйелдер",
        "messages": "Хабарлар",
        "analyze": "Merlin AI талдау",
        "recommendations": "Адамдардың ұсынымы",
        "settings": "Баптаулар",
        "manager": "Жекелерді басқару",
    },

    "Auth": {
        "back": "Артқа",
        "another-phone": "Басқа телефон нөмірін енгізіңіз",
        "prev-step": "Артқа бару",
        "auth": "Авторизация",
        "confirm-phone":
            "Сіз шынымен адамсыз ба деп тексеру керек. Телефон нөміріңізді енгізіңіз:",
        "retry-after":
            "Ұзақ уақыт болмағанда сіз қоңырау тіледіңіз, сондықтан сіз жаңа екеуге қожеттілікке ие боласыз {{smsTimerCountdown}} секунд ішінде.",
        "not-found": "Ештенте табылмады",
        "country-code": "Ел коды",
        "phone": "Телефон нөмірі",
        "agree_1": "Телефон нөміріңізді енгізген кезде сіз ",
        "agree_and": "және",
        "agree_2": "пайдалану шарттарымен",
        "agree_3": "құпиялық саясатымен таныстық",
        "telegram":
            "Тіркеугіңізді аяқтау үшін, телефон нөміріңізді бөлісіңіз - ол Telegram-ге кіруге доступ жоғалтып кеткенде қолданылады.",
        "call": "Келесі қоңырау",
        "message": "Келесі хабар",
        "blocked":
            "Сіздің нөміріңіз жақында бұғатталды, сіз {{smsTimerCountdown}} секунд ішінде қайтадан бастай аласыз.",
        "retry-error":
            "Егер сізге ешқандай себепті ерік болмаса немесе текстілік хабар алынбаса, сіз көрсету кодын {{smsTimerCountdown}} секунд ішінде қайта сұрау жасай аласыз.",
        "telegramTitle": "Боттан сұрау алынды. Авторизация жүріп жатыр.",
        "telegramTitle2":
            "Мерлін сізге минут ішінде Telegram хабарын жібереді.",
        "enter-call-code":
            "Келесі нөмірдің соңғы 4 цифрасын төмендегі қатарға енгізіңіз.",
        "enter-message-code": "Мәтіндік хабардан кодты енгізіңіз",
        "code": "ракамды растау:",
        "confirm": "растау",
        "request": "сұрау...",
        "enter": "кіру",
        "loading": "жүктелуде...",
        "requestError":
            "Қоңырау сұрауын жіберудегі қате, бірнеше секунд ішінде қайталап көріңіз.",
        "phoneError":
            "Халықаралық форматта нақты телефон нөмірін енгіздіңіз. Өрнек, Ресей үшін: +79174246633",
        "codeExpired":
            "Сіз 60 секунд ішінде соңғы төрт рақамды енгізе алмадыңыз. Қайтадан растау процедурасына кіріңіз.",
        "codeError":
            "Дұрыс емес цифрлар, барлық ұмтылыстар бітті: {attempts} из 3",
    },

    "PhoneForm": {
        "back": "Кері",
        "another-phone": "Басқа телефон нөмірін енгізіңіз",
        "prev-step": "Кері қайту",
        "auth": "Авторлық күйге ену",
        "confirm-phone":
            "Сіз нақты адам екеніңізді тексеру қажет. Телефон нөміріңізді енгізіңіз:",
        "retry-after":
            "Сіз жақында дайындалуға сұрау жасадыңыз, сондықтан {{smsTimerCountdown}} секундтардан кейін жаңа сұрау жасауға болады.",
        "not-found": "Еш нәрсе табылмады",
        "country-code": "Ел коды",
        "phone": "Телефон нөмірі",
        "agree_and": "және",
        "agree_1": "Телефон нөміріңізді енгізу арқылы Сіз ",
        "agree_2": "қолдану шарттарымен",
        "agree_3": "құпиялық саясатымен таныс екеніңізді растаңыз",
        "telegram":
            "Тіркелуіңізді аяқтау үшін, телеграмға контактыңызды бөлісіңіз - сіз телеграмды жетістереді жоғалтсаңыз осы контакты пайдалана алады.",
        "call": "Келесі қоңырау",
        "message": "Келесі хабар",

        "blocked":
            "Сіздің нөміріңіз жуық қойылған, сіз {{smsTimerCountdown}} секундтан кейін бастай аласыз.",
        "retry-error":
            "Егер сізге бағытталмаса немесе тексттік хабарламаңыз жеткізілмесе, сіз көдесі өзге бір рет сұрау жасай аласыз {{smsTimerCountdown}} секундтан кейін.",
        "telegramTitle": "Боттан сұрау алынды. Авторизация жүргізілуде.",
        "telegramTitle2":
            "Мерлін сізге бір минут ішінде телеграммада хабарлама жібереді.",
        "enter-call-code":
            "Төмендегі келуші нөмірдің соңғы 4&nbsp;санды енгізіңіз.",
        "enter-message-code": "Тексттік хабарламадан кодды енгізіңіз",
        "code": "Растау коды:",
        "confirm": "растау",
        "request": "сұрау...",
        "enter": "енгізіңіз",
        "loading": "жүктелуде...",
        "requestError":
            "Қоңырау сұрауын жіберу қателігі, бірнеше секундтан соң қайталап көріңіз.",
        "phoneError":
            "Халықаралық форматта дұрыс телефон нөмірін енгізіңіз. Орысия үшін мысалдары: +79174246633",
        "codeExpired":
            "Сіз 60 секунд ішінде соңғы төрт санды енгізуді бағалаған жоқсыз. Растау процедурасын қайта бастаңыз.",
        "codeError": "Дұрыс емес сандар, әлі үстем бар: {attempts} үстем 3-тен",
    },

    "choose-want": {
        "choose-1-1": "Ақша",
        "choose-1-2": "мамандық",
        "choose-1-3": "өзін-өзі бастама",
        "choose-2-1": "Энергия",
        "choose-2-2": "бағыттану",
        "choose-2-3": "мағына",
        "choose-3-1": "Қатынастар",
        "choose-3-2": "отбасы",
        "choose-3-3": "тобышылар",
        "choose-4-1": "Ішки орталық",
        "choose-4-2": "өздік бағалау",
        "choose-4-3": "еншілік",
    },

    "CelebrityProgram": {
        "compare":
            "Өзіңіздікі атап бирген адамдарға қаншалықты ықпалдасыз, және егер сіз жоғары шығсаңыз, жеке ретте кездесе аласыз",
        "desc": "Бұл бағдарлама адамдарға және атап бирген адамдарға мүмкіндік береді:",
        "get-know": "Танысу",
        "collaborate": "Бірлесу",
        "talk": "Сөйлесу",
        "date": "Кездесу",
        "all": "Бағдарламадағы барлық атап бирген адамдар",
    },
    "MessagesHistory": {
        "no-messages": "Хабарлар жоқ",
        "accessError": "Сіз бұл пайдаланушыға жаза алмайсыз. Егер бұл қате деп ойласаңыз, қолдау қызметіне хабарласыңыз.",
    },
    "MessageHistoryHead": {
        "new-chat": "Жаңа чат",
        "history": "Тарих",
        "delete-chat": "Чатты өшіру",
        "disable-notifications": "Хабарландыруларды өшіру",
        "enable-notifications": "Хабарландыруларды іске қосу",
        "attempts-left": "{0} сұраныс қалды",
    },
    "ChatMessage": {
        "myMessageTitle": "сіз",
        "upscale": "Үлкейту",
        "generated-by-stable-diffusion":
            "Сурет Стабильдік таралу арқылы жасалды",
        "generated-by-midjorney": "Сурет Midjorney арқылы жасалды",
        "optimization-midjorney": "Сурет оптимизациясын жасау",
        "seconds": " сек.",
        "generated-by-midjorney-error":
            "Сурет құрастыру сәтсіз аяқталды. Не болжамай, біз қондырған мәселені білеміз және жақын арада оны шешеміз. Бірақша кейінірек көріңіз",
        "midjorney-parameters-error": "Белгісіз параметрлер берілген:",
        "midjourney-queue": "Күте тұрыңыз, сурет құрастыруға сіз {0} іздеудесіз",
        "midjorney-stress-error":
            "Серверге тым көп жүктеу. Бір минутанан кейін қайталап көріңіз",
        "midjorney-moderator-error":
            "Кешіріңіз, сурет құру сұранымаңыз ережелерді бұзған. Мен мұндай суреттерді жасамаймын, олар: \n- Кедейге кірісуі мүмкін немесе адалдықты кеміту. \n- Шатақтай сөздер немесе зорлық бар. \n- Адамдарды киімсіз немесе жарамсыз жағдайда көрсетеді. \n- Түрлі мәдениеттерде бейбіт болуы мүмкін.",
        "midjorney-unknown-error":
            "Әй, сурет құру сұранымаңызда айдағыдай не болмағанымыз төнді. \n\nЖиі кездесетін қателер үлгілері:\n1️⃣ --version 5.2 параметрін тек --style: raw арқылы пайдалану. \n2️⃣ Берілген параметрлердің дұрыстығы. Сөйтіп шығаратын параметрлер -ar, 21:9, -style, raw, -q2, -seed, 302280510 сияқтылар болуы мүмкін.\n3️⃣ Тармақтың дұрыс теңдестігі. Бұл Ш:В форматында болуы керек, масалаң, '16:9', емес 'da'.\n\nБұл тек үлгілер неліктен дұрыс емес екенінің ықшамдары. Орынды сұранымаңызды тексеріп, қайталап көріңіз.",
        "midjorney-single-image-error":
            "Тек өткеніз сурет бойынша құрастыру мүмкін емес, мәтіндік сұраныс қосып қайталап көріңіз",
        "gpt-error-try-again": "Бір не болмаған. Қайталап көріңіз",
        "download": "Жүктеу",  
    },
    "AddMessageForm": {
        "inputPlaceholder": "Хабарды енгізіңіз...",
    },
    "Chat": {
        "loading": "Диалогты жүктеу...",
        "write": "басып шығару...",
        "online": "Желіде",
        "offline": "Желіден тыс",
        "delete-chat": "Чатты өшіру",
        "disable-notifications": "Хабарландыруларды өшіру",
        "enable-notifications": "Хабарландыруларды іске қосу",
        "partner": "Сіз бизнес ұсыныс жасадыңыз",
        "partner-for-you": "Сіз бизнес ұсыныс алдыңыз",
        "target": "Сіз мақсатты жабу ұсыныс жасадыңыз",
        "target-for-you": "Сізден мақсатты жабу ұсынылды",
        "AIPlaceholder": "Суретті жасау үшін жолды енгізіңіз",
        "edit": "Өзгерту",
        "copy": "Көшіру",
        "delete": "Өшіру",
        "read": "Оқу",
        "notRead": "Оқылмаған",
        "empty-error": "Хабар бос болмауы керек",
        "length-error-min": "Хабар {min} таңбадан аз болмауы керек",
        "length-error-max":
            "Хабардың ұздығы {max} таңбадан артық болмауы керек",
        "target-message": "Сіз мақсатты жабу ұсыныс жасадыңыз",
        "partner-message": "Сіз бизнес ұсыныс жасадыңыз",
        "stable-diffusion-message":
            "Сурет жасау режимі Стабильдік таралу арқылы",
        "midjorney-message": "Сурет жасау режимі Midjorney арқылы",
        "editing": "Өзгерту",
        "confirmTitle": "Әрекетті растау",
        "confirmDeleteMessageText": "Сіз шынымен хабарды жою қажет пе?",
        "confirmDeleteAllMessagesText":
            "Сіз шынымен барлық сөйлесуді жою қажет пе?",
        "manyMessage5seconds":
            "Кәдімгі уақытта тым көп хабар, 5 секунд күтіңіз.",
        "manyMessage60seconds":
            "Бір минут ішінде 30 хабардан артық болмауы керек, шама 60 секунд күтіңіз.",
        "userDisable": "Пайдаланушы хабарларды алу мүмкіндігін шектеді.",
        "sendError": "Хабарды жіберу қателігі, қайталап көріңіз.",
        "new-chat": "Жаңа сөйлесу",
        "history": "Сөйлесу тізімі",
        "gpt-message-to-long": "Хабарыңыз тым ұзын",
    },
    "Dialogs": {
        "search": "Достарды іздеу",
        "open": "Чатты ашу",
        "nothing": "Сізде белсенді чаттар жоқ",
        "image": "сурет",
        "new-chat": "Жаңа чат",
        "delete": {
            "title": "Жоюды растаңыз",
            "desc": "Чатты өшіретін кезде, оның барлық хабарлары өшетіледі",
            "btn": "Чатты өшіру",
        },
        "attempts-left": "Қалды {0} сұрау",
    },
    "BlockedUI": {
        "title": "Merlin AI Сіздің таланттарыңызды анықтайды",
        "description":
            "Сайтқа толық қатынас алу үшін фотосуреттеріңізді жүктеңіз",
        "upload": "Фотосуреттерді жүктеу",
    },
    Messages: {
        loading: "Чат жүктелуде...",
        "chooseAI": "Пайдаланғыңыз келетін нейрондық желіні таңдаңыз",
        chooseChat: "Хабарламаларды қарау үшін чатты таңдаңыз",
    },
    ChatMenu: {
        title: "Мәзір",
        stableDiffusionTitle: "Тұрақты таралу",
        stableDiffusionDescription:
            "Кез келген мәтінге негізделген фотореалистік суреттерді жасау",
        midjorneyTitle: "Ортасы жол",
        midjorneyDescription:
            "Кез келген мәтінге негізделген фотореалистік суреттерді жасау",
        enable: "Іске қосу",
        disable: "Өшіру",
    },

    PayoutModal: {
        balance: "Сіздің балансыңыз:",
        title: "Ақшаны шығару:",
        sum: "Шыгару сомасы:",
        sumRequiredError: "Ақшаны шығару сомасын көрсетіңіз",
        sumMinError:
            "Кешіріңіз, біз өтінішті қабылдауға қамаймыз. Шығару үшін ең аз сома {min} рубль. Активті жалғастырыңыз және жақын арада сіз табысқан ақшаны шығара аласыз.",
        sumMaxError: "Шығара алатын ең үсті сома {max}",
        success:
            "Ақшаны шығару өтініші қабылданды! Менеджер тез арада сізбен байланысады",
        newFormat:
            "Біз ақылы форматқа көштік, сіз үздік себетте болған ақшаны достарыңызбен салыстыруға немесе үнсіздермен салыстыруға жүгініп көре аласыз. Және барлық шынықтауыңызды пайдалану үшін бір немесе андық бөлімдерге жазылыңыз",
        deposits: "Толтырылғандар",
        payments: "Сатып алулар",
        bonuses: "Бонустық ұсыныстар",
        table: {
            "date": "Күн-айы",
            "sum": "Сома",
            "description": "Сипаттама",
            "remainder": "Қалған сома",
            loading: "Жүктелуде...",
            noMore: "Қосымша запись жоқ",
            no: "Бір де бір транзакция табылмады",
        },
    },

    purchaseDescriptions: {
        category: {
            deposit: "Есепке толтыру",
            analyse_item_unlock: "Санат бойынша сипаттаманы сатып алу",
            bonus_analyse_item_unlock:
                "Ешкім сіздің санат бойынша сипаттама элементіңізді ашты",
            referral_reward: "Шетелдікке қатысу бағасы",
            merlinapp_referral_created:
                "Мобильді қолданбада шетелдікке қатысу үшін баға",
            person_compare: "Сіз тексердіңіз",
            bonus_person_compare: "Кез келген адам сізді тексерді",
            person_compare_photos: "Суреттер бойынша талдау",
            person_indicator: "Көрсеткіш ашылды:",
            bonus_person_indicator: "Ешкім сіздің көрсеткішіңізді ашты:",
            phone_confirm: "Телефонды растау бонусы",
            bonus_coach_partner: "Тренер партнерлік бағдарламасынан бонус",
            payout: "Ақшаны шығару",
            refund: "Өтінішқа орай ақшаны қайтару",
        },
        subcategory: {
            professionaltype: "мамандықтың түрі",
            neurotype: "невротип",
            leadertype: "басшылық",
            character: "характер",
            relations: "байланыс және отбасы",
            motivation: "модивация және даму",
            selfAssessmen: "өзін іздеу және әсер-әмір табу",
            professional: "мамандық және өзін реализациялау",
            compatibility: "үйлесімділік",
            similarity: "ұқсасыздык",
            business: "бизнес жүзегерділік сынамасы үшін үйлесімділік",
            interaction_business: "бизнесмендердің арасындағы байланыс түрі",
            interaction_interpersonal: "өзара байланыс түрі",
            mma_champion: "чемпион болу ықтималы",
            mma_potential: "көрес ықтималы",
            aggression_common: "жалпы агрессия",
            aggression_physical: "физикалық агрессия",
            aggression_emotional: "эмоциялық агрессия",
            aggression_social: "әлеуметтік агрессия",
            aggression_intellectual: "интеллектуалды агрессия",
        },
    },

    serviceTypes: {
        COMPATIBILITY: "Қатынастықтардың ықпалдылығы",
        COMPATIBILITY_PLAN: "Қатынастықтар мен отбасы",
        SIMILARITY: "Таныс болуы",
        SIMILARITY_PLAN: "Өзін-өзі бағалау және бекіту",
        BUSINESS: "Бизнес ықпалдылығы",
        BUSINESS_PLAN: "Мамандық және мақсат",
        INTERACTION_BUSINESS: "Бизнес қатынас",
        INTERACTION_BUSINESS_MY: "Мен бизнесті қалай жүргіземін",
        INTERACTION_BUSINESS_OTHER: "Ол бизнесте қалай ықыласа",
        INTERACTION_BUSINESS_PLAN: "Мамандық және мақсат",
        INTERACTION_INTERPERSONAL: "Адамдармен қатынас",
        INTERACTION_INTERPERSONAL_MY: "Мен адамдармен қалай кездесемін",
        INTERACTION_INTERPERSONAL_OTHER: "Ол адамдармен қалай ықыласа",
        INTERACTION_INTERPERSONAL_PLAN: "Қатынастықтар және отбасы",
        AGGRESSION_COMMON: "Жалпы агрессия",
        AGGRESSION_COMMON_PLAN: "Қатынастықтар мен отбасы",
        AGGRESSION_PHYSICAL: "Физикалық агрессия",
        AGGRESSION_PHYSICAL_PLAN: "Мамандық және мақсат",
        AGGRESSION_EMOTIONAL: "Түрлі емоциялық агрессия",
        AGGRESSION_EMOTIONAL_PLAN: "Мамандық және мақсат",
        AGGRESSION_SOCIAL: "Әлеуметтік агрессия",
        AGGRESSION_SOCIAL_PLAN: "Мамандық және мақсат",
        AGGRESSION_INTELLECTUAL: "Интеллектуалдық агрессия",
        AGGRESSION_INTELLECTUAL_PLAN: "Қатынастықтар мен отбасы",
        INTELLIGENCE_LEVEL: "Интеллектівтік деңгейі",
    },

    buyActions: {
        "compareUnlimited": "Салыстырудың саны шектеусіз",
        "compareLimit": "Қалған салыстырулар: {limit}",
        "indicatorUnlimited": "Тексерудің саны шектеусіз",
        "indicatorLimit": "Қалған талпыныстар: {limit}",
        compareCelebrity: "атап айтармен",
        compareUser: "пайдаланушымен",
        indicatorCelebrity: "атап айтарлар",
        indicatorUser: "пайдаланушы",
        compareConfirmTitle: "Салыстыруды растау",
        compareConfirmText: `Сіз шынымен {userType} {name} тобымен "{activeType}" санатында өзіңізді салыстырғыңыз келе ме?`,
        compareConfirmBtn: "Сатып алуны растаңыз",
        indicatorConfirmTitle: "Сатып алуны растау",
        indicatorConfirmText:
            'Сіз қатарынан "{activeType}" санатын {userType} {name}\'де ашқыңыз келе ме?',
        indicatorConfirmBtn: "Сатып алуны растаңыз",
    },
    "compare-result-dialog": {
        more: "Адам туралы көбірек білу",
    },
    "ConfirmDialog": {
        price: "Баға:",
        timer: "Жазылым бойынша тексеруға дейінгі уақыт:",
        cancel: "Болдырмау",
        apply: "Растау",
    },

    "LegalLinksMobile": {
        "referral": "Сылтаулық бағдарлама",
        "legal-title": "Заңды ақпарат",
        "requisites": "Байланыс және деталдар",
        "about": "Компания туралы",
        "payments": "Қызметтер үшін төлем",
        "term-of-use": "Пайдаланушы келісімі",
        "privacy-policy": "Құпиялық саясаты",
        "payout": "Бонустық бағдарлама",
        "support": "Қолдау қызметіне жазу",
        "logout": "Шығу",
    },
    "AnotherPerson": {
        "rating": "Сіздің бағамдарыңыз",
        "person-rate": "Адамды бағалаңыз",
        "compare-with-me": "Өзіңізбен салыстырыңыз",
        "more": "Көбірек білу",
        "aggression": "Әзірлек деңгейі",
    },
    "AnotherPersonActions": {
        "sendMessage": "ХАБАР ЖІБЕРУ",
        "sendTarget": "МАҚСАТТЫ ЖАБУ ҰСЫНУ",
        "sendBusiness": "БИЗНЕС ҰСЫНЫС",
        "targetConfirmTitle": "ескерту",
        "targetConfirmText":
            "Бұл әрекетті жасау үшін, алдымен оны достарыңызға қосуыңыз қажет.",
        "businessConfirmText":
            "Адамға бизнес ұсыныс жасау үшін, сізге «Мамандық және нысан» санатына жазылу қажет",
        "add": "Достарға қосу",
        "subscribe": "жазылу",
    },

    "AnotherPersonInfo": {
        "getType": "Менің психо-түрімді анықтау",
        "typeHidden": "Жататын түрі анықталмаған",
        "web": "Веб-сайт",
        "webUndefined": "Көрсетілмеген",
        "selfEsteem": "Өзімді бағалауым",
        "selfEsteemUndefined": "Көрсетілмеген",
        "occupation": "Мәртебе",
        "occupationUndefined": "Көрсетілмеген",
        "personalInfo": "Жеке ақпарат",
        "age_1": "жыл",
        "age_2": "жыл",
        "age_3": "жыл",
        "height": "см",
        "weight": "кг",
        "noChildren": "Бала жоқ",
        "children_1": "бала",
        "children_2": "бала",
        "children_3": "бала",
    },
    "AnotherPersonBusinessCard": {
        "merlin": "Мерлинден",
        "aboutMe": "{name} өзім туралы",
        "blocked": "Бұғатталған",
        "unlock": "Босату",
        "more": "Көбірек оқу",
        "mrln_strengths": "Сапалы жақтары",
        "mrln_talents": "Таланты",
        "mrln_self_esteem": "Өзімді қандай бағалаймын",
        "mrln_motivation": "Мотивацияны қандай күшейтеді",
        "mrln_tasks": "Нағыз тапсырмаларды орындайды",
        "mrln_activities": "Соның ғылыми саласына жақын",
        "self_esteem": "Өзімді қалай бағалаймын",
        "user_like": "Нелерді сүйеді",
        "user_good": "Ең жақсысы",
        "user_usually": "Әдетте не істейді",
        "user_work": "Кіммен жұмыс істемек",
        "user_goal": "Ақша қаражат ету қалайды",
        "user_skill": "Оқуға болады",
        "career": "ТЕКСЕРУ",
        "education": "БІЛІМ",
        self_esteemUndefined: "Ештеңе жоқ",
        user_likeUndefined: "Ештеңе жоқ",
        user_goodUndefined: "Ештеңе жоқ",
        user_usuallyUndefined: "Ештеңе жоқ",
        user_workUndefined: "Ешкім жоқ",
        user_goalUndefined: "Сағатына 5 доллар",
        user_skillUndefined: "креативтік жақ",
        fromNow: "қазіргі уақытқа дейін",
        categoryProfession: "Мамандық және мақсаты",
        categorySelfEsteem: "Өзімді бағалау және ыстық байлық",
        categoryMotivation: "Мотивация және өнімділік",
        needSubscribe: "Жазылу керек",
        needSubscribeText:
            'Бұл элементті босату үшін, "{category}" санатына жазылу керек.',
        subscribe: "Жазылу",
    },

    "AvailableSocial": {
        "notSelected": "ЕШТЕҢЕ ТАҢДАНЫЛҒАН ЖОҚ",
    },
    "AvatarGallery": {
        retry: "Қайталаңыз",
        new: "Жаңа аватарды жүктеңіз",
    },
    "ChangeName": {
        "placeholder": "Атыңызды және тегіңізді енгізіңіз",
        "error": "Аты және тегі 2 азырқы таңбадан көп болуы тиіс",
        "saving": "Сақталуда...",
        "save": "Сақтау",
    },

    "ChangePhotos": {
        "another": "Басқасын жүктеңіз",
        "anotherAnfasDrag": "<br/> Толық бет суретіні мында сөктіңіз",
        "anotherProfileDrag": "<br/> Профильдік суретіңізді мында сөктеңіз",
        "there": "мұнда",
        "didntFit": "Сурет сәйкес келмеді",
        "limit": "<br/> Бүгінгі жүктеу шегінен астыңыз",
        "example": "Сілтеме суретінін місалы",
        anfas: {
            title: "Сіздің суретіңізді тек нейрондық желі алгоритмі көреді. Суреттер үшінші тараптарға қатынаулы емес.",
            desktopText: "Сүйекі суретіңізді мында сөктіңіз.",
            mobileText: "Сүйекі суретіңізді мында жүктеңіз.",
            dragText: "Сүйекі суретіңізді мында сөктіңіз",
        },
        profile: {
            desktopText: "Профильдік суретіңізді мында сөктеңіз",
            mobileText: "Профильдік суретіңізді мында жүктеңіз",
            dragText: "Профильдік суретіңізді мында сөктіңіз",
        },
        avatar: {
            title: "Сіздің аватарыңызды жүктеңіз, бұл барлық парақшаны көретін адамдарға көрінеді.",
            text: "Аватарыңызды жүктеңіз",
            dragText: "Аватарыңызды мында сөктіңіз.",
            help: "Мен сізге жақсы көрінетін фотосурет жүктеп қоюды ұсынамын. Бұл басқа пайдаланушылар көруді. Мен басқалардың фотосуреттерімен сізді өзгерте алмаймын.",
        },
    },

    "CompareWithPhoto": {
        "back": "Персоналдық сипаттамага оралу",
        "another-type": "Басқа түрлі салыстыруды таңдаңыз",
        "choose-in-list": "Достар тізімінен таңдаңыз",
        "searchFriends": "Достарды іздеу",
        "noFriends":
            "әзірше достарыңыз жоқ. Достарды шақырып, олар қосылса, сіз бонустар алуға болады",
        "invite":
            "Кешіріңіз, осы адам әзірше достар тізіміңізде жоқ. Сіз оны шақырып, ол қосылса, бонус алуға болады",
        "loading": "Жүктеп алу...",
        "no": "Ешкім табылмады :)",
        "noFriendsShort": "Сіздің достарыңыз жоқ",
        "upload-friend-photo": "досыңыздың суретін жүктеңіз",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Фотосурет жүктелді",
        "choose-main": "Негізгі ретте қылу",
        "confirmDeleteTitle": "Жоюды растау",
        "confirmDeleteText": "Фотосуретті шынымен жойғыңыз келе ме?",
        "confirmDeleteBtn": "Жою",
    },
    "PersonCompareMobile": {
        compare: {
            myProfile: "Достарыңызбен салыстыру",
            withUser:
                "<span>{user}</span> пайдаланушысына қара себебін салыстыру",
        },
        indicator: {
            myProfile: "Өзіңіз туралы көптеген мәліметті үйрену",
            withUser: "Өзіңізді үйрену:<br/> {user}",
        },
    },

    "PersonDescription": {
        "back": "Артқа",
        "choose-type": "Сізді қызығушылық көрсеткен бағытты таңдаңыз",
        "need-subscribe": "Жазылу қажет",
        "need-subscribe-text":
            "Таңдалған бөлімнің сипаттамасын оқу үшін, санатқа жазылу қажет",
        "btn": "Тіркелу",
    },
    "PersonInfo": {
        "age": "Жас",
        "age_1": "жылдық",
        "age_2": "жылдық",
        "age_3": "жылдық",
        "no": "Көрсетілмеген",
        "nothing": "Ештеңе",
        "children": "Бала",
        "children_1": "бала",
        "children_2": "балалар",
        "children_3": "балалар",
        "height": "Өсім",
        "weight": "Салмақ ауырымдылығы",
        "subscriptions": "Жазылушылар",
        "photo-error":
            "Егер сізде өз фотосуреттеріңіз болса - визитка өте тым қызықты көрінеді",
    },
    "PersonMobileBox": {
        history: "Тарих",
    },

    "PersonMobileBusinessCard": {
        merlin: "Мерлиннен",
        user: "{user}нен",
        career: "Мамандық",
        education: "Білім",
    },
    "PersonMobileBusinessCardPreview": {
        "title":
            "Басқа адам үшін менің визиттік карта көрінсінше не болар <br/>",
        "send": "МЕНІҢ БЕТІМЕ СІЛТЕМЕНІ ЖІБЕРІҢІЗ",
    },
    "PersonMobileHeader": {
        "noName": "Аты ескерілмеген",
        "changeName": "Атын орнатыңыз",
        "my-type": "Менің психо-түрімді анықтаңыз",
        "type-not-available": "Тұлғаны өзі өзгермеген",
    },
    "PersonalRecommendations": {
        title: "Жеке рекомендациялар",
    },
    "PersonalRecommendationsDialog": {
        title: "Сізге жекеленген рекомендациялар",
        canOpen: "Сіз аша аласыз",
        number: "Рекомендация нөмірі",
        openAfter: "Сіз аша аласыз через: ",
    },

    "PhotoExamples": {
        title: "Сипаттама фотосуреттерінің мысалдары",
        text: "Фотосурет нормага ең жақын болса, нейрондық желі сіздер туралы ең дәлірек айтады. <br/> Фотосуреттер тек сізге қолжетімді болады.",
        anfas: "Фотосурет толық үйме",
        profile: "Жеке сурет",
        btn: "Суреттеріңізді жүктеңіз",
    },
    "AboutSettingsForm": {
        "user_like": "Сіз не істемеңізді ұнатасыз?",
        "user_good": "Сіз не істемеңізде жақсысыз?",
        "user_usually": "Сіз әдетте не істейсіз?",
        "user_work": "Сіз не істе болады деп ойлайсыз?",
        "user_goal": "Сіз несізге көп жасай алу керек?",
        "user_skill": "Сіз не үйренгіз келеді?",
        "saved": "Сақталды",
    },
    "CareerSettingsForm": {
        "company-name": "Компанияның атауы",
        "search-title": "Бәлкім, сіз бұны ықтимал көреді:",
        "company-name-error": "Компанияның атауын енгізіңіз",
        "company-position": "Лауазымы",
        "company-position-error": "Лауазымын енгізіңіз",
        "company-start": "Бастау",
        "company-start-error": "Басталу күнін енгізіңіз",
        "company-end": "Жұмыстан шығу",
        "company-end-now":
            "Егер сіз әлі де компанияда жұмыс істесеңіз, бұл жолды бос қалдырыңыз",
        "saved": "Сақталды",
        "save": "Сақтау",
        "andContinue": " және жалғастыру",
        "delete": "Жұмысты жою",
        "add": "Жұмыс қосу",
    },

    "EducationSettingsForm": {
        "country": "Ел:",
        "selectCountry": "Елді таңдаңыз",
        "alreadySelectCountry": "Алдымен елді таңдаңыз",
        "alreadySelectCity": "Алдымен қаланы таңдаңыз",
        "city": "Қала: ",
        "searchCity": "Қала бойынша іздеу",
        "no-cities": "Сіздің сұрауыңыз үшін ешқандай қалалар табылмады",
        "education-place": "Мектеп аты",
        "education-place-nothing":
            "Сіздің сұрауыңыз үшін ешқандай мектеп табылмады",
        "education-palce-error": "Мектептің атын енгізіңіз",
        "education-position": "Мамандық",
        "education-position-error": "Оқу алаңыңызды енгізіңіз",
        "education-start": "Оқуға бастау жылы",
        "education-start-error": "Оқуға бастау жылын енгізіңіз",
        "education-end": "Түсетін жылы",
        "education-end-now":
            "Егер сіз үйде жатсаңыз, осы өрісті бос қалдырыңыз",
        "saved": "Сақталды",
        "save": "Сақтау",
        "andContinue": " және жалғастыру",
        "delete": "Білім мекенін жою",
        "add": "Білім мекенін қосу",
    },

    "Settings": {
        "myMessageTitle": "Сіз",
        lang: "Сайт тілі",
        "information": "Бас тарау",
        "added-info": "Ақпарат",
        "about": "Қызықты",
        "career": "Мамандық",
        "education": "Білім",
        "photos": "Суреттер",
        "name": "Аты",
        "person-name": "Адамның атын енгізіңіз",
        "name-error": "Аты 60 таңбадан кыска болуы керек",
        "address": "Бет мекен-жайы",
        "address-error":
            'Бет мекен-жайы тек латын таңбаларын, нөмірлер мен "_" және "-" белгілерін қамтыған болуы керек. Ол 3-тен кем немесе 30-дан артық таңба болуы мүмкін емес',
        "address-length-error":
            "Адрес өлшемі {min} таңбадан ұзын болмауы керек",
        "birthday": "Туылған күн",
        "birthday-error": "Туылған күнін таңдаңыз",
        "marital": "Үйіліс статусы",
        "maritalList": [
            "Ерекше",
            "Үйленді",
            "Үйленбеген",
            "Бөлек үйленген",
            "Бөлінген",
        ],
        "select": "Тізімнен таңдаңыз",
        "children": "Балалар",
        "children-error": "Толық санды енгізіңіз немесе алаң қалдырыңыз",
        "height": "Биіктігі",
        "maxHeightError": "Максималды рұқсат етілген мән - 200",
        "maxWeightError": "Максималды рұқсат етілген мән - 200",
        "heightUnit": "см",
        "weight": "Салмақ",
        "weightUnit": "кг",
        "height-error": "Бойыңызды енгізіңіз",
        "weight-error": "Салмағыңызды енгізіңіз",
        "gender": "Жыныс",
        "genderList": [
            {
                id: "М",
                name: "Еркек",
            },
            {
                id: "F",
                name: "Айал",
            },
        ],
        "eye-color": "Көз түсі",
        "saved": "Сақталды",
        "delete-account": "Тіркелгіні жою",
        "exit": "Шығу",
        "nameTimeError": "Атыны 24 сағаттан бір рет өзгертуге болады",
        "successPhotoTitle": "Фотосуреттерді сәтті жүктедік",
        "successPhotoDescription":
            "Енді Сіз сайттың барлық мүмкіндіктерін пайдалануға болады",
    },

    "InfoSettingsForm": {
        "web": "Уеб-сайт",
        "selfEsteem": "Сіздің өзіңіздің қадір-қасиетін не арттырады?",
        "address-incorrect":
            "Дұрыс сілтемені кірізу, форматты: https://merlinface.com",
        "self-esteem-error":
            "50 таңбадан артық емес, сіз {{ selfEsteem.length }} енгіздіңіз",
        "social-status": "Сіздің әлеуметтік мәртебеңіз:",
        "socialList": [
            "Мектеп оқушысы",
            "Студент",
            "Жұмыссыз",
            "Үздік тұлға",
            "Компания жұмысшысы",
            "Компанияның (бас өздігі)ие",
        ],
        "country": "Ел:",
        "city": "Қала:",
        "selectCountry": "Елді таңдаңыз",
        "searchCities": "Қала арқылы іздеу",
        "saved": "Сақталды",
    },
    "settings": {
        "start": "Қалай{suffix}",
        "end": "I",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "Ұсынылған адамдарды талдау жасалды. Бұл 24 сағатқа дейін созылуы мүмкін.",
        "category_1": "мамандық&nbsp;мысал",
        "category_2": "Байланыс&nbsp;отбасы",
        "category_3": "Мотивация&nbsp;өнімділік",
        "category_4": "өз-құнділік&nbsp;өзіне сенім",
    },

    Person: {
        "you": "Сіз",
        "user": "User",
        compare: {
            "fromMe": "Достарыңызбен салыстыру",
            "fromUnnamedUser":
                "<span>Аты жоқ кісі</span>мен өзіңізді салыстыру",
            "fromNamedUser": "<span>{user}</span>мен өзіңізді салыстыру",
        },
        indicator: {
            "fromMe": "Өзіңіз туралы көбірек білу",
            "fromUnnamedUser": "Білу: <span>Аты жоқ пайдаланушы</span>",
            "fromNamedUser": "Білу: <span>{user}</span>",
        },
        aggression: {
            "fromMe": "Өз агрессия деңгейіңізді анықтаңыз",
            "fromUnnamedUser":
                "Агрессия деңгейі: <span>Аты жоқ пайдаланушы</span>",
            "fromNamedUser": "Агрессия деңгейі: <span>{user}</span>",
        },
    },

    "PersonList": {
        "celebrities": "Машхұр адамдар",
        "fans": "Болашақтар",
        "friends": "Барлық достар",
        "friends-requests": "Дос болу сұраулары",
        "select-all": "Барлық достарыңызды таңдаңыз",
        "no-with-params":
            "Көрсетілген параметрлер бойынша ешқандай машхұр адам табылмады",
        "no-friends": "Сізде әлі дос жоқ.",
        "no-requests": "Сізде әлі дос болу сұраулары жоқ.",
        "no-celebrities":
            "Көрсетілген параметрлер бойынша ешқандай машхұр адам табылмады",
        search: {
            "celebrity": "Машхұр адамдарды іздеу",
            "friends": "Достар бойынша іздеу",
            "requests": "Дос болу сұрауларын іздеу",
            "users": "Адамнан іздеу",
        },
    },

    "PersonShortCard": {
        typeLocked: "Тұлғаның түрі әлі ашылған жоқ",
        sendMessage: "Хабар жіберу",
        compare: "Салыстыру",
        hide: "жасыру",
        removeFromFriends: "Достардан алып тастау",
        acceptRequest: "Қосу",
        declineRequest: "Қабылдамау",
        confirmDeleteFriendTitle: "Арекетті растау",
        confirmDeleteFriendText: `"{user}" деген пайдаланушыны досыздардан алып тасауды шынымен қалайсыз ба?`,
        confirmDeleteFriendBtn: "Жою",
    },
    "Promocode": {
        "btn": "Менде промокод бар",
        "enter": "Промокодды енгізу",
    },
    "RecommendedPeople": {
        "no-persons": "Мерлин әлі сіздер үшін адамдарды таңдамады",
        tabs: {
            partner: "Іскерлік танысу",
            love: "Қарым-қатынас",
            coming: "Жақында",
        },
        "type-undefined": "Анықталмаған",
        toProfile: "ПРОФИЛЬГЕ",
        "age_1": "жыл",
        "age_2": "жыл",
        "age_3": "жыл",
        "status": "Әлеуметтік статус",
        "no-status": "Статустық жоқ",
        "no-goal": "Белгіленбеген",
        "city": "Қала",
        "no-city": "Белгілі емес",
        "noAge": "Жас ескерілмеген",
        "money-target": "Менің орнық мақсатым",
        "spheres": "Ісің саласы",
        "no-spheres": "Белгіленбеген",
        "next": "Келесі",
        "loading": "Жүктелуде...",
        category_1: "Мамандық және мақсат",
        category_2: "Қатынастар және отбасы",
        category_3: "Мотивация және продуктивтік",
        category_4: "Өзіңе сенім және сенімділік",
        "next-recommendation": "Келесі адам",
    },
    "SubscriptionsCategoriesItem": {
        "active": "жазылу белсенде",
    },

    "SubscriptionsContent": {
        "active": "{date} дейін жарамды жазылу",
        category_1: "Мамандық және Мақсат, ТЕГІН",
        category_2: "Қатынастар және Отбасы, ТЕГІН",
        category_3: "Мотивация және Өнімділік, ТЕГІН",
        category_4: "Өзін-өзі бағалау және Сенім, ТЕГІN",
        cancelTitle: "жазылуын тоқтату.",
        cancelText:
            'Сіз {period} жазылуын " {category} " болымында айырмақты келтіресіз бе екенін растайсыз ба?',
        cancelBtn: "жазылудан болып шығу",
        mounthly: "ай сайын",
        annual: "жыл сайын",
    },
    SubscriptionsTableForm: {
        payBtn: "Төлемге өту",
        withBalance: "Төлем сайт балансынан шықарылады",
    },
    SubscriptionsTableInfo: {
        discount: "Сіздің жеңілдігі: {discount}&nbsp;₽",
        chooseAnnual:
            "Жыл сайын жазылуды таңдаңыз<br/>және {discount}&nbsp;₽ үшін сақтаңыз",
        active: "Белсенді жазылу",
    },

    Subscriptions: {
        title: "Сізді қызықтырушы абоненттік санатты таңдаңыз",
        canceled: "Абоненттік сәтті тоқтатылды!",
        succeeded: "Сәтті жазылу!",
        choosePlan: "Жоспарды таңдаңыз:",
    },
    "about": {
        "title": "Merlinface.com жобасына қош келдіңіз!",
        "text-1": "Жалпы ережелер",
        "text-2":
            "Біздің команда Нейроналық Желі құрастырды, оны арқылы сіз ақпарат немесе графикалық диагнозды алуға болады, оның ішінде диагнос, эмоционалды-ерекшелік сфера, міндетті болып табылатын мемлекет шаруашылығы, әрекет, сондай-ақ міндетті болып табылатын көптеген байланысқан кеңестер.",
        "text-3":
            "Пайдаланушылардың диагностикасын жүргізу үшін квалифицированны ла ла болған мамандардың жұмысын жүргізу: нейропсихология, когнитивті-мүйіздер терапиясы және басқа салалардағы мамандар.",
        "text-4": "Пайдалы көз тұғырымдар",
        "vk": "Біздің VKontakte топымыз:",
    },

    "cookie": {
        "title": "Cookie саясаты",
        "text-1":
            "Cookies дегеніміз - серверден жиберіліп, Пайдаланушының компьютерінде сақталатын кішкентай мәтін паршалары. Cookies бірнеше функцияларды орындайды, мысалы, Пайдаланушы тарапынан жасалған параметрлерді сақтайды, рұқсат етеді страницаларды тексеріп, авторизациядан кейін және жалпы сайтта жұмыс істеуді жеңілдетеді.",
        "text-2": "Біз cookies дегенді келесіге қолданамыз:",
        "text-3":
            "1. Теңдіктер — cookies жайлы ұстанымдарыңызды және жеке меншігіңізді танытып, егер логин мен паролді енгізсеңіз, басқа бетке өткенде оларды әр рет сұраймайды.",
        "text-4":
            "2. Статистика — cookies жан-жақты беттерді шолу жөніндегі деректерді жинауды мүмкін етеді.",
        "text-5":
            "Браузеріңізді cookies дегенді қабылдауын болдырмауға болады, бірақ бұл Платформаны пайдалану қабілетіңізді өте шамадан шектеу керек.",
    },

    "payments": {
        "title": "Тапсырыс және квитанцияларды төлеу",
        "text-1": "Банк картасымен төлеу",
        "text-2":
            "Robokassa өңдеу орталығы - VISA және MasterCard-тың ресми қызмет көрсетушісі - сайтта төлемдерді қабылдайды.",
        "text-3":
            "Онлайн төлеу үшін сіз MIR, Visa, Visa Electron, MasterCard және Maestro банк карталарын пайдалана аласыз. Егер сіздің карточкаңыз 3D-Secure-га жазылса, төлеуңіз бір реттік құпия сөз арқылы авторизацияда болады.",
        "text-4":
            "Сезімтал төлеу деректерінің енгізуі және өңдеуі өңдеу орталығы тарапынан орындалады. Төлеу деректері қауіпсіз арналар арқылы шифрлатылып банкке жіберіледі. Ешкім, тауарды сататын кісі де емес, клиенттік карта деректерін алуы мүмкін емес, бұл оның ақшасының және жеке деректерінің толық қауіпсіздігін кепілдейді.",
        "text-5":
            "Төлеудің сәтті аяқталуынан кейін, төлеуді растайтын және оның бірегей анықтауышысын мазмұндайтын электронды квитанция төлеушінің электрондық поштаңызға жіберіледі.",
        "text-6":
            "Ақшаны қайтару жағдайында, ақша сол картаға қайтарылады, жанынан төлеу жасалды.",
        "text-7":
            "Төлем процессінен туыс жататын барлық сұрақтарды Robokassa 24/7 қолдауының мамандарына жазылу арқылы +7 499 110 57 25 немесе support@robokassa.ru қағазға жазу арқылы сұрай аласыз",
        "text-8": "Yandex.Money, Webmoney, Qiwi арқылы төлеу",

        "text-9":
            "merlinface.com веб-сайты бойынша сіз тапсырысыңызды тікелей төлей аласыз - Яндекс Әуе, WebMoney, QIWI әуе кошелькесі арқылы. Төлем қауіпсіздігі Robokassa өңдеу орталық тергеу мекенжайымен қамтамасыз етіледі.",
        "text-10":
            "Ақшаны қайтару жағдайында, ақша төлем жасалған сол айналым кошельгесіне қайтып келеді.",
        "text-11":
            "Әуе кошелькесі арқылы төлем туралы сұралымдарыңыз болса, сіз сауат асыз қолдау қызметіне Robokassa телефону +7 499 110 57 25 арқылы хабарласуға болады",
        "text-12": "Сатып алу үшін ақпарат қызметтері тізімі:",
        "text-13":
            '1. "Кәсіп және Жекекөз белгіленушілік" қызметі. Пайдаланушының өзін жекекөз белгіленушілік жөнінден сипаттау және сәйкесті кәсіптерге ұсыныстар жасауды қамтамасыз ету. Бағасы 150-500 рубльден басталады.',
        "text-14":
            '2. "Өз-бағас" қызметі. Пайдаланушының сипаттамасын және өз-бағас темасы бойынша ұсыныстарды қамтамасыз ету. Бағасы 150-200 рубльден басталады.',
        "text-15":
            '3. "Мотивациялау және Энергия" қызметі. Пайдаланушының өзін сипаттау және өзбағас темасы бойынша ұсыныстар жасау. Бағасы 150-500 рубльден басталады.',
        "text-16":
            '4. "Байланыс және Отбасы" қызметі. Пайдаланушының басқа адамдармен байланыс жөніндегі сипаттауын және ұсыныстарды қамтамасыз ету. Бағасы 100-400 рубльден басталады.',
        "text-17":
            '5. "Байланыста дайындылық" қызметі, оның дәрежесі пайызмен көрсетілген. Бағасы 50 рубль.',

        "text-18":
            '6. "Тағылымдардың ұқсастығы" қызметі пайыздық шкала бойынша көрсетеді. Бағасы 25 рубль.',
        "text-19":
            '7. "Бизнестік үйлесім" қызметі пайыздық шкала бойынша көрсетеді. Бағасы 100 рубль.',
        "text-20":
            '8. "Ол бизнесте қалай мүмкіндік пайдаланады" қызметі пайыздық шкала бойынша көрсетеді. Бағасы 50 рубль.',
        "text-21":
            '9. "Ол адамдармен қалай мүмкіндік пайдаланады" қызметі пайыздық шкала бойынша көрсетеді. Бағасы 50 рубль.',
        "text-22":
            '10. "Созылушылық іс-шаралардағы потенциал" қызметі пайыздық шкала бойынша көрсетеді. Бағасы 50 рубль.',
        "text-23":
            '11. "Чемпиондық потенциал" қызмети пайыздық деңгейде көрсетеді. Бағасы 50 рубль.',
        "text-24":
            "Бір реттік сатып алу арқылы платформаның әрбір пайдаланушысымен салыстыру немесе әрбір пайдаланушыға қызығушылықты сипаттау блогын ашу үшін жүйе ішіндегі баланстан алынады.",
        "text-25":
            "Төлем сәтті орындалған соң, қызмет merlinface.com сайтында пайдаланушының бетінде автоматты түрде ашылады. Қызмет мәңгі түрде көрсетіледі.",
        "text-26":
            'Ақшаны қайтару Ресей Федерациясы Заңымен 07.02.1992 ж. № 2300-I "Тұтынушылардың құқықтарын қорғау туралы" реттеулер бойынша отырып кеттіріледі.',
    },

    "privacy-policy": {
        "title": "",

        "text-1": "Жалпы нұсқаулық",
        "text-2":
            "Осы Құпиялылық саясаты 27.07.2006. Ж. № 152-ФЗ «Жеке деректер туралы» Федералдық Заңдың талаптарына сәйкес дайындалған, және ол «Merlin AI» ЖШС (Қатардағы - Әкімшілік) жеке деректерін өңдеу және жеке деректерді қорғау үшін шараларды анықтайды.",
        "text-3":
            "1.1. Әкімшілік мақсаты - жеке деректерді өңдеу жөніндегі кешенің және азаматтық құқық пен азаттықтың бағыттауы, оның ішінде жекелікке, жеке және отбасы құпиясына құқықты қорғау.",
        "text-4":
            "1.2. Бұл Әкімшіліктің жеке деректерді өңдеу бойынша саясаты («Саясат») -  https://merlinface.com тораптарынан келушілер және Merlin Dating мобильді қолданбасы туралы Администрация алуға болатын барлық ақпаратқа дайындау әдістерін камтамасыз етеді.",
        "text-5": "2. Саясатта пайдаланылған негізгі терминдер",

        "text-6":
            "Жеке деректерді блоктау – жеке деректерді өңдеуді уақытша тоқтату (деректерді нақтылау үшін өңдеу керек емес екенде);",
        "text-7":
            "Веб-сайт – графика және ақпарат материалдарының жиынтығы, сондай-ақ компьютерлік бағдарламалар мен дерекқорларды https://merlinface.com авыру және мобильді қосымша Merlin Dating-де жасалған Интернеттен рұқсат етеді.",
        "text-8":
            "Жеке деректердің ақпарат жүйесі - жеке деректердің дерекқорларындағы жеке деректердің жиынтығы және оларды өңдеу үшін ақпараттық технологиялар мен техникалық құралдарды ұсыну;",
        "text-9":
            "Жеке деректерді деперсоналдандыру - өзен болмаса, қошымша ақпаратты қолданып тегі дәл қай жеке деректердің конкретті пайдаланушыға немесе басқа жеке деректерге тән екенін анықтау мүмкін емес дейтін әрекеттер;",
        "text-10":
            "Жеке деректерді өңдеу - жеке деректермен өңделетін әрбір әрекет (операция) немесе әрекеттер топтамасы (операциялар), автоматтандыруды пайдаланып немесе пайдаланбайып жинау, жазу, системалық жасау, жинамау, сақтау, толықтыру (жаңарту, өзгерту), шығару, пайдалану, тарату (тарау, қол жетімді қылу, рұқсат ету), анонимізация, блоктау, жою, жеке деректерді последикеру;",

        "text-11":
            "Әкімшілік - заңды тұлға немесе жеке тұлға, өз басына немесе басқалармен бірге жеке даналықтарды өңдеуді және (немесе) орындауды ұйымдастырушы, сондай-ақ жеке даналықтардың өңделуін, өңделетін жеке даналықтардың құрамын, жеке даналықтармен орындалатын әрекеттерді (операцияларды) анықтаушы;",
        "text-12":
            "Жеке дана - https://merlinface.com веб-сайтының және Merlin Dating мобильді қосымшасының тіркелген немесе анықтай тұлғанына тікелей немесе долайық байланысты кез келген ақпарат",
        "text-13":
            "Пайдаланушы - https://merlinface.com және Merlin Dating мобильді қосымшасының кез келген келушісі",
        "text-14":
            "Жеке даналықты ұсыну - жеке даналықты анық адамға немесе анық адамдар тобына ашуға бағытталған әрекеттер;",
        "text-15":
            "Жеке даналықты тарату - жеке даналықты шектеусіз адамдарға ашуға бағытталған кез келген әрекет (жеке даналықты тасымалдау) немесе жеке даналыққа танысу мен анықтама, таратады жолында жеке даналықты баспа орталығында жариялау, ақпаратты телекоммуникациялық желілерде орналастыру немесе жеке даналыққа кез келген басқа жолмен қатынау қамтамасыз ететін дайындығы;",
        "text-16":
            "Жеке даналықты шетелге тасымалдау - жеке даналықты шетелдік мемлекетке, шетелдік ұйымға, шетелдік тұлға немесе шетелдік заңды тұлғая тасымалдау;",
        "text-17":
            "Жеке даналықты жою - жеке даналықтың толығымен жоюдағы әрекет, жеке даналықтардың мағлұматтар жүйесінде немесе жеке даналықтарды материалды қолданбаларда жою мүмкіндігі жоқ;",
        "text-18":
            "Төлем – Әкімшілікке ақы, пайдаланушының әкімшілікке пайдасыз лицензия ұсыну үшін төлеген ақы.",
        "text-19":
            "3. Әкімшілік пайдаланушының келесі Жеке даналықтарын өңдей алады:",
        "text-20": "3.1. Тегі, аты, әкесінің аты;",
        "text-21": "3.2. Электрондық пошта мекенжайы;",
        "text-22": "3.3. Телефон нөмірлері;",
        "text-23": "3.4. Туылған жылы, айы, күні және орны;",
        "text-24": "3.5. Суреттер;",

        "text-25":
            "3.6. Білім, мамандық, мекенжай мен квалификация туралы ақпарат, білімдік құжаттардың егжей-тегжейлері;",
        "text-26":
            "3.7. Сайт сондай-ақ посетиттердің жекелеген деректерін (астықтарды қоса тұрақ) жинау және обработкалай алмайтын интернет статистикалық қызметтермен (Яндекс Метрика және Google Analytics, т.б.).",
        "text-27":
            "3.8. Сайт автоматты түрде немесе пайдаланушының қатысуымен қосымша ақпаратты жинауға мүмкін, өз ішінде IP адресі, браузер нұсқасы, өлке, тіл, жабық теңдіктер, пайдаланушының сайтқа кірген бетінің мекенжайы, аса қиындықсыз пайдаланушыны тану үшін пайдаланылата тұратын басқа техникалық деректер.",
        "text-28":
            "3.9. Пайдаланушыдан сайттың қызметтерін пайдалану үшін үшінші тарапты қызметтердің есептеріне қатынас беруді талап етуге мүмкін, өз ішінде бірақ шектелмей, ортақ файл сақтау, тура  сообщениелер, социалдық желілер, т.б. Осы жағдайда сайт қосымша пайдаланушының жекеншілікке қатысты ақпаратты үшінші тараптардан алуға болады, өз ішінде бірақ жыныс, орналасу, пайдаланушы аватары (userpic) және т.б. Үшінші тарапты қызмет көрсетуші арқылы қол жетімді барлық ақпарат қолданушы келісіміне және құпиялылық саясатына сәйкес өңделіп жатады және сақталады.",
        "text-29": "4. Жеке деректерді өңдеудің мақсаттары",

        "text-30":
            "4.1. Пайдаланушының Жеке Деректерін өңдеу мен шолу нысаны - Пайдаланушының Тіркелгісі мен Жеке Кабинетті жасау; Пайдаланушыға электронды пошта арқылы немесе хат жіберу арқылы ақпарат беру; қолдау көрсету және техникалық қолдау көрсету; Пайдаланушыға Веб-сайтта және қолданбада болған қызметтерге, ақпаратқа және/немесе материалдарға қатынас беру.",
        "text-31":
            "4.2. Сонымен қатар, Басқару құқығы бар Пайдаланушыға жаңа өнімдер, қызметтер, арнайы үйлесімдер және түрлі іс-шаралар туралы хабарлар жіберу. Пайдаланушы әрдайым ақпаратты хабарларды алуға болмайды, Басқаруға электронды пошта арқылы info@merlinface.com мекенжайына «Жаңа өнімдер, қызметтер және арнайы үйлесімдер туралы хабарларды қабылдамаймын» деген ескерту жасай отырып.",
        "text-32":
            "4.3. Пайдаланушы Қайтарым сайтымен қандай қақтылықпен әрекет еткеніне байланысты, бірден-бір продукттар мен қызметтер туралы маркетингтік хабарлар, әрекеттенген сайтпен байланыс туралы ақпарат Пайдаланушыға жіберіледі, олардың жарнама наурызын реттейту рұқсат етеді, місалы, көрсетілгендерге сосылды желілерде жарнамаларды не көрсетпеді.",
        "text-33":
            "4.4. Интернет статистикалық қызметтер арқылы құйылған анонимдерден пайдаланушы деректері - Пайдаланушылардың сайттағы әрекеттері туралы ақпарат жинау, сайттың сапасын және оның мазмұнын жақсарту үшін пайдаланылады.",
        "text-34":
            "4.5. Пайдаланушының myAlpari дегенде әрекет еткенде қауіпсіздік үшін және алдау әрекеттерін болдырмау үшін келесі әрекеттер жазылып алынады: тіркеуге және авторизацияға уақыт көрсету, жазылуға жӣбе басқа түрдегі қызметтерді алу уақытын белгілеу, хаттар жіберу уақыты, профиль бетін жою уақыты.",
        "text-35": "5. Жеке деректерді өңдеудің заңды негіздері",
        "text-36":
            "5.1. Басқару қолданушының Жеке Деректерін тек ғана қолданушы өздерін толтырып және/немесе сайтта және оның мобильдік платформасында орналасқан арнайы формалар арқылы жіберген жағдайда өңдейді. Өзінің жеке деректерін басқаруға толтырып және/немесе жібергенде қолданушы бұл саясатқа ризамдылығын білдіреді.",
        "text-37":
            "5.2. Басқару қолданушының анонимдегі деректерін қолданушының браузер параметрлерінде рұқсат берілген жағдайда өңдейді (cookie сақтау және JavaScript технологиясы пайдалану қосылған).",
        "text-38":
            "5.3. Қолданушы сайтқа интегреленген үшінші тарапты қызметтерге қосылу және пайдалану кезінде өздігінен меншікті өз Жеке Деректерін ұсынуы мүмкін.",
        "text-39":
            "6. Жеке деректер дайындау, сақтау, тасымалдау және басқа түрдегі өңдеу процедурасы",
        "text-40":
            "6.1. Басқару өңделушімен өңделетін жеке деректерін қорғау өздерінде қауіпсіздік, заңды, ұйымдастырушы және техникалық қадамдарды іске асыру арқылы қамтамасыз етіледі, олардың жеке деректерді қорғау саласындағы қолданылымдағы заңдық талаптарға толық сәйкес келу қажет.",
        "text-41":
            "6.2. Басқару қолданушының жеке деректерін жекелеген немесе кездейсоқ қатынау, жою немесе өзгерту, блоктау, көшіру, ашылу немесе басқа үшінші тараптылардың және авторланған әрекеттерінен қорғау үшін барлық қажетті техникалық және ұйымдастырушы қадамдарды қабылдауы қажет. Қолданушының жеке деректерінің жоғалғаны немесе ашылуы туралы басқару қолданушыға хабарлайды.",

        "text-42":
            "6.3. Қолданушыға, мысалы, төлем жасау керек немесе сайтпен біріккен үшінші тараптық қызметтерде қосымша мүмкіндіктерді қосу керек болған жағдайда үшінші тараптар жеке деректер мен басқа ақпарат сұрауы мүмкін. Қолданушы өзегі жеке деректер мен басқа ақпаратты береді. Үшінші тараптардың сұрауы болған барлық жеке деректер, қолданушы келісімі мен бұлардың құпиялылық саясатына сәйкес өңделуі және сақталуы керек.",
        "text-43":
            "6.4. Әкімшілік жеке деректерді өзіне қызмет көрсетушілерге тасымалдай алатын. Мысалы, Әкімшілік қолданушылармен жұмыс істеу, үшінші тараптық ресурстарда жарнама басу, Әкімшіліктің атынан маркетингді және басқа хаттарды жіберу немесе деректерді сақтауға көмек қылу үшін үшінші тараптықтарды байланысқа шақыру мүмкін. Мұндай үшінші тараптықтардың қолданушыларының жеке деректерін жарнама мақсаттарында қолдануы тыйым салынады.",
        "text-44":
            "6.5. Егер жеке деректерде қателіктер болса, Қолданушы өзі оларды update ете алады, Әкімшілікке хабарлама жіберуге, электрондық поштақа law@merlinface.com хат жазуға болады тақырыбы өтініш: «Жеке деректерді жаңарту».",
        "text-45":
            "6.6. Жеке деректерді өңдеу мерзімі шектеусіз. Қолданушылар өзіңдері жеке деректерді өңдеу келісімін кез келген уақытта болдырмайды, Әкімшілікке электрондық пошта арқылы хабарлама жіберу арқылы, law@merlinface.com адресіне хат жазу арқылы, мөрімделген «Жеке деректерді өңдеу келісімінен болдырмау». Техникалық себептермен, ақпараттар тура жойылмай, толық өшірілуіне дейін кейінгі уақытты алып тұру мүмкін. Ескертпелі, заңды міндеттерді орындау мүмкіндігі, нақтылау шаралары, алдауға қарсы қорғау және Әкімшіліктің шекті мүдделерін қорғау үшін кейбір ақпараттарды сақтау мүмкін.",
        "text-46":
            "6.7. Қолданушы тарапынан жүктелген үшінші тараптық фотосуреттер сақталмай, жанның деректерді жекелендіру немесе жеке деректер субъектін анықтау үшін қызмет пен жаттығу жасамайды.",
        "text-47":
            "6.8. Сайт тек қолданушы сайттың арқылы тікелей алған деректер бойынша жеке деректердің операторы болып табылады және тек қолданушы тарапынан жүктелген ақпаратты іздеу мақсаттарына жаттығу үшін қолданды.",
        "text-48":
            "6.9. Пайдаланушы ақпаратын пайдалану және жүктелген фотосуреттерді Пайдаланушылардың фотосуреттерін Серверге передават жасайды. Merlin алгоритмі бетті нүктелер арқылы анықтап, параметрлері және өлшемдерін анықтайды. Қолданушы фотосуреттері Серверде сақталмайды және қызмет пен/немесе үшінші тараптарға қолданылмайды. Өлшем параметрлері мен өздікте айтарлықтар үшінші тараптарға передаватсız Серверде сақталады.",
        "text-49":
            "6.10 Қызмет, келесідегі мақсаттар үшін өлшем параметрлері және өздік жүгертушіліктер жайындағы деректерге негізделіп қолданады:",
        "text-50":
            "Жазушылық әдістемесін негізді адамдардың екі адамның теңдістігін, ұқсас өздіктерін теңдестіру үшін Merlin алгоритмін пайдалану;",
        "text-51":
            "Жазушылық әдістемесін негізді қолданушы үшін ұқсасты өздіктерді таңдау үшін, Merlin алгоритмін енгізу;",
        "text-52":
            "Қызметтің дерекқорында теңдік параметрлері бойынша шахсиятты іздеу;",
        "text-53":
            "Сайт пен қолдамшы аппарат туралы болашақ пайдаланушылар үшін теңдік нәтижелерін сақтау.",
        "text-54": "7. Төлем саясаты",

        "text-55":
            "7.1. Пайдаланушы Сайт қызметтерін жазылу бойынша төлетін (Visa, MasterCard, Maestro, МИР, Яндекс.Ақша, Webmoney, Qiwi, ApplePay, GooglePay, PayPal сияқты, толығы тізбеден ауырылмай) төлем жүйелерінің біреуінден қолдана отырып төленеді. Бұл төлем жүйелері қолданушы келісімшарты әрі құпиялық саясаты бойынша қаржылық ақпаратты жинақтауда және сақтайды.",
        "text-56":
            "7.2. Әкімшілік картадан толық деректерді жатқа қоюда және төлемдерге өңдеуде жоқ, төлем жүйелерінен тек сәтті төлем туралы хабардар болады.",
        "text-57":
            "7.3. Әкімшілік пайдаланушының жасаған төлемге қатысты қосымша ақпаратты жинауға болады, т.б. төлем нөмірі, төлем уақыты, төлемді жасау үшін пайдаланылатын картаның түрі мен төмендегі уақыты, сондай-ақ карта нөмірінің соңғы төрт ретті, карта иесіның аты, карта машинасынан жазылатын ел мен қала.",
        "text-58": "8. Жеке деректерді шет елге тасымалдау",
        "text-59":
            "8.1. Жеке деректерді шет елге тасымалдау алдында Әкімшілік ол шет елдің, оның жеріне Жеке Деректердің тасымалдауы орындалатын дейін, Жеке Деректер тұлғасының құқықтарын ысырапты қорғау қамтамасыз ететініне кепіл болуы керек.",
        "text-60":
            "8.2. Жеке деректерді Жеке Деректер мәселелері мен / не Жеке Деректер тұлғасы келісімшарт соғысқан жағдайда толмаса дерек болжамдарына сәйкес болмаган шет елге тасымалдауы тек Жеке Деректердің тұлғасының жазба түріндегі келіссоңна тасымалдануы мүмкін.",
        "text-62": "9. Тараптардың жауапкершілігі.",
        "text-63":
            '9.1.  Егер пайдаланушы үшінші тараптардың жеке деректерін өңдесе, пайдаланушы жеке деректерді қорғау үшін дәлелді қадағаларды сақтайтындығына (Жеке Деректер Кеңсесі (GDPR), 152-FZ Федералдық Заң "Жеке деректер туралы" 27.07.2006 жылгы, және басқа заңдар мен ережелер) шүкінді болу үшін жауап береді, сондай-ақ міндетті рұқсаттарды алу, пайдаланушының сайтына қажетті құжаттар мен ақпаратты орналастыру.',
        "text-64":
            "9.2. Егер қызметтерін орындау жүкімсіздіктерге ұшыраған болса, әкімшілік пайдаланушының пайдаланушы деректерін рұқсатсыз пайдалану нәтижесінде жасаған заңы тізді болмаған шектік ау, бірақ тек ағымдағы тарифтегі жазылу үшін пайдаланушы төлеген нөсердің көлеміне дейін жауапты болады, Ресей заңы бойынша, егерде жеке деректер:",
        "text-65": "а) тиімді журисдикцияның органдарына жарияланды;",

        "text-66":
            "b) Өздік тұрған бұйрық бергеннен кейін үшінші тараптан ашық сыйақы айтылды;",
        "text-67":
            "c) олар жогалтады немесе ашылғаннан бұрын олар қоғамдық болды;",
        "text-68":
            "d) Өздік тұрған билікке алу алдында үшінші тараптан алынды;",
        "text-69": "e) пайдаланушының келісімімен айтарылды;",
        "text-70": "f) күйге байлау әсерінен аяныса;",
        "text-71":
            "g) үшінші тараптың құқықтарын бұзу және/немесе пайдаланушы келісіміні бұзу туралы турлы талаптан аяныса;",
        "text-72": "10. Ақырғы мысалдар",
        "text-73":
            "10.1. Пайдаланушы өздік деректерін өңдеу туралы сұрақтары бойынша келісім алуга ерте суретте хабарласа алады, е-пошта арқылы өздіктікке хабарласа law@merlinface.com.",
        "text-74":
            "10.2. Бұл құжат өздік тұрған биліктің жеке деректерін өңдеу саясатында өзгертулерді беркемдеу үшін көрсетіледі. Саясат жаңа нұсқа алмастырылғанға дейін жақсы болады.",
        "text-75":
            "10.3. Саясаттың ағымдағы нұсқасы Интернетте еркін қолжетімді: https://merlinface.com/docs/privacy-policy",
    },

    "subscriptions": {
        "choose-type": "Сіз қызығушылық тудыратын жазылым санатын таңдаңыз",
        "cancel": "Жазылу сәтті жою!",
        "success": "Жазылу сәтті ориңдалды!",
        "choose-plan": "Жоспарды таңдаңыз:",
        "junior":
            "Жас дауыс жазылымы 4 санаттың барлығында Gold пайда болады: <br/>Мамандық және Жеке орнықтандыру, Өзін-өзі қадірлеу және Ж confidence,Мотивация және Энергия, Қатынастар және Отбасы",
        "before-charges": "ақы орналасқанға дейін",
        "to-support": "қолдау қызметімен байланысу",
        "left": "қалған",
        "status": "Жазылымның күйі",
        "failed-to-renew": "жазылымды жаңарту сәтсіз аяқталды",
        "auto-renew-active": "автоматты жаңарту іске қосылды",
        "active-to": "белсенді мерзімге дейін",
    },

    "RetakeOnboarding": {
        DEFAULT: "Менің психотипімді анықтау",
        PROFESSION_ORIENTATION: "Профессионалдық түрлікті өткізу",
    },

    ConnectSocial: {
        title: "Әлеуметтік желілерді байланыстыру",
        enabled: "Байланыстырылды",
        enable: "Байланыстыру",
        enterEmail: "Электрондық поштаңызды енгізіңіз",
        codeSend:
            "Электрондық поштаңызға код жіберілді. Оны осы жолақта енгізіңіз.",
        youGetCode: "Тастықтау коды бар электрондық хат аласыз",
        retryEmail:
            "Эгер электрондық хат келмесе, сіз кодты қайта жіберуге болады <span> {timer} сек...",
        codeError: "Код жарамасты емес",
        retrySend: "КОДТЫ ҚАЙТА ЖІБЕРУ",
    },
    FriendActions: {
        requestSend: "Достқа шақыру жіберілді",
        cancel: "Болдырмау",
        incomingRequest: "Келген дос шақыруы, қабылдау керек пе?",
        accept: "Қабылдау",
        decline: "Қабылдамау",
        add: "Қосу",
        sendMessage: "Хабарлама жазу",
        addToFriends: "Достарға қосу",
        partnerRequestSend: "Серіктестікке арналған өтініш жіберілді!",
        makePartner: "Серіктестік жасау",
    },

    RecommendedPeopleCtrl: {
        title: "Адамдардың ұсынымдары",
    },
    AppLoading: {
        title: "Интернет байланысын тексеріңіз<br/>және бетті қайта жүктеңіз",
    },
    ChooseFriendPhotos: {
        "analyze": "Талдау",
    },
    "ServiceCollapse": {
        "openNow": "Қазір ашу",
        confirmTitle: "Ұсынымды ашу",
        confirmText:
            "Ұсынымды қазір ашу үшін, сізге PLATINUM тарифіне жазылу керек.",
        confirmBtn: "Жазылу",
    },
    LandingHeader: {
        advantages: "Сізге қандай пайда беретіні",
        steps: "Қалай жұмыс істейді",
        mission: "Біздің міндеттеміз",
        enter: "Кіру",
    },
    LandingBanner: {
        title: "<span>Мамандық бойынша қызмет көрсету тесті</span><br/> Нейрондық желі сізге тиісті 3 мамандықты таңдайды",
        description:
            "Жасанды интеллект әлеміне еніп, Merlin AI көмегімен дағдыларыңызды қолданыңыз",
        "list": [
            "Бірегей алгоритм сіздің күшті және зақымды жақтарыңызды таңдайды",
            "Кәсіби таңдау және байланыста кеңес береді",
            "Жеке ұсыныстар алыңыз, кескіндерді жаратыңыз, сөйлесіп, оқыңыз",
        ],
        btn: "Мамандығыңызды үйреніңіз",
    },
    "LandingAbout": {
        "title":
            "<span>Merlinface —</span> дайындалушылардың жасаған жаппайыз болмаған алгоритм, жасандық интеллект саласындағы мамандандары",
        "list": [
            {
                "1": "Өз фотосуретіңді жүкте, біздің нейросетіміз сағатты пісіре тұрады",
                "2": "AI-мен жұмыс төгел опыты",
            },
            {
                "3": "Нысана задачалар мен жобалар",
                "4": "Біздің нейросетімізбен күнделікті аралас, курстың өзі дайындалган табыстарын орында турған араланық нейросеті операторы",
            },
            {
                "5": "Шындықтағы жобаларда алынған білімдерді қолданыңыз",
                "6": "Өздің бағытына аз болмаса бастдыңыз: біздің AI сенімсіздама дамыту бойынша жекелеген ұсынуларды дайындайды",
                "7": "IT-ге сәтті бағыттау үшін ұсынулар ",
            },
        ],
    },
    LandingAdvantages: {
        title: "Сіз не аласыз?",
        showVideo: "Бейне көріңіз",
        list: [
            {
                id: 1,
                active: true,
                code: "professional",
                title: "Мамандық теріздеме",
                description:
                    "Базамызда тағы 1000-ден астам мамандыҡ бар. Merlin AI сізге 3-те сәтті болуға көмек берер.",
            },

            {
                id: 2,
                active: false,
                code: "psychotype",
                title: "Психологиялық тиыптар",
                description:
                    "Сіздердің барлық нысандарыңызды және саулықты қалай дамытуға көмекшілік ететін тізімді аласыз.",
            },
            {
                id: 3,
                active: false,
                code: "person-description",
                title: "Сіздің психикалық қариятыңызды және мүмкіндіктеріңізді анықтау",
                description:
                    "Біздің сайтымыздың әр үшінші пайдаланушысы өзінің сүйікті істерін табып отыр.",
            },
            {
                id: 5,
                active: false,
                code: "ұсыныстар",
                title: "Личные рекомендации",
                description:
                    "Merlin AI сізге қызмет ету үшін жайлы 150 жеке рекомендацияларды дайындады, бұл сізге жылдам жету үшін мүмкіндік береді",
            },

            {
                id: 6,
                active: false,
                code: "person-recommendations",
                title: "Басқа платформа мүшелерінің ұсыныстары",
                description:
                    "Merlin AI мыкты бизнес ұйымдастырушыларды немесе тыңдай алмайтын адамдарды жиі ұсынады, сізде бизнес қызметкер, достар немесе қатынас жолдасын табуға мүмкіндік болады",
            },
            {
                "id": 7,
                "active": false,
                "code": "messages",
                "title":
                    "Пісіруге ұсынылатын мамандықтың оны таныту сілтемелері\n",
                "description":
                    "Бизнес, байланыс немесе мотивация үшін өздеріне дос табуға қызықтыратын басқа пайдаланушылармен байлаша аласыз",
            },
            {
                "id": 8,
                "active": false,
                "code": "celebrity",
                "title": "мәшһұр адамдар базасына кіру",
                "description":
                    "Олармен жеке түрде кездесу және ұйымдастыру болатын мүмкіндігіні алып, орындау сәйкестігін салыстыра аласыз.",
            },
        ],
    },
    LandingMission: {
        title: "Міндетіміз",
        description:
            "Адамдарды өз потенциалдарын жетілдіруге және резюме бойынша емес, бірақ қабілеттері бойынша жұмыс табуға көмек ету құралын жасау.",
        memberCount: "Кимдік қызметке енген",
        btn: "Қосылыңыз",
    },
    "LandingSteps": {
        "title": "Бұл қалай жұмыс істейді?",
        "steps": [
            {
                "number": 1,
                "title": 'Төмендегі "Бастау" түймесін басыңыз',
            },
            {
                "number": 2,
                "title": "Телефон нөміріңізді растаңыз",
            },
            {
                "number": 3,
                "title": "Чатқа кіріп, нейрондық желімен байланысуды бастаңыз",
            },
            {
                "number": 4,
                "title": "Нәтижелерді алу",
            },
            {
                "number": 5,
                "title":
                    "Жасанды интеллекттен өзіңізге қолайлы мамандықтар тізімін алыңыз",
            },
        ],
        "result": {
            "title": "Сіз нәтиже аласыз",
            "list": [
                "Танымал нейрондық желілерге бір жерден қол жеткіз",
                "Merlin AI кез келген сұрақтарға жауап алу мүмкіндігі",
                "Кез келген мәтін бойынша суреттер жасау мүмкіндіг",
            ],
        },
        "btn": "Бастау",
    },
    LandingReviews: {
        title: "Мерлин бастысы туралы не айтады:",
    },
    MerlinLimit: {
        title: "Бүгінгі күнге сұрау шегі жетілді - 5/5",
        desc: "Мерлин AI-мен кез-келген ақылы тарифке ауысу арқылы келісімді жалғастыру",
        btn: "Тарифтерді қарау",
    },

    SubscriptionsData: {
        PROFESSION: {
            TITLES: [
                {
                    id: "PROF_OPTION_1",
                    name: "Өздік орыс описаниялары",
                },
                {
                    id: "PROF_OPTION_2",
                    name: "Өздік рекомендациялар орындау үшін",
                },
                {
                    id: "PROF_OPTION_3",
                    name: [
                        "Қол жетімді индикаторлар:",
                        "Бизнес совместимості",
                        "Бизнес қарым-қатынас",
                        "Физикалық агрессия",
                        "Эмоционалды агрессия",
                    ],
                },
                {
                    id: "PROF_OPTION_4",
                    name: "Атқарушылардың бизнестік сыналуы",
                },
                {
                    id: "PROF_OPTION_5",
                    name: "Бизнес ортактарын Merlinface-пен жабу",
                },
            ],
            FREE: [
                {
                    id: "PROF_OPTION_1",
                    value: "30% ашық",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "Аптасына бір рет",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "Күндеріне 1 белгіленген",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "—",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "Аптасына бір рет",
                        "Бір маган таңдалды",
                        "Бір аласыз",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "PROF_OPTION_1",
                    value: "Толығымен ашық",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "Үш күнге 1",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "Сағатына 1 белгіленген",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "Айына 1",
                },
                {
                    id: "PROF_OPTION_5",
                    value: ["Үш күнге 1", "1 маган таңдалды", "3 аласыз"],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "Аптасына 1",
                },
            ],
            PLATINUM: [
                {
                    id: "PROF_OPTION_1",
                    value: "Толығымен ашық",
                },
                {
                    id: "PROF_OPTION_2",
                    value: ["Күніне 1"],
                },
                {
                    id: "PROF_OPTION_3",
                    value: "Шектеусіз",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "Аптасына 1",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "Күніне 1",
                        "Бір маган таңдалды",
                        "5 сізге жеткізеді",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "Аптасына 1",
                },
            ],
        },

        SELF_ESTEEM: {
            TITLES: [
                {
                    id: "ESTEEM_OPTION_1",
                    name: "Өзін-өзі құрметтейтін сипаттамалар",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    name: "Өзін-өзі құрметтейтін жеке ұсыныстар",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    name: [
                        "Қол жетімді индикаторлар",
                        "Кейіпкерлердің ұқсастығы",
                        "Бизнес байланыстар",
                        "Адамдармен қатынастар",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_5",
                    name: "Тәжірибе алмасуда адамдарды таңдау",
                },
            ],
            FREE: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "30% ашық",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "аптасына 1 рет",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "күніне 1 затту көрсетілген",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "—",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "аптасына 1 рет",
                        "1-і мен үшін таңдалды",
                        "1-ге алуға",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "толық ашық",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "3 күнге 1 рет",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "сағатына 1 затту көрсетілген",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "айда 1 рет",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "3 күнге 1 рет",
                        "1-і мен үшін таңдалды",
                        "3-ге алуға",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "аптасына 1 рет",
                },
            ],
            PLATINUM: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "толық ашық",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: ["күніне 1 рет"],
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "шектеусіз",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "аптасына 1 рет",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "күніне 1 рет",
                        "1-і мен үшін таңдалды",
                        "5-ке жеткізуге",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "аптасына 1 рет",
                },
            ],
        },

        MOTIVATION: {
            TITLES: [
                {
                    id: "MOTIVATION_OPTION_1",
                    name: "Мотивацияны арттыру үшін жеке сипаттамаңыз",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    name: "Мотивацияны арттыру үшін жеке ұсыныстар",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    name: "Адамдар-мотиваторларды таңдау",
                },
            ],
            FREE: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "30% ашык",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "аптасына 1 рет",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: ["айына 1 рет", "маған 1 таңдалды", "1 мені құлады"],
                },
            ],
            GOLD: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "толығымен ашык",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "үш күнде 1 рет",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "үш күнде 1 рет",
                        "маған 1 таңдалды",
                        "1 мені алды",
                    ],
                },
            ],
            PLATINUM: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "толығымен ашык",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: ["күніне 1 рет"],
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "күніне 1 рет",
                        "маған 1 таңдалды",
                        "1 мені құлады",
                    ],
                },
            ],
        },

        RELATIONS: {
            TITLES: [
                {
                    id: "RELATIONS_OPTION_1",
                    name: "Сіздің қатысушылық орналасқан жұмыстары",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    name: "Шексіз жатыстықты жақсартуға арналған ұсыныстар",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    name: [
                        "Қол жетімді көрсеткіштер:",
                        "Байланыстық ықпалдылық",
                        "Адамдармен жайық-тосық",
                        "Жалпы Агрессия",
                        "Физикалық Агрессия",
                        "Эмоционалды Агрессия",
                        "Қоғамдык Агрессия",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_4",
                    name: "Атапараттық ықпалдылықты салыстыру",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    name: "Қатысушылық үшін серіктестерді таңдау",
                },
            ],
            FREE: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "30% ашық",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "аптасына 1 рет",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "күніне 1 рет көрсетілгендерден",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "—",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "аптасына 1 рет",
                        "маған 1 таңдалды",
                        "1 көрсетіледі",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "толық ашық",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "үш күн сайын 1",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "сағат сайын 1 көрсетілгенден",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "айлық 1",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "үш күн сайын 1",
                        "маған 1 таңдалды",
                        "3-ге жеткізеді",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "аптасына 1",
                },
            ],
            PLATINUM: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "толық ашық",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "күніне 1",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "шектеусіз",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "аптасына 1",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: ["күніне 1", "маған 1 таңдалды", "5-ге жеткізеді"],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "аптасына 1",
                },
            ],
        },
    },

    TariffJunior: {
        "title-1": "Жас іске аса алды",
        "title-2": "Жас",
        desc: "Жас жазылуында алтын жоспарының барлық 4 санатының артықшылығы бар: <br/>Мамандық және өзін-өзі орнату, өзіне сенім және батылшылық, мотивация және энергия, жатысулар және отбасы",
        cancelSubscription: "Жазылуды тоқтату",
        confirmation: {
            title: "Жазылудан бас тарту",
            desc: "Сіз шыныменде JUNIOR жазылуын тоқтату қажеттігіне растылайдысыз ба? Барлық артықшылықтар төленген периодтың соңына дейін жетімді болады",
            btn: "Жазылудан бас тарту",
        },
    },

    SubscriptionPeriodLabel: {
        MONTH: "ай сайын",
        ANNUAL: "жыл сайын",
    },
    SubscriptionPeriodOptions: {
        MONTH: "айлық",
        ANNUAL: "жылдық",
    },
    Tips: {
        close: "Кеңесті жабу",
        prev: "Артқа",
        next: "Келесі",
        disable: "Кеңестерді өшіру",
        categories: {
            PROFESSIONAL: "Мамандық және мақсат",
            RELATIONS: "Қатынастар және отбасы",
            MOTIVATION: "Мотивация және өнімділік",
            SELFASSESSMENT: "Өзін бағалау және батылдық",
        },
        list: [
            {
                name: "auth",
                element: ".auth-form",
                title: "Телефон нөмірімен тіркеліңіз",
                subtitle:
                    "Бұл сізге тек өз деректеріңізге қатынасуға болады және құпия сөзді ойлап табу қажет емес.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },

            {
                name: "avatar",
                element: "[data-tip='avatar']",
                title: "Сіздің аватарды орнатыңыз",
                subtitle:
                    "Аватарсыз, алгоритм сізді сайттың басқа пайдаланушыларына ұсынмайтын болады.",
                timeout: 2000,
                next: "recommendations",
                prev: false,
                showOn: ["жұмыс үстелі", "ұялы телефон"],
                hideAfterShow: true,
            },

            {
                name: "recommendations",
                element: "[data-tip='recommendations']",
                title: "",
                subtitle:
                    "Бұл жерде әр түрлі мақсаттарға көмек етуге арналған ұсыныстарды таба аласыз.",
                timeout: 2000,
                next: "people-recommendations",
                prev: "avatar",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "people-recommendations",
                element: "[data-tip='people-recommendations']",
                title: "",
                subtitle:
                    "Бұл жерде сізге әр түрлі санаттарға ұсынған адамдарды таба аласыз",
                timeout: 2000,
                next: "search",
                prev: "recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "sphere",
                element: "[data-tip='sphere']",
                title: "Адамдардың өте тиімденгенде кенет жоғалған төрт негізгі алақы бар",
                subtitle:
                    "Мен әлдеқашан сіздің фотосуреттеріңізді талдаған және жеке сипаттаманы құрдым. Қалғаны қызықты санатты клик жасау.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },

            {
                name: "inside-sphere",
                element: "[data-tip='sphere']",
                title: "Ашық және Жабық Шар Санаттары",
                subtitle:
                    "Сары түс алдағыдан оқып жатындары көрсетіледі. Қараңғылары қызықты санаттарға жазылу арқылы ашылуы мүмкін.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "search",
                element: "[data-tip='search']",
                title: "",
                subtitle:
                    "Іздеу жолағы арқылы сіз әрқандай орыс шексіздіктер және адамдарды ат немесе тегі бойынша таба аласыз.",
                timeout: 2000,
                next: "notifications",
                prev: "people-recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "notifications",
                element: "[data-tip='notifications']",
                title: "Қоңырау Хабарландыру Түймесі",
                subtitle:
                    "Ол әлеуметтік желіде белсенді әрекеттер үшін түрлі бонустар туралы хабарландыруларды, кімдер сізбен сәйкес дегенді тексерген жағдайда хабарландыруларды көрсетеді.",
                timeout: 2000,
                next: "subscriptions",
                prev: "search",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "subscriptions",
                element: "[data-tip='subscriptions']",
                title: "",
                subtitle:
                    "Мұнда сізге жазылу үшін ықтималды тізім және сіздің белсенді жазылымдарыңызды таба аласыз.",
                timeout: 2000,
                next: false,
                prev: "notifications",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },

            {
                name: "multi-compare",
                element: "[data-tip='multi-compare']",
                title: "Санаттарды таңдаңыз",
                subtitle:
                    "Достарыңызбен салыстыру үшін бірнеше санаттарды таңдауға болады. Тек сіз PLATINUM жоспарын іске қосқан салыстырмалар ғана қол жетімді.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "choose-avatar",
                element: "[data-tip='choose-avatar']",
                title: "Аватарыңызды орнатыңыз",
                subtitle:
                    "Сонда мен сені басқа адамдарға ұсына аламын. Мен сізді фотосыз жарнамалай алмаймын.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "recommendations-modal",
                element: "[data-tip='recommendations-modal']",
                title: "Жеке ұсыныстар",
                subtitle:
                    "Есептегіштің мерзімі аяқталғаннан кейін сізде бір ұсыныс ашық болады. Келесі ұсыныстың есептегіші соңғы қолжетімді нұсқаны оқымайынша іске қосылмайды.",
                timeout: 500,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
        ],
    },

    WelcomeLayout: {
        ctrl: {
            "step-1": "Сұрақтармен тест",
            "step-2": "Фототест",
            "step-3": "Фототест",
            "step-4": "Мамандық тесті",
        },
        "back-to-main": "Басты бетке оралу",
        "back-to-main-confirm-title": "Әрекетті растау",
        "back-to-main-confirm-desc":
            "Басты бетке оралуға сенімдісіз бе? Барлық прогресс толық жоюдалады.",
        "back-to-main-confirm-apply": "Басты бетке оралу",
    },

    WelcomeWant: {
        title: "Құттықтаймыз, алғашқы қадам алынды.",
        description:
            "Менім атым Мерлин және мен сізге шын мағаныста қажет нәрсені алуға көмек етуші. <br/>Керемет заты табу үшін не ізденуді көрсетіңіз:",
    },
    WelcomeTest: {
        "title-1": "Тестке 15 минут уақыт",
        "title-2": "Біз сізге сәйкес келетін 12 алаңды анықтадық!",
        "title-3": "Сіз үшін жаңа білім болу дейін 2 тапсырма қалды",
        "subtitle-1": "Қазір психологиялық тестке келтірім ",
        "subtitle-2": "Келтірімді жалғастырайық. Басқа біріне жауап беріңіз",
        "subtitle-3":
            "Сізге сәйкес келетін алаңды анықтау үшін соңғы {count} сұрақ. <br/>Сіздің жеке типіңізді анықтау",
        "question-destination-1": "сұрақ",
        "question-destination-2": "сұрақ",
        "question-destination-3": "сұрақтар",
        "choose-title":
            "Қызықтырып, сізді ең үздік сипағаттау вариантын таңдаңыз:",
        "next-question": "Келесі сұрақ",
        "quote-1": "Әр сұрақта өзіңізді таба аласыз",
        "quote-2":
            "Жақсы көретін затты таңдау қуатына ие болу және өз позицияңыздан болмаса, ол олтаның азырағы жақсы. (с) Альберт Камю",
        "quote-3":
            "Толық адам өз ішінде барлығын іздейді, бараңыз адам - басқаларда. (с) Конфуций",
    },

    WelcomePhotoTest: {
        title: "Нәтижеге дейін 1 қадам қалды",
        subtitle: "Құралыңызды анықтауға арналған ПСИХОЛОГИЯЛЫҚ тест",
        "choose-title": "Сізге қай нарықтағы өріс жақсы көрінеді?",
        "next-btn": "Келесі фотосуреттер",
    },
    WelcomeCheckPay: {
        "title-success": "Төлем сәтті орындалды",
        "title-error": "Төлемде қате пайда болды",
        "description-success":
            "Сіз 5 секундтан кейін келесі қадамға өтедіңіз...",
        "description-error": "Жалғастыру үшін төлем жасау керек",
        "btn-success": "жалғастыру",
        "btn-error": "Қайталау",
    },
    WelcomeAnalyze: {
        title: "Жауаптарды талдау",
    },
    WelcomePhotos: {
        title: "ӨЗ НӘТИЖЕЛЕРІҢІЗДІ ЖАКСЫРТЫҢЫЗ",
        subtitle:
            "Сіздің жауаптарыңызды нейралдық желі алгоритмі талдайды.<br/> Дәл дәлей іс жөніндегі ілесімді алу үшін фотосуреттеріңізді жүктеңіз.",
        "upload-photos-title":
            "Дәл дәлей іс қою үшін фотосуреттеріңізді жүктеңіз",
        "next-btn": "Келесі",
        "skip-btn": "Өткізу",
    },
    WelcomeAnalyzeReady: {
        title: "Маған 170 қатеуынша кәсібтер қалдыра беруді басардым.",
        subtitle: "10 минут ишинде сіз үшін ең жақсы кәсібтерді тануымыз.",
        desc: "Сізге жарамайтын салаларға уақыт сарптамаңыз.<br/> Тест жұмысқа үйрету процесін жылдамдатады<br/> Тестті тапқан адамдардың 90%-ы өздерінің сүйікті жұмысын тапты және оның өзін білді",
        quote: "Кез-келген мақсат орындалуға тиіс. (c) Aristarkh Romashin",
        "start-btn": "Бастау",
    },

    WelcomeProfessionVote: {
        "title-1": "",
        "title-2": "Жақсы! {count} {decl} 1150-ден қалды",
        "title-3": "Жақсы! Біз жақындаймыз",
        "title-4": "Жаңа мамандықты таңдаңыз да, жалғастаймыз",
        "subtitle-1":
            "Бұл шеңберде мен сіздердің өзіңізді ең үздіксізденуіңізге болатын жұмыс аймақтарын белгіледім.<br/>Мен {count} {decl} 1150-ден қалдым. Мағынасыздың ең жақсысын таңдап алыңыз.<br/> <br/>Сары бөлімде түсірілген жерді басыңыз",
        "subtitle-2": "Сары бөлімде түсірілген жерді басыңыз",
        "subtitle-3": "{count} {decl} 1150-ден қалды",
        "subtitle-4": "Тек {count} {decl} 1150-ден қалды",
        "profession-declination-1": "мамандық",
        "profession-declination-2": "мамандықтар",
        "profession-declination-3": "мамандық",
    },

    ProfessionVotePopup: {
        "help-1":
            "Жақсы. Сіздің жауабыңызды есімдедім. Енді келесі бірнеше мамандықтарды толтырыңыз және Есептеу түймешігін басыңыз.",
        "help-2":
            "Егер сіз мамандықты білмесеңіз, тек қайси да бір атауын басыңыз.",
        "help-3":
            "Осы жерде оны қалай орындау кімге қалаған көңіл-күй отыратыны және сіздің қандай сипаттарыңыздың осы істе көмекші болуы мүмкіндігі жазылған.",
        "help-4":
            "Бұл - сіздің жетістікке жету мүмкін болатын мамандыққа үлгеру місалы. Қажет жаттығуы шамасын бағалаңыз. <br/><br/>Қылмысты, мен сіздің жауаптарыңыздың көмегімен ең жақсы мамандықты таңдау үшін пайдаланамын <br/ ><br/>Бірінші мамандыққа дауыс беріңіз",
        "error": "Келесі мамандықтарға дауыс беріңіз:",
        "next-btn": "Жалғастыру",
        "calc-btn": "Есептеу",
    },

    OnboardDescriptionPopupRadio: {
        list: [
            {
                id: 1,
                text: "Ешқашан қызықпас",
                showSub: false,
            },
            {
                id: 2,
                text: "Ешқашан болмады, бирок мен қызықсызын",
            },
            {
                id: 3,
                text: "Мен қазір осы областы оқып жатамын",
            },
            {
                id: 4,
                text: "Мен болдым, берік емес",
            },
            {
                id: 5,
                text: "Мен бұны қуатты жасап жатырмын және жақсы жасап жатырмын",
            },
            {
                id: 6,
                text: "Мен бұл туралы санаткермен көп тәжірибем бар",
            },
        ],
    },

    WelcomeProfPay: {
        "title-1": "1150-дан мамандықтан 3-і сізге сәйкес келді",
        "title-2": "Төлем тәсілін таңдаңыз",
        "desc-title":
            "{count} адам оқиғаны көп ұнатушы мамандықты табып, олардың кіруін арттырды",
        "desc": "Тесттен өткендердің 87%-ы бұл тестті себеттен жақындарына ұсынады <br/>Бұл тест өлкеміздегі 38 өндіруші компанияның бас директорлары тарапынан пайдаланылады <br/><br/>Мамандықта қол жеткізу туралы жеке кеңес алыңыз",
        "open-btn": "499 рубльге ашу",
        "motivation": "Сіз үшін қалған соңғы қадам, 15 минут ішінде оралдыңыз!",
        "recreate-text": {
            "btn": "Тестті қайтадан өтіңіз",
            "confirm-title": "Тестті қайта іске қосу керек пе?",
            "confirm-desc":
                "Егер сіз ойласаңыз, тестті дұрыс өткізбедіңіз, қайтадан өтіп көре аласыз. Назар аударыңыз: болған тесттің прогрессі тұрақты жойылады.",
            "confirm-btn": "Тестті қайтадан өтіңіз",
        },

        partners: {
            "title": "Біздің серіктестер тарапынан бекітілген:",
            "list": [
                "Орыс Федерациясының Білім Министрлігі",
                "Жаңылықты өндіру қоры",
                "B20 Индонезия 2022",
                "Сколково",
                "Merlin AI - Telegram ресми серіктесті",
            ],
        },
        paymentMethods: {
            all: "Барлық төлем әдістерін көрсет",
            phone: {
                title: "1. Телефон нөмірі арқылы",
                copy: "көшіріп алу",
                copied: "көшірілді",
                "copy-number": "Нөмірді көшіріп алу",
            },
            link: {
                title: "1. Сілтеме арқылы",
                btn: "Сілтемеге өт",
            },
            kassa: {
                title: "2. Robokassa арқылы",
                btn: "Төлеу",
            },
            qr: {
                title: "4. QR коды арқылы",
                btn: "Таңдау",
            },
        },
        "after-pay":
            "Төлемден кейін, сияқтыны болдыр жатсаңыз біз төлемді тексере аламыз",
        "after-pay-label": "Төленген 499 рубль",
        "after-pay-btn": "Жалғастыру",
    },

    WelcomeProfessions: {
        "title": "Сізге ең көп жататын салаларды анықтадық<br/>",
        "title-1":
            "Жарайды!<br/>Сізге ең жақсы келетін мамандықтарды анықтадық",
        "title-2": "Жарайды!<br/>Енді",
        "title-3": "Жарайды!<br/>Енді",
        "subtitle-1":
            "<b class='gold'>Басқалардан</b> дәуірселік ең мамандықты таңдаңыз",
        "subtitle-2":
            "Байланыстылықтар көбірек - сфераның сәйкестігі төмен болады<br><br>Кез келген сфераны таңдаңыз және сізге жататын мамандықтарды көріңіз",
        "title-helper": "сфераның мамандықтары",
        "these": "Осылар",
        "merlin-help":
            "Мен сізге оларды өздеріңізге үйренуге көмекші боламын. Merlinface-те алдақашан оларды жақсы білуші мүлде бар. Мен сізді олармен таныстырамын. <br/><br/> Бірақ маңызды нәрсе бар...",
        "more-btn": "Үйрену",
        "next-btn": "Жалғау",
    },

    WelcomeBusinessCardInfo: {
        "info-title": "Өзіңіз туралы біраз мәлімет толтырыңыз",
        "edu-title": "Білім беру жайлары туралы мәлімет толтырыңыз",
        "career-title": "Жұмыс мәліметін толтырыңыз",
        "subtitle":
            "Басқа пайдаланушылар сіздің бетіңізді көретін кезде осы мәлімет көрінеді. Мынауа сіздің болмаса да, бизнес ортақшыңыз болуы мүмкін.",
        "all-fields-error": "Барлық өрістер толтырылуы қажет",
        "edu-fields-error": "Кем дегенде бір оқу орнын енгізіңіз",
        "career-fields-error": "Кем дегенде бір жұмыс орнын енгізіңіз",
    },
    WelcomeBusinessCardPay: {
        loading: "Жүктелуде...",
    },
    WelcomeStart: {
        "title": "Профильдіңізді өтінішпен толтырыңыз!",
        "subtitle":
            "Мәлімет дәліретті болса, нәтижесі де ол шамадаң дәліретті болады",
    },

    WelcomePersonInfo: {
        title: "Өте жақсы, ",
        subtitle: "Өзіңіз туралы кейбір мәліметтерді толтырыңыз:",
        age: "жас",
        "age-placeholder": "Сіздің жасыңыз неше?",
        "age-error": "14 және 99 аралығындағы жасыңызды енгізіңіз",
        "choose-country": "Елді таңдаңыз",
        "choose-city": "Қаланы таңдаңыз",
        "country-placeholder": "Елді іздеп табу",
        "city-placeholder": "Қала бойынша іздеп табу",
        "city-no-options":
            "Таңдалған елде іздеп табу үшін қаланың атын енгізіңіз",
        "social-title": "Сіздің әлеуметтік мәртебеңіз:",
        "social-error": "Әлеуметтік мәртебені таңдаңыз",
        "next-btn": "Жалғастыру",
    },
    WelcomeTestComplete: {
        title: "Мен Сіздің Психотипіңізді Анықтадым",
        "your-type":
            "Біздің сайтымызда сіздің психотипіп сеніммен {count} {dec}",
        "your-partners": "{count} {decl} сізге бизнестік ынталығы жоғарыда.",
        "celebrity":
            "{count} танымал және сіздің өз шахсиет типіңізбен сәтті адамдар.",
        "next-btn": "Жалғастыру",

        typeDescriptions: {
            "Аралас ":
                "Сіздің дәстүрлі таланттарыңыз сізді тым жақсы примирителем жасайды. Сіздер қарама-қарсы ортасын табу және түрлі адамдармен бірге тұру үшін талайтасыз. Басқалардың қажеттіліктерін тыңдап түсіну қабілетіңіз сізді қандай да бір командада маңызды мүшеге айналады. Енгізулеріңізге рахаттықтандыру және бейімделу құны барқанысты және өнімді байланысты қоршауға жылжады.",
            "Коммуникатор":
                "Сізде байланыс жасау үшін таңдаулы сый бар. Сіздің дайындықтарыңыз сізді өз ойларыңызды және пікірлеріңізді бастапқы жағдайды ескеріп, оңай түсіндіріп беруге мүмкіндік береді. Байланысты оңай және өздік жасау қабілетіңіз сізге кәсіптердік жүгінің қиынауларын арсылауға көмек етеді.",
            "Коннектор":
                "Сіздер үлкен байланысты адамсыздар, адамдар мен идеяларды байланыстыруға қабілендір. Сенімді жағдайға сайлаушы дайындықтарыңыз және басқалардың ескерулеріне сізді өздеріңізді кәсіпорындардың жұмыс желісінің ортасынған майдаусыздар, организаторлардың идеялары сізді жеткізу және проектілерді қалам-қалама жасауға көмек етеді.",
            "Қуанышпаз":
                "Сіздер әзірлек пен рахаттықты кәсіпкерлік өмірде тападыңыз. Сіздің дайындықтарыңыз сізді жұмыспен рахат төндіруге және жоғары нәтижелерге жетуге мүмкіндік береді. Сіздер рахаттықты, толықтықты бағалайдыңыз және өз жұмыс процесіңізді рахат және оңай етібарлы етудің жолдарын табадыңыз.",
            "Қылмыс зауытшы":
                "Сізде байланысушы және адамдарға әсер етеу үшін бірегей сый бар. Сіздің дарындықтарыңыз сізге барлық орташылықта орташа жер табуға және истіліктеріңізді болдыруға мүмкіндік береді, ол сізді кәсіпкерлік сферада аса орындарлы етеді. Сіз өсу және жетістікке деген қуатты одақты, содан ішті басқару және оң әсер ету үшін жетістікке ұмтыласыз. Тең жаңа болыңыз, сіз күнді алдындағы ең қиын шешімдерге дейін жетуге мүмкіндік беретін көмекшілік пен дипломатиялық бойдау қабілетіңіз бар.",
            "Лоббист":
                "Сізде тынықтану дайындықтарын және әсер ету үшін бірегей сый бар. Сіздің қабілеттеріңіз байланысты оңайлауға, басқаларды өз тарапына ұсынуға және мақсаттарыңызға жетуге мүмкіндік береді. Сіз процестерді басқару, стратегияны жасау және қызыгушылықтарды төлеуге қадірсіздер. Басқаларды әсерлеу қабілетіңіз сізді әр түрлі салада мүлік қылу үшін көптеген маңызды етесіздер. Сіздің бастамашылық және ұйымдастыру дайындықтарыңыз сізді қиын жағдайларда және кедергілерді арқыла жұмыс істеуде сот жоюға көмек етеді.",
            "Саясатшы":
                "Басқару және әсер ету қабілеттеріңіз сізді саясаттан үстем етуде жетістілікке көмек етеді. Сіз маңызды қерарларды қабылдау, қарама-қарсы ортасын табу және түрлі пікірле болған адамдармен байланысқан жүйрік қолайлы табуға қадірсіздер. Жанды іске асырым, өзгеру үшін құлық және басқаларды өз пікірлеріңізге тарту қабілетіңіз сізді әмірге күшейтуші етеді. Сіздің абыройлы характеріңіз және өзіне сенімділік пайдалы үлес жасау және облыс процестеріне өз үлесіңізді енгізу үшін құдірет етеді.",
            "Материалист":
                "Сіз материалды байлықпеншілігін бағалайдыңыз және нәтижелер үшін жұмыс істеуге қадірсіздер. Сіздің прагматизміңіз және мақсаттылықтық шешіміңіз сізді кез-келген уақытта перде ұстанады. Сіздер ресурстарды басқару, жоспарлау және өз жоспарларыңызды орындау туралы дайындықтары қарсы алмайтын, өздеріңізді қай кезде бір команда немесе жобада қажет етпедігін қамтитындарыңызды қабілетіңіз бар.",

            "Консерватор":
                "Сіздің дарындылықтарыңызды және қабілеттеріңізді реттеуші рөлімен толық тілейді. Сіз ұшығушылық, рет-көрсеткіш, дағдыса құндылық болып, оларды кез келген кәсіпкерлік алаңда болады. Сізде реттелу және жүйелеу қабілеті бар, бұл әрекетті астанаға беруді қолданып. Сіздің қорлыққа және тырысǵа делінгендіктеріңіз, нысанды және тура қалыптасу болуы мүмкін.",
            "Социализатор":
                "Сіз ісімдік құрушы рөліне болмаса, кім болады. Сіздің қабілеттеріңізді және да кәрі вықтылықтарыңызды тайып, біріккен мақсаттарға жетуде қатыссыздық қосуға мүмкіндік береді. Сіздің төменгі түрлі құралдарыңыз, жоюшылықтыңыз және бас жүргізу қабілетіңіз сізді кез келген жобаға айырыма беретін өзегі болады.",
            "Таратушы":
                "Сіз приверженец рөліне толық сәйкессіз. Сіздің дағдыларыңыз және таланттарыңыз жалпы мақсаттарды жетуге сенімді және садақатты болуға мүмкіндік береді. Сіздің пысылдық, жалқаулылық және командада жұмыс істеу дағдыларыңыз сізді кез келген жобада ауырмалдануы мүмкін емес мүшені жасайды.",
            "Сақлаушы":
                "Басқаларға көмек көрсету және тартип сақтау қабілеті сізді толық хранитель жасайды. Сіздің дағдыларыңыз маңызды қасиеттер мен ресурстарды сақтауға мүмкіндік береді, өзге жағдайларда жауапкершілік және дисциплиналылық сізге кез келген жағдайда туралық және сенімділік қамтамасыз етуге көмек болады.",
            "Көпшілік білікті":
                "Сіздің да бойымдылықтарыңыз ішке көшеру қару-жарақ болууда сіз автор одан ғана. Сізде алаңды қөтеру, реттелу және заманауи идеяларға ауыстыру қабілеті болады. Создательствоңыз және ентузиазмыңыз сізге жаңа жағдайларға шыға алу және болымша мотивациямен шешілген мәселелерге мүмкіндік береді. Энергиясыңыз және емпатиясыңыз сізді барлық командалардың сүйікті мүшесіне айналдырар.",
            "Ұйымдастырушы":
                "Сізде табиғи организациялау және планироватьдическое мақсаты бар. Сіздің байланыс және ресурс басқару қабілеттеріңіз адамдарды және тапсырымдарды өтінішті реттей алатындықтары бар. Сіз жұмысты оқыту қажеттілігі дәріптелу және команданың рухын сақтау қабілеті ролында біріккен жобада елеуметтилігінің қажеттілігіне қанықтайсыз.",
            "Оптимист":
                "Сіздің оптимизміңіз және болашаққа келуші ойыныңыз сізге кез келген кәсіпкер тізімінде үстем береді. Кез келген жағдайда жоғары біржолға жету қабілетіңіз және басқа адамдарды бақылау қабілети шешімдерді тез табуға, күйді басып өтуге болады. Ентузиазмыңыз және оянулыққа сенім өзіне жатқызар және жалпы өнімділікті көтереді.",
            "Активист":
                "Сіздің енергиясыңыз, ентузиазмыңыз және адамдарды әсерлей алатын қолында ідеальды активист боларсыз. Сіз бірде жаттықтыру процессіне қатысуға жатасыз да болысу емес, ерекшелікпен көптеген адамдарды қосу мүмкін. Сіздің байланыс және организациялау қабілеттеріңіз, әрі ойларыңызды сәтті жүзеге асыруда үлгерімді жеткізу үшін көмек болады. Сіздің ентузиазмыңыз және озықтыққа сенім өздерге ілхам береді және зор нәтижелерге жетуге мүмкіндік береді.",
            "Идеялық көзге қарушы":
                "Әлемді ұлға рамкальдарда көру қабілетіңіз және мәселелерді шешу үшін творческий аспекті сізді идеалды көріністі шығарушы болар түр. Сіздің идеяларыңыз адамдардың жанында шындандыру жарықша көрсетеді, оларға жаңа нешелер істеу үшін көмек береді. Сіздің интуициялық және да болашаққа арналған болашаққа арналған мүмкіндіктерді безеуге мүмкіндік береді және инновациялық шешімдер жасауға мүмкіндік береді.",

            "Романтик":
                "Әлемді негізгі түрде түсіну және сезгіштігіңіз сізді шынайы романтик қылып табылады. Көбден-көп нысан түрде сезгілдік пен сезімдеріңізді жұмысыңызға айналдыру қабілетіңіз болады. Басқаларды құтыту және қауырмасыздандыру қабілетіңіз  жаңа пікірлерге және нұсқаулықтарға ашулынуына кілт болуы мүмкін.",
            "Меценат":
                "Сүйіспеншілік және армонияны жаттығу, өзге адамдарға көмек көрсету, олардың құрметін көтеріп, өнерді алғыслау құрметті сенімдерін өзгерту және даму қабілеттері сізге өнердің идеалды сүйіктісі болуы мүмкін етеді.",
            "Түсінікпаз":
                "Толық айналған пікірлер, эмоцияларға тереңдікке күшейту және көпше-көрші қарау туралы күшейтін қабілеттеріңіз сізді іс-шара әріпшісі қылып табылады. Сіздің үлгерімділік және жаттығушылық қабілеттеріңіз, сіздің қолыңыздың жалпы қарауына жол ашуға мүмкіндік жасайды.",
            "Идеялық лидер":
                "Жаңа пікірлер жасау және орындау, олармен бірге басқаларды  құтыту және белсендік беру күшейтін қабілеттеріңіз сізді ідеалды ойдастырғыш қылып табылады. Сіздің алдын-ала ойлау, жARаттылық жақтарыңыз және аңғым етіп адамдарға жаңа мүмкіндіктер ашу ұлы қуат болып табылады.",
            "Имиджмейкер":
                "ЖARаттылық көрінісіңіз және зерттелgen стиль сезімі сізге адамдарды әсер ететін суреттер жасауға және сақтауға мүмкіндік береді. Сіздің сүйіспеншілік сезіміңіз, жаңалыққа қабілетты болу және сыртқы сурет арқылы пікірлер мен маңызды құқықтарды білдіру қабілеттері қолданбаларды толтыратын жарық қойады.",
            "Идеялық":
                "Түпнұсқа пікірлерді жасау және даму қабілеттеріңіз сізді орынды пікірге арналатын қызметші қылып табылады. Мәселелерді шешу кезіндегі жARаттылық тапсырмаңыз таңғалар жасыратын жолдарын табу құқығын нақтылықпен тапады және оларды өзге адамдар жоғары қатынасады.",
            "Бастамашы":
                "Үшін шығарады, жаңалыққа күшейтін ережелер сізді ідеалды бастаушы қылып еді. Сіздің жаңа жобаларды бастау, әр түрлі пікірлерді біріктіру және адамдарды ортақ мақсатка бағыттау қабілеттеріңіз бірдей жобаның бастауын қолдану үшін сізге керек болады, әсіресе оларды жARаттылық пікірлерге қажет етеді.",
            "Проповедник":
                "Сіздің сенім, басқалардың эмоциялық жағымен байланысу және әлемді өзге адамдарды артықшылықтан шилтелеу сізді ідеалды дін басқақатқы. Сіздің көмек көрсетушілігіңіз, адамдардың сезімдеріне жүйкілікті жеткізу қабілетіңіз сізге адамдармен еффектілі жолмен обыздыра алауға мүмкіндік береді. Сіз нәрселердің жүрегін қарап, ойлардыңызды және пікірлеріңізді ашық көріністе береді.",

            "Моралист":
                "Сенде жоғары деңгейдегі этикалық бейімділік бар, тура тұрғылыққа және бірлікке көңіл бөлуің, сені идеалды моралист қылып табылады. Адам табиғатынан үйренуің, құндылықтарды және принциптерді қадрлауың және орта жағығыңды жақсартуға тырысуың сені кез келген топтың немесе органдардың бәрінша қажет етіледі.",
            "Көзге қарушы":
                "Сенің үйрену мәліметің және адам табиғатына жақын үйренуің сені идеалды білім беруші қылып табылады. Сенде ойыншылдық айқындастыру және шешімдер ұсыну, басқа адамдардың қабілеттерін ашу және дамытуға көмек ету мүмкіндігі бар. Сабырлы, байқаушы және сезімді болу кабілетің сенің оқушыларыңдың құны мен сенімді көрсетуін қамтамасыз етеді.",
            "Мұғалім":
                "Сіздің білім алуға деген қызғылтуыңыз және адам табиғатының дерін түсінігі сізді идеалды мұғалім қылып келеді. Сізде мәселелердің мазмұнына кіру және шешім ұсыну күнделіктіліктері бар, сондай-ақ басқа адамдардың өз кабілеттерін ашып-ұлғайтуға көмек көрсету қабілеті бар. Сіздің шыдамды, назик және сезімді болу қабілетіңіз сізге оқушылардан көңілдік және сенім келтіреді.",
            "Эмоционалды лидер":
                "Сенің эмоционалды назиктіктерің, басқаларға күшті ықпал ету және жақын байланыстар құру кабілетің сені идеалды эмоционалды лидер қылып табылады. Басқалардың эмоцияларын түсіну және отраздайтын кабілетің, адамдардан және командадан көмексіз және мотивациялық кезеңдерің, олардың өндірістігі және қанағаттану деңгейлерін асқан көбейтеді.",
            "Энтузиаст":
                "Сенің энергиялық, ашық және оптимистік өзгеруің жаңа маңызды және баймал және баймал және баймал жобада қатысуға мүмкіндік береді. Сен жылы тұрақ және бөлісу тек қарайы, белгілі болады.",
            "Наставник":
                "Басқа адамдардың жатыратын қажеттіліктерге тиіс уақыт және адамдарға жақындайтын кабілетің, сені идеалды ментор қылып табылады. Сабырлы, емпатиялық және білім мен тәжірибені беру кабілетіңне жаттығу қажет.",
            "Әртіс":
                "Сенің шығармашылық, эмоционалық білдіру және өзгеше дүние қарастары cізді идеалды өнеркөш қыла алады. Өзіңді іпарат ету және шығармашылықпен ойлау, сондай-ақ өздеріңнің қринді көру қабілеті әр түрлі және ықпал етуші жұмыстарды жаратуға мүмкіндік береді.",
            "Шебер":
                "Сенің деталдарға бағандануың, практикалық ойлау және мәселе шешу дағдыларынды жақсы басқару кабілетің сені толық ыстық қылып табылады. Сіз әр түрлі детальды бағалайсыз, жұмыста ең ұздык сапаны жету үшін тырысасыз. Сенің колмен жұмыс жасау, жақсарту және даулап ету қабілетің көптеген кәсіпкерліктерде өте құнды.",
            "Оптимизатор":
                "Жумыс процесін жетілдіру, практика және ұйқымды шешім табуың сені идеалды оптимизатор қылып табады. Сіз әрдайым жобаны жодырқай және жұмысқа боларлық жеткізу үшін имкандар ақықатын жатасыз. сіздің байық көрінісіңізді көру, күрделі системаларды талдау және оптимизациялау қабілетің сіздің негізгі қорекесі көрінеді.",

            "Технолог":
                "Сіздің техникалық түсініктенуіңіз, талдауға қабілетіңіз және күрделі системаларды түсінуіңіз сізді идеалды техниктер қылып жатыр. Сіз технологиямен жұмыс істеу, оның қалай жұмыс істейтінін түсіну және оны өзіңізге пайдалы маңыздар шешу үшін пайдалана аласыз. Жылдам үйрену және жаңа технологияларға көздестіруге қабілетіңіз көптеген артықшылықтарыңыздың бірі.",
            "Маманды":
                "Сіздің қолдарды жұмыс істеуде құдіретіңіз, ынталықтарыңыз және ойыншыл өзге таппай қызметші. Сіз өз көзіңізбен жарату, жөндеу және жаңалау қызметіне қабілітетіңіз бар. Күнделіктігіне бағымындылық жасауыңыз, материалдар мен құралдар бен ағаш-жүргізуден рухани қуат алуыңыз - бұл қуатыңыз.",
            "Зорлаушы зауытшы":
                'Сіздің ұрыс көрсетуіңіз, жылдам шешім қабулдауыңыз және ықпалды басшы болуыңыз сізді ідеалды "зорлыққа көмек ететін қауіпсіз басшы" істіп жатыр. Олар қиындықтарды көргенде сіз мүмкіндіктерді көресіз және әрдайым мәртебелі жұмыс жасауға дайын боласыз. Сіз команданы бақылау және бағымды қатынау жасау үшін мақсат орнатуға идеалдысыз, тым большую тықпақтың іске асырға тырысып, команда мендеулеріңіз үшін міндетті боласыз.',
            "Лидер":
                "Сіздің батылығыңыз, өздеріне сенімділік және адамдарды қозғау қабілеті сізді идеалды басшы қылып жатыр. Сіз әрдайым өзіңіздің шешімдеріне сенімдісіз және өз және командага сенім уйқытудың қалай іске асыратыны білесіз. Сіздің табиғи бастамашылығыңыз, батылығыңыз және қиын жағдайларда жауапкершілік етуіңіз қолға келетін негізгі сипаттарыңыз.",
            "Баскарушы":
                "Сіздің ұйымдастыру күнделіктеріңіз, шешім қабылдау қабілетіңіз және дерттегі батылдық қасиетіңіз сізді төменде баскарушы жасайды. Сіз ресурстарды, адамдарды және процестерді басқаруға талантталысыз, қичінелі мақсат орнатып және оны жету. Қиңыр ситуацияларда тыныштықты сақтау қабілетіңіз және бәрікімді жақтарға алу сізге басқару сферасында жетістікке жетуге көмекші болып табылады.",
            "Легионер":
                "Сіздің ықылас болуыңыз, жаттысуда тармақ дайындайтын әдіс және өмір сүрау қабілеті сізді тамаша легионер қыла береді. Сіздің ықуыл болуыңыз, байқау қабілетіңіз және жеңістің айғақтылығы сізге мақсаттарыңызға жету үшін көмек қылып отырады. Сіздің әрдайым бас жолын толықтайтыныңыз және қажеттігінде, жұмыс орындау үшін тек қара келетін көруіңіз - бұл бастағандарыңыздан басқа.",
            "Инспектор":
                "Толтырып арқылы дайындалды және бағдарламата мүмкіндіктерді, жөндеме жаттықтыруды және өзекелеген сырғататын тайықты болуыңыз бақылау және тексерудежақы көп пайдалы бола тұратын міндеттердің бірі. Сіздің пікір жасау және жасалған стандарттар мен әдістерді жорықтарыңыз жоғары сапаға ие жұмыс жасайды.",
            "Контроллер":
                "Сіздің стрес пайда болған кезде ыра болуыңыз, детальга арналған назарыңыз болып табатынғаныңызды қамтамасыз ететін мұндай ергек иновациондық жолмен өте күрделі процестерге жеткізу қабілетіңіз. Сіз әрекеттер мен стандарттарды сақтауда жатады, бұл сіздің жобаларыңыздың өнімділігіне және қауіпсіздігіне өз келе шейін қол жеткізеді.",
            "Стабилизатор":
                "Сіздің өзгеріске төзімді болуыңыз, тұрақтылық және мақсаттарды орындауда көз көруге қабілетіңіз команда немесе жоба үшін тұрақтылықтар мен дайыностықтарды күшейтеді. Сіздің тек бір ретті және тура жөндеуді үшін мұнай жұмыста жол ашу қабілетіңіз бен тізбектілік пен болжамдылық үшін арзанмен көретін қызметіңізға көмектесу қажет.",

            "Инженер":
                "Сіздің системалық және талдаушы ойлау дағдыларыңыз, мәселе шешу бойынша шығармашыл жолдар мен деталдарға жақын махаббатындай болған марапатты инженер және техникалық міндеттерге болашақ жолдас болуға болады. Сіздің деталдармен дәуірді жұмыс жасау, күрделі жүйелерді түсініп, оларды жақсарту және жаңа технологияларға қосылу қабілеттеріңіздің техникалық салада сәттікілігіңізге кепілдік болады.",
            "Управленец":
                "Сіздің ұйымдастыру, жоспарлау және басқару қабілеттеріңіз, сондай-ақ өзара байланыс жасау және ефективті тағайындау қабілетіңіз сізді идеалды менеджер болуға айналатады. Сіз командаларды үйлестіруге, ресурстарды басқаруға және мақсаттарды сәтті ортая салуға түрлі көзқарастыру жағдайларда да қолайлы боласыз.",
            "Практиктік":
                "Сіздің тегісті жұмысқа қарсы шығармашыл мұндайдық қызметіңіз, нақты және өлшендірілетін нәтижелерге ыңғайлы қарауыңыз сізді шын мамандық болуға кепілдік береді. Сіздің ойларды өмірдегі құралуыңыз, тегісті қадамдар қабылдауыңыз және реалистік жақтан қарауыգыз сіздің негізгі мұндайдықтарыңыз.",
            "Орындаушы":
                "Сіздің пландарды ырайымшылықпен орындау, мақсатка байланыстыру және жұмысқа тегісті мұндайдық қызметіңіз сізді идеалды қолданба жасаушы болуға жақсы үйлеседі. Ойларды өмірдегі құралудың және нақты нәтижелерге жету қабілетің турған әр ұйымда маңызды.",
            "Администратор":
                "Сіздің ұйымдастыру дағдыларыңыз, деталдарға қызығуыңыз және бірдақарау жұмыстарды ұйымдастыру қабілетіңіз сізді бақылаушы тұтар дайынды болуға көмек етеди. Сіздің ресурстарды басқару, тәртіпті орнату және сақтау және байланыс әдісеттерінің тиімділігін қамтамасыз ету сіздің кәсіпкерлік жетістіктеріңіздің негізін құрады.",
            "Сұр топшырақ":
                "Сіздің стратегиялық ойлау, талдау үшін мүлдем қабілетіңіз және барлық ыңғайлы сценарийлерді есептеу қабілетіңіз сізді 'сұр тұшбалай' рөлінде болуға идеалды мегзекен болып табылады. Сіз закуо көрінбейтін өзара байланыс ортасында жұмыс жасауға, шешім жасау процесіне көмек етуде жаттығуға түсінікті боласыз.",
            "Прогнозқор":
                "Сіздің деталдарға қызығуыңыз, талдаушы ойлауыңыз және тенденцияларды болжамдау қабілетіңіз сізді мақұл жағдай талдаушысы болуға көмек етеді. Сіздің улкен суретті көру, ақпаратты талдау және келешектегі оқиғалар не тенденцияларды алдын-ала болжамдау қабілетіңіз сәтті стратегиялық жоспарлауға негіз болады.",
            "Скептик":
                "Сіздің танымды ойлау, қарап шықтау және шығармашыл болу қабілеттеріңіз сізді жақсы шығармашы болуға көмек етеді. Сіз әрдайым дағдылы жаттықжақтар мен ойларды сұрауды қашан да қарарады, оларды әр түрлі бағыттардан талдаып, олардың зайлылықтарын анықтай аласыз.",

            "Критик":
                "Критикалды бағалау және анализдеу кәсіби жолмен, қалаұлылықтан және дәлдігімен арадағы қызығушылық сізді идеалды критик болып жасайды. Сіз кемшіліктерді байқау және жетіспеулерді ұсыну қабілетіндегісіз, бұл дайындық пен дамуға толық заманауи бағытты қамтамасыз етеді.",
            "Өнер көрнекпаз":
                "Ерекшелегіңіз - сіздің тозықты енергияңыз, жаңа нәрселерге арналған мүддейліліктіңіз және түрлі ауырмалы жаттығушылығыңыз. Сіз әрдайым жаңа ойдарды іздеп жүресіз, тәжірибеден ашық болып, патентті техникалық жаңалықтарды орындауға дайын боласыз. Бұл сізді жаңатуға, ережелерді жаңартуға және әдеттегі шекара сырттан шығуға мүмкіндік береді. Сіз өзгектік қатысаумен команданы жылдам және жаңалық болуын қамтамасыз етесіз, әрі сіздің ойларыңыз өте жиі жаңалықтық болып табылады.",
            "Жалпызатасы":
                "Сіздің мәлімет құмағыңыз, өзіңді үнемі жақтауға болған қуыныс, оқиғаларды терең шолу қабілеті сізді жалпызатасы жасайды. Сіздің білімге деген қуатты қызығушылық пен дұрыс сұрақтар қою қабілеті оқиғалар мен мәселелерге терең түсінікке жеткізеді. Сіз басқалардың көрмейтін жерде байланыстарды табуға қабілеттісіз, бұл жаңа стратегиялар мен ысқырларды жасауға мүмкіндік береді. Сіздің жалпызатасы дәріптеріңіз және заттардың мәнін түсінуге деген тырысуыңыз даму және жаңалықтарға негіз болып табылады.",
            "Жаңаданушы":
                "Сіздің болмаса тырысу үзінде жаңа заттар қарай қуатты қызығушылығыңыз, стандартсыз ойлауға сұрулу және статус-квони шынайды болмауға дайындық жасайды жаңаданушы. Сіз заттарды олардың келісіп шыққан көрінісімен қабылдамайсыз, бірақ әрдайым жаңа жолдар мен жақсы шешімдер іздесіз. Сіз басқалардың адістер көреді жерде мүмкіндік табуға қабілеттісіз, және сіздің жаңалықтарға мүмкіндік сіздің командаңызды немесе ұйымыңызды жетістіруге көмек етеді.",
            "Генератор":
                "Сіздің сиырлылығыңыз, идеяларды тез алгашқы болып өндірге қабілетіңіз жӣне әрдайым жаңалықтарға заманауи тілге беру қабілетіңіз сізді үлгеріш оттоқысы үшін өте маңызды етеді. Сіздің ойларыңыз өте жиі өзгеріске айқас болуы мүмкін, және сіздің жарықты тигізу қабілетіңіз командаға жаңалықты көру ынайы мен культуралық мөлшерін сақтауға үлес көрсетеді. Сіздің басқа адамдардың елемейтін мүмкіндіктерді табу көзіңіздегі үнік сипатыңыздың орындауы, идеяларды дайыр жасау қабілетіңіздің арқалауы және бағыттауы үшін істеп жатқан орын, организация, жоба болмақ.",
            "Аналитик":
                "Сіздің анализдей беру және жарықтырып тастау қабілетіңіз сізді дайындықтары сұранатын аналитик етеді. Сіз әрдайым мүмкін болар мен әр аймақтың толық кескін тесіндегі болады, әрі сіздің ақылды бағалау қабілетіңіз жӣне ақпаратты және деректерді объективті бағалау қабілетіңіз сізге оқиныс шындықтарды ұсыну үшін болмақ.",
            "Системалық":
                "Тәртип, структура жӣне жүйелеу үшін сіздің соңқұтты нұсқачаңыз сізді жаңалық болып табылады. Ақпаратты, байланыстарды тәртипті және ашық және логикалық жүйелерді құру қабілетіңіз сізге хаосқа нысан болу қабілетіңізді береді. Бұл организациялар процестерін жақсартуға, өзара байланысты жаңалыларды арттыруға жӣ ендіруге көмек етеді.",
            "Өнеркәсіби":
                "Сіздің намысты өздеріңізге көрсетуіңіз, толық барлық көлемдегі әрекеттер жӣне жинақтау детальдар ойдаб әсер етіп жатыр. Ис жүк аяқтады, кәсіби деңгейде мүмкін болар дайын. Сіздің жоғары стандарттарыңыз жӣ жақсарту туралы шабытты жаттығуыңыздың сыйфатын берді, бұл жоғары сапалы жұмыс кезінде көрінетін той құралыңыз бола етеді.",
            "Рационализатор":
                "Сіздің анализдеу және оптимиздеу қабілетіңіз сізді жаңалықтанушы етеді. Сіз жедел жолымен ис-шара қарай орналарды табу мүддейлілігіңіз жӣ береді. Сіздің оптимизацияға арналған себепшілігіңіз жӣ нысандай кететін шешенді жаңарту қабілетіңіздегі организациялардың азайтуға жӣ көмек көрсету қабілетіңізді береди, әрі олардың өнім немесе қызметтерінің акуылы болатын меңгеру қабілетіңіздегі серіктестікке өте жақын келесіз.",

            "Қырметтілік жаратушы":
                "Басқалары орнын таба алмайтын жерде мүмкіндіктерді көруге, жаңа ойларға ашық болуға және дайын болуға қабілетіңіз сізді кәсіпкерлік жасаушы ретінде анықтайды. Сіздердің үздік үлгіреу қабілеттеріңіз кез келген ұйымда немесе жобада өсу және жаңғырту ушының болуы мүмкін.",
            "Алгоритмшы":
                "Ақпаратты өңдеуге, алгоритмдер жасауға және білім ұйымдастыруға болатын күштіліктеріңіз сізді бірегей алгоритмдік жасайды. Сіз жұмыс процесіне логика және ұйым саласыз, бұл ұйымдарға жұмыс істеу процесін жетілдіру және болжамдашуға көмек етеді. Сіздердің алгоритмді ойлау күштілігіңіз жұмыстың продуктивтілігін және сапасын көтереді.",
            "Интегратор":
                "Адамдарды, ойларды және ресурстарды біріктіруге қабілетіңіз сізді интегратор ретінде анықтайды. Сіз әр түрлі элементтің қалай өзара әсер ететінін және өзара әсер ететінін көруге қабілеттісіз, бұл командаларды үздік пен тиімдірек жасауды мүмкіндік береді. Сіздің интеграцияға бағытталған адалдығыңыз ұйымдарға синергиялар жаратуға және олардың өнімділігін көтеруге көмек етеді.",
            "Эксперименттеуші":
                "Гипотезаларды тексеруге, номзодтерді сынақтан өткізуге және жалпы табиғатты жетілдіруге сұраныс қойуға, қателіктер жасаудан қорқпаймын, оларды үйрену мүмкіндігі ретінде көремін. Тезден өзгерушілікке ұшырау үшін қолданбаларды өзгертуге және бұзылысқа байланысты қауіпсіздікке шыдамдылығыңыз ұйымдарға салдыратын өзгеруден қорқпаймын.",
        },
    },
    WelcomeSubscribe: {
        title: "Мен сізді өсу үшін үлгертемін, таңдалған салаларда жетістік қазандыратын және ақша табуға көмек етемін",
        subtitle:
            "Профильіңізге өтуден бұрын - сіздің үшін қазір осы заманда тамаша ұсыныс бар<br/><br/> Оның ішінде нелер бар:",
        pay: "{price} РУБ/ай төлемді толтырып барлық жетімділікке ие болу",
        "to-profile": "Профильге өту",
        list: [
            {
                id: 1,
                icon: "people-recommendations",
                text: "Сізге сайттан 5 пайдаланушының сізмен табысқан бизнес ынтымақтастығы бар деп саналады",
            },
            {
                id: 2,
                icon: "text-recommendations",
                text: "Психотипіңіздің терең талдауын және оның шифрлауын алыңыз",
            },
            {
                id: 3,
                icon: "brain",
                text: "Қасиеттіңіздің және мінез-құлығының сипаттамасын алыңыз",
            },
            {
                id: 4,
                icon: "environment",
                text: "Бизнесті өсуде психотипіңізге арналған 10-дан астам ұсыныс",
            },
            {
                id: 5,
                icon: "stars",
                text: "Сізде атап өткізгендер дерекқорына қолжетімділік болады және олармен жеке тұру мүмкіндігі немесе бірлесуді бастау немесе өздерінің әдестігін білу мүмкіндігі болады",
            },
            {
                id: 6,
                icon: "photo",
                text: "Сіз сайтта және сайттың сыртқы өзен болып любое адамды бизнестік сәйкес қарай алады",
            },
        ],
    },
};

/* eslint-disable */
export default {
    "newbie": "Nomlanmagan",
    "logout": "Chiqish",
    days: "kun",
    "onBoardingMixin": {
        "title-1": "Psixotip ta'rifi",
        "title-2": "Daromad",
        "description-1": "Merlin AI sizning psixotipingizni aniqlagandan so'ng, siz va sahifangizning barcha mehmonlari buni ko'rasiz. Merlin sizni ijtimoiy tarmoqning boshqa foydalanuvchilariga ham tavsiya qila boshlaydi,",
        "description-2": "Siz sertifikat olishni xohlaysizmi?"
    },
    "errors": {
        "unknown-error": "Noma'lum xato",
        error: "Xato ro'y berdi",
        unknownError: "Noma'lum xato",
        attention: "Diqqat",
        wrongPhotoTitle: "Surat ishlamadi",
        noAccess: "Kirish yo'q",
        alreadyFriendsTitle: "Siz allaqachon do'stsiz!",
        info: "Ma `lumot",
        reEnter: "Hisobingizga qayta kiring yoki biz bilan bog'laning.",
        notEnoughMoneyTitle: "Mablag'lar yetarli emas",
        compareNotAllowedTitle: "Taqqoslash mumkin emas",
        retry: "Hatolik ro'y berdi. Iltimos keyinroq yana urinib ko'ring.",
        noAccessText:
            "Siz uchun mavjud bo'lmagan xususiyatdan foydalanmoqchisiz.",
        personDataNotReceived:
            "Shaxsingiz maʼlumotlarini olishda xatolik yuz berdi; sahifani qayta yuklashingiz kerak boʻlishi mumkin.",
        wrongProfilePhoto:
            "Profil rasminiz mos kelmayapti, boshqa rasm yuklang. Namunaviy variantlarga e'tibor bering.",
        wrongPhotoToTheRight:
            "Siz boshingizni o'ta o'ngga burdingiz, yana urinib ko'ring.",
        wrongPhotoToTheLeft:
            "Rasm mos kelmayapti, boshingizni o'ta chapga burdingiz, yana urinib ko'ring.",
        wrongPhotoToTheBottom:
            "Rasm mos kelmayapti, boshingizni o'ta pastga egdingiz, yana urinib ko'ring.",
        wrongPhotoFewPersons:
            "Rasmda bir nechta yuzlar aniqlandi, rasmni o'zgartirib, faqat kerakli yuzni qoldiring",
        wrongPhotoToTheUp:
            "Rasm mos kelmayapti, boshingizni o'ta yukoriga ko'tardingiz, yana urinib ko'ring.",
        wrongPhotoSame:
            "Siz anfas va profil uchun bir xil rasmni yukladingiz, boshqa rasm yuklang",
        wrongPhotoFeatures:
            "Sizning rasmlaringizdan yuz xususiyatlari olish uchun o'zgargan. Iltimos, barcha rasmlarni o'zgartiring.",
        wrongPhotoYouAreNot:
            "Yangi fotografiyada sizning yuzingiz topilmadi. Sizning yuzingiz bilan rasmlarni yuklang",
        wrongAnfasPhoto:
            "Anfas foto mos kelmayapti, boshqa rasm yuklang. Namunaviy variantlarga e'tibor bering.",
        wrongCelebrityPhoto:
            "Tanlangan shaxsning fotosini tahlil qilishda xatolik yuz berdi. Foydalanuvchi to'g'ri fotolarni yuklagan miqdorda, solishtirish va tavsif mavjud bo'ladi",
        photoNotUploaded:
            "Foto yuklab bo'lmadi, keyinroq yana urinib ko'ring...",
        myPhotoNotUploaded:
            "Sizning fotolar yuklab bo'lmadi. Iltimos, sahifani yangilang.",
        paymentsListNotReceived:
            "To'lovlar ro'yxatini olish mumkin emas, keyinroq urinib ko'ring.",
        incorrectPaymentData:
            "To'lov ma'lumotlari noto'g'ri. Iltimos, keyinroq yana urinib ko'ring.",
        paymentGateWayNotRespond:
            "To'lov gatewayi javob berishdi. Iltimos, keyinroq yana urinib ko'ring.",
        friendStatusNotReceived:
            "Do'stning holatini olish mumkin emas, keyinroq yana urinib ko'ring.",
        pageError:
            "Bu sahifada noma'lum muammo yuz berdi, sahifani yangilang va amalni yana bajaring.",
        friendRequestError:
            "Do'stlariga so'roq yuborish mumkin emas, keyinroq urinib ko'ring.",
        alreadyFriends:
            "Agar siz \"Do'stga qo'shish\" tugmasini ko'rasangiz, iltimos, sahifani yangilang.",
        alreadySendFriendRequest:
            "Siz allaqachon so'rov yuborgan ediz. Iltimos, do'stingiz so'rovni qabul qilguncha kuting.",
        friendRequestDecline:
            "Afsuski, do'stlarning so'rovi rad etildi. Siz o'z so'rovingizni oxirgi urinishdan keyin 24 soatdan keyin yana urinishingiz mumkin.",
        notificationsNotReceived:
            "Sizning ogohlantirishlar ro'yxatini olish mumkin emas, keyinroq urinib ko'ring.",
        servicePriceNotReceived:
            "Xizmat narxini olish mumkin emas. Sahifani yangilang va qaytadan urinib ko'ring",
        needAuth: "Ushbu amalni bajarmoq uchun autorizatsiya kerak",
        notEnoughMoney:
            "Iltimos, hisobingizni istalgan qulay usulda to'ldiring.",
        personListNotReceived:
            "Shaxslar ro'yxatini olgan paytda xatolik yuz berdi, keyinroq harakat qilib ko'ring.",
        friendListNotReceived:
            "Do'stlar ro'yxatini olgan paytda xatolik yuz berdi, keyinroq harakat qilib ko'ring.",
        badName:
            "Tanlangan nom mos kelmaydi, boshqalarini sinab ko'ring yoki keyinroq harakat qilib ko'ring.",
        nameLimit: "Nomi faqat 24 soat ichida bir marta o'zgartirish mumkin.",
        friendsCount:
            "Do'stlar sonini olgan paytda xatolik yuz berdi. Keyinroq so'rovni takrorlang.",
        friendRequestsCount:
            "Do'stlarga murojaatlar sonini olgan paytda xatolik yuz berdi. Keyinroq so'rovni takrorlang.",
        compareNotAllowed:
            "O'zingizni boshqa shaxslar bilan solishtirish uchun, o'zingizning fotosuratlarini yuklash kerak",
        photoUploadPersonIsNotFound:
            "Siz fotosurat o'rnatayotgan sahifa topilmadi",
        photoUploadPersonIsNotYours:
            "Siz fotosurat o'rnatmoqchi bo'lgan sahifa sizga tegishli emas.",
        yourFaceIsNotFound:
            "Yangi fotosuratda sizning yuzingiz topilmadi, iltimos yuzingiz bilan fotosurat yuklang.",
        partnerStatusNotReceived: "Hamkorlik holatini olish mumkin emas.",
        uuidError:
            "Foydalanuvchining noto'g'ri ID-si yuborildi, administratorga murojaat qiling.",
        alreadyPartner: "Foydalanuvchi allaqachon sizning hamkoringiz.",
        waitingResponse:
            "So'rov avvalroq yuborilgan, hamkorlik qarorini kuting.",
        partnerRequestDeclined:
            "Sizning so'rovingiz rad etildi, so'rovni oxirgi so'rovdan keyin 24 soatdan so'ng qayta urinish mumkin.",
        partnerResponseAlreadySend:
            "So'rov topilmadi. Ehtimol, siz allaqachon uni qabul qilgansiz.",
        partnerResponseNotSend:
            "Noto'g'ri ma'lumotlar yuborildi. Texnik yordamga murojaat qiling",
        createDescriptionError:
            "Sizning shaxsiyatingiz tavsifini yaratish mumkin emas. Texnik yordamga murojaat qiling",
        firstUploadAnfas: "Avval anfasga foto olish kerak",
        descriptionNotReady:
            "Merlin fotosuratdan yuz xossalari o'qilguncha profilga foto yuklash mumkin emas. Bir daqiqadan keyinroq qayta harakat qilib ko'ring.",
        REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Maqsadni yopish taklifini kuniga bir marta ko'proq jo'natish mumkin emas",
        BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Tadbirkorlik takliflarini kuniga bir marta ko'proq jo'natish mumkin emas",
        AVATAR_LIMIT_REACHED:
            "Siz fotosurat yuklash bo'yicha kunlik chegarani yetishingiz mumkin. Ertaga qayta harakat qilib ko'ring.",
    },
    "cookies": {
        "prefix-text": "Biz to'plamoqdamiz ",
        "text": "Kukilar",
        "btn-text": "Mayli",
    },
    "DescriptionPopup": {
        "learn-about": "Siz odam haqida o'qiyapsiz:",
        "light-background": "Yorug'lik fon",
        "dark-background": "Qorong'i fon",
        "start-learn-profession": "Bugun to'g'ri kasbni o'rganishga boshlang:",
        "start-learn-profession-btn-text": "Boshlash",
    },
    MerlinDating: {
        "title":
            "{name}'ning shaxsiyati bilan tanishuv tushunchasini qadam-ma-qadam algoritmini xohlasizmi?",
        "subtitle": "Bizning ilovamizni yuklab oling.",
    },
    "FooterLinks": {
        about: "Loyihai haqida",
        contacts: "Aloqa",
    },
    "FriendsFilter": {
        "gender": {
            all: "Hammasi",
            male: "Erkaklar",
            female: "Ayollar",
        },
        age: {
            any: "Har qanday",
            interval_1: "18 dan 24 yoshgacha",
            interval_2: "25 dan 34 yoshgacha",
            interval_3: "35 dan 44 yoshgacha",
            interval_4: "45 va undan yuqori",
        },

        typeList: [
            {
                id: "ALL",
                name: "Har qanday",
            },
            {
                id: "ADMINISTRATOR",
                name: "Administrator",
            },
            {
                id: "ACTIVIST",
                name: "Faol",
            },
            {
                id: "ALGORITHMIST",
                name: "Algoritmist",
            },
            {
                id: "ARTIST",
                name: "Rassom",
            },
            {
                id: "MASTERMIND",
                name: "Ustoz-Mutahassis",
            },
            {
                id: "HEDONIST",
                name: "Xedonist",
            },
            {
                id: "GENERATOR",
                name: "Generator",
            },
            {
                id: "DIPLOMAT_INVADER",
                name: "Diplomat Hujumchi",
            },
            {
                id: "IDEOLOGIST",
                name: "Ideolog",
            },
            {
                id: "IDEOLOGICAL_MASTERMIND",
                name: "Ideologiya Ustoz-Mutahassisi",
            },
            {
                id: "IDEOLOGICAL_LEADER",
                name: "Fikr Lideri",
            },
            {
                id: "IMAGE_MAKER",
                name: "Tasvir Yaratuvchi",
            },
            {
                id: "ENGINEER",
                name: "Muhandis",
            },
            {
                id: "INITIATOR",
                name: "Tashkilatchi",
            },
            {
                id: "INSPECTOR_PERFECTIONIST",
                name: "Perfeksionist-Tekshiruvchi",
            },
            {
                id: "INTEGRATOR",
                name: "Birlashtiruvchi",
            },
            {
                id: "RESEARCHER",
                name: "Teadbirchi",
            },
            {
                id: "RESEARCHER_ANALYST",
                name: "Teadbir Analitigi",
            },
            {
                id: "COMMUNICATOR",
                name: "Aloqachi",
            },
            {
                id: "CONNECTOR",
                name: "Birlashtiruvchi",
            },
            {
                id: "CONTROLLER",
                name: "Nazoratci",
            },
            {
                id: "CRITIC",
                name: "Tanqidchi",
            },
            {
                id: "LEGIONARY",
                name: "Legioner",
            },
            {
                id: "LEADER",
                name: "Lider",
            },
            {
                id: "LOBBYIST",
                name: "Lobbist",
            },
            {
                id: "MASS_MEDIA_ENTERTAINER",
                name: "Ommaviy Oyinchi",
            },
            {
                id: "MASTER_IMPLEMENTOR",
                name: "Amalga Oshiruvchi Ustoz",
            },
            {
                id: "MATERIALIST",
                name: "Materialist",
            },
            {
                id: "MAECENAS",
                name: "Kafolatchi",
            },
            {
                id: "DREAMER",
                name: "Orzudor",
            },
            {
                id: "MORALIST",
                name: "Akhloqchi",
            },
            {
                id: "MENTOR",
                name: "Mentor",
            },
            {
                id: "ASSERTIVE_INVADER",
                name: "Javobgar Hujumchi",
            },
            {
                id: "INFORMAL_LEADER_MANIPULATOR",
                name: "Manipulyator Koni Lider",
            },

            {
                id: "INNOVATOR",
                name: "Innovator",
            },
            {
                id: "INNOVATOR_ENTHUSIAST",
                name: "Innovator Entuziast",
            },
            {
                id: "OPTIMIZER",
                name: "Optimizer",
            },
            {
                id: "OPTIMIST",
                name: "Optimist",
            },
            {
                id: "ORGANIZER",
                name: "Organizator",
            },
            {
                id: "EDUCATOR",
                name: "O'qituvchi",
            },
            {
                id: "PERFECTIONIST",
                name: "Perfeksionist",
            },
            {
                id: "POLITICIAN",
                name: "Politik",
            },
            {
                id: "MEDIATOR_DIPLOMAT",
                name: "Diplomat Vasitachi",
            },
            {
                id: "PRACTITIONER",
                name: "Amaliyotch",
            },
            {
                id: "PRACTITIONER_CONSERVATIVE",
                name: "Konservativ Amaliyotchi",
            },
            {
                id: "PRACTITIONER_MANAGER",
                name: "Boshqaruvchi Amaliyotchi",
            },
            {
                id: "ENTREPRENEUR_INNOVATOR",
                name: "Innovativ Tadbirkor",
            },
            {
                id: "ADHERENT",
                name: "O'zgarmas Fuqaro",
            },
            {
                id: "FORECASTER",
                name: "Prognozlashuvchi",
            },
            {
                id: "PREACHER_MENTOR",
                name: "Vaiz Murabbiy",
            },
            {
                id: "RATIONALIZATOR",
                name: "Innovator",
            },
            {
                id: "IMPLEMENTATOR",
                name: "Amalga Oshiruvchi",
            },
            {
                id: "ROMANTIC",
                name: "Romantik",
            },
            {
                id: "EMINENCE_GRISE",
                name: "Qizil Kardinal",
            },
            {
                id: "SYSTEMATIZER",
                name: "Systematizator",
            },
            {
                id: "SKEPTIC",
                name: "Skeptik",
            },
            {
                id: "SOCIALIZER",
                name: "Ijtomaichilik",
            },

            {
                id: "STABILIZER",
                name: "Stabilizator",
            },
            {
                id: "TECHIE",
                name: "Texnik",
            },
            {
                id: "HANDYMAN",
                name: "Hunarmand",
            },
            {
                id: "MANAGER",
                name: "Menejer",
            },
            {
                id: "KEEPER",
                name: "Sahibkor",
            },
            {
                id: "EXPERIMENTER",
                name: "Tajribakor",
            },
            {
                id: "ENTHUSIAST",
                name: "Dolzarb",
            },
        ],
        searchSortList: [
            {
                id: "DATE",
                name: "Qo'shilgan sana",
            },
            {
                id: "NAME",
                name: "Ism",
            },
        ],
        searchList: [
            {
                id: "DATE",
                name: "Qo'shilgan sana",
            },
            {
                id: "NAME",
                name: "Ism",
            },
            {
                id: "COMPATIBILITY",
                name: "Muvofoqilik",
            },
            {
                id: "SIMILARITY",
                name: "Oxshashliklar",
            },
            {
                id: "BUSINESS",
                name: "Biznes Bo'yicha Muvofoqilik",
            },
            {
                id: "INTERACTION_BUSINESS",
                name: "Biznes Aloqasi",
            },
            {
                id: "INTERACTION_INTERPERSONAL",
                name: "Shaxsiy Aloqalar",
            },
        ],

        "any": "har qanday",
        "all": "Barcha",
        "by-age-title": "Yoshi bo'yicha:",
        "by-person-type-title": "Shaxsiyat turi bo'yicha:",
        "by-person-category-title": "Shaxsiyat kategoriyasi bo'yicha:",
        "sort-by-title": "Saralash:",
        "sort-by-default": "Qo'shilgan sana",
        "sort-by-gender-title": "Jinsi bo'yicha:",
        "reset-text": "Filtrni tiklash",
    },

    "InviteFriends": {
        "btn-text": "Do'st qo'shing",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "Bu foydalanuvchi fotosuratlar yuklamagan!",
        "feedback": "Yordam xizmatiga yozish",
        "referral-program": "Tavsiya Etish Dasturi",
        "bonus-program": "Bonus Dasturi",
        "legal-info-title": "Qonuniy Ma'lumot",
        "contacts-and-requisites": "Kontaktlar va tafsilotlar",
        "about": "kompaniya haqida",
        "service-pay": "Xizmatlar uchun to'lov",
        "term-of-use": "Foydalanuvchi Shartnomasi",
        "privacy-policy": "Shaxsiy ma'lumotlar siyosati",
    },
    "RightAsideSocial": {
        "title": "Ulashish",
        "vk": "VK.com",
        "ok": "ok.ru",
    },
    "loading": {
        "network-error":
            "Internet ulanishini tekshiring <br/> va sahifani yangidan yuklash",
    },
    "MainHeader": {
        "back-to-profile": "Profilga qaytish",
        "createAccount": "Hisob yaratish",
        "support": "Mijoz Xizmati",
        "logout": "Chiqish",
    },
    "MobileMenu": {
        support: "YORDAM",
    },
    "NotificationsModule": {
        "all": "Barcha xabarlar",
        "partner": "Sizga sherik bo'lish taklif qilindi",
        "deposit": "Depozit",
        "bonus": "Siz bonus olasiz",
        "reward": "Tavsiya etish dasturida mukofot",
        "referral": "{name} sizning tavsiyaniz bo'ldi<br/> Merlin Dating-da",
        "free-period":
            "Sizning tavsiya etgan {name} sizning bepul abunement muddatini faollashtirdi {message} Merlin Dating-da",
        "cancel-subscribe":
            "Sizning tavsiya etgan {name} merlin Dating-dan abunementni bekor qildi {message}",
        "pay-subscribe":
            "Siz mukofot olasiz. Sizning tavsiyaniz: {name} abunementni to'ladi {message} Merlin Dating-da",

        "subscribe-error":
            "Merlin Datingda obunamani {0} avvalroq yangilab bo'lmadi, sabablari bilinmayapti",
        "mapp_celebrity_compare":
            "Merlin Dating tanishuvlarida uygunlikni sotib olish mukofoti",
        "bonus_coach_partner": "Sherigingizning amallari uchun bonus",
        "person_compare": "Kimdir sizni solishtirdi",
        "compatibility": "uygunlik",
        "similarity": "o'xshashlik",
        "business": "biznes sherikchilik uchun uygunlik",
        "person_indicator": "Kimdir sizi tanidi",
        "interaction_business": "biznes aloqalar turi",
        "interaction_interpersonal": "xonadon ichidagi aloqalar turi",
        "mma_champion": "chempion potensiali",
        "mma_potential": "judo potensiali",
        "aggression_common": "umumiy g'azabingiz",
        "aggression_emotional": "emotsional g'azabingiz",
        "aggression_social": "ijtimoiy g'azabingiz",
        "aggression_intellectual": "intellektual g'azabingiz",
        "aggression_physical": "jismiy g'azabingiz",
        "analyse_item_unlock":
            "Kimdir sizning kategoriyadagi sharhingizni o'qidi",
        "professionaltype": "Kasbiy tur",
        "neurotype": "neyrotur",
        "leadertype": "boshqaruv",
        "character": "shaxsiyat",
        "relations": "munosabatlar va oila",
        "motivation": "motivatsiya va energetika",
        "selfAssessmen": "o'z-o'zini qadrlash va ishonch",
        "professional": "kasb va o'z-o'zini takomillashtirish",
        "person_review_created": "Shaxsiyatga oid xulosani tayyorlash",
        "new_in_top":
            "Uygunlik reytingingiz yangilandi: +{{ notification.message }}",
        "phone_confirm": "Siz bonus olindingiz {count} $",
        "upload_photos_request": "Kimdir sizdan rasmlarni yuklashni so'radi",
        "upload_photos_completed": "{user} o'zining fotosuratini yukladi",
        "coach_partner_request": "{name} sizni sherigiga taklif qildi",
        "coach_partner_request_accept": "Qabul qilish",
        "coach_partner_request_decline": "Rad etish",
        "analyse_item_unlock_reward":
            "Siz bonusni olidingiz {{ notification.message }} $",
        "new_in_top_show": "Ko'rish uchun bosing",
        "phone_confirm_reward": "Telefon raqamini tasdiqlash uchun",
        "loading": "Yuklanmoqda...",
        "no-more": "Boshqa xabarlar topilmadi",
        "no-notifications": "Sizda yangi xabarlar yo'q.",
        "show-all": "Barchasini ko'rsatish",
    },

    "OfflineIndicator": {
        title: "Internet ulanishi yo'q.",
        retry: "Iltimos, keyinroq yana urinib ko'ring...",
    },
    "OnboardingDescriptionPopup": {
        text: "Bunda sizning nima qilishingiz kerakligi va sizda qanday sifatlar borligi haqida aytiladi.",
        error: "Siz barcha kasblar uchun ovoz bermagansiz:",
    },
    "Search": {
        "findFriends": "Do'stlarni topish",
        "found": "Foydalanuvchilar topildi:",
        "limit": "Kamida 3 ta belgi kiriting",
        "not-found": "Hech narsa topilmadi",
        "show-all": "BARCHASINI KO'RSATISH",
    },
    "SearchPeople": {
        "full-name": "Birinchi yoki familiyangizni kiriting",
        "all": "Barcha",
        "friends": "Do'stlar",
        "celebrity": "Mashhur shaxslar",
        "fans": "Azolar",
        "type-is-not-available": "Shaxsiyat turi hali aniqlanmagan",
        "limit": "Kamida 3 ta belgi kiriting",
        "empty-filter":
            "Ko'rsatilgan parametrlarga ko'ra hech qanday odam topilmadi",
    },
    "Select": {
        nothing: "So'rovga mos keladigan narsa topilmadi",
        choose: "Tanlash",
    },
    "ServiceMenu": {
        "with-friends": "Do'stlaringiz bilan solishtiring",
        "about-me": "O'zim haqida ko'proq bilish",
        "about-another": "Ko'proq ma'lumot olish:",
        "aggression-another": "Agressiya darajasi:",
        "aggression": "Agressiya darajasini bilib oling",
        "person-aggression": "Agressiya darajasi:",
    },
    "UserNav": {
        "my-page": "Mening sahifam",
        "friends": "Do'stlar",
        "compare-with-me": "Mening bilan solishtirish",
        "celebrities": "Mashhur shaxslar",
        "messages": "Xabarlar",
        "analyze": "Merlin AI tahlili",
        "recommendations": "Odamlar tomonidan tavsiyalar",
        "settings": "Sozlamalar",
        "manager": "Shaxsiyatlar boshqaruvi",
    },

    "Auth": {
        "back": "Orqaga",
        "another-phone": "Boshqa telefon raqamini kiriting",
        "prev-step": "Orqaga qaytish",
        "auth": "Avtorizatsiya",
        "confirm-phone":
            "Sizning haqiqiy inson ekanligingizni tasdiqlashimiz kerak. Iltimos, telefon raqamingizni kiriting:",
        "retry-after":
            "Siz yaqinda qo'ng'iroq so'radimiz, shuning uchun siz {{smsTimerCountdown}} soniyadan so'ng yangi birini so'rashingiz mumkin.",
        "not-found": "Hech narsa topilmadi",
        "country-code": "Mamlakat kodi",
        "phone": "Telefon raqami",
        "agree_1":
            "Telefon raqamingizni kirgizganingizda, siz bilan tanishganligingizni tasdiqlaysiz ",
        "agree_2": "foydalanish shartlari",
        "agree_3": "maxfiylik siyosati",
        "telegram":
            "Ro'yxatdan o'tishni tugatish uchun, iltimos, aloqa ma'lumotlaringizni ulashing - bu siz Telegramga kirish huquqini yo'qotgan bo'lsangiz ishlatiladi.",
        "call": "Kirish qo'ng'iroqi",
        "message": "Kirish xabari",
        "blocked":
            "Sizning raqamingiz yaqinda bloklangan, siz {{smsTimerCountdown}} soniyadan so'ng boshlashingiz mumkin.",
        "retry-error":
            "Agar sizga ba'zi sabablarga ko'ra e'tibor berilmagan yoki matnli xabaringiz olilmagan bo'lsa, siz kodni {{smsTimerCountdown}} soniyadan so'ng qayta talab qilishingiz mumkin.",
        "telegramTitle":
            "Botdan so'rov qabul qilindi. Avtorizatsiya jarayonda.",
        "telegramTitle2":
            "Merlin sizga yanada bir daqiqada telegram xabari yuboradi.",
        "enter-call-code":
            "Kirish raqamining oxirgi 4 raqamini quyidagi joyga kiriting.",
        "enter-message-code": "Matnli xabar kodini kiriting",
        "code": "tasdiqlash kodi:",
        "confirm": "tasdiqlash",
        "request": "so'rov...",
        "enter": "kiriting",
        "loading": "yuklanmoqda...",
        "requestError":
            "Qo'ng'iroq so'rovi yuborishda xatolik, yana bir necha soniyadan so'ng urinib ko'ring.",
        "phoneError":
            "To'g'ri telefon raqamini xorijiy formatda kiriting. Misol uchun Rossiya uchun: +79174246633",
        "codeExpired":
            "Siz oxirgi to'rt raqamni 60 soniya ichida kiritinga vaqt etmasdingiz. Iltimos, tasdiqlash jarayonini yana bir bor boshlang.",
        "codeError": "Noto'g'ri raqamlar, urunishlar yo'q: {attempts} 3 dan",
    },

    "PhoneForm": {
        "back": "Orqaga",
        "another-phone": "Boshqa telefon raqamini kiriting",
        "prev-step": "Orqaga qaytish",
        "auth": "Avtorizatsiya",
        "confirm-phone":
            "Biz sizni haqiqiy odam deb tekshirishimiz kerak. Iltimos, telefon raqamingizni kiriting:",
        "retry-after":
            "Siz yaqinda qo'ng'iroqni so'ragan edingiz, shuning uchun siz yangi birini {{smsTimerCountdown}} sekunddan so'ng so'rashiz mumkin.",
        "not-found": "Hech narsa topilmadi",
        "country-code": "Mamlakat kodi",
        "phone": "Telefon raqami",
        "agree_1": "Telefon raqamingizni kirganingiz bilan siz ",
        "agree_2": "foydalanish sharaiti",
        "agree_3": "maxfiylik siyosati",
        "telegram":
            "Ro'yxatingizni tugatish uchun, iltimos, aloqangizni ulashing - agar siz Telegramga kirishni yo'qotgan bo'lsangiz, bu ishlatiladi..",
        "call": "Kelib tushadigan qo'ng'iroq",
        "message": "Kelib tushadigan xabar",
        "blocked":
            "Sizning raqamingiz yaqinda bloklandi, siz {{smsTimerCountdown}} sekunddan so'ng yangidan boshlashiz mumkin.",
        "retry-error":
            "Agar biror sababli sizga yo'llab bo'lmagan yoki matn xabarini olmagan bo'lsangiz, siz kodni {{smsTimerCountdown}} sekunddan so'ng yana bir bor so'rashingiz mumkin.",
        "telegramTitle":
            "Botdan kelgan so'rov qabul qilindi. Avtorizatsiya davom etmoqda.",
        "telegramTitle2":
            "Merlin sizga bir daqiqa ichida telegram xabar yuboradi.",
        "enter-call-code":
            "Pastdagi kelgan raqamning oxirgi 4&nbsp;raqamlarini kiriting.",
        "enter-message-code": "Matn xabaridagi kodni kiriting",
        "code": "tasdiqlash kodi:",
        "confirm": "tasdiqlash",
        "request": "so'rov...",
        "enter": "kiriting",
        "loading": "yuklanmoqda...",
        "requestError":
            "Call so'rovi yuborishda xato, bir necha sekunddan so'ng qayta urinib ko'ring.",
        "phoneError":
            "To'g'ri telefon raqamini xalqaro formatda kiriting. Misol Rusiya uchun: +79174246633",
        "codeExpired":
            "Siz 60 sekund ichida oxirgi to'rt raqamni kirishga vaqt topolmadingiz. Iltimos, tasdiqlash jarayonini yana bir bor boshlang.",
        "codeError":
            "Noto'g'ri raqamlar, urunishlar tugadi: {attempts} 3 tadan",
    },

    "choose-want": {
        "choose-1-1": "Pul",
        "choose-1-2": "kasb",
        "choose-1-3": "ozini amalga oshirish",
        "choose-2-1": "Energiya",
        "choose-2-2": "motivatsiya",
        "choose-2-3": "ma'noni tushunish",
        "choose-3-1": "Munosabatlar",
        "choose-3-2": "oilaviy",
        "choose-3-3": "hamkorlar",
        "choose-4-1": "Ishonch",
        "choose-4-2": "o'zini baholang",
        "choose-4-3": "tinchlik",
    },
    "CelebrityProgram": {
        "compare":
            "O'zingizni mashhurlik bilan taqqoslang va agar siz uch ko'rsatishingiz mumkin bo'lsa, siz shaxsan uchrashingiz mumkin",
        "desc": "Bu dastur aholi va mashhurni quyidagiga imkon qiladi:",
        "get-know": "Tanishish",
        "collaborate": "Hamkorlik qilish",
        "talk": "Suhbat qilish",
        "date": "Sana bo'yicha borish",
        "all": "Dasturda barcha mashhurlar",
    },
    "MessagesHistory": {
        "no-messages": "Xabar yo'q",
        "accessError": "Siz bu foydalanuvchiga yozolmaysiz. Agar bu xato deb hisoblasangiz, iltimos, qo'llab-quvvatlash xizmatiga murojaat qiling.",
    },
    "MessageHistoryHead": {
        "new-chat": "Yangi chat",
        "history": "Tarix",
        "delete-chat": "Suhbatni o'chirish",
        "disable-notifications": "Bildirishnomalarni o'chirish",
        "enable-notifications": "Bildirishnomalarni yoqing",
        "attempts-left": "{0} ta so'rovnoma qoldi"
    },
    "ChatMessage": {
        "myMessageTitle": "ви",
        "upscale": "Ko'paytirish",
        "generated-by-stable-diffusion":
            "Stable Diffusion orqali yaratilgan rasm",
        "generated-by-midjorney": "Midjorney orqali yaratilgan rasm",
        "optimization-midjorney": "Rasm optimizatsiyasi amalga oshirilmoqda",
        "seconds": " sek.",
        "generated-by-midjorney-error": "Rasmni yaratishda muammo yuz berdi. Endi xavotir olmang, biz bu muammoga tayyor mizoz va tez orada uni hal qilamiz. Bir ozdan keyin urinib ko'ring",
        "midjorney-parameters-error": "Noma'lum parametrlar berilgan:",
        "midjourney-queue": "{0} chiroyli rasmlarni yaratishni kutmoqdasiz",
        "midjorney-stress-error": "Serverda o'ta katta yuklanish. Iltimos, 1 daqiqa keyin urinib ko'ring",
        "midjorney-moderator-error": "Uzr, lekin sizning tasvir yaratish uchun so'rovingiz qoidalarga mos kelmaydi. \nMen tasvirni yaratmayman, agar ular:\n- Kimnidir haqorat qilishi yoki hayron qilishi mumkin.\n- Haqorat so'zlari yoki zoravonlikni o'z ichiga oladi.\n- Kimgadir kiyim-kechak yoki noto'g'ri holatlarda tasavvur qiladi.\n- Turli madaniyatlarda hurmat bermaslik deb hisoblanishi mumkin.",
        "midjorney-unknown-error": "Kecha, sizning rasmlarni yaratish uchun so'rovingiz bilan biror narsa noto'g'ri ketdi. \n\nXatoliklar misollari:\n1️⃣ --version 5.2 parametrini faqat --style: raw bilan ishlatish. \n2️⃣ Kiritilgan parametrlarning to'g'riligi. Shubhali qilib ko'radigan parametrlar -ar, 21:9, -style, raw, -q2, -seed, 302280510.\n3️⃣ Ko'rsatilgan tomonlar nisbatining to'g'riligi. Sh:V formatida bo'lishi kerak, masalan, 16:9, lekin 'da' emas.\n\nBu faqat xatolik bo'lishi mumkin bo'lgan narsalar misollari. Iltimos, so'rovingizni tekshiring va yana bir bor urinib ko'ring.",
        "midjorney-single-image-error": "Tasvirga birinchi marta yaratish mumkin emas, matn so'rovini qo'shing va urinib ko'ring",
        "gpt-error-try-again": "Nima-to xato bo'ldi. Yana bir bor urinib ko'ring",
        "download": "Yuklab olish",  
    },
    "AddMessageForm": {
        "inputPlaceholder": "Xabarni kiritish...",
    },
    "Chat": {
        "loading": "Suhbat yuklanmoqda...",
        "write": "chop etmoqda...",
        "online": "Onlaynda",
        "offline": "Onlayn edi",
        "delete-chat": "Suhbatni o'chirish",
        "disable-notifications": "Bildirishnomalarni o'chirish",
        "enable-notifications": "Bildirishnomalarni yoqing",
        "partner": "Siz biznes taklif topshirganingiz",
        "partner-for-you": "Sizga biznes taklif berildi",
        "target": "Siz maqsadni yakunlashni taklif etdingiz",
        "target-for-you": "Sizdan maqsadni yakunlashni so'raldi",
        "AIPlaceholder": "Rasmni yaratish uchun satrni kiritish",
        "edit": "Tahrirlash",
        "copy": "Nusxalash",
        "delete": "O'chirish",
        "read": "O'qilgan",
        "notRead": "O'qilmagan",
        "empty-error": "Xabar bo'sh bo'lmasligi kerak",
        "length-error-min":
            "Xabarning uzunligi {min} belgidan qisqa bo'lmasligi kerak",
        "length-error-max":
            "Xabarning uzunligi {max} belgidan oshmasligi kerak",
        "target-message": "Siz maqsadni yakunlashni taklif etmoqdasiz",
        "partner-message": "Siz biznes taklif beramoqdasiz",
        "stable-diffusion-message":
            "Stable Diffusion yordamida tasvir yaratish rejimi",
        "midjorney-message": "Midjorney orqali tasvir yaratish rejimi",
        "editing": "Tahrirlamoqda",
        "confirmTitle": "amaliyotni tasdiqlang",
        "confirmDeleteMessageText": "Haqiqatan ham xabarni o'chirmoqchimisiz?",
        "confirmDeleteAllMessagesText":
            "Haqiqatan ham barcha muloqotni o'chirmoqchimisiz?",
        "manyMessage5seconds":
            "Qisqa vaqt ichida juda ko'p xabarlar, 5 soniya kutib turing.",
        "manyMessage60seconds":
            "Bir daqiqada 30 dan ortiq xabar jo'natmaslik. Iltimos, taqriban 60 soniya kutib turing.",
        "userDisable":
            "Foydalanuvchi xabarlarni qabul qilish imkoniyatini cheklagan.",
        "sendError": "Xabar yuborish xatosi, qayta urinib ko'ring.",
        "new-chat": "Yangi suhbat",
        "history": "Suhbatlar ro'yxati",
        "gpt-message-to-long": "Xabar juda uzoq",
    },
    "Dialogs": {
        "search": "Do'stlarni qidirish",
        "open": "Chatni ochish",
        "nothing": "Sizda faol chat yo'q",
        "image": "rasm",
        "new-chat": "Yangi suhbat",
        "delete": {
            "title": "O'chirishni tasdiqlang",
            "desc": "Suhbatni o'chirgandan so'ng, undagi barcha habarlar o'chiriladi",
            "btn": "Suhbatni o'chirish"
        },
        "attempts-left": "{0} ta so'rov qoldi"
    },
    "BlockedUI": {
        "title": "Merlin AI sizning iste'dodlaringizni aniqlaydi",
        "description": "Saytga to'liq kirish huquqini olish uchun rasmlaringizni yuklang",
        "upload": "Rasmlarni yuklash"
    },
    Messages: {
        loading: "Chat yuklanmoqda...",
        "chooseAI": "Foydalanmoqchi bo'lgan neyron tarmoqni tanlang",
        chooseChat: "Xabarlarni ko'rish uchun chat tanlang",
    },
    ChatMenu: {
        title: "Menu",
        stableDiffusionTitle: "Stable Diffusion",
        stableDiffusionDescription:
            "Har qanday matn asosida fotorealistik tasvirlar yaratish",
        midjorneyTitle: "Midjorney",
        midjorneyDescription:
            "Har qanday matn asosida fotorealistik tasvirlar yaratish",
        enable: "Yoqish",
        disable: "O'chirish",
    },
    PayoutModal: {
        balance: "Sizning balansingiz:",
        title: "Mablag'lar yechib olish:",
        sum: "Chiqariladigan summa:",
        sumRequiredError: "Chiqariladigan mablag'ni ko'rsating",
        sumMinError:
            "Kechirasiz, biz ariza qabul qila olmaymiz. Chiqariladigan minimal miqdor {min} rubl. Shunday aktiv davom eting va tez orada siz topgan pulni chiqarib olasiz.",
        sumMaxError: "Maksimal chiqariladigan summa {max}",
        success:
            "Mablag'larni chiqarish uchun ariza qabul qilindi! Menejer tez orada siz bilan bog'lanadi",
        newFormat:
            "Biz abonent formatiga o'tdik, siz joriy balansingizni do'stlar yoki mashhur shaxslar bilan solishtirishda sarf qilishingiz mumkin. Va barcha chip-larni foydalanish uchun bir yoki undan ko'p bo'limlarga obuna bo'ling",
        deposits: "To'lovlar",
        payments: "Xaridlar",
        bonuses: "Bonuslar",
        table: {
            "date": "Sana",
            "sum": "Summa",
            "description": "Tavsif",
            "remainder": "Qoldiq",
            loading: "Yuklanmoqda...",
            noMore: "Ko'proq kirishlar yo'q",
            no: "Tranzaktsiyalar topilmadi",
        },
    },

    purchaseDescriptions: {
        category: {
            deposit: "Hisob to'ldirish",
            analyse_item_unlock: "Sotishni tavsiflash toifasida tushuntirish",
            bonus_analyse_item_unlock:
                "Kimdir sizning tavsiflangan toifalaringizni ochib qo'ydi",
            referral_reward: "Referal dasturidan sovrin",
            merlinapp_referral_created: "Mobil ilovada referal uchun sovrin",
            person_compare: "Siz tekshirdingiz",
            bonus_person_compare: "Kimdir sizni tekshirib qo'ydi",
            person_compare_photos: "Rasmlar bo'yicha tahlil qilish",
            person_indicator: "Ko'rsatkichni oching:",
            bonus_person_indicator: "Kimdir sizning ko'rsatkichingizni ochdi:",
            phone_confirm: "Telefonni tasdiqlangan bonus",
            bonus_coach_partner: "Murabbiy hamkorlik dasturi bonusi",
            payout: "Chiqarish",
            refund: "Qaytarish",
        },
        subcategory: {
            professionaltype: "kasb ta'limi turi",
            neurotype: "nevro tip",
            leadertype: "rahbarlik",
            character: "xarakter",
            relations: "munosabatlar va oila",
            motivation: "motivatsiya va energiya",
            selfAssessmen: "o'ziga ishonch va tashakkur",
            professional: "kasbiy va o'zini amalga oshirish",
            compatibility: "muvozanat",
            similarity: "o'xshashlik",
            business: "biznes sherikchilikga moslik",
            interaction_business: "biznes muloqot turi",
            interaction_interpersonal: "odamlararo muloqot turi",
            mma_champion: "champion potentsiali",
            mma_potential: "sog'indiron potentsiali",
            aggression_common: "umumiy agressiya",
            aggression_physical: "jismoniy agressiya",
            aggression_emotional: "his-tuyg'u agressiyasi",
            aggression_social: "ijtimoiy agressiya",
            aggression_intellectual: "intellektual agressiya",
        },
    },

    serviceTypes: {
        COMPATIBILITY: "Munosabatlar to'g'risidagi moslik",
        COMPATIBILITY_PLAN: "Munosabatlar va oila",
        SIMILARITY: "Sharflarning o'xshashligi",
        SIMILARITY_PLAN: "O'ziga ishonch va ma'naviyat",
        BUSINESS: "Biznes mosligi",
        BUSINESS_PLAN: "Kasb va maqsad",
        INTERACTION_BUSINESS: "Biznes aloqasi",
        INTERACTION_BUSINESS_MY: "Men biznesda qanday tutunaman",
        INTERACTION_BUSINESS_OTHER: "U biznesda qanday muomala qiladi",
        INTERACTION_BUSINESS_PLAN: "Kasb va maqsad",
        INTERACTION_INTERPERSONAL: "Insonlar bilan munosabatlar",
        INTERACTION_INTERPERSONAL_MY:
            "Men insonlar bilan qanday muomala qilaman",
        INTERACTION_INTERPERSONAL_OTHER:
            "U insonlar bilan qanday muomala qiladi",
        INTERACTION_INTERPERSONAL_PLAN: "Munosabatlar va Oila",
        AGGRESSION_COMMON: "Umumiy Hujum",
        AGGRESSION_COMMON_PLAN: "Munosabatlar va Oila",
        AGGRESSION_PHYSICAL: "Jismoniy Hujum",
        AGGRESSION_PHYSICAL_PLAN: "Kasb va Maqsad",
        AGGRESSION_EMOTIONAL: "Hissiy Hujum",
        AGGRESSION_EMOTIONAL_PLAN: "Kasb va Maqsad",
        AGGRESSION_SOCIAL: "Ijtimoiy Hujum",
        AGGRESSION_SOCIAL_PLAN: "Kasb va Maqsad",
        AGGRESSION_INTELLECTUAL: "Intellektual Hujum",
        AGGRESSION_INTELLECTUAL_PLAN: "Munosabatlar va oila",
        INTELLIGENCE_LEVEL: "Intellekt darajasi",
    },
    buyActions: {
        "compareUnlimited": "Soliqlarning soni cheksiz",
        "compareLimit": "Qolgan soliqlar: {limit}",
        "indicatorUnlimited": "Ko'rikmalarning soni cheksiz",
        "indicatorLimit": "Qolgan urunishlar: {limit}",
        compareCelebrity: "mashhur shaxs",
        compareUser: "foydalanuvchi",
        indicatorCelebrity: "mashhur shaxslar",
        indicatorUser: "foydalanuvchi",
        compareConfirmTitle: "Soliqni tasdiqlang",
        compareConfirmText: `Siz rostdan ham o'z qiyoslamalarizdan birini {userType} {name} bilan "{activeType}" kategoriyasida qilmoqchimisiz?`,
        compareConfirmBtn: "Xaridni tasdiqlang",
        indicatorConfirmTitle: "Xaridni tasdiqlang",
        indicatorConfirmText:
            'Siz rostdan ham {userType} {name} ning "{activeType}" kategoriyasini ochmoqchimisiz?',
        indicatorConfirmBtn: "Xaridni tasdiqlang",
    },
    "compare-result-dialog": {
        more: "Shaxs haqida ko'proq o'rganing",
    },
    "ConfirmDialog": {
        price: "Qiymati:",
        timer: "Obuna tarkibidagi tekshirishgacha:",
        cancel: "Bekor qilish",
        apply: "Tasdiqlash",
    },

    "LegalLinksMobile": {
        "referral": "Tavsiya dasturi",
        "legal-title": "Qonuniy ma'lumotlar",
        "requisites": "Kontaktlar va tafsilotlar",
        "about": "Kompaniya haqida",
        "payments": "Xizmatlar uchun to'lov",
        "term-of-use": "Foydalanuvchi shartnomasi",
        "privacy-policy": "Maxfiylik siyosati",
        "payout": "Bonus dasturi",
        "support": "Yordamga yozing",
        "logout": "Chiqish",
    },
    "AnotherPerson": {
        "rating": "Sizning baholaringiz",
        "person-rate": "Insonga baholash",
        "compare-with-me": "O'zingiz bilan solishtiring",
        "more": "Ko'proq bilish",
        "aggression": "Aggressiya darajasi",
    },
    "AnotherPersonActions": {
        "sendMessage": "XABAR YUBORISH",
        "sendTarget": "MAQSADNI YOPISH TAKLIF QILISH",
        "sendBusiness": "BIZNES TAKLIFI",
        "targetConfirmTitle": "eslatma",
        "targetConfirmText":
            "Bu amalni bajara olish uchun, avvalo uni do'stlar ro'yxatiga qo'shing.",
        "businessConfirmText":
            "Insonga biznes taklifi qilish uchun, siz \"Kasb va maqsad\" kategoriyasiga a'zo bo'lishingiz kerak",
        "add": "Do'stlar ro'yxatiga qo'shish",
        "subscribe": "obuna bo'lish",
    },

    "AnotherPersonInfo": {
        "getType": "Mening psixotipimni aniqlash",
        "typeHidden": "Personlilik turi hali aniqlanmagan",
        "web": "Veb-sayt",
        "webUndefined": "Ko'rsatilmagan",
        "selfEsteem": "Nima oz ishonchini oshiradi",
        "selfEsteemUndefined": "Ko'rsatilmagan",
        "occupation": "Vaziyat",
        "occupationUndefined": "Ko'rsatilmagan",
        "personalInfo": "Shaxsiy ma'lumotlar",
        "age_1": "yil",
        "age_2": "yil",
        "age_3": "yil",
        "height": "sm",
        "weight": "kg",
        "noChildren": "Bolalar yo'q",
        "children_1": "bola",
        "children_2": "bola",
        "children_3": "bola",
    },
    "AnotherPersonBusinessCard": {
        "merlin": "Merlindan",
        "aboutMe": "{name} o'zim haqimda",
        "blocked": "Bloklanadi",
        "unlock": "Bloklashni bekor qilish",
        "more": "Ko'proq o'qish",
        "mrln_strengths": "Kuch-quvvatlar",
        "mrln_talents": "Qobiliyat",
        "mrln_self_esteem": "Nima oz ishonchini oshiradi",
        "mrln_motivation": "Nima motivatsiyani oshiradi",
        "mrln_tasks": "U muvaffaqiyatli bajargan vazifalar",
        "mrln_activities": "Uning meyl qilgan faoliyat maydonlari",
        "self_esteem": "Nima oz ishonchini oshiradi",
        "user_like": "Nima yoqadi",
        "user_good": "Nima yaxshi",
        "user_usually": "Odatan nima qiladi",
        "user_work": "Kim bilan ishlamoqchi",
        "user_goal": "Daromad qilishni xohlaydi",
        "user_skill": "Mahoratli bo'lishni xohlaydi",
        "career": "TAJIRIBA",
        "education": "TA'LIM",
        self_esteemUndefined: "Hech nima",
        user_likeUndefined: "Hech nima",
        user_goodUndefined: "Hech nima",
        user_usuallyUndefined: "Hech nima",
        user_workUndefined: "Hech kim",
        user_goalUndefined: "Soatiga 5$",
        user_skillUndefined: "Krovatning chap tomoni",
        fromNow: "hozirga qadar",
        categoryProfession: "Kasb va yo'nalish",
        categorySelfEsteem: "oz ishonch va ishonch",
        categoryMotivation: "Motivatsiya va samaradorlik",
        needSubscribe: "Abonentlik talab qilinadi",
        needSubscribeText:
            'Ushbu elementni ochish uchun "{category}" toifasiga abonentlik talab qilinadi.',
        subscribe: "Abonentlikni rasmiylashtirish",
    },
    "AvailableSocial": {
        "notSelected": "HECH NIMA TANLANMAGAN",
    },
    "AvatarGallery": {
        retry: "Qayta urinib ko‘ring",
        new: "Yangi avatar yuklash",
    },

    "ChangeName": {
        "placeholder": "Ismingizni va familiyangizni kiriting",
        "error": "Ism va familiya 2 ta xarfdan ko'proq bo'lishi kerak",
        "saving": "Saqlanmoqda...",
        "save": "Saqlash",
    },
    "ChangePhotos": {
        "another": "Boshqa yuklab oling",
        "anotherAnfasDrag": "Boshqa anfas <br/> rasmni shu joyga torting",
        "anotherProfileDrag": "Boshqa profile <br/> rasmni shu joyga torting",
        "didntFit": "Rasm mos kelmadi",
        "limit": "Bugungi kun uchun yuklab olish cheklovi tugadi",
        "example": "Mavzuga oid rasm misoli",
        anfas: {
            title: "Faqat neyron tarmog'idagi algoritm rasmlaringizni ko'rishi mumkin. Rasmlar uchinchi tomonlar uchun mavjud emas.",
            desktopText: "Rasmni anfasga tortib o'tkazing",
            mobileText: "Anfasga rasm yuklab oling",
            dragText: "Rasmni anfasga torting",
        },
        profile: {
            desktopText: "Profil rasmingizni shu joyga torting",
            mobileText: "Profil rasmingizni yuklab oling",
            dragText: "Profil rasmingizni shu joyga torting",
        },
        avatar: {
            title: "Avatarizni yuklab oling, bu hamma sizning sahifangizga tashrif buyurishganlar uchun ko'rinadi.",
            text: "Avatarizni yuklab oling",
            dragText: "Avatarizni shu joyga torting",
            help: "Men sizga yaxshi ko'rinadigan rasmni yuklab olishni tavsiya etaman. Bu rasm boshqa foydalanuvchilar tomonidan ko'riladi. Men boshqa odamlar rasmlari bilan sizni targ'ib eta olmayman.",
        },
    },

    "CompareWithPhoto": {
        "back": "Shaxsiyat ta'rifi o'rtasiga qaytish",
        "another-type": "Boshqa turi taqnoshligini tanlang",
        "choose-in-list": "Do'stlar ro'yxatidan tanlash",
        "searchFriends": "do'stlarni qidirish",
        "noFriends":
            "hozircha do'stlaringiz yo'q. Do'stlaringizni taklif qilishingiz va ular qo'shilishlari haqidagi bonuslarni olishingiz mumkin",
        "invite":
            "Kechirasiz, ushbu shaxs hali sizning do'stlaringiz ro'yxatida emas. Uning taklifini qilishingiz va qo'shilishdan so'ng bonus olishingiz mumkin",
        "loading": "Yuklanmoqda...",
        "no": "Boshqalar topilmadi :)",
        "noFriendsShort": "Sizda do'stlar yo'q",
        "upload-friend-photo": "Do'stning fotosuratini yuklash",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Foto yuklandi",
        "choose-main": "Asosiy qilib olish",
        "confirmDeleteTitle": "O'chirishni tasdiqlang",
        "confirmDeleteText": "Rostanbu foto o'chirishni xohlaysizmi?",
        "confirmDeleteBtn": "O'chirish",
    },
    "PersonCompareMobile": {
        compare: {
            myProfile: "Do'stlaringiz bilan taqqoslang",
            withUser: "O'zingizni <span>{user}</span> bilan taqqoslang",
        },
        indicator: {
            myProfile: "O'zingiz haqida ko'proq bilish",
            withUser: "Ko'proq bilish:<br/> {user}",
        },
    },
    "PersonDescription": {
        "back": "Ortga",
        "choose-type": "Sizga qiziqarli yo'nalishni tanlang",
        "need-subscribe": "Obuna bo'lish kerak",
        "need-subscribe-text":
            "Tanlangan bo'lim ta'rifini o'qish uchun kategoriyaga obuna bo'lish kerak",
        "btn": "Ro'yxatdan o'ting",
    },

    "PersonInfo": {
        "age": "Yosh",
        "age_1": "yoshda",
        "age_2": "yoshda",
        "age_3": "yoshda",
        "no": "Ko'rsatilmagan",
        "children": "Bolalar",
        "children_1": "bola",
        "children_2": "bolalar",
        "children_3": "bolalar",
        "height": "O'sish",
        "weight": "Vazn",
        "subscriptions": "Obunalar",
        "photo-error":
            "O'zingizning bir nechta rasmlaringiz bo'lsa, vizitkartaga ko'proq jalblanadi",
    },
    "PersonMobileBox": {
        history: "Tarix",
    },
    "PersonMobileBusinessCard": {
        merlin: "Merlindan",
        user: "{user}dan",
        career: "Kasb",
        education: "Ta'lim",
    },
    "PersonMobileBusinessCardPreview": {
        "title":
            "Meni vizitkartam qanday ko'rinishda ekanligini tasavvur qiling <br/>boshqa odam uchun",
        "send": "MENING SAHIFAMGA HAVOLANI YUBORING",
    },
    "PersonMobileHeader": {
        "noName": "Ism kiritilmagan",
        "changeName": "Ismni o'rnating",
        "my-type": "Mening psixotipimni aniqlash",
        "type-not-available": "Shaxsiyat turi hali aniqlanmagan",
    },
    "PersonalRecommendations": {
        title: "Shaxsiy tavsiyalar",
    },
    "PersonalRecommendationsDialog": {
        title: "Siz uchun maxsus tavsiyalar",
        canOpen: "Ochib bo'ladi",
        number: "Tavsiya No",
        openAfter: "Quyidagidan ochishingiz mumkin: ",
    },
    "PhotoExamples": {
        title: "Ma'lumotli fotolar misollari",
        text: "Rasm standartga qanchalik yaqin bo'lsa, neyron tarmog'i siz haqida shunchalik aniqroq aytadi. <br/> Rasmlar faqat sizga ko'rsatiladi.",
        anfas: "To'liq yuzli foto",
        profile: "Profil rasm",
        btn: "O'zingizning rasmlaringizni yuklang",
    },

    "AboutSettingsForm": {
        "user_like": "Siz nima qilishni yaxshi ko'rasiz?",
        "user_good": "Siz nimada yaxshisiz?",
        "user_usually": "Siz odatda nima qilasiz?",
        "user_work": "Siz nima ishlamoqchisiz?",
        "user_goal": "Siz nechta pul ishlamoqchisiz?",
        "user_skill": "Siz nimani o'rganmoqchisiz?",
        "saved": "Saqlandi",
    },
    "CareerSettingsForm": {
        "company-name": "Kompaniya nomi",
        "search-title": "Balki siz mana buni nazarda tutdingiz:",
        "company-name-error": "Kompaniyaning nomini kiriting",
        "company-position": "Lavozimi",
        "company-position-error": "Lavozimini kiriting",
        "company-start": "Ishni boshlash",
        "company-start-error": "Boshlanish sanasini kiriting",
        "company-end": "Ishni tugatish",
        "company-end-now":
            "Agar hali ham kompaniyada ishlaysiz, bu maydonni bo'sh qoldiring",
        "saved": "Saqlandi",
        "save": "Saqlash",
        "andContinue": " va davom etish",
        "delete": "Ishni o'chirib tashlash",
        "add": "Ishni qo'shish",
    },
    "EducationSettingsForm": {
        "country": "Mamlakat:",
        "selectCountry": "Mamlakatni tanlash",
        "alreadySelectCountry": "Avval mamlakatni tanlang",
        "alreadySelectCity": "Avval shahrni tanlang",
        "city": "Shahar: ",
        "searchCity": "Shahar bo'yicha qidirish",
        "no-cities": "Sizning so'rovingiz uchun shaharlar topilmadi",
        "education-place": "Maktab nomi",
        "education-place-nothing": "Sizning so'rovingiz uchun maktab topilmadi",
        "education-palce-error": "Maktab nomini kiriting",
        "education-position": "Mutaxassislik",
        "education-position-error": "Mutaxassislik raqamingizni kiriting",
        "education-start": "Ta'limni boshlash yili",
        "education-start-error": "Ta'limni boshlanish yilini kiriting",
        "education-end": "Bitirish yili",
        "education-end-now":
            "Agar siz hali ham kompaniyada ishlaysiz, bu maydonni bo'sh qoldiring",
        "saved": "Saqlandi",
        "save": "Saqlash",
        "andContinue": " va davom etish",
        "delete": "Ta'lim joyini o'chirib tashlash",
        "add": "Ta'lim joyini qo'shish",
    },

    "Settings": {
        "successPhotoTitle": "Rasmlar yuklandi",
        "successPhotoDescription": "Endi siz saytdagi barcha funktsiyalardan foydalanishingiz mumkin",
        "myMessageTitle": "Siz",
        lang: "Sayt tili",
        "information": "Asosiy",
        "added-info": "Ma'lumot",
        "about": "Qiziqishlar",
        "career": "Kasbiy yo'l",
        "education": "Ta'lim",
        "photos": "Fotograflar",
        "name": "Ism",
        "person-name": "Shaxsning ismini kiriting",
        "name-error": "Ism 60 ta harfdan oshmasligi kerak",
        "address": "Sahifa manzili",
        "address-error":
            "Sahifa manzili faqat lotin harflarini, raqamlarni va «_» va «-» belgilarini o'z ichiga olishi kerak va 3 dan kam yoki 30 dan ko'p bolmasligi kerak",
        "address-length-error":
            "Manzil {min} ta harfdan uzun bo'lmasligi kerak",
        "birthday": "Tug'ilgan kun",
        "birthday-error": "Tug'ilgan kunni tanlang",
        "marital": "Oilaviy holati",
        "maritalList": [
            "Bo'ydoq",
            "Turmushga chiqqan",
            "Turmushga chiqmagan",
            "Turmushda",
            "Ajralgan",
        ],
        "select": "Roʻyxatdan tanlang",
        "children": "Bolalar",
        "children-error": "Butun son kiriting yoki maydonni bo'sh qoldiring",
        "height": "bo'y",
        "maxHeightError": "Maksimal ruxsat etilgan qiymat 200",
        "maxWeightError": "Maksimal ruxsat etilgan qiymat - 200",
        "heightUnit": "sm",
        "weight": "Og'irligi",
        "weightUnit": "kg",
        "height-error": "Bo'yingizni kiriting",
        "weight-error": "Og'irligingizni kiriting",
        "gender": "Jinsi",

        "genderList": [
            {
                id: "M",
                name: "Erkak",
            },
            {
                id: "F",
                name: "Ayol",
            },
        ],
        "eye-color": "Ko'z rangi",
        "saved": "Saqlangan",
        "delete-account": "Hisobni o'chirish",
        "exit": "Chiqish",
        "nameTimeError": "Nomi 24 soat ichida bir marta o'zgartirilishi mumkin",
    },
    "InfoSettingsForm": {
        "web": "Veb-sayt",
        "selfEsteem": "Nima uzingizni oshiradi?",
        "address-incorrect":
            "To'g'ri havolani kiriting, shu formatda: https://merlinface.com",
        "self-esteem-error":
            "50 ta belgidan oshmasligi kerak, siz kiritdingiz{{ selfEsteem.length }}",
        "social-status": "Sizning ijtimoiy holatingiz:",
        "socialList": [
            "Maktab o'quvchisi",
            "Talaba",
            "Ishtan bekor",
            "O'zini band qilgan",
            "Kompaniya xodimi",
            "Kompaniyaning (Co) egasi",
        ],
        "country": "Davlat:",
        "city": "Shahar:",
        "selectCountry": "Davlatni tanlash",
        "searchCities": "Shahar bo'yicha qidirish",
        "saved": "Saqlangan",
    },
    "settings": {},
    "SocialRating": {
        "start": "Sizning bahoingiz{suffix}",
        "end": "baholari",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "Tavsiya etilgan odamlar analizi amalga oshirildi. Bu 24 soatga qadar davom etishi mumkin.",
        "category_1": "kasb и&nbsp;maqsad",
        "category_2": "Aloqa и&nbsp;oilaviy",
        "category_3": "Motivatsiya и&nbsp;efektivlik",
        "category_4": "ozizga ishonch и&nbsp;ishonchli",
    },

    Person: {
        "you": "siz",
        "user": "User",
        compare: {
            "fromMe": "Dostlar bilan taqqoslang",
            "fromUnnamedUser":
                "<span>Noma'lum shaxs</span> bilan o'zizni taqqoslang",
            "fromNamedUser": "<span>{user}</span> bilan o'zingizni taqqoslang",
        },
        indicator: {
            "fromMe": "O'ziz haqida ko'proq bilib oling",
            "fromUnnamedUser":
                "Batafsilroq: <span>Noma'lum foydalanuvchida</span>",
            "fromNamedUser": "Batafsilroq: <span>{user}</span>",
        },
        aggression: {
            "fromMe": "Aggressiyalik darajangizni aniqlang",
            "fromUnnamedUser":
                "Agresiyalik daraja: <span>Noma'lum foydalanuvchi</span>",
            "fromNamedUser": "Agresiyalik daraja: <span>{user}</span>",
        },
    },
    "PersonList": {
        "celebrities": "Mashhur shaxslar",
        "fans": "Fanlar",
        "friends": "Hamma dostlar",
        "friends-requests": "Dostlik so'rovlari",
        "select-all": "Hamma dostlaringizni saylang",
        "no-with-params":
            "Ko'rsatilgan parametrlarga mos mashhur shaxslar topilmadi",
        "no-friends": "Sizda hozircha dostlar yo'q.",
        "no-requests": "Sizda hozircha dostlik so'rovi yo'q.",
        "no-celebrities":
            "Ko'rsatilgan parametrlarga mos mashhur shaxslar topilmadi",
        search: {
            "celebrity": "Mashhur shaxslarni qidirish",
            "friends": "Dostlarni qidirish",
            "requests": "Dostlik so'rovlarni qidirish",
            "users": "Shaxslarni qidirish",
        },
    },

    "PersonShortCard": {
        typeLocked: "Shaxsiyat turi hali ochilmagan",
        sendMessage: "Xabar yuborish",
        compare: "Solishtirish",
        hide: "Yashirish",
        removeFromFriends: "Do'stlar ro'yxatidan o'chirish",
        acceptRequest: "Qo'shish",
        declineRequest: "Rad etish",
        confirmDeleteFriendTitle: "Amalni tasdiqlash",
        confirmDeleteFriendText: `"{user}" ni do'stlar ro'yxatidan o'chirishga ishonchingiz komilmi?`,
        confirmDeleteFriendBtn: "O'chirish",
    },
    "Promocode": {
        "btn": "Menda reklama kodi bor",
        "enter": "Reklama kodini kiriting",
    },
    "RecommendedPeople": {
        "no-persons": "Merlin hali siz uchun odamlar tanlab bergan emas",
        tabs: {
            partner: "Biznes tanishuv",
            love: "Munosabatlar",
            coming: "Yaqinda",
        },
        "type-undefined": "Aniqlanmagan",
        toProfile: "PROFILGA",
        "age_1": "yil",
        "age_2": "yil",
        "age_3": "yil",
        "status": "Ijtimoiy maqom",
        "no-status": "Maqom mavjud emas",
        "no-goal": "Belgilanmagan",
        "city": "Shahar",
        "no-city": "Belgilanmagan",
        "noAge": "Yosh belgilanmagan",
        "money-target": "Daromadga nisbatan maqsadim",
        "spheres": "Faoliyat sohaları",
        "no-spheres": "Belgilanmagan",
        "next": "Keyingisi",
        "loading": "Yuklanmoqda...",
        category_1: "Kasb va &nbsp;maqsad",
        category_2: "Aloqalar &&nbsp;oilalik bağları",
        category_3: "Motivatsiya &&nbsp;effektivlik",
        category_4: "Öziga ishonch &&nbsp;ishonch",
        "next-recommendation": "Keyingi shaxs",
    },

    "SubscriptionsCategoriesItem": {
        "active": "obuna faol",
    },
    "SubscriptionsContent": {
        "active": "Obuna {date} gacha faol",
        category_1: "Kasb va manzil, FREE",
        category_2: "Aloqa va oila, FREE",
        category_3: "Motivatsiya va samaradorlik, FREE",
        category_4: "O'ziga ishonch va jasorat, FREE",
        cancelTitle: "Obunani bekor qilish.",
        cancelText:
            '{period} davrli "{category}" kategoriyadagi obunani bekor qilmoqdamisiz?',
        cancelBtn: "Obunani bekor qilish",
        mounthly: "oylik",
        annual: "yillik",
    },
    SubscriptionsTableForm: {
        payBtn: "To'lovga o'tish",
        withBalance: "To'lov web-saytning balansidan kamaytiriladi",
    },
    SubscriptionsTableInfo: {
        discount: "Sizning chegirmaningiz: {discount}&nbsp;₽",
        chooseAnnual: "Yillik obuna tanlang<br/>va {discount}&nbsp;₽ tejash",
        active: "Faol obuna",
    },
    Subscriptions: {
        title: "Sizga qiziq qiladigan obuna kategoriyasini tanlang",
        canceled: "Obuna muvaffaqiyatli bekor qilindi!",
        succeeded: "Muvaffaqiyatli obuna!",
        choosePlan: "Rejani tanlang:",
    },

    "about": {
        "title": "Merlinface.com loyihasiga xush kelibsiz!",
        "text-1": "Umumiy takliflar",
        "text-2":
            "Jamoaamiz Diqqat, xotira, his-tuyg'u-samaradorlik maydoni, muomala va shu ko'rsatkichlarga asoslangan ko'plab individual to'g'rislagan tavsiyalarni olish uchun Yadroviy Tarmoq yaratdi.",
        "text-3":
            "Foydalanuvchilar tashxisini o'tkazishda mutaxassislar ham ishlashadi: neyropsixologlar, kognitiv-davranish terapevtlari va boshqa sohalar mutaxassislar.",
        "text-4": "Foydali ma'lumotlar!",
        "vk": "Bizning VKontakte guruhimiz:",
    },
    "cookie": {
        "title": "Cookie siyosati",
        "text-1":
            "Cookies - server tomonidan yuborilgan va foydalanuvchi kompyuterida saqlanadigan kichik matn bo'liklari. Kukilar ko'plab vazifalarni bajaradi, masalan, Foydalanuvchi tomonidan yaratilgan sozlamalarni saqlaydi, ma'lumotlarni tasdiqlagan holda sahifalarni o'zgartirishga ruxsat beradi va umuman saytni ishlashni soddalashtiradi.",
        "text-2": "Biz kukilardan foydalanamiz:",
        "text-3":
            "1. Identifikatsiya — kukilar sizning qurilmaningizni va hisobingizni tanovuchilar, agar siz foydalanuvchi nomi va parolni kiritgan bo'lsangiz va ularni boshqa sahifaga o'tish har safar so'ramasliklar.",
        "text-4":
            "2. Statistika — kukilar ma'lumotlarni olib tashlash uchun biron bir sahifalar bo'yicha ko'rish ma'lumotlarini to'plashga yordam beradi.",
        "text-5":
            "Brauzeringizga kukilarni qabul qilishni to'xtatish imkonini berishingiz mumkin, lekin bu Sizning Platformani ishlatish imkoniyatlarini hosil qiladi.",
    },
    "payments": {
        "title": "To'lov va buyurtmani qabul qilish",

        "text-1": "Bank karty orqali to'lov",
        "text-2":
            "Robokassa qayta ishlash markazi — VISA va MasterCard rasmiy xizmat ko'rsatuvchisi — saytda to'lovlar qabul qiladi.",
        "text-3":
            "Onlayn to'lov uchun siz MIR, Visa, Visa Electron, MasterCard va Maestro bank kartalaridan foydalanishingiz mumkin. Agar sizning kartaingiz 3D-Secure ga yozilgan bo'lsa, sizning to'lovingiz bir marta o'tishli parol yordamida tasdiqlanadi.",
        "text-4":
            "Nozik to'lov ma'lumotlarining kiritilishi va qayta ishlanishi qayta ishlash markazida amalga oshiriladi. To'lov ma'lumotlari muhofaza kanallari orqali shifrlangan shaklda bankga yuboriladi. Hech kim, hattoki sotuvchi ham, mijoz tomonidan kiritilgan bank kartasi tafsilotlarini olish imkoni yo'q, bu uning puli va shaxsiy ma'lumotlarining to'liq xavfsizligini kafolatlaydi.",
        "text-5":
            "Muvaffaqiyatli to'lovdan keyin, to'lovchiga elektron xat yuboriladi, bu xat to'lovni tasdiqlaydi va uning noyob identifikatorini o'z ichiga oladi.",
        "text-6":
            "Pulni qaytarish holatida pul shu kartaga qaytariladi, qaysidan to'lov amalga oshirilgan.",
        "text-7":
            "To'lov jarayoniga oid barcha savollar Robokassa 24/7 yordam ko'rsatuvchilariga +7 499 110 57 25 telefon raqami orqali yoki support@robokassa.ru ga yozish orqali so'rash mumkin",
        "text-8": "Yandex.Money, Webmoney, Qiwi bilan to'lov",
        "text-9":
            "Siz merlinface.com veb-saytidagi buyurtmani o'zining elektron hamyonlari Yandex.Money, WebMoney, QIWI orqali to'lashingiz mumkin. To'lov xavfsizligi Robokassa qayta ishlash markazi tomonidan ta'minlanmoqda.",
        "text-10":
            "Pulni qaytarish holatida pul shu elektron hamyona qaytariladi, qaysidan to'lov amalga oshirilgan.",
        "text-11":
            "Elektron hamyon orqali to'lov to'g'risida savollaringiz bo'lsa, siz Robokassa davomiy yordam xizmatiga +7 499 110 57 25 telefon raqami orqali murojaat qilishingiz mumkin",
        "text-12": "Xarid qilish uchun ma'lumot xizmatlarining ro'yxati:",

        "text-13":
            "1. \"Kasb va O'z-o'zini rostlab borish\" xizmati. Foydalanuvchi haqida o'z-o'zini rostlab borish mavzusida ta'rif berish va mos kasblar uchun tavsiyalar berish. Narxi 150 dan 500 rublgacha.",
        "text-14":
            "2. \"O'z-o'zini baholash\" xizmati. Foydalanuvchi haqida ta'rif berish va o'z-o'zini baholash mavzusida tavsiyalar berish. Narxi 150 dan 200 rublgacha.",
        "text-15":
            "3. \"Motivatsiya va Energiya\" xizmati. Foydalanuvchi haqida ta'rif berish va o'z-o'zini baholash mavzusida tavsiyalar berish. Narxi 150 dan 500 rublgacha.",
        "text-16":
            "4. \"Munosabatlar va Oila\" xizmati. Foydalanuvchi haqida ta'rif berish va boshqa odamlar bilan munosabatlarda bo'lish mavzusida tavsiyalar berish. Narxi 100 dan 400 rublgacha.",
        "text-17":
            '5. "Munosabatlardagi moslik" xizmati, darajani foizda ko\'rsatadi. Narxi 50 rubl.',
        "text-18":
            "6. \"Xarakterlarning o'xshashligi\" xizmati, darajani foizda ko'rsatadi. Narxi 25 rubl.",
        "text-19":
            '7. "Biznesdagi moslik" xizmati, darajani foizda ko\'rsatadi. Narxi 100 rubl.',
        "text-20":
            '8. "Biznesda qanday xulqda ekanligi" xizmati, darajani foizda ko\'rsatadi. Narxi 50 rubl.',
        "text-21":
            "9. \"Odamlar bilan qanday munosabatda bo'lishi\" xizmati, darajani foizda ko'rsatadi. Narxi 50 rubl.",
        "text-22":
            '10. "Mustahkamlik sportida potentsial" xizmati, darajani foizda ko\'rsatadi. Narxi 50 rubl.',
        "text-23":
            '11. "Chempion potentsiali" xizmati, darajani foizda ko\'rsatadi. Narxi 50 rubl.',
        "text-24":
            "Marta-marta xaridlar summasi, platformaning har bir foydalanuvchisi bilan solishtirilganda yoki har bir foydalanuvchi uchun qiziqarli ta'rif blokini ochganda, tizim ichidagi balansdan yechiladi.",
        "text-25":
            "Muvaffaqiyatli to'lovdan so'ng, xizmat merlinface.com saytidagi foydalanuvchi sahifasida avtomatik ravishda ochiladi. Xizmat doimiy ravishda ko'rsatiladi.",
        "text-26":
            "Mablag'lar qaytarilishi Rossiya Federatsiyasi Qonunining 07.02.1992 № 2300-I \"Iste'molchi huquqlarini himoya qilish to'g'risida\" bilan belgilangan.",
    },

    "privacy-policy": {
        "title": "",

        "text-1": "Umumiy qoidalar",
        "text-2":
            "Ushbu Maxfiylik siyosati 27.07.2006 yilgi Federal Qonun talablariga muvofiq tuzilgan. No. 152-FZ \"Shaxsiy ma'lumotlar to'g'risida\" va \"Merlin AI\" MChJ (keyingi o'rinlarda - Ma'muriyat) tomonidan shaxsiy ma'lumotlarni qayta ishlash tartibi va shaxsiy ma'lumotlar xavfsizligini ta'minlash chora-tadbirlarini aniqlaydi.",
        "text-3":
            "1.1. Ma'muriyat o'zining eng muhim maqsadi va faoliyat sharti sifatida shaxsiy ma'lumotlarni qayta ishlashda odam va fuqarolik huquq va erkinliklari, shu jumladan maxfiylik, shaxsiy va oilaviy sir huquqlarini himoya qilishni belgilaydi.",
        "text-4":
            "1.2. Ushbu Ma'muriyatning shaxsiy ma'lumotlarni qayta ishlash to'g'risidagi siyosati (\"Siyosat\") barcha ma'lumotlarga tegishli bo'ladi, bunda Ma'muriyat https://merlinface.com veb-sayti tashrifchilari va Merlin Dating mobil ilovasi haqida ma'lumot olishi mumkin.",
        "text-5": "2. Siyosatda foydalaniladigan asosiy tushunchalar",
        "text-6":
            "Shaxsiy ma'lumotlarni bloklash – shaxsiy ma'lumotlarni vaqtinchalik to'xtatish (har xil shaxsiy ma'lumotlarni aniqlovchi jarayonni amalga oshirish istisno bo'lganda);",
        "text-7":
            "Veb-sayt – grafika va axborot materiallari, shuningdek kompyuter dasturlari va ma'lumotlar bazasi to'plamlari, ularni Internetda https://merlinface.com va mobil ilova Merlin Datingda qo'llashni ta'minlaydi.",
        "text-8":
            "Shaxsiy ma'lumotlar axborot tizimi – ma'lumotlar bazasida joylangan shaxsiy ma'lumotlar to'plami, va ularni qayta ishlash uchun axborot texnologiyalari va texnik vositolardan foydalanadi;",
        "text-9":
            "Shaxsiy ma'lumotlarni shaxsiydan ajratib olish – shu amal, natijasida qo'shimcha ma'lumotlarni qo'llashsiz konkret foydalanuvchi yoki boshqa shaxsiy ma'lumotlar egasi qaysi shaxsiy ma'lumotga tegishli ekanligini aniqlab bo'lmaydi;",
        "text-10":
            "Shaxsiy ma'lumotlarni qayta ishlash - shaxsiy ma'lumotlar bilan yoki avtomatizatsiyadan foydalanib yoki foydalanmasdan bajariladigan har qanday amal (operatsiya) yoki amallar to'plami (operatsiyalar), jumladan to'plash, yozuv qilish, sistemalashtirish, yig'ish, saqlash, aniqlov (yangilash, o'zgartirish), chiqarish, foydalanish, tarqatish (tarqatish, taqdim etish, kirish), anonimlashtirish, bloklash, o'chirish, shaxsiy ma'lumotlarni yo'q qilish;",
        "text-11":
            "Ma'muriyat – yuridik yoki jismoniy shaxs, istiqbolga qarab yoki boshqalar bilan birga shaxsiy ma'lumotlarni qayta ishlashni tashkil etuvchi va (yoki) bajaruvchi, shuningdek shaxsiy ma'lumotlarni qayta ishlovchi, qayta ishlanadigan shaxsiy ma'lumotlar tarkibi, shaxsiy ma'lumotlar bilan bajariladigan amallar (operatsiyalar)ni belgilaydi;",
        "text-12":
            "Shaxsiy ma'lumotlar – to'g'ridan-to'g'ri yoki to'g'ridan-to'g'ri bog'liq har qanday ma'lumot https://merlinface.com veb-sayti va Merlin Dating mobil ilova foydalanuvchisi bilan;",
        "text-13":
            "Foydalanuvchi - https://merlinface.com va Merlin Dating mobil ilovasiga tashrif buyurgan har qanday shaxs",

        "text-14":
            "Shaxsiy ma'lumotlar taqdim etish - shaxsiy ma'lumotlarni aniqlangan shaxs yoki aniqlangan odamlar tomonidan namoyish qilishga qaratilgan harakatlar;",
        "text-15":
            "Shaxsiy ma'lumotlarni tarqatish - shaxsiy ma'lumotlarni cheksiz sonli kishilarga (shaxsiy ma'lumotlarni o'tkazish) yoki shaxsiy ma'lumotlar bilan tanishish uchun cheksiz sonli kishilarga namoyish qilishga qaratilgan har qanday amallar, shu jumladan shaxsiy ma'lumotlarni ommaviy axborot vositalarida nashr etish, ma'lumotlar va telekommunikatsiya tarmoqlarida joylashtirish yoki shaxsiy ma'lumotlarga boshqa usulda kirish imkoniyatini berish;",
        "text-16":
            "Shaxsiy ma'lumotlarni chegaradan o'tkazish - shaxsiy ma'lumotlarni xorijiy davlatga, xorijiy organ, xorijiy shaxs yoki xorijiy yuridik shaxs tomonidan o'tkazish;",
        "text-17":
            "Shaxsiy ma'lumotlarni yo'q qilish - shaxsiy ma'lumotlarni yo'q qilish natijasida qayta tiklanib bo'lmaydigan shaxsiy ma'lumotlar tizimidagi shaxsiy ma'lumotlarni mazmuni va (yoki) shaxsiy ma'lumotlar madadli vositalarini yo'q qilishga qaratilgan har qanday harakatlar.",
        "text-18":
            "To'lov - Ma'muriyatga mukofot, Foydalanuvchi tomonidan Ma'muriyatga cheksiz litsenziyani taqdim etish uchun to'lagan pul.",
        "text-19":
            "3. Ma'muriyat foydalanuvchining quyidagi Shaxsiy ma'lumotlarini ko'rib chiqishi mumkin:",
        "text-20": "3.1. Familiya, ism, otasining ismi;",
        "text-21": "3.2. Elektron pochta manzili;",
        "text-22": "3.3. Telefon raqamlari;",
        "text-23": "3.4. Tug'ilgan yil, oy, sana va joy;",
        "text-24": "3.5. Fotolar;",
        "text-25":
            "3.6. Ta'lim to'g'risidagi ma'lumotlar, kasbiy ma'lumotlar, ixtisoslik va malakalar, ta'lim hujjatlari haqida ma'lumotlar;",

        "text-26":
            "3.7. Sayt shuningdek, internet statistik xizmatlari (Yandex Metrika va Google Analytics va hokazo) yordamida zumladan nom-ayon tahlil olunuvchi ma'lumotlarni yig'ib hisoblaydi (o'quv yordamida).",
        "text-27":
            "3.8. Sayt avtomatik yoki foydalanuvchinig tasviri bilan qo'shimcha ma'lumotlar tashlashi mumkin, shu jumladan IP manzil, brauzer versiyasi, mamlakat, til, sotishda qo'shimcha tag'lar, shundan oldin foydalanuvchi saytga kirgan sahifaning manzili va boshqa texnika ma'lumotlar, ularga qo'shimcha usullar orqali aniqlash mumkin.",
        "text-28":
            "3.9. Foydalanuvchidan sayning xizmatlarini turli xil xizmatlarni ishlatish maqsadida, shu jumladan albatta, jamoat xotiralar, tezkor payvandlash xizmatlari, ijtimoiy tarmoqlar va shunga o'xshashlarni ochiq holda taqdim etilishi mumkin. Shunday holatda, sayt uchinchi tomondan qo'shimcha shaxsiy ma'lumotlarni qabul qilishingiz mumkin, shu jumladan, albatta, gender, joylashuv, foydalanuvchi avatar (foydalanuvchi tasviri) va shunga o'xshash jarayonlar. Uchinchi tomon xizmat doirasi orqali olinadigan barcha ma'lumotlar Foydalanuvchi Shartnomasi va Maxfiylik Siyosati asosida ishlab chiqarilib va saqlanib turiladi.",
        "text-29": "4. Shahsiy Ma'lumotlarni Qayta Ishlash Maqsadi",
        "text-30":
            "4.1. Foydalanuvchining shaxsiy ma'lumotlarini ishlov berishning maqsadi - Foydalanuvchining Hisobini va Shaxsiy Kabinetini yasab berish, Sayt va Mobil ilova bilan ishlash; elektron pochta orqali yoki xabarlar yuborish orqali Foydalanuvchini ma'lumotlantirish; xizmat ko'rsatish va texnik qo'llab-quvvatlash; Foydalanuvchini sayt va ilovada joylashgan xizmatlarga, ma'lumotlar va / yoki materiallarga ruxsat berish.",
        "text-31":
            "4.2. Administratsiyani yangi mahsulotlar va xizmatlar, maxsus takliflar va har xil voqealar haqidagi habarnomalar yuborishga ijozat beradi. Foydalanuvchi doimiy ravishda ma'lumot xabarlari qabul qilishni rad etish mumkin, info@merlinface.com ga shundan olib, Administratsiyaga elektron pochta orqali xabar yuborishadi: «Yangi mahsulotlar va xizmatlar va maxsus takliflar to'g'risidagi xabarnomalar yo'qotildi».",
        "text-32":
            "4.3. Foydalanuvchining Sayt bilan aloqada bo'lishi sharti bilan individual marketing xabarnomalar, maxsulotlar va xizmatlar to'g'risida Foydalanuvchi uchun ma'lumot yuborish, shuningdek, sayt bilan aloqa qilish haqida ma'lumot, masalan, ijtimoiy tarmoqlardagi reklamalar haqida ma'lumot taqdim etmaslik maqsadida ma'lumot yuborish mumkin. kim allaqachon Foydalanuvchi.",
        "text-33":
            "4.4. Internet statistik xizmatlari yordamida to'planadigan anonim foydalanuvchi ma'lumotlari sayt foydalanuvchilari tomonidan olingan ma'lumotlar to'plamini yig'ish, sayt sifatini va uning tarkibini yaxshilash uchun ishlatiladi.",
        "text-34":
            "4.5. Foydalanuvchi myAlpari da amal bajarayotganda xavfsizlik maqsadida va foydalanuvchi soxtakorlik ishlarini oldini olish maqsadida quyidagi faoliyatlar yozib olinadi: ro'yxatdan o'tish va ruxsat berish sanasi va vaqti, xizmatlar buyurtma va boshqa turdagi xizmatlar xarid qilish sanasi va vaqti, xabarlar yuborish sanasi va vaqti profil sahifasini o'chirish sanasi va vaqti.",
        "text-35": "5. Shahsiy Ma'lumotlarni Qayta Ishlashning Huquqiy Asosi,",

        "text-36":
            "5.1. Boshqaruv Uchastkoning shaxsiy ma'lumotlarni faqat Uchastko ularni to'ldirganda va/yoki ularni sayt va uning mobil platformasida joylashgan maxsus formalar orqali mustaqil tarzda yuborishini ishlaydi. Tegishli formalarini to'ldirish va/yo shaxsiy ma'lumotlarini Boshqaruvga yuborish orqali, Uchastko bu Siyosatga rozi bo'lishini ifoda etadi.",
        "text-37":
            "5.2. Boshqaruv Uchastkodan anonimizatsiya qilingan ma'lumotlarni ishlaydi, agar bu Uchastkonning brauzer sozlamalarida ruxsat etilgan bo'lsa (cookie saqlash va JavaScript texnologiyasini ishlatish yoqilgan).",
        "text-38":
            "5.3. Uchastko, saytga integratsiya qilingan boshqa tashqi xizmatlarga ulanish va ularni ishlatishda ixtiyoriy tarzda Shaxsiy ma'lumotlarni berishi mumkin.",
        "text-39":
            "6. Shaxsiy ma'lumotlarni yig'ish, saqlash, o'tkazish va boshqa usullari bo'yicha tartibot",
        "text-40":
            "6.1. Boshqaruv tomonidan ishlanayotgan shaxsiy ma'lumotlarning xavfsizligi, shaxsiy ma'lumotlarni himoya qilish sohasidagi amaldagi qonunchilik talablari bilan to'liq mos kelish uchun zarur bo'lgan qonuniy, tashkiliy va texnik chora-tadbirlarning amalga oshirilishi bilan ta'minlanadi.",
        "text-41":
            "6.2. Boshqaruv Uchastkonning shaxsiy ma'lumotlarini nazoratsiz yoki tasodifiy kirish, o'chirish yoki o'zgartirish, bloklash, nusxa ko'chirish, e'lon qilish yoki boshqa tasodifiy harakatlardan himoya qilish uchun barcha zarur texnik va tashkiliy chora-tadbirlarni amalga oshirishi kerak. Agar Uchastkonning shaxsiy ma'lumotlari yo'qotilgan yoki e'lon qilingan bo'lsa, Boshqaruv Uchastkoga shaxsiy ma'lumotlarining yo'qotilgan yoki e'lon qilgan to'g'risida xabar beradi.",
        "text-42":
            "6.3. Uchastkodan Shaxsiy ma'lumot va boshqa ma'lumotlarni uchun to'lovni amalga oshirish, yoki sayt bilan integratsiya qilingan boshqa tashqi xizmatlarning qo'shimcha funksiyalarini qo'shish kerak bo'lganida, uchta tomonlar so'rashi mumkin. Foydalanuvchi shaxsiy ma'lumotlarni va boshqa ma'lumotlarni ixtiyoriy tarzda topshiradi. Uchta tomonlar tomonidan so'ralgan barcha shaxsiy ma'lumotlar Foydalanuvchi Shartnoma va bu uchta tomonlar Konfidensiallik siyosati asosida ishlanishi va saqlanishi kerak.",
        "text-43":
            "6.4. Boshqaruv Shaxsiy ma'lumotlarni Boshqaruv nomidan xizmat ko'rsatuvchilariga o'tkazishi mumkin. Masalan, Boshqaruv mijozlariga yordam berish, uchta tomondagi resurslarda reklamalarni boshqarish, Boshqaruv nomidan marketing va boshqa xabarlar yuborish yoki ma'lumotlarni saqlashga yordam berish uchun uchta tomonlarni jalb qilishi mumkin. Ushbu uchta tomonlar Foydalanuvchilarning Shaxsiy ma'lumotlarini reklama maqsadlarida ishlatishdan man etiladi.",
        "text-44":
            "6.5. Agar Shaxsiy ma'lumotlarda noto'g'ri ma'lumotlar bo'lsa, Uchastko ularni o'z-o'zini yangilay oladi, Boshqaruvning elektron pochtasiga law@merlinface.com mavzusidagi xabarni yuborish orqali «Shaxsiy ma'lumotlarni yangilash».",
        "text-45":
            "6.6. Shaxsiy ma'lumotlarni ishlash muddati cheksizdir. Foydalanuvchilar Shaxsiy ma'lumotlarni ishlashga ruxsat berishni istalgan vaqtda rad etishlari mumkin, Boshqaruvga law@merlinface.com elektron pochtasiga «Shaxsiy ma'lumotlarni ishlashga ruxsat berishni bekor qilish» deb belgilangan xabar yuborish orqali. Texnik sabablarga ko'ra, ma'lumotlar hozircha o'chirilmaydi, lekin kechiktirilgan. Shuningdek, yuridik majburiyatlarni bajarish, nizolarini hal qilish, fareshtalikni oldini olish va Boshqaruvning halol manfaatlarini himoya qilish uchun ba'zi ma'lumotlarni saqlash mumkinligini hisobga olish kerak.",

        "text-46":
            "6.7. Foydalanuvchi tomonidan yuklangan uchinchi tomonning fotograflari saqlanmaydi va shaxsiy ma'lumotlar mavzusini aniqlash yoki ma'lumotlarni shakllashtirish uchun xizmat tomonidan ishlatilmaydi.",
        "text-47":
            "6.8. Sayt faqatgina Foydalanuvchi tomonidan Saytga to'g'ridan-to'g'ri berilgan ma'lumotlar bo'yicha Shaxsiy Ma'lumotlar operatori hisoblanadi va faqatgina Foydalanuvchi tomonidan yuklangan ma'lumotlarni qidirish uchun ishlatadi.",
        "text-48":
            "6.9. Foydalanuvchi ma'lumotlari va yuklangan fotograflardan foydalanish Serverga foto suratini o'tkazish orqali amalga oshiriladi. Merlin algoritmi yuzda nuqta-nuqta chizadi, xususiyatlar parametrlari va ifodalarini aniqlash uchun. Foydalanuvchi foto suratlari Serverda saqlanmaydi va Xizmat va/yo uchinchi tomonlar tarafidan foydalanilmaydi. Xususiyatlar hajmi va ifodalar parametrlari uchinchi tomonlarga o'tkazishsiz Serverda saqlanadi.",
        "text-49":
            "6.10 Xizmat xususiyatlar hajmi va ifodalarini quyidagilarni amalga oshirish uchun biriktiradi va foydalanadi:",
        "text-50":
            "ikki kishining xususiyatlar parametrlarini muvofiqlik, o'xshashlik va boshqa ko'rsatkichlarni aniqlash, Merlin algoritmini muallifi metodologiyasi bo'yicha solishtirish;",
        "text-51":
            "Foydalanuvchi uchun muvofiqlik darajasi bo'lgan shaxslarni tanlash, Merlin algoritmi ichiga o'rnatilgan muallifi metodologiyaga asosan;",
        "text-52":
            "Xizmat ma'lumotlar bazasi ichida xususiyatlar parametrlari bo'yicha shaxsni qidirish;",
        "text-53":
            "Sayt va Dastur kelajak foydalanuvchilari uchun muvofiqlik natijalarini saqlash.",
        "text-54": "7. To'lov siyosati",
        "text-55":
            "7.1. Foydalanuvchi Sayt xizmatlaridan foydalanish uchun obunani to'lash, quyidagilardan biri orqali amalga oshiriladi: Visa, MasterCard, Maestro, MIR, Yandex.Money, Webmoney, Qiwi, ApplePay, GooglePay, PayPal kabi to'lov tizimlari. Ushbu to'lov tizimlari ularning Foydalanuvchi shartnomasi va Maxfiylik siyosati asosida moliyaviy ma'lumotlarni yig'ib saqlaydi.",
        "text-56":
            "7.2. Administratsiya kartadagi to'liq ma'lumotlarni saqlamaydi va to'lovlar amalga oshmaydi, to'lov tizimidan faqatgina muvaffaqiyatli to'lov to'g'risida xabarnomani oladi.",
        "text-57":
            "7.3. Administratsiya Foydalanuvchi tomonidan amalga oshirilgan to'lovga oid qo'shimcha ma'lumotlarni yig'ishi mumkin, ular ichiga tranzaksiya raqami, tranzaksiya vaqti, to'lov uchun ishlatilgan kartaning turi va muddati, kartaning oxirgi to'rt raqami, kartaning egasi ismi, kartadan pul yechilgan mamlakat va shahar kiradi.",
        "text-58": "8. Shaxsiy ma'lumotlarni boshqa mamlakatlarga o'tkazish",

        "text-59":
            "8.1. Shaxsiy ma'lumotlarni transgranitsa o'tkazishdan oldin, Administratsiya kafolat berishi kerakki, chet elga shaxsiy ma'lumotlar o'tkazishining majburiy bo'lgan eli, Shaxsiy ma'lumotlar sub'ektining huquqlarini ishonchli himoya qiladi.",
        "text-60":
            "8.2. Shaxsiy ma'lumotlar transgranita o'tkazilishi quyidagi talablarga javob bermaydigan chet elga faqatgina Shaxsiy ma'lumotlar sub'ekti o'z shaxsiy ma'lumotlarini tasdiqlagan va / yoki shu shartnomani amalga oshirgan holda amalga oshirilishi mumkin.",
        "text-62": "9. Tomonlar mas'uliyati.",
        "text-63":
            "9.1. Foydalanuvchi uchinchi tomonning shaxsiy ma'lumotlarini qayta ishlashi kerak bo'lsa, Foydalanuvchi o'z muxbiriga muvofiq qo'rqqanliklarni himoya etish uchun ishga tushirishga jon bermoqda, shu jumladan mos ruxsatlarni olish, Foydalanuvchi tomonidan kerakli hujjat va ma'lumotlarni Saytda joylash.",
        "text-64":
            "9.2. Egar vazifalarini bajarish yuz berishsa, Administratsiya istalgan yo'qotishlar uchun mas'ul bo'ladi, lekin faqat hozirgi tarifga asosan Foydalanuchi tomonidan To'lov majburiyatidan ko'proq emas, bu yuz berishi mumkin, Foydalanuchi o'zining shaxsiy ma'lumotlaridan noyob foydalanish natijasida sodir bo'lgan zararlar soni.",
        "text-65": "a) tegishli yurisdiksiya organlariga etkazilgan;",
        "text-66":
            "b) uchinchi tomon tomonidan Administratsiyaga Foydalanuvchi tomonidan ruxsat berilganidan so'ng ochilgan;",
        "text-67": "c) ular yo'qolgan yoki ochilganidan oldin ochiqcha ekan;",
        "text-68":
            "d) ular Administratsiyaga topshirilmasdan oldin uchinchi tomondan olindi;",
        "text-69": "e) Foydalanuvchi ruxsat berilgan holda ochilgan;",
        "text-70": "f) kuchli kuch ishlab chiqargan natija sifatida ochilgan;",
        "text-71":
            "g) Uchinchi tomonning huquqlarini buzish va / yoki Foydalanuvchi Shartnomasini buzish to'g'risida targ'ib qilingan natijada ochilgan.",
        "text-72": "10. Yakunlayotgan takliflar",
        "text-73":
            "10.1. Foydalanuvchi o'zining shaxsiy ma'lumotlarini qayta ishlash to'g'risidagi manfaatdor savollarga to'liq tushunishga ega bo'lish maqsadida, e-pochta orqali law@merlinface.com adresiga murojaat qilib Administratsiyaga murojaat qilishi mumkin.",
        "text-74":
            "10.2. Ushbu hujjatda Administratsiyaning shaxsiy ma'lumotlarini qayta ishlash siyosati ostidagi o'zgarishlar aks etadi. Siyosat yangi versiya bilan almashtirilguncha amaldagi qoldiriladi.",
        "text-75":
            "10.3. Siyosatning hozirgi versiyasi bepul tarzda quyidagi Internet manzilida mavjud: https://merlinface.com/docs/privacy-policy",
    },

    "subscriptions": {
        "choose-type":
            "O'zingizga qiziqqan abunoturar joylashtirish toifasini tanlang",
        "cancel": "Abunoturaga muvaffaqiyatli bekor qilingan!",
        "success": "Abunotura muvaffaqiyatli yuklandi!",
        "choose-plan": "Rejani tanlang:",
        "junior":
            "Junior abunoturasi jami 4 toifadagi Gold imtiyozlarni o'z ichiga oladi: <br/> Kasbiylik va shaxsiy amalga oshirish, O'z-o'zini qadrlov va ishonch, Motivatsiya va kuch-quvvat, Munosabatlar va oila",
        "before-charges": "hisoblashdan oldin",
        "to-support": "yordam xizmatiga murojaat qilish",
        "left": "qoldiq",
        "status": "Abunotura holati",
        "failed-to-renew": "abunoturani yangilashda xatolik",
        "auto-renew-active": "avtotayyorlash faol",
        "active-to": "faol holatda",
    },
    "RetakeOnboarding": {
        DEFAULT: "Mening psixotipimni belgilash",
        PROFESSION_ORIENTATION: "Kasbiy tipizatsiyani o'tmoq",
    },
    ConnectSocial: {
        title: "Ijtimoiy tarmoqlar orttirilmoqda",
        enabled: "Orttirilgan",
        enable: "Bog'lash",
        enterEmail: "Elektron manzilingizni kiriting",
        codeSend:
            "Kodingiz elektron manzilingizga yuboriladi. Uni usha maydonga kiriting.",
        youGetCode: "Tasdiqlash kodini e-mail orqali olasiz",
        retryEmail:
            "E-mail, biror sababli kelmasa, siz yakunroq kodni yuborishingiz mumkin<span> {timer} sekundda...</span>",
        codeError: "Kod mos kelmaydi",
        retrySend: "KODNI QAYTA YUBOR",
    },

    FriendActions: {
        requestSend: "Do'stlik so'rovi yuborildi",
        cancel: "Bekor qilish",
        incomingRequest: "Kelib tushgan do'stlik so'rovi, qabul qilasizmi?",
        accept: "Qabul qilish",
        decline: "Rad etish",
        add: "Qo'shish",
        sendMessage: "Xabar yozing",
        addToFriends: "Do'stlariga qo'shish",
        partnerRequestSend: "Hamkorlik arizasi yuborildi!",
        makePartner: "Hamkorlik qilish",
    },
    RecommendedPeopleCtrl: {
        title: "Odamlar tomonidan tavsiyalar",
    },
    AppLoading: {
        title: "Internet ulanishingizni tekshiring<br/>va sahifani qayta yuklang",
    },
    ChooseFriendPhotos: {
        "analyze": "Tahlil qilish",
    },
    "ServiceCollapse": {
        "openNow": "Hozir oching",
        confirmTitle: "Tavsiyaning ochilishi",
        confirmText:
            "Tavsiyani hozir ochish uchun, PLATINUM tarifiga obuna bo'lishingiz kerak.",
        confirmBtn: "Obuna bo'lish",
    },
    LandingHeader: {
        advantages: "Nima olishingiz mumkin",
        steps: "Qanday ishlaydi",
        mission: "Bizning vazifamiz",
        enter: "Kirish",
    },
    LandingBanner: {
        title: "<span>Kasbiy ko'rsatgich testi</span><br/> A neural tarmoq sizga mos keladigan 3 kasbiy yo'naltiradigan<br/>",
        description: "Sun'iy intellekt dunyosiga kiring va Merlin AI bilan o'z mahoratingizni qo'llang",
        "list": ["Unikal algoritma sizning yaxshi va yomon tomonlaringizni tahlil qiladi", "Kasb tanlashda va munosabatlarda maslahat beradi", "Shaxsiy to'g'risizliklarni oling, tasvirlarni generatsiya qiling, suhbatlashing va o'qing"],
        btn: "O'z kasbingizni o'rganing",
    },
    "LandingAbout": {
        "title": "<span>Merlinface —</span> bu sun'iy intellekt sohasidagi mutaxassislar tomonidan yaratilgan, analogi bo'lmagan algoritmdir",
        "list": [{
            "1": "Fotoingizni yuklang va bizning neyron tarmogimiz siz uchun mos keladigan kasblarni tanlab beradi",
            "2": "AI bilan suhbatlashish tajribasi"
        }, {
            "3": "Haqiqiy vazifalar va loyihalar",
            "4": "Bizning neyron tarmogimiz bilan suhbatlashing, kursdan vazifalarni bajarish va neyron tarmoqlari operatorining mahoratlarini oshiring"
        }, {
            "5": "Chatda to'g'ridan-to'g'ri amaliy loyihalarda o'rganilgan bilimlarni qo'llang",
            "6": "Bugundan karieraga tayyorgarlik qiling: bizning AI siz uchun shaxsiy tavsiyalar beradi",
            "7": "ITga muvaffaqiyatli boshlash uchun tavsiyalar"
        }]
    },
    LandingAdvantages: {
        title: "Nima olishingiz mumkin?",
        showVideo: "Videoni ko'ring",
        list: [
            {
                id: 1,
                active: true,
                code: "professional",
                title: "Kasbiy Taqdimot",
                description:
                    "Bizning bazamizda 1000 dan oriq turli xil kasbiyliklar mavjud. Merlin AI sizga muvaffaqiyatli bo'lishingiz mumkin 3 ta kasbiylikni tanlashga yordam beradi.",
            },

            {
                id: 2,
                active: false,
                code: "psychotype",
                title: "Psixologik turlash",
                description:
                    "Sizning barcha kuch va zaif joylaringiz ro'yxati, shuningdek ularni qanday rivojlantirish haqida ma'lumot olishingiz mumkin.",
            },
            {
                id: 3,
                active: false,
                code: "person-description",
                title: "Shaxsingizning shaxsiyat va xulq-ahlakini tavsiflash",
                description:
                    "Saytimizda foydalanuvchilar uchun sevimli narsa topadigan har bir uchinchi foydalanuvchi.",
            },
            {
                id: 5,
                active: false,
                code: "recommendations",
                title: "Shaxsiy tavsiyalar",
                description:
                    "Merlin AI siz uchun yaxshi natijani tezroq erishishingizga yordam beradigan 150 dan ortiq individual tavsiya tayyorladi",
            },
            {
                id: 6,
                active: false,
                code: "person-recommendations",
                title: "Boshqa platforma a'zolaridan tavsiyalar",
                description:
                    "Merlin AI sizga doimiy ravishda ishonchli yoki biznesda yuqori moslikka ega odamlarni tanlashingizga yordam beradi, qayerda siz tadbirkor, do'st yoki munosabat uchun hamkor topishingiz mumkin",
            },
            {
                "id": 7,
                "active": false,
                "code": "messages",
                "title": "Tavsiya etilgan kasblar bo'yicha o'qishga linklar",
                "description": "Siz hamkorlik uchun biznes, munosabatlar yoki motivatsiya qidirayotgan boshqa foydalanuvchilar bilan muloqot qilishingiz mumkin"
            },
            {
                "id": 8,
                "active": false,
                "code": "celebrity",
                "title": "Mashhurlar bazasiga kirish",
                "description": "Siz shaxsan ularga uchrashish imkoniyatiga ega bo'lishingiz va hamkorlikni boshlashingiz mumkin, shuningdek, mos kelishuvchanlikni solishtirishingiz mumkin."
            },
        ],
    },
    LandingMission: {
        title: "Bizning vazifamiz",
        description:
            "Ularning potentsialini aniqlash va qobiliyatlari asosida, emas, ularning rezume bo'yicha ish topishga yordam beradigan vosita yaratish.",
        memberCount: "Identifikatoriya allaqachon xizmatda",
        btn: "Qo'shilish",
    },
    "LandingSteps": {
        "title": "Bu qanday ishlaydi?",
        "steps": [
            {
                "number": 1,
                "title": "Quyidagi \"Ishga tushirish\" tugmasini bosing",
            },
            {
                "number": 2,
                "title": "Telefon raqamingizni tasdiqlang",
            },
            {
                "number": 3,
                "title": "Suhbatga kiring va neyron tarmoq bilan aloqani boshlang",
            },
            {
                "number": 4,
                "title": "Natijalarni olish"
            },
            {
                "number": 5,
                "title": "Sun'iy intellektdan sizga mos keladigan kasblar ro'yxatini oling",
            },
        ],
        "result": {
            "title": "Siz natijaga erishasiz",
            "list": [
                "Mashhur neyron tarmoqlarga bir joyda kirish",
                "Merlin AI-dan har qanday savollarga javob olish qobiliyati",
                "Har qanday matn asosida rasm yaratish qobiliyati"
            ]
        },
        "btn": "Boshlash",
    },
    LandingReviews: {
        title: "Merlinface haqida nima deyishadi:",
    },
    MerlinLimit: {
        title: "Bugun uchun savol chegarasi tayinlangan - 5/5",
        desc: "Merlin AI bilan muloqotni davom ettirish uchun, har qanday to'lov rejimiga o'ting",
        btn: "Tariflarni ko'rish",
    },

    SubscriptionsData: {
        PROFESSION: {
            TITLES: [
                {
                    id: "PROF_OPTION_1",
                    name: "O'zingizning shaxsiy tavsiflaringiz o'zini amalga oshirish uchun",
                },
                {
                    id: "PROF_OPTION_2",
                    name: "Shaxsiy tavsiyalar o'zini amalga oshirish uchun",
                },
                {
                    id: "PROF_OPTION_3",
                    name: [
                        "Mavjud ko'rsatkichlar:",
                        "Biznes mosligi",
                        "Biznes aloqasi",
                        "Fiziki hujum",
                        "His-tuyg'u hujum",
                    ],
                },
                {
                    id: "PROF_OPTION_4",
                    name: "Mashhurlar Biznes Mosligi Sinovi",
                },
                {
                    id: "PROF_OPTION_5",
                    name: "Merlinface orqali biznes hamkorlarni jalb etish",
                },
            ],
            FREE: [
                {
                    id: "PROF_OPTION_1",
                    value: "30% ochiq",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "Haftada 1 marta",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "Kuniga belgilangan 1 ta",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "—",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "Haftada 1 marta",
                        "1 ta men uchun tanlangan",
                        "1 ta siz olasiz",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "PROF_OPTION_1",
                    value: "To'liq ochiq",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "Uch kuniga bir marta",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "Soatiga belgilangan 1 ta",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "Oyiga bir marta",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "Uch kuniga bir marta",
                        "1 ta men uchun tanlab olingan",
                        "3 ta siz olasiz",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "Haftada bir",
                },
            ],
            PLATINUM: [
                {
                    id: "PROF_OPTION_1",
                    value: "To'liq ochiq",
                },
                {
                    id: "PROF_OPTION_2",
                    value: ["Kuniga bir marta"],
                },
                {
                    id: "PROF_OPTION_3",
                    value: "Cheksiz",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "Haftada bir marta",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "Kuniga bir marta",
                        "1 ta tanlab olingan",
                        "5 ta sizga to'kib ketadi",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "Haftada bir marta",
                },
            ],
        },

        SELF_ESTEEM: {
            TITLES: [
                {
                    id: "ESTEEM_OPTION_1",
                    name: "O'z-o'zini hurmat qilishni oshirish uchun individual tavsiflar",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    name: "O'z-o'zini hurmat qilishni yaxshilash uchun shaxsiy tavsiyalar",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    name: [
                        "Mavjud ko'rsatkichlar",
                        "Belgilar o'xshashligi",
                        "Biznes munosabatlari",
                        "Odamlar bilan aloqalar",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_5",
                    name: "Tajribani almashish uchun odamlar tanlovi",
                },
            ],
            FREE: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "30% ochiq",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "Haftasiga 1 marta",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "Kundaligida ko'rsatilgan 1",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "—",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "Haftasiga 1 marta",
                        "1 meni uchun tanlandi",
                        "1ga siz erishingiz mumkin",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "To'liq ochiq",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "Uch kundan 1",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "Soatiga ko'rsatilgan 1",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "Oyiga 1 marta",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "Uch kundan 1",
                        "1 meni uchun tanlandi",
                        "3ga siz erishingiz mumkin",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "Haftasiga 1 marta",
                },
            ],
            PLATINUM: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "To'liq ochiq",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: ["Kuniga 1"],
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "Cheksiz",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "Haftasiga 1 marta",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "Kuniga 1",
                        "1 meni uchun tanlandi",
                        "5ga siz yetasiz",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "Haftasiga 1 marta",
                },
            ],
        },

        MOTIVATION: {
            TITLES: [
                {
                    id: "MOTIVATION_OPTION_1",
                    name: "O'zingizning shaxsiy tavsiflarizning motivatsiyani oshirish uchun",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    name: "Motivatsiyani oshirish uchun shaxsiy tavsiyalar",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    name: "Motivator insonlarni tanlash",
                },
            ],
            FREE: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "30% ochiq",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "Haftada 1 marta",
                },
                {
                    id: "MOTIVATION_OPTIONT_3",
                    value: [
                        "Oyda 1 marta",
                        "Menga bir tanlanadi",
                        "1 marta sizga tepadi",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "to'liq ochiq",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "Uch kundan bir marta",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "Uch kundan bir marta",
                        "Menga bir tanlanadi",
                        "Sizga bir marta olib keladi",
                    ],
                },
            ],
            PLATINUM: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "to'liq ochiq",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: ["Kundan bir marta"],
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "Kundan bir marta",
                        "Menga bir tanlanadi",
                        "1 marta sizga tepadi",
                    ],
                },
            ],
        },

        RELATIONS: {
            TITLES: [
                {
                    id: "RELATIONS_OPTION_1",
                    name: "Sizning munosabatingizning xususiyatlari",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    name: "Yaxshi munosabat uchun individual tavsiyalar",
                },
                {
                    id: "RELATIONS_OPTIONT_3",
                    name: [
                        "Mavjud ko'rsatkichlar:",
                        "Munosabatlar uygunligi",
                        "Odam bilan aloqa",
                        "Umumiy hujum",
                        "Jismoniy hujum",
                        "His tuyg'usi hujum",
                        "Jamiyat hujum",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_4",
                    name: "Yo'l-yo'riq uygunligini solishtirish",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    name: "Munosabatlar uchun hamkorlarni tanlash",
                },
            ],
            FREE: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "30% ochiq",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "haftada 1 marta",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "kuniga ko'rsatilgan 1ta",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "—",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "haftada 1 marta",
                        "1ta men uchun tanlandi",
                        "1ta siz oling",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "to'liq ochiq",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "uch kun ichida 1ta",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "soatiga ko'rsatilgan 1ta",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "oylik 1ta",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "uch kun ichida 1ta",
                        "1ta men uchun tanlandi",
                        "3ta siz oling",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "haftada 1ta",
                },
            ],
            PLATINUM: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "to'liq ochiq",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "kuniga 1ta",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "cheksiz",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "haftaga 1ta",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "kuniga 1ta",
                        "1ta men uchun tanlandi",
                        "5tagacha siz oling",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "haftada 1ta",
                },
            ],
        },
    },

    TariffJunior: {
        "title-1": "JUNIOR faol",
        "title-2": "Junior",
        desc: "Junior abonementi oltin reja foydalarini 4 toifada o'z ichiga oladi: <br/>Kasb va o'zini namoyon etish, o'ziga ishonch, motivatsiya va energiya, munosabatlar va oila",
        cancelSubscription: "Abonementni Bekor Qilish",
        confirmation: {
            title: "Obuna bekor qilindi",
            desc: "JUNIOR abonementingizni bekor qilmoqchi ekaningizni tasdiqlaysizmi? To'lov qilingan davrning oxirigacha barcha foydalar mavjud bo'ladi",
            btn: "Abonement bekor qilish",
        },
    },
    SubscriptionPeriodLabel: {
        MONTH: "oylik",
        ANNUAL: "yillik",
    },
    SubscriptionPeriodOptions: {
        MONTH: "oylik",
        ANNUAL: "yillik",
    },

    Tips: {
        close: "Yordamchi izohni yopish",
        prev: "Orqaga",
        next: "Keyingi",
        disable: "Yordamlarni o'chirish",
        categories: {
            PROFESSIONAL: "Kasba va maqsad",
            RELATIONS: "Munosabatlar va oila",
            MOTIVATION: "Motivatsiya va samarali",
            SELFASSESSMENT: "O'z-o'zini baholash va ishonch",
        },
        list: [
            {
                name: "auth",
                element: ".auth-form",
                title: "Telefon raqam orqali ro'yxatdan o'ting",
                subtitle:
                    "Bu, faqat siz ma'lumotlariga ega bo'lishingiz va parolni o'ylab topishga to'g'ri kelmasligi uchun kerak.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "avatar",
                element: "[data-tip='avatar']",
                title: "Avatar o'rnating",
                subtitle:
                    "Avatar bo'lmasa, algoritm sizni boshqa sayt foydalanuvchilariga maslahat berishini maslahat bermaydi.",
                timeout: 2000,
                next: "recommendations",
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },

            {
                name: "recommendations",
                element: "[data-tip='tavsiyalar']",
                title: "",
                subtitle:
                    "Siz bu yerda turli maqsadlar uchun yordam beradigan tavsiyalar topasiz.",
                timeout: 2000,
                next: "people-recommendations",
                prev: "avatar",
                showOn: ["desktop", "mobil"],
                hideAfterShow: true,
            },
            {
                name: "people-recommendations",
                element: "[data-tip='odamlar-tavsiyasi']",
                title: "",
                subtitle:
                    "Siz bu yerda sizga turli kategoriyalardagi odamlarning tavsiyasini topasiz",
                timeout: 2000,
                next: "search",
                prev: "recommendations",
                showOn: ["desktop", "mobil"],
                hideAfterShow: true,
            },
            {
                name: "sphere",
                element: "[data-tip='sphere']",
                title: "Odamlar tez-tez 4 asosiy sohalarda muammoga duch kelishadi",
                subtitle:
                    "Men allaqachon sizning rasmlaringizni tahlil qildim va shaxsiy tushunchni yaratdim. Qiziq bo'lgan kategoriya bo'yicha bosing.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobil"],
                hideAfterShow: true,
            },
            {
                name: "inside-sphere",
                element: "[data-tip='sphere']",
                title: "Ochiq va Yopiq Soha Kategoriyalari",
                subtitle:
                    "Sariq rang sizga o'qish uchun ochiq bo'lganlarni ko'rsatadi. Qorong'i rangli kategoriyalar qiziqishiga obuna bo'lganida ochiladi.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobil"],
                hideAfterShow: true,
            },

            {
                name: "search",
                element: "[data-tip='search']",
                title: "",
                subtitle:
                    "Qidiruv orqali siz nom yoki familiya bo'yicha istalgan russ shaxsiyatini topishingiz mumkin.",
                timeout: 2000,
                next: "notifications",
                prev: "people-recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "notifications",
                element: "[data-tip='notifications']",
                title: "Qo'ng'iroq Bildirishnomasi Tugmasi",
                subtitle:
                    "U sizga ijtimoiy tarmoqdagi faol harakatlar uchun turli bonuslar haqida xabarlar, biror kishi siz bilan moslikni tekshirgani haqida bildirishnomalarni ko'rsatadi.",
                timeout: 2000,
                next: "subscriptions",
                prev: "search",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "subscriptions",
                element: "[data-tip='subscriptions']",
                title: "",
                subtitle:
                    "Bu yerda siz mumkin bo'lgan obunalar ro'yxati va faol obunalaringizni topishingiz mumkin.",
                timeout: 2000,
                next: false,
                prev: "notifications",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "multi-compare",
                element: "[data-tip='multi-compare']",
                title: "Kategoriyalarni Tanlang",
                subtitle:
                    "Siz dostlaringiz bilan solishtirishni xohlagan bir qancha kategoriyalarini tanlashingiz mumkin. Faqatgina ular solishtirishlar mavjud, uchun siz PLATINUM rejasi faollashtirdingiz.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "choose-avatar",
                element: "[data-tip='choose-avatar']",
                title: "Avataringizni o'rnating",
                subtitle:
                    "Keyin sizni boshqalarga tavsiya qilishim mumkin. Sizni suratsiz reklama qila olmayman.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "recommendations-modal",
                element: "[data-tip='recommendations-modal']",
                title: "Shaxsiy tavsiyalar",
                subtitle:
                    "Hisoblagich muddati tugagandan so'ng sizda bitta tavsiya ochiq bo'ladi. Keyingi tavsiya uchun hisoblagich oxirgi mavjud bo'lganini o'qimaguningizcha ishga tushmaydi.",
                timeout: 500,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
        ],
    },

    WelcomeLayout: {
        ctrl: {
            "step-1": "So'rovlar bilan test",
            "step-2": "Foto test",
            "step-3": "Foto test",
            "step-4": "Kasbiy test",
        },
        "back-to-main": "Bosh sahifaga qaytish",
        "back-to-main-confirm-title": "Amalni tasdiqlang",
        "back-to-main-confirm-desc":
            "Bosh sahifaga qaytishga ishonchingiz komilmi? Barcha jarayonlar abadiy yo'qotiladi.",
        "back-to-main-confirm-apply": "Bosh sahifaga qaytish",
    },

    WelcomeWant: {
        title: "Tabriklaymiz, birinchi qadam qo'yildi.",
        description:
            "Mening ismim Merlin va men sizga haqiqatan ham kerak bo'lgan narsani olishga yordam beraman. <br/> Iltimos, nima olmoqchi bo'lganlaringizni ko'rsating:",
    },
    WelcomeTest: {
        "title-1": "Test vaqti 15 daqiqa",
        "title-2": "Biz sizga mos keladigan 12 ta soha aniqladik!",
        "title-3": "SIZ JUDA YAXSHISIZ! Yangi bilim olishga 2 bosqich qoldi",
        "subtitle-1": "Endi bir oz psixologik test",
        "subtitle-2":
            "Keling, kesimni davom ettiraylik. Yana birga javob bering",
        "subtitle-3":
            "Shaxsiyat turingizni aniqlash uchun oxirgi {count} savollar.<br/> Sizga mos keladigan sohalarni aniqlash",
        "question-destination-1": "savol",
        "question-destination-2": "savol",
        "question-destination-3": "savollar",
        "choose-title": "O'zingizni eng yaxshi ifodalovchi variantni tanlang:",
        "next-question": "Keyingi savol",
        "quote-1": "Har bir savolda o'zingizni topasiz",
        "quote-2":
            "Yoqtirgan narsani tanlashda va uni tark etmaslikda kuch topish. Aks holda, o'lim yaxshi. (c) Albert Camus",
        "quote-3":
            "Kamalot odobi o'zida hamma narsani qidiradi, xushomad odobi - boshqalarda. (c) Konfutsiy",
    },

    WelcomePhotoTest: {
        title: "Natija qolgan 1 bosqich",
        subtitle: "PSIXOLOGIK TEST sizning kuchlaringizni aniqlash uchun",
        "choose-title": "Qaysi yuzni eng ko'proq yoqtirasiz?",
        "next-btn": "Keyingi Fotosuratlar",
    },
    WelcomeCheckPay: {
        "title-success": "To'lov muvaffaqiyatli amalga oshirildi",
        "title-error": "To'lovda xatolik",
        "description-success":
            "5 sekund ichida keyingi qadamga yo'naltirilasiz...",
        "description-error":
            "Davom ettirish uchun to'lovni amalga oshirishingiz kerak",
        "btn-success": "davom eting",
        "btn-error": "Qayta urunish",
    },
    WelcomeAnalyze: {
        title: "Javoblarni tahlil qilish",
    },
    WelcomePhotos: {
        title: "NATIJALARINGIZNI YAXSHILANG",
        subtitle:
            "Sizning javoblaringiz neyron tarmoq algoritmi tomonidan tahlil qilinadi.<br/> Aniqroq kasb tanlovi uchun fotosizingizni yuklang.",
        "upload-photos-title": "Aniqroq kasb mosligi uchun rasm yuklash",
        "next-btn": "Keyingi",
        "skip-btn": "O'tkazib yuborish",
    },
    WelcomeAnalyzeReady: {
        title: "Men 170 ta munosib bo'lmagan kasblarni olib tashladim.",
        subtitle: "10 daqiqada siz uchun eng yaxshi kasblarni tanlaymiz.",
        desc: "O'zingizga mos kelmas mamlakatlarga vaqt sarflamang.<br/> Test ishga joylashtirish jarayonini tezlashtirishga yordam beradi.<br/> Test o'tgan 90% odamlar o'zlarini sevuvchan ishda topishdi va o'zlarining yo'l topishdi",
        quote: "Har qanday maqsad bajarilishni talab qiladi. (c) Aristarkh Romashin",
        "start-btn": "Boshlash",
    },
    WelcomeProfessionVote: {
        "title-1": "",
        "title-2": "Ajoyib! {count} {decl} 1150dagi qoldi",
        "title-3": "Ajoyib! Biz yaqinda",
        "title-4": "Yangi kasbga bosib, davom etamiz",
        "subtitle-1":
            "Ushbu aylantiruvda, sizning o'zingizni maksimal darajada aniqlashingiz mumkin bo'lgan faoliyat yo'nalishlari topildi.<br/>1150 ta kasbdan {count} {decl} qoldi. Meni siz uchun eng yaxshisini tanlashga yordam qiling.<br/> <br/>Saralgan bo'limni belgilang",
        "subtitle-2": "Saralgan bo'limni belgilang",
        "subtitle-3": "{count} {decl} 1150dagi kasblar qoldi",
        "subtitle-4": "1150dagi {count} {decl} kasblar qoldi ",
        "profession-declination-1": "kasb",
        "profession-declination-2": "kasblar",
        "profession-declination-3": "kasb",
    },

    ProfessionVotePopup: {
        "help-1":
            "Juda yaxshi. Men sizning javobingizni esladim. Endi keyingi bir nechta kasblarni to'ldiring va Hisoblash tugmasini bosing.",
        "help-2":
            "Agar siz kasb nima ekanligini bilmagan bo'lsangiz, faqat uning nomini bosing.",
        "help-3":
            "Bu yerda sizga bu ishni qilishga nima sabab bo'lishi va sizning qaysi sifatlaringiz bu ishda yordam berishi mumkinligi yozilgan.",
        "help-4":
            "Bu sizning kasbingizda o'zgarishlarni keltirib chiqarishi mumkin bo'lgan kasbning misoli. Iltimos, bu siz uchun qanchalik qiziqarli ekanligini baholang. <br/><br/>Diqqat bilan baholang, men sizning javoblarizni eng yaxshi kasbni tanlashda foydalanaman <br/ ><br/>Birinchi kasb uchun ovoz berish",
        "error": "Iltimos, keyingi kasblar uchun ovoz bering:",
        "next-btn": "Davom eting",
        "calc-btn": "Hisoblang",
    },

    OnboardDescriptionPopupRadio: {
        list: [
            {
                id: 1,
                text: "Hech qachon qiziqmayman",
                showSub: false,
            },
            {
                id: 2,
                text: "Hech qachon urinib ko'rmadim, lekin men qiziqchiman",
            },
            {
                id: 3,
                text: "Men hozirgi paytda ushbu yo'nalishni o'rganyapman",
            },
            {
                id: 4,
                text: "Men urindim, bu ishlamadi",
            },
            {
                id: 5,
                text: "Men faol ravishda buni qilyapman va men bu ishda yaxshi yurayapman",
            },
            {
                id: 6,
                text: "Men buni juda ko'p tajribaga ega bo'lgan mutahassisman",
            },
        ],
    },

    WelcomeProfPay: {
        "title-1": "1150 kasb orasida sizga 3 ta mos",
        "title-2": "To'lov usulini tanlang",
        "desc-title":
            "{count} kishi o'zining sevimli kasbini topdi va daromadini oshirdi",
        "desc": "Test o'tganlarning 87% i uni sevimli kishilari uchun tavsiya qildi <br/>Test mamlakatdagi 38 ta yetakchi kompaniyalarining boshqaruv rahbarlari tomonidan ishlatilmoqda <br/><br/>Kasbda muvaffaqiyatli bo'lish uchun shaxsiy maslahat oling",
        "open-btn": "499 rubl uchun oching",
        "motivation":
            "Siz allaqachon 15 daqiqa sarf qildingiz, oxirgi qadam qoldi!",
        "recreate-text": {
            "btn": "Testni qayta topshiring",
            "confirm-title": "Testni qayta boshlash kerakmi?",
            "confirm-desc":
                "Agar siz o'ylasangiz, testni to'g'ri topshirib bo'lmadingiz, yana bir bor uni topshirishingiz mumkin. Diqqat: topshirilgan testning o'tgan jarayoni abadiy o'chiriladi.",
            "confirm-btn": "Testni qayta topshiring",
        },
        partners: {
            "title": "Bizning hamkorlarimiz tomonidan tasdiqlandi:",
            "list": [
                "Rossiya Federatsiyasi Ta'lim Vazirligi",
                "Innovatsiyalarni rag'batlantirish fondi",
                "B20 Indoneziya 2022",
                "Skolkovo",
                "Merlin AI - Telegramning rasmiy hamkori",
            ],
        },

        paymentMethods: {
            all: "Barcha to'lov usullarini ko'rsatish",
            phone: {
                title: "1. Telefon raqami orqali",
                copy: "nusxa ko'chirish",
                copied: "nusxa ko'chirildi",
                "copy-number": "Raqamni nusxa ko'chirish",
            },
            link: {
                title: "1. Havola orqali",
                btn: "Havolaga o'tish",
            },
            kassa: {
                title: "2. Robokassa orqali",
                btn: "To'lash",
            },
            qr: {
                title: "4. QR kod orqali",
                btn: "Tanlash",
            },
        },
        "after-pay":
            "To'lovni amalga oshirganingizdan so'ng, belgini belgilang, biz to'lovni tekshirishimiz mumkin",
        "after-pay-label": "To'landi 499 rubl",
        "after-pay-btn": "Davom etish",
    },
    WelcomeProfessions: {
        "title":
            "Biz sizning eng qiziqarli bo'lgan sohalaribizni aniqlab oldik<br/>",
        "title-1":
            "Juda yaxshi!<br/>Biz sizga eng mos keladigan kasblarni aniqlab oldik",
        "title-2": "Juda yaxshi!<br/>Hozir",
        "title-3": "Juda yaxshi!<br/>Hozir",
        "subtitle-1":
            "<b class='gold'>ko'proq</b> sizni qiziqtirgan kasbni tanlang",
        "subtitle-2":
            "Aloqalar ko'p - soha mosligi yuqori<br><br>Har qanday sohani bosib, mos kasblarni ko'rasiz",
        "title-helper": "sohadagi kasblar",
        "these": "Bu lar",
        "merlin-help":
            "Men ularga sizni tanishtiraman. Merlinface-da kimdir-kin buni yaxshi biladi. Men ularga sizni tanishtiraman. <br/><br/>Lekin hozir menim bir narsangiz bor...",
        "more-btn": "O'rganish",
        "next-btn": "Davom etish",
    },

    WelcomeBusinessCardInfo: {
        "info-title": "O'zingiz haqida ba'zi ma'lumotlarni to'ldiring",
        "edu-title": "Ta'lim to'g'risida ma'lumotlaringizni to'ldiring",
        "career-title": "Ish to'g'risida ma'lumotlaringizni to'ldiring",
        "subtitle":
            "Ushbu ma'lumotlar sizning sahifangizni ko'rganda boshqa foydalanuvchilar tomonidan ko'riladi. Bu sizning potentsial biznes hamkorlaringizni o'z ichiga olishi mumkin",
        "all-fields-error": "Barcha maydonlar to'ldirilishi kerak",
        "edu-fields-error": "Kamida bir ta'lim joyiga qo'shing",
        "career-fields-error": "Kamida bir ish joyini qo'shing",
    },
    WelcomeBusinessCardPay: {
        loading: "Yuklanmoqda...",
    },
    WelcomeStart: {
        "title": "Profilni diqqat bilan to'ldiring!",
        "subtitle":
            "Ma'lumotlarni ko'proq to'g'ri kiritish, natijani ham ko'proq to'g'ri qiladi",
    },
    WelcomePersonInfo: {
        title: "Juda yaxshi, ",
        subtitle: "O'zingiz haqida ba'zi ma'lumotlarni to'ldiring:",
        age: "yosh",
        "age-placeholder": "Sizning yoshingiz nechida?",
        "age-error": "14 dan 99 gacha bo'lgan yoshni kiritish",
        "choose-country": "Mamlakatni tanlash",
        "choose-city": "Shaharni tanlash",
        "country-placeholder": "Mamlakatni qidirish",
        "city-placeholder": "Shaharni qidirish",
        "city-no-options":
            "Tanlangan mamlakatda qidirish uchun shaharning ismini kiriting",
        "social-title": "Ijtimoiy holatingiz:",
        "social-error": "Ijtimoiy vaziyatni tanlang",
        "next-btn": "Davom etish",
    },
    WelcomeTestComplete: {
        title: "Men Sizning Psixotipingizni Aniq qildim",
        "your-type": "Bizning saytda sizning psixotypingiz {count} {dec}",
        "your-partners":
            "Siz bilan yuqori biznes mosligi bo'lgan {count} {decl}.",
        "celebrity":
            "{count} mashhur va muvaffaqiyatli kishilar sizning shaxsiyatingiz turi.",
        "next-btn": "Davom etish",

        typeDescriptions: {
            "Vositachi":
                "Tabiiy qobiliyatlaringiz sizni ajoyib mediator qiladi. Siz muvofiqlarni hal qilish va turli xil odamlar bilan umumiy nuqtai-nazarga kelishga qodir bo'lasiz. Boshqalarning ehtiyojlarini tinglash va tushunishga qodir bo'lgan qobiliyatingiz sizni har qanday jamoa uchun qadrli a'zoga aylantiradi. Ma'lumotlarga diqqat vositasi va tinchlikqa shayni munosibatlarni kuchli va samarali qiladi.",
            "Kommunikator":
                "Sizda alohida kommunikatsiya qilishga mo'ljallangan sovg'a mavjud. Qobiliyatlaringiz sizga samarali ravishda muloqot qilish, fikrlar va fikrlaringizni ifodalashga va boshqalarning fikrlariga hörmat bildirishga yordam beradi. Kommunikatsiya qilishni oson va zevkli qiluvchi qobiliyatingiz kasbiy muammolarni yechishda sizga yordam beradi.",
            "Ulanish":
                "Siz ajoyib bog'lovchi bo'lasiz, odamlar va fikrlar bilan aloqa qilishga qodir bo'lasiz. Sizning ijtimoiy moslashuvchanlik qobiliyatlaringiz va boshqalariga qaratilgan diqqatingiz sizni kasbiy tarmoqlarning markaziga keltiradi. Tashkilotchilik qobiliyatlaringiz muvaffaqiyatli jamoalar va loihalarni yaratishda sizga yordam beradi.",
            "Gedonist":
                "Siz kasbiy hayotda rahmat va zevk topasiz. Qobiliyatlaringiz sizga ishni zevk bilan bajaringizga yordam beradi va ajoyib natijalar hosil qilasiz. Siz qulaylikni, tinchlikni qiymatlantirasiz va ish jarayonini imkon qadar zevkli va samarali qilish uchun usullarni topishingiz mumkin.",
            "Diplomat bosqinchisi":
                "Sizda alohida kommunikatsiya qilishga mo'ljallangan sovg'a va odamlarni ta'sirlantirish uchun qobiliyat bor. Qobiliyatlaringiz har qanday muhitda umumiy ma'no topishga va ishlar bajarishga imkon beradi, bu sizni kasbiy sohada ajoyib ta'sirlantiruvchiga aylantiradi. Siz mehnat asrida muvaffaqiyatga olib keladigan harakatlar va muvaffaqiyat uchun harakat qilasiz, va olib borishga va ijobiy ta'sir qilishga xohlaysiz. Shuningdek, o'zining qobiliyatlari bilan hamkorlik qilishga va taxmini qilishga qodir bo'lasiz, bu sizga eng murakkab muammolarni yechishda yordam beradi.",
            "Lobbist":
                "Sizda alohida majbur etish va ta'sir ko'rsatish qobiliyatlari bor. Qobiliyatlaringiz sizga osonlik bilan aloqalarni o'rganishga, atrofingizdagi odamlarni o'z tarafingizga jalb qilishga va maqsadlaringizga erishishga imkon beradi. Siz jarayonlarni boshqarish, strategiyalar yaratish va manfaatlar lobbyini qilishga qodir bo'lasiz. Boshqalar ustida ta'sir ko'rsatishga qodir bo'lgan qobiliyatingiz sizni har qanday sohada qadrli a'zo qiladi. Sizning tashabbuskorligingiz va tashkilotchilik qobiliyatlaringiz sizga qiyin sharoitlarda muvaffaqiyatli ishlashda va to'siqni yengishda yordam beradi.",
            "Siyosatchi":
                "Olib borish va ta'sir ko'rsatishga qodir bo'lishingiz sizga siyosiy maydonga muvaffaqiyatli bo'lishga imkon beradi. Agar siz muhim qarorlar qila olsangiz, muvofiqlarni hal qilish va turli fikrli odamlar bilan umumiy nuqtai-nazarga kelasiz. Sizning faol yondashuv va o'zgartirish xohlashingiz va boshqalarni fikrlaringizga jalb qilish qobiliyatingiz sizni samarali siyosatchi qiladi. Sizning ijtimoiyligingiz va o'zini ishonch qilishga qodir bo'lishingiz sizga maqsadlarni erishishga va davlat jarayonlarida ma'noli hissa olishga imkon beradi.",
            "Materialist":
                "Siz madaniyatga ehtiyoj qilasiz va natija olish uchun ishlashga yordam beradi. Sizning amaliylik va maqsadga yo'naltirish qobiliyatlaringiz sizni doim halqaro bo'ysunadi. Siz resurslarni boshqarish, planlash va o'z rejalaringizni samarador bajarish uchun qodir bo'lasiz. Tashkilot, optimallashtirish va qaror qabul qilish qobiliyatlaringiz sizni har qanday jamoa yoki loihaga kam unsiz qiladi.",

            "Konservativ":
                "Sizning qobiliyatlaringiz va ko'nikmalaringiz saqluvchi sifatida ishlashga to'liq mos keladi. Siz stabillik, tartib va an'anani qadrlaysiz va bu qiymatlarni har qanday kasbga kiritishingiz mumkin. Sizni tashkil etish va tizimlashga qodirlikni saqlashingiz va diqqatni har bir tushunchaga yo'naltirishingiz mumkin. Maqsadlarni erish uchun harakatlar va sabrmingiz mustahkam va barqarar tuzilmalar yaratadi.",
            "Yopuvchi":
                "Sizni vakolat beruvchi roli uchun eng yaxshi variant sifatida ko'radilar. Sizning qobiliyatlaringiz va mahoratingiz sizni umumiy maqsadlarni erishda sadoqatli va ishonchli qilib beradi. Sizning ijodiylik, javobgarlik va jamoa ishlash ko'nikmalaringiz sizni har qanday loyihada kamolatli hissa qo'shuvchi qiladi.",
            "Qo'riqchi":
                "Sizning boshqalarni nazorat qilish va tartibni saqlash uchun qobiliyatlaringiz sizni idealdagi xizmatkor qiladi. Sizning mahoratlaringiz sizning qiymatlarni va resurslarni saqlab qolishga, shuningdek mas'uliyat va nazorat orqali istiqomat va ishonch hosil qilishga imkon beradi.",
            "Ommaviy fikrlovchi":
                "Sizning qobiliyat va mahoratingiz sizni ko'ngilochar rolida ajoyib qilib beradi. Sizda yuqori darajada motivatsiyali muammolarni hozircha yechishga mos keladigan g'oya bilan odamlarni ko'tarish, tashkil etish va shug'ullanishga qodirlik bor. Sizning ijodiylik va g'ayratlaringiz yangi vaziyatlarga tez moslashga yordam beradi. Sizning energiya va insonparvarlik sizni har qanday jamoadan sevimli a'zo qiladi.",
            "Tashkilotchi":
                "Sizda tashkil qilish va rejalashtirishga tabiiy mahoratlar bor. Kommunikatsiya va resurs boshqarish ko'nikmalaringiz sizga odamlar va vazifalar tashkil etishga imkon beradi. Siz bilasiz qanday ishni boshqarish kerak, shunda siz faqat samarali emas, balki har bir qatnashchiga yoqimli bo'lishi kerak. Rahbarlik qilishga qodirlik va jamoa ruhini saqlashga qodirlik sizni har qanday loyihada muhim element qiladi.",
            "Optimist":
                "Sizning optimizm va hayotga qarash istiqbolbaxshingiz sizga har qanday kasbda ustunlik beradi. Har qanday vaziyatni yorug'lik jihatidan ko'rish imkoniyati va boshqalarni ruhlantirish imkoniyatingiz sizga tezroq echim topishga va muammolarni yechishga yordam beradi. Sizning g'ayrat va hushyorligingiz jamoadan ijodiy energetika beradi, umumiy samaradorlikni oshiradi.",
            "Faol": "Sizning energiya, faollik va odamlarni ta'sir qilish qobiliyati sizni a'lo activist qiladi. Siz faqat jarayonga faol qatnashishga emas, balki unga boshqalarni ham jalb qilishga qodirsiz. Sizning kommunikatsiya va tashkil qilishga qodirlik fazilatlaringiz sizning g'oyalar rozilashuviga va maqsad olishga yordam beradi. Sizning g'ayrat va o'ziga ishonch sizga ajoyib natija olishga yordam beradi.",
            "Ilhomlantiruvchi":
                "Dunyoni kengaytirilgan kontekstga ko'rish qobiliyati va muammolarni echishga ijodiy yondashuv sizni inspirational fikrlash uchun ideal qiladi. Sizning g'oyalar odamlarning yuraklarida ulkan olov yorib, ularni yangi narsalar qilishga rag'batlantira oladi. Intuitsiya va imkoniyatlarga nozik his qobiliyatlaringiz sizga trendlarni oldindan ko'rishga va yangi echimlar yaratishga imkon beradi.",

            "Romantik":
                "Sizning hassaslik va dunyoni chuqurroq tushunishingiz sizni ro'molchi qiladi. Sizda oddiy narsalarda go'zallikni ko'rishga doir noyob qobiliyat mavjud va siz his-tuyg'ularingizni ishingizga tarjima qilasiz. Boshqalar uyg'otish va uyg'onashtirish qobiliyatingiz yangi g'oyalarga va nuqtai nazarlarga kaliti bo'lishi mumkin.",
            "Filantrop":
                "Sizda go'zallik va uyushishni, shuningdek yaratuvchi g'oyalarni qo'llab-quvvatlash va rivojlandirishga doir tabiiy qobiliyat bor. Boshqalarga yordam berish, ularning ijodiyliklarini qo'llab-quvvatlash va san'atga e'tibor berish istagingiz sizni san'atni himoya qiluvchi ideal odam qiladi.",
            "Xayolparast":
                "G'oyalar yaratish, his-tuyg'ularni chuqurroq tushunish va katta rasmlarni ko'rish qobiliyatingiz sizni ideal orzudor qiladi. Siz orzularni tushunish va g'oyalaringizni uchiq qo'yishdan qo'rqmasdan, inspiratsiya va tasavvurningiz sizga har qanday sayohatda yangi imkoniyatlar ko'rishingizga imkon beradi.",
            "G'oya rahbari":
                "Yangi g'oyalarni yaratish va amalga oshirishga doir qobiliyatingiz, shuningdek boshqalar uyg'onish va rag'batlantirishga doir qobiliyatingiz sizni ideal lider fikr qiladi. Siz odatiydan o'tagina ko'rib, o'zingiz va jamoangiz uchun yangi ufg'onliklarni ochishingiz mumkin. Oldinga yo'nalgan, ijodiy yondashuv va yangilikni mavjud bo'lishi sizni kuzatuvchilar uchun hayajonlanish sababi bo'ladi.",
            "Image-maker":
                "Ijodiy ma'lumotlaringiz va zo'rfikr stil sezginingiz sizga insanlarga ta'sir etkazuvchi tasvirlar yaratib berishingiz va unilarning ko'zlamasini saqlash imkoniyatini beradi. Sizda g'oyalarni va qadriyatlar tashqi tasvir orqali ifodalashga doir noyob qobiliyat bor, va siz kuchli va esdalab qoluvchi tajribalarni yaratasiz. Go'zallik his-tuyg'ulashingiz, yangi tendensiyalarga moslashish qobiliyatingiz va vizual uslubga ijodiy yondashuv sizni istalgan jamoa uchun o'rinbosar qiladi.",
            "Idealist":
                "O'zgina g'oyalarni yaratish va rivojlanishga doir qobiliyatingiz sizni ideal iniqlovchi odam qiladi. Sizning masalalarni hal qilishga doir ijodiy yondashuvingiz, kutubdan tashqari fikrlash va boshqalar ostida to'siq ko'rishga doir qobiliyatlaringiz sizga yangicha yechimlarni yaratish va amalga oshirishga yordam beradi. Sizning g'oyalarizga boshqalar ilhomlanadi va o'zgarishlarni keltiradi.",
            "Tashabbuschi":
                "Energia va o'zgarishlar uchun uyg'onish qobiliyatingiz sizni ideal boshlatuvchi qiladi. Sizda yangi loyihalarni boshlash, turli g'oyalarni birlashtirish va odamlar o'rtasida umumiy maqsadga yo'naltirishga doir noyob qobiliyat bor. To'siqlar qaramasidan qat'iy nazar, yangi g'oyalarga ochiq bo'lish qobiliyatingiz sizni har qanday loyiha uchun, ayniqsa, yangi usullarni talab qiluvchi loyihalar uchun kamtar bo'lmaydigan qiladi.",
            "Voiz": "Isiqoniyatingiz, boshqalar his-tuyg'ularining nuanslariga bo'lgan sezginingiz va dunyoni yaxshiroq joy qilishga istagingiz sizni ideal va'z yetkazuvchi qiladi. Boshqalarga yordam berish uchun hissiyot va boshqalar his-tuyg'ulari bilan chuqurroq bog'lanish qobiliyatingiz sizga odamlar bilan samarali ravishda aloqalarni o'rnatishga va ularga katta ta'sir qilishga imkon beradi. Siz narsalarning mohiyatiga kirib ko'rishingiz mumkin va fikrlaringizni va g'oyalarizni aniq ifodalashingiz mumkin.",

            "Axloqshunos":
                "Sizda chuqur etik tushuncha va to'g'ri va adolatli bo'lish uchun hozirgi istak bor, bu sizni ideal Moralist qiladi. Inson tabiati, qiymatlarga va prinsiplarga hurmat, dunyoni yaxshilashga xohishingiz sizni har bir jamoa yoki tashkilotga mustahkam qiladi.",
            "Pedagog":
                "O'rganishga doir sizi janjallatuvchi his-tuyg'uniz va inson tabiati haqida chuqur tushunchangiz sizni ideal muallim qiladi. Muammoning mohiyatiga yetish va yechimlarni taklif etish uchun kerak bo'ladigan ko'nikmalarga ega bo'lganingizdan tashqari, boshqaruvchiga o'z qobiliyatlari ochiq va rivojlangan ko'rinishini yordam berishingiz mumkin. Sabrli, e'tiborkor va nazik bo'lishga qodir bo'lganingiz o'quvchilarning hurmatini va ishonchini ta'minlaydi.",
            "Hissiy rahbar":
                "Sizning his-tuyg'ularingizga bo'lgan sezgiringiz, boshqalarni ta'sir etish quvvatingiz va yaqin aloqalar o'rnatish yetarligingiz sizni ideal his-tuyg'ular bo'yicha rahbar qiladi. Atrofdagi odamlarning his-tuyg'ularini yaxshilab ko'ring va aks ettiring, hamda jamoangizni qo'llab-quvvatlashingiz va rag'batlantirishingiz ularga produktivlik va qoniqish darajasini ancha oshirishi mumkin.",
            "Entuziast":
                "Sizning energiyangiz, ochiq bo'lishingiz va optimizmizm qobiliyatingiz sizga atrofdagilarini ilhomlantirish va ularga rag'batlantirish imkonini beradi. Siz osongina har qanday ishga hamrag'bat va ijobiy his-tuyg'u kiritishingiz mumkin. Yukori kelajakni ko'rish va boshqalar bilan ulashish qobiliyatingiz sizni har qanday jamoa a'zosiga zaruri qiladi.",
            "Mentor":
                "Boshqalarning ehtiyojlariga sezguringiz va insonlarga yondashish qobiliyatingiz sizni ideal mentor qiladi. Sabrli, empatiya qilish va bilimingizni va tajribangizni o'tkazish qobiliyatingiz sizga boshqalarni o'qitish va ularni maqsadlariga erishishga ilhomlantirishga yordam beradi.",
            "Rassom":
                "Yaratish qobiliyatingiz, his-tuyg'ularni namoyish etish va butun dunyo uchun noyob ko'rish sizni ideal rassom qiladi. O'zingizni ifodalash va yaratishga qodir bo'lish qobiliyatingiz, shuningdek, atrofdagi go'zalligini ko'rish va boshqalarga uni aytib berish imkoniyati sizga noyob va ta'sirchan asarlar yaratish imkoniyatini beradi.",
            "Ustoz":
                "Batafsil yo'nalishingiz, amaliy o'ylash va muammolarni hal etish ko'nikmalari sizni ajoyib ishchi qiladi. Har bir tafsilotni tekshirarsiz, ishingizda maksimal sifatga erishishga harakat qilasiz. Qo'lingiz bilan ishlash, narsalarni takomillashtirish va tuzatish qobiliyatingiz ko'p sohada juda qimmat.",
            "Optimizator":
                "Samarali bo'lishga, amaliylikka va aqliy echimlarni topishga oid rag'batlaringiz sizni ajoyib optimizator qiladi. Siz har doim jarayonlarni takomillashtirish, ishni osongina va samarali qilish usullarini qidirib topasiz. \"katta tasvir\"ni ko'rish, murakkab tizimlarni tahlil qilish va ularning ishlashini optimallashtirish sizning asosiy aktivingiz.",
            "Texnik":
                "Texnik tushunchangiz, tahliliy o'ylash qobiliyatingiz va murakkab tizimlarni tushunish qobiliyatingiz sizni ideal texnik qiladi. Texnologiyalar bilan ishlash, ularning qanday ishlashini tushunish va ularni amaliy muammolarni hal qilishda ishlatishga qodir bo'lganingizdan tashqari, tez o'rganish va yangi texnologiyalarga moslashuvchanlik sizning ulug' avantajingiz.",
            "Aqlli":
                "Qo'lingizning mahorati, amaliyligi va muammolarni hal etishdagi ijodiy yondashingiz sizni ideal ishchi qiladi. O'z qo'lingiz bilan yaratish, tuzatish va takomillashtirishga qodir bo'lganingizdan tashqari, batafsillikka e'tibor berish, materiallar va asboblardan foydalanish va yangi va foydali narsani yaratishga oid rag'batlantirishingiz sizning kuchlaringiz",

            "Qattiq ushlagich":
                "Sizning azimatlilik, tez qarorlar qabiliyati va ta'sirli rahbarlik qobiliyatingiz sizni ideal \"zararkunanda tasodifchi\" qiladi. Siz ba'zilari uchrashlarni ko'rib turgan joyda imkoniyatlarni ko'rasiz va doimiy mashg'ulotlarga tayyorgarsiz. Siz mehnat katta kuch talab qiladigan va xavfli vaziyatlarda ham jamoangizga maqsadlarni erishishga ruh berishingiz mumkin.",
            "Rahbar":
                "Sizning jasoratingiz, o'zingizga ishonchingiz va odamlarni boshqarish qobiliyatingiz sizni ideal rahbar qiladi. Siz o'zingizning qarorlariga doim ishonch hosil qilasiz va jamoangizga ishonch hosil qilishni bilasiz. Sizning tabiiy rahbarlik, jasorat va murakkab vaziyatlarda javobgarlik qabilanmodingiz sizning asosiy sifatingizdir.",
            "Legioner":
                "Sizning muloyimligingiz, jamoa bilan ishlash qobiliyati va jismoniy taqatlaningiz sizni mukammal leqioner qiladi. Sizning rag'batlantiringiz, to'g'ri kelish qobiliyatingiz va g'alaba qilish istagingiz sizni maqsadingizga yetkazadi. Har doim oldinga chiqish istagingiz va eng qiyin sharoitlarda ham o'z yerlaringizdan turib qolish qobiliyatingiz sizni boshqalardan ajralab turadi.",
            "Tekshiruvchi":
                "Sizning nazariy va batafsil tahlil qilish qobiliyatingiz, tartib va tizimlantirishga munosabat, qattiq va basharatasiz - sizni yaqin kuzatish va tekshirish talab qiladigan vazifalarga ideal joylashtiradi. Sizning tanqidiy o'ylash va me'yoriyatta va texronologiyalarda standartlari isbotlash qobiliyatingiz sizning ishingizning yuqori sifatini ta'minlaydi.",
            "Nazoratchi":
                "Stressli holatlarda ham behafsona qolish, diqqat bilan ishlash va mukammallikka bo'lgan muhabbatlaringiz sizga murakkab jarayonlari muvaffaqiyatli nazorat qilishga imkon beradi. Siz, qoidalarga va me'yoriyatlarga riayat qilishni ta'minlash bo'yicha mahoratga egasiz, bu sizning loihalaringizning samaradorligi va xavfsizligiga hissa qo'shadi.",
            "Stabilizator":
                "O'zgarishlarga qarshi ta'siqsizlik, ishonchli va maqsadga bag'ishlanish sizning jamoa yoki loihada o'zgarishsizlik va doimiylikni ta'minlaydi. Tartib va tizimlantirishga qodir bo'lish qobiliyatingiz va o'zgarishsizlik va basharatasizlikka bo'lgan istagingiz sizni istalgan sohada jamoa a'zosiz qiladi.",
            "Muhandis":
                "Tizim va analitik fikrlash qobiliyatingiz, mushkullarni hal etishda yaratuvchi yondashuv va tafsilotlarga bo'lgan muhabbatlaringiz enginering va texnik vazifalarga ideal namizoddirlar. Sizning aynan ishlash, murakkab tizimlarni tushunish va takomillashtirish, yangi texnologiyalarga moslashish qobiliyatingiz sizni texnik sohada muvaffaq qiladi.",
            "Menejer":
                "Tashkillash, rejalashtirish va boshqaruv qobiliyatingiz, hamda samarali ravishda aloqalashish va vazifalarni tarqatish qobiliyatingiz sizni ideal manejer qiladi. Siz jamoalarni koordinatsiyalashtirish, resurslarni boshqarish va maqsadlarni muvaffaqiyatli erishishga qodirsiz, hatto noaniqlikka qaramasdan ham.",
            "Amaliyotchi":
                "Ishga qo'l yordami bilan yondashuv, real imkoniyatlarni ko'rish va qo'lga kiritishga bo'lgan muhabbat sizning asl amaliy ekansizligingizni ko'rsatadi. Fikrlarni namoyish etish, amaliy qadamlar qilish va haqiqiylikka bo'lgan yondashuvingiz sizning asosiy mulkiguzar moddalariz.",
            "Amalga oshiruvchi":
                "Rejarlarni samarali bajarish, maqsadga bag'ishlanish va ishga qo'l yordami bilan yondashuv qobiliyatingiz sizni ideal amaliy qiladi. Sizning fikrlarni hayotga keltirish va maxsus natijalarni erishishga doir mahoratingiz har qanday tashkilot uchun muhimdir.",

            "Administrator":
                "Sizning tashkilotchilik qobiliyatingiz, diqqat sifatida tarkibiylik va bir nechta vazifalarni bir vaqtning o'zida boshqarish qobiliyati sizni a'lo darajada administrator qiladi. Sizning resurslarni boshqarish, tartib va tizimni o'rnatish va samarali jarayonlarni ta'minlashga qodirliklarga ega bo'lishingiz sizning kasbiy muvaffaqiyatingiz asosidir.",
            "Kulrang kardinal":
                "Sizning strategiyani o'ylashga qodir bo'lishingiz, tahlil qilishga, yoki barcha mumkin bo'lgan ssenariylarni hisobga olishga qodirliklaringiz sizni 'Kul rangli kardinal' lavozimi uchun ideal namoyon qiladi. Siz qaror qabul qilish jarayoniga ta'sir etishga va shuningdek, ko'rinmas bo'lib ishlashga qodirsiz.",
            "Sinoptik":
                "Sizning diqqatingiz, tahlil qilishga qodir bo'lishingiz va trendlarni bashorat qilish qobiliyatingiz sizni a'lo darajada bashoratchi qiladi. Katta rasmni ko'rish, ma'lumotlarni tahlil qilish va kelajak voqealar yoki trendlarini oldindan ko'rish qobiliyatingiz muvaffaqiyatli strategik rejalashtirish asosidir.",
            "Skeptik":
                "Tanqid tushunchalarini yaratish, tahlil qilib ko'rish va skeptik bo'lishga qodirliklaringiz sizni yaxshi skeptik qiladi. Siz har doim tushunchalar va g'oyalarni savolga tashlashga, ularni turli nuqtai nazarlardan tahlil qilishga va kuchsizliklarini aniqlashga tayyormiz.",
            "Tanqidchi":
                "Sizning noyob tanqidiy baholash va ma'lumotlarni tahlil qilishga qodirliklaringiz, shuningdek sifat va aniqlikka qizg'inliklaringiz sizni ideal tanqidchi qiladi. Siz kamchiliklarni aniqlash va takomillashtirishni taklif etishga qodirsiz, bu esa davomiy a'lo darajadagi yutuqlarni qo'zg'atishga va rivojlantirishga yordam beradi.",
            "Innovator ishqibozi":
                "Sizning to'xtovsiz energiyingiz, yangiliklarga yo'l qo'yish va yangiliklarni topishga doimiy qizg'inliklaringiz sizni qiziqarli innovator qiladi. Siz har doim yangi g'oyalar qidirib yurishingiz, tajribaga ochiq bo'lishingiz va nima-nima yangi va yorug'likni amalga oshirish uchun xavfga duch kelishga tayyormiz. Bu sizga to'sqinliklar bilan kurashish, konvensional chegaralarni qayta o'ylab ko'rish va ulardan tashqariga chiqishga imkon beradi. Siz har bir jamoa ichida jonli va yangi g'oyalarni kiritasiz, va sizning g'oyalar begona yangiliklarni kiritishga oftob bo'lib xizmat qiladi.",
            "Tadqiqotchi":
                "Sizning shiqqatingiz, o'zingizni takomillashtirishga qizg'inliklaringiz va vaziyatlarni chuqur tahlil qilishga qodirliklaringiz sizni haqiqiy tadqiqotchi qiladi. Bilim izlash uchun shunchalik uyg'unsiz bo'lishingiz va to'g'ri savollarni berishga qodirliklaringiz vaziyatlarni va muammolarni chuqur tushunishga olib keladi. Siz u yerda aloqalarni topishga qodirsiz, qayerda boshqalar unga muvaffaq bo'lolmaydi, bu sizga yangi strategiyalar va yondashuvlar ishlab chiqish imkonini beradi. Sizning tadqiqot qobiliyatlaringiz va voqealarning mohiyatiga yetib borishga arzimaydigan istagingiz rivojlanish va yangiliklar asosidir.",
            "Generator":
                "Sizning ijodiyatlaringiz, tez-tez g'oya yaratishga va doimiy tajriba va o'sishga yo'l qo'lishga qodirliklaringiz sizni a'lo generator qiladi. Sizning g'oyalaringiz o'zgarishlarni amalga oshirish uchun katalizator bo'ladi, va sizning ijodiyatga yo'l qo'lish g'oyalaringiz jamoa ichida yangiliklar madaniyatini saqlashga yordam beradi. Siz boshqalar tomonidan e'tiborga olmaydigan imkoniyatlarni topa olasiz, va bu g'oyalarni yaratish qobiliyati hamma tashkilot yoki loyihada o'sish va rivojlanishga katalizator bo'lishi mumkin.",

            "Tahlilchi":
                "Analiz qilish va narsalarni bölaklashdagi iqtidorizingiz sizni o‘z ehtiyojiga asoslangan tahlilchiga aylandiradi. Siz har doim har bir unsur ko‘rsatgichga qanday ta‘sir korsatiyotganini ko‘rasiz va obyektiv ma'lumotlarni baholashiga qodir bo‘lgan inobatiy ko‘nikmalariz sizga aniq va bilimar natijalar taqdim etishga imkon beradi. Analitik ko‘nikmalarizing tashkilotlarni ma'lumotlarga asoslangan qarorlar qabul qilish va samarali strategiyalar ishlab chiqishga yordam beradi.",
            "Tizimlashtiruvchi":
                "Tartib, tuzilma va sistematizatsiya qiluvchilardan hosil bo‘lgan muhabbatingiz sizni kamolotli sistematizatsiyachi qiladi. Ma'lumotlarni tartibga solish, aloqa o'rnatish va aniq mantiqiy tizimlar yaratishning qobiliyati sizga tartibsizlikni tuzilma holatiga aylantirishda yordam beradi. Bu tashkilotlarni jarayonlarini takomillashtirish, samaradorlikni oshirish va ishlab chiqarishni tezlashtirishga yordam beradi.",
            "Perfektsionist":
                "Kamolchilikka, aniqlikka va tafsilotlarga e'tiborningiz sizni ideal kamolotli ustoz qiladi. Siz ish ayolguncha yaxshi amalga oshmasa to'xtamaysiz. Oliy darajadagi standartlaringiz va sifatni erishish uchun utmagan qurolingiz hammasi loyiha olib borishinda tasir etuvchi bo'ladi, bu esa yuqori sifatli ish shaklida konkret natijalar keltiradi.",
            "Innovator":
                "Tahlil qilish va optimallashtirish qobiliyatingiz sizni ulug‘ov innovator qiladi. Siz doimo narsalarni yanada samarali va samarador qilish uchun yo'llar topasiz. Optimallashtirishga bag'ishlangan ishqingiz va murakkab narsalarni sodda qilib chiqarish qobiliyatingiz tashkilotlarni samaradorlikni oshirish va xizmat yoki mahsulotlar sifatini oshirishda yordam beradi.",
            "Tadbirkor innovator":
                "Katta tasvirni ko'rishga, boshqalar bilan boliq topishga va yangiliklarga ochiq bo'lganligingiz sizi tadbirkorga aylantiradi. Siz risklarni olmoqdan qo'rqmaysiz va ahamiyatingiz va energiyangiz sizga fikrlaringizni amalga oshirishga yordam beradi. Sizning tadbirkor o'yinboshingiz tashkilot yoki loyihalarda o'sish va innovatsiyaga katalizator bo'lib xizmat qilishi mumkin.",
            "Algoritmist":
                "Ma'lumotlarni qayta ishlash, algoritmalar yaratish va bilimni tashkil qilish qobiliyatingiz sizni algoritmlarga oid uniqtayin qiladi. Siz har qanday jarayonga mantiq va strukturani keltirasiz, bu esa tashkilotlarni yanada samarador va boshqariladigan ishlaydiganini ta'minlaydi. Algoritmga asoslangan o'ylashingiz ishlab chiqarishni tezlashtiradi va ishning sifatini oshiradi.",
            "Integrator":
                "Odamlar, tushunchalar va resurslar bilan bog'lanish qobiliyatingiz sizni integratorga aylantiradi. Siz turli elementlar qanday ta'sirlanganini va bir-biriga ta'sirlanganini ko'rgani uchun siz aniqroq va samarali jamoalar tuzasiz. Integratsiyaga intilishingiz tashkilotlarni sinergiya yaratish va ularning samaradorligini oshirishga yordam beradi.",
            "Tajribachi":
                "Faraziya sinash, farziyalar sinovlari va davomli takomillashtiruvchi muhabbatingiz sizni mahoratli tajribachi qiladi. Siz xato qilishdan qo'rqmasdan ularni o'rganish imkoniyatiga aylanasiz. Eksperiment qilish qobiliyatingiz va noaniqlikka sabr ta'siri orqali tashkilotlar doimiy o'zgaruvlar bilan kelishish va moslashishga yordam beradi.",
        },
    },

    WelcomeSubscribe: {
        title: "Men sizni targ'ib qilaman, siz tanlangan sohada muvaffaqiyatga erishingizda yordam beraman va pul ishlashga imkon beraman",
        subtitle:
            "Profilga o'tishdan oldin - hozir siz uchun ajoyib taklif mavjud<br/><br/> U nima ichadigan:",
        pay: "Hammasini {price} so'm/oy uchun oling",
        "to-profile": "Profilda bordi",
        list: [
            {
                id: 1,
                icon: "people-recommendations",
                text: "Har kuni sizga saytdagi 5 foydalanuvchi tavsiya qilinadi, siz bilan ajoyib biznes mosligi bor.",
            },
            {
                id: 2,
                icon: "text-recommendations",
                text: "O'zingizning psixotipingizning chuqur tahlilini va uning dekodlamasini olib chiqing",
            },
            {
                id: 3,
                icon: "brain",
                text: "O'zingizning shaxsiyat va xulq-ahloqingizni ta'riflang.",
            },
            {
                id: 4,
                icon: "environment",
                text: "O'zingizning psixotipingiz uchun ushbu biznesda rivojlantirish uchun 10 dan ortiq tavsiyalar",
            },
            {
                id: 5,
                icon: "stars",
                text: "Sizda shoh ismoniyatlar bazasiga kirish huquqi bo'ladi va ular bilan shaxsan uchrashish, yoki birgalikda ish boshlash yoki ularning xarakterini o'rganish imkoniyati bo'ladi.",
            },
            {
                id: 6,
                icon: "photo",
                text: "Siz saytdagi va saytdan tashqari biror shaxsni biznes mosligi uchun tekshirishingiz mumkin.",
            },
        ],
    },
};

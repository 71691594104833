/* eslint-disable */

export default {
    "newbie": "Жаңычо",
    "logout": "Чыгуу",
    "days": "күн",
    "errors": {
        "unknown-error": "Белгисиз ката",
        "error": "Ката аттый",
        "unknownError": "Белгисиз ката",
        "attention": "Болугуштук",
        "wrongPhotoTitle": "Сүрөт туура келбеди",
        "noAccess": "Кирүү рухсаты жок",
        "alreadyFriendsTitle": "Сиз алдаагыча досторсуз!",
        "info": "Маалымат",
        "reEnter": "Аккаунтуңузга кайра кириңиз же биз менен байланышыңыз.",
        "notEnoughMoneyTitle": "Акча жетиштүү эмес",
        "compareNotAllowedTitle": "Салыштыруу мүмкүн эмес",
        "retry": "Ката аттый, кайра аракет кылыңыз.",
        "noAccessText": "Сиз акысыз функцияларды колдонуу кылмакчысыз.",
        "personDataNotReceived":
            "Жеке маалыматтарды алууда ката катта, балки, Сиздер бетти жаңыдан жүктөп көрүү керек болушу мүмкүн.",
        "wrongProfilePhoto":
            "Профиль фотосу жарак емес, башка сүрөт жүктөп көрүңүз. Жинемес варианттарга көзүңүздү айлатыңыз.",
        "wrongPhotoToTheRight":
            "Сиз башыңызды ортондо жакшы чыгымай кайра айландыныз, кайра аракет кылып көрүңүз.",
        "wrongPhotoToTheLeft":
            "Сүрөт жарак түшмөдү, башыңызды оңуша жакшы чыгымай айландыныз, кайра аракет кылып көрүңүз.",
        "wrongPhotoToTheBottom":
            "Сүрөт жарак түшмөдү, башыңызды ортондо жакшы чыгымай эгиздетип жатасыз, кайра аракет кылып көрүңүз.",
        "wrongPhotoFewPersons":
            "Сүрөттө бир нече баш зааналып калды, керектүү баш калган жерден сүрөттү кесип алып көрүңүз",
        "wrongPhotoToTheUp":
            "Сүрөт жарак түшмөдү, башыңызды почто жогорло көтердиңиз, кайра аракет кылып көрүңүз.",
        "wrongPhotoSame":
            "Сиз бири деп жаткан жөнүндө жана профилдеги сүрөттөрдү бирикке жүктөдүңүз, башка сүрөт жүктөп көрүңүз",
        "wrongPhotoFeatures":
            "Сиздик жүктөгөн сүрөттөрдөн баш белигилерин алууда оңой эмес. Эки сүрөттү жаныбардык өзгөртүңүз.",
        "wrongPhotoYouAreNot":
            "Жаңы сүрөттө Сиздин башыңызды табуу катата, Сиздин башыңыз менен фото жүктөп көрүңүз",
        "wrongAnfasPhoto":
            "Анфас жакын фотосу кабыл алынбайт, башка фотону жүктөҩүүнү сурайсыз. Стандартты варианттарды көзөңүздөн өткөрүүңүздү сурайсыз.",
        "wrongCelebrityPhoto":
            "Тандоолуп алынган кыскачанын фотосун талаш жасаганда ката келип чыгды. Колдонуучу туура жаткан фотосун жүктөгөндө – салыштыруу жана баяндоо болушат",
        "photoNotUploaded":
            "Фото жүктөлбөт, бирача убрак жаркыкта талап кылып көрүңүз...",
        "myPhotoNotUploaded":
            "Сиздин фотолоруңузду жүктөлбөй кетти. Ордуна баракчаны жаңылап көрүңүз.",
        "paymentsListNotReceived":
            "Төлөмдөр тизмесин алуу мүмкүн эмес, кечире катталып көрүңүз.",
        "incorrectPaymentData":
            "Төлөм маалыматы туура эмес. Кечире катталып көрүңүз.",
        "paymentGateWayNotRespond":
            "Төлөм шлюзи жооп бербейт. Кечире катталып көрүңүз.",
        "friendStatusNotReceived":
            "Достуңуздун статусу алынбайт, кечире катталып көрүңүз.",
        "pageError":
            "Бул баракча менен белгисиз ката пайда болду, баракчаны жаңылап жана аракетти кайтадан жаркыктап көрүңүз.",
        "friendRequestError":
            "Достуңузга чакыруу жиберилө албайт, кечире катталып көрүңүз.",
        "alreadyFriends":
            'Егер сиз "Достунузга кошуу" түймөсүн көрсөңүз, ордуна баракчаны жаңылап көрүңүз.',
        "alreadySendFriendRequest":
            "Сиз алгач озуңуз эми сурама жибердиңиз. Достуңуз сурамача кабылдоогуну жакшыла.",
        "friendRequestDecline":
            "Кызык, сиздин дос ганышууңуз четкелтирилди. Сиз акыркы алдыгы катташыуудан 24 сааттан кийин талабы сурап болот.",
        "notificationsNotReceived":
            "Сиздин билдирүү тизмеңизди алуун мүмкүн эмес, кечире катталып көрүңүз.",
        "servicePriceNotReceived":
            "Кызматтын баасын алуу мүмкүн эмес. Баракчаны жаңылап жана кайталап талаап көрүңүз",
        "needAuth":
            "Бул аракетти жүргүзүү үчүн авторизация ордуна клышуу керек",
        "notEnoughMoney":
            "Сураныч, сиздин эсебиңизди сизге дайынмачы түрдө толтурууңузду сурайсыз.",
        "personListNotReceived":
            "Шахсиёт тизмесин алууда катташып кетти, кечире катталып көрүңүз.",
        "friendListNotReceived":
            "Достор тизмесин алууда ката келип чыкты, кечире биздик катталып көрүңүз.",
        "badName":
            "Тандалган атыңыз туура келбейт, башка аттарды аракет кылып көрүңүз же бирача убрак катталып көрүңүз.",
        "nameLimit": "Атты өзгөртүү 24 саат ичинде бир гана болушат.",
        "friendsCount":
            "Достуктардын санын алууда ката кетти. Сураныштын бир даагы кайтарыныз.",
        "friendRequestsCount":
            "Достукка чакыруулардын санын алууда ката кетти. Сураныштын бир даагы кайтарыныз.",
        "compareNotAllowed":
            "Өзүңүздү башка адамдар менен салыштыруу үчүн сүрөттөрүңүздү жүктөп алыңыз",
        "photoUploadPersonIsNotFound":
            "Фотографияны орноткон баракчаңыз табылган жок.",
        "photoUploadPersonIsNotYours":
            "Фотографияны орноткон баракчаңыз сизге тиешелбейт.",
        "yourFaceIsNotFound":
            "Жаңы фотографиянызда жүзүңүз табылган жок, жүзүңүз менен фото жасап жүктөп алыңыз.",
        "partnerStatusNotReceived": "Ортактын статусун алуу мүмкүн эмес.",
        "uuidError":
            "Берилген колдонуучунун IDsi туура эмес, администраторга кайрылыңыз.",
        "alreadyPartner": "Колдонуучу буга чейин сиздин ортакчыңыз.",
        "waitingResponse":
            "Сураныш мурунтан жиберилген, ортакчынын шешимин күтүүнүз.",
        "partnerRequestDeclined":
            "Сиздин суранышыңыз өчүрүлүп койду, суранышын акырык сураныштан кийин 24 саат ичинде кайтарып болот.",
        "partnerResponseAlreadySend":
            "Сураныш табылбайт. Балкым, сиз алдырак тастыктагансыз.",
        "partnerResponseNotSend":
            "Туура эмес маалыматтар жиберилди. Техникалык колдоого обращатьсядіңиз.",
        "createDescriptionError":
            "Жекечегиниздин сүрөттөмөсүн түзө албайт. Техникалык колдоого обращатьсядіңиз.",
        "firstUploadAnfas": "Анфаска фотографияны бирокча жүктөгүлүңүз керек",
        "descriptionNotReady":
            "Мерлин анфастагы фотографиядан жүздүн жабышкандарын окуп жатканда, профильге фотографияны жүктөп болбойт. Бир мүнөт ичинде аракетти кайтарып көрүңүз.",
        "REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED":
            "Максатты жабуунун сунуштарын күнү жүзүндө бир рет жазып болот",
        "BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED":
            "Ишки тектөөлөрүн күнү жүзүндө бир рет жазып болот",
        "AVATAR_LIMIT_REACHED":
            "Сүрөттөрдү жүктөөчүлүк төмөнку чегө четти. Аракетти эртең кайтарып көрүңүз.",
    },
    "cookies": {
        "prefix-text": "Биз жинаймыз ",
        "text": "Cookies",
        "btn-text": "OK",
    },

    "DescriptionPopup": {
        "learn-about": "Биз киши жөнүндө окуй аласыз:",
        "light-background": "Жарык фон",
        "dark-background": "Караңгы фон",
        "start-learn-profession": "Бүгүн правильдүү касипти окууга баштаңыз:",
        "start-learn-profession-btn-text": "Башта",
    },
    "MerlinDating": {
        "title":
            "{name}диң байлыкшалыгын билүү үчүн кадам боюнча алгоритмди ызынасызбы?",
        "subtitle": "Биздин колдонмону жүктөп алыңыз.",
    },
    "FooterLinks": {
        "about": "Долбоор жөнүндө",
        "contacts": "Байланыш",
    },
    "FriendsFilter": {
        "gender": {
            "all": "Баарысы",
            "male": "Эркектер",
            "female": "Аялдар",
        },

        "age": {
            "any": "Кайсы",
            "interval_1": "18-ден 24 жашка чейин",
            "interval_2": "25-ден 34 жашка чейин",
            "interval_3": "35-ден 44 жашка чейин",
            "interval_4": "45 жаштан жогорку",
        },

        "typeList": [
            {
                "id": "ALL",
                "name": "Кайсыз",
            },
            {
                "id": "ADMINISTRATOR",
                "name": "Администратор",
            },
            {
                "id": "ACTIVIST",
                "name": "Активист",
            },
            {
                "id": "ALGORITHMIST",
                "name": "Алгоритмист",
            },
            {
                "id": "ARTIST",
                "name": "Сураткор",
            },
            {
                "id": "MASTERMIND",
                "name": "Эйлемчи",
            },
            {
                "id": "HEDONIST",
                "name": "Гедонист",
            },
            {
                "id": "GENERATOR",
                "name": "Генератор",
            },
            {
                "id": "DIPLOMAT_INVADER",
                "name": "Дипломат Карайдын",
            },
            {
                "id": "IDEOLOGIST",
                "name": "Идеолог",
            },
            {
                "id": "IDEOLOGICAL_MASTERMIND",
                "name": "Идеология Эйлемчиси",
            },
            {
                "id": "IDEOLOGICAL_LEADER",
                "name": "Таныш Башчысы",
            },
            {
                "id": "IMAGE_MAKER",
                "name": "Имаж Даярдагыч",
            },
            {
                "id": "ENGINEER",
                "name": "Инженер",
            },
            {
                "id": "INITIATOR",
                "name": "Башлагыч",
            },
            {
                "id": "INSPECTOR_PERFECTIONIST",
                "name": "Төлгөмөчү Инспектор",
            },
            {
                "id": "INTEGRATOR",
                "name": "Интегратор",
            },
            {
                "id": "RESEARCHER",
                "name": "Илимиятчы",
            },
            {
                "id": "RESEARCHER_ANALYST",
                "name": "Илимиятчы Анализатор",
            },
            {
                "id": "COMMUNICATOR",
                "name": "Катталоочу",
            },

            {
                "id": "CONNECTOR",
                "name": "Байланушчу",
            },
            {
                "id": "CONTROLLER",
                "name": "Башкарчы",
            },
            {
                "id": "CRITIC",
                "name": "Тенкитчи",
            },
            {
                "id": "LEGIONARY",
                "name": "Легионер",
            },
            {
                "id": "LEADER",
                "name": "Башчы",
            },
            {
                "id": "LOBBYIST",
                "name": "Лоббист",
            },
            {
                "id": "MASS_MEDIA_ENTERTAINER",
                "name": "Массивик көшөчү",
            },
            {
                "id": "MASTER_IMPLEMENTOR",
                "name": "Мамлекетчи",
            },
            {
                "id": "MATERIALIST",
                "name": "Материалист",
            },
            {
                "id": "MAECENAS",
                "name": "Маеценас",
            },
            {
                "id": "DREAMER",
                "name": "Кыыскачы",
            },
            {
                "id": "MORALIST",
                "name": "Моралист",
            },
            {
                "id": "MENTOR",
                "name": "Ментор",
            },
            {
                "id": "ASSERTIVE_INVADER",
                "name": "Целеустремленный наштыгычы",
            },
            {
                "id": "INFORMAL_LEADER_MANIPULATOR",
                "name": "Неформалдык башчы манипулятор",
            },

            {
                "id": "INNOVATOR",
                "name": "Инноватор",
            },
            {
                "id": "INNOVATOR_ENTHUSIAST",
                "name": "Инноватор энтузиаст",
            },
            {
                "id": "OPTIMIZER",
                "name": "Оптимизатор",
            },
            {
                "id": "OPTIMIST",
                "name": "Оптимист",
            },
            {
                "id": "ORGANIZER",
                "name": "Организатор",
            },
            {
                "id": "EDUCATOR",
                "name": "Мугалим",
            },
            {
                "id": "PERFECTIONIST",
                "name": "Толуккоочу",
            },
            {
                "id": "POLITICIAN",
                "name": "Саясаткер",
            },
            {
                "id": "MEDIATOR_DIPLOMAT",
                "name": "Дипломат аралашуучу",
            },
            {
                "id": "PRACTITIONER",
                "name": "Амалиячы",
            },
            {
                "id": "PRACTITIONER_CONSERVATIVE",
                "name": "Консервативдуу амалиячы",
            },
            {
                "id": "PRACTITIONER_MANAGER",
                "name": "Башкыруучу амалиячы",
            },
            {
                "id": "ENTREPRENEUR_INNOVATOR",
                "name": "Инновативдуу бизнесмен",
            },
            {
                "id": "ADHERENT",
                "name": "Жакындуу",
            },
            {
                "id": "FORECASTER",
                "name": "Прогноздоочу",
            },

            {
                "id": "PREACHER_MENTOR",
                "name": "Жаманакты Ментор",
            },
            {
                "id": "RATIONALIZATOR",
                "name": "Инноватор",
            },
            {
                "id": "IMPLEMENTATOR",
                "name": "Реализатор",
            },
            {
                "id": "ROMANTIC",
                "name": "Романтик",
            },
            {
                "id": "EMINENCE_GRISE",
                "name": "Кызыл Кардинал",
            },
            {
                "id": "SYSTEMATIZER",
                "name": "Системалоо",
            },
            {
                "id": "SKEPTIC",
                "name": "Скептик",
            },
            {
                "id": "SOCIALIZER",
                "name": "Социализатор",
            },
            {
                "id": "STABILIZER",
                "name": "Стабилизатор",
            },
            {
                "id": "TECHIE",
                "name": "Техник",
            },
            {
                "id": "HANDYMAN",
                "name": "Мастер",
            },
            {
                "id": "MANAGER",
                "name": "Менеджер",
            },
            {
                "id": "KEEPER",
                "name": "Сактоочу",
            },
            {
                "id": "EXPERIMENTER",
                "name": "Экспериментатор",
            },
            {
                "id": "ENTHUSIAST",
                "name": "Энтузиаст",
            },
        ],
        "searchSortList": [
            {
                "id": "DATE",
                "name": "Кошулган сана",
            },
            {
                "id": "NAME",
                "name": "Аты",
            },
        ],
        "searchList": [
            {
                "id": "DATE",
                "name": "Кошулган сана",
            },
            {
                "id": "NAME",
                "name": "Аты",
            },
            {
                "id": "COMPATIBILITY",
                "name": "Совместимость",
            },
            {
                "id": "SIMILARITY",
                "name": "Окшошуу",
            },
            {
                "id": "BUSINESS",
                "name": "Бизнес боюнча совместимость",
            },
            {
                "id": "INTERACTION_BUSINESS",
                "name": "Бизнес-байланыштың интеракциясы",
            },
            {
                "id": "INTERACTION_INTERPERSONAL",
                "name": "Жеке байланыштың интеракциясы",
            },
        ],
        "any": "Кайсы да болсо",
        "all": "Бардыгы",
        "by-age-title": "Жашы боюнча:",
        "by-person-type-title": "Адамдык типи боюнча:",
        "by-person-category-title": "Жеке категориясы боюнча:",
        "sort-by-title": "Иреттөө :",
        "sort-by-default": "Кошулган күнү боюнча",
        "sort-by-gender-title": "Жынысы боюнча:",
        "reset-text": "Фильтрди жаныландыруу",
    },
    "InviteFriends": {
        "btn-text": "Дос кошуу",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "Бул колдонуучу сүрөт жүктөмөгөн!",
        "feedback": "Демөөрчүдөн жазуу",
        "referral-program": "Сурамжылык программасы",
        "bonus-program": "Бонус программасы",
        "legal-info-title": "Юридикалык маалымат",
        "contacts-and-requisites": "Байланышуу жана тиштөөчүлөр",
        "about": "Компания тууралуу",
        "service-pay": "Кызматты төлөө",
        "term-of-use": "Колдонуучу келишими",
        "privacy-policy": "Максатталган кешелерди сактоо саясаты",
    },

    "RightAsideSocial": {
        "title": "Бөлүшүү",
        "vk": "VK.com",
        "ok": "ok.ru",
    },
    "loading": {
        "network-error":
            "Интернет байланышын текшериңиз <br/> жана баракчаны кайра жүктөңүз",
    },
    "MainHeader": {
        "back-to-profile": "Профилге кайра баруу",
        "createAccount": "Эсептаманы түзүү",
        "support": "Клиенттерге көмөк көрсөтүү бөлүмү",
        "logout": "Чыгуу",
    },
    "MobileMenu": {
        "support": "КӨМӨКЧҮЛҮК",
    },
    "NotificationsModule": {
        "all": "Бардык билдирүүлөр",
        "partner": "Сизди жеке талапкер болууга чакырып жатат",
        "deposit": "Депозит",
        "bonus": "Сиз бонус алдыңыз",
        "reward": "Рефералдык программадан марапат",
        "referral": "{name} сиздин рефералыңыз болду<br/>Merlin Dating-де",
        "free-period":
            "Сиздин рефералыңыз {name}, Merlin Dating-де текшерүү жазылуу мөөнөтүн {message} белгиледи",
        "cancel-subscribe":
            "Сиздин рефералыңыз {name}, Merlin Dating-ден {message} жазылууну токтотушу",
        "pay-subscribe":
            "Силерге марапат берилди. Сиздин рефералдыңыз {name}, Merlin Dating-ге жазылуу өтөөлүн {message} төлөнгөн",
        "subscribe-error":
            "Merlin Dating-де абдан кандай бир себепкарга жазылуу жаңыртылган жок {0}",
        "mapp_celebrity_compare":
            "Merlin Dating бириктүү сатып алынган марапат",
        "bonus_coach_partner":
            "Сиздин өз акылыңызга келген жасоочунун даяр болушу учун",
        "person_compare": "Кимдир сиз менен салышты",
        "compatibility": "бириктүү",
        "similarity": "үйрөштүк",
        "business": "бизнестик ортаганчылыкка бириктүү",
        "person_indicator": "Кимдир табды",
        "interaction_business": "бизнестик интеракциянын түрү",
        "interaction_interpersonal": "байланыштык интеракциянын түрү",
        "mma_champion": "чемпиондук потенциял",
        "mma_potential": "боюнча курашчынын потенциалы",
        "aggression_common": "сиздин ийгилик тозуңуз",
        "aggression_emotional": "сиздин эмоционалдуу тозугуңуз",
        "aggression_social": "сиздин социалдык тозуңуз",
        "aggression_intellectual": "сиздин интеллектуалдык тозуңуз",
        "aggression_physical": "сиздин физикалык тозуңуз",
        "analyse_item_unlock":
            "Кимдир сиздин суроо бөлүгүндөгү тушуналууну окуду",
        "professionaltype": "Професия катары",
        "neurotype": "нейротип",
        "leadertype": "лидерчилик",
        "character": "кыирактер",
        "relations": "байланышуу жана уй-бала",
        "motivation": "мотивация жана энергия",
        "selfAssessmen": "сиздин өзүңөздү өзүңөз баагыңыз жана ишенч",
        "professional": "профессия жана өз-өзү актуализациялоо",
        "person_review_created": "Билдирүү туура жасалды",
        "new_in_top":
            "Сиздин бириктүү топуңуз жаңыланды: +{{ notification.message }}",
        "phone_confirm": "Сиз бонус алдыңыз {count} $",
        "upload_photos_request": "Кимдир сизден фотосурот жүктөргөнү сурашат",
        "upload_photos_completed": "{user} өз фотосуротун жүктөдү",
        "coach_partner_request": "{name} - сизди жеке талапкер болууга чакырат",
        "coach_partner_request_accept": "Кабыл алуу",
        "coach_partner_request_decline": "Четкөзүү",
        "analyse_item_unlock_reward":
            "Сиз бонус алдыңыз {{ notification.message }} $",
        "new_in_top_show": "Көрүү үчүн чекит басыңыз",
        "phone_confirm_reward": "Телефон номерин текшерүүгө",
        "loading": "Жүктөнүү...",
        "no-more": "Дагы билдирүүлөр табылган жок",
        "no-notifications": "Сизде жаңы билдирүүлөр жок.",
        "show-all": "Баарысын көрсөтүү",
    },

    "OfflineIndicator": {
        "title": "Интернетке байланыш жок",
        "retry": "Сураныч, бир аздан кийин...",
    },
    "OnboardingDescriptionPopup": {
        "text": "Бул сизге ылалышкан иштеп калуу болгон бир соодонуна жаса алган объясной эмес пейилдериңиз талап кылынат.",
        "error": "Сиз бүт көз каранды эмеспиз:",
    },
    "Search": {
        "findFriends": "Досторду табуу",
        "found": "Колдонуучулар табылды:",
        "limit": "Кеминде 3 тамга киргизгиле:",
        "not-found": "Эч нерсе табылган жок",
        "show-all": "БAАРЫН КӨРСӨТҮҮ",
    },
    "SearchPeople": {
        "full-name": "Сиздин атыңызды же фамилияңызды киргизиңиз",
        "all": "Баары",
        "friends": "Достор",
        "celebrity": "Машгыушулуулуктар",
        "fans": "Жолдоштор",
        "type-is-not-available": "Жакшы келсе болгон түр ачык эмес",
        "limit": "Кеминде 3 тамга киргизгиле",
        "empty-filter": "Берилген параметрлер боюнча эчкимди табылган жок",
    },

    "Select": {
        "nothing": "Суранышңызга дал келип жок",
        "choose": "Тандоо",
    },
    "ServiceMenu": {
        "with-friends": "Досторуңуз менен салыштырыңыз",
        "about-me": "Өзүңүз жөнүндө көбүрөөк билүү",
        "about-another": "Көбүрөөк билүү менен:",
        "aggression-another": "Агрессиянын дөрөвү:",
        "aggression": "Агрессиянын дөрөвүн аныктаңыз<br/>",
        "person-aggression": "Агрессия дөрөвү -:",
    },
    "UserNav": {
        "my-page": "Менин баракчам",
        "friends": "Достор",
        "compare-with-me": "Менен салыштырыңыз",
        "celebrities": "Уктуруулу кишилер",
        "messages": "Билдирүүлөр",
        "analyze": "Merlin AI талдоо",
        "recommendations": "Адамдардын тактаалары",
        "settings": "Орнотуулар",
        "manager": "Жекелерди башкаруу",
    },

    "Auth": {
        "back": "Артка",
        "another-phone": "Башка телефон номерин киргизиниз",
        "prev-step": "Артка баруу",
        "auth": "Авторизация",
        "confirm-phone":
            "Сиздин адам эле экендигинизди текшерүү үчүн биздин телефон номериңизди киргизгилиңиз керек.",
        "retry-after":
            "Сиз азыркы чакыруу талаашындысыныз, тактыкпак {{smsTimerCountdown}} секундан кийин сиз жаңысын талааштып аласыз.",
        "not-found": "Эч нерсе табылган жок",
        "country-code": "Мамлекет коду",
        "phone": "Телефон номери",
        "agree_1":
            "Телефон номериңизди киргизип, сиз туура болгонуңузду ырастайсыз ",
        "agree_2": "колдонуу шарттары",
        "agree_3": "керчигиндик саясаты",
        "telegram":
            "Каттоо процедураны аяктоо үчүн, кол контактты бөлүшүп көрүңүз - бул сиз Телеграмдагы каттаманы жоготугондо колдонулат.",
        "call": "Кирише турган чакыруу",
        "message": "Кирише турган билдирүү",
        "blocked":
            "Сиздин номериңиз жакынча блоккаланган, {{smsTimerCountdown}} секундадан кийин сиз башталасыз.",
        "retry-error":
            "Сиз якашалган жок эгерде, жана текст билдирегине алма болсо, сиз кодуну {{smsTimerCountdown}} секундадан кийин талааш мүмкүн.",
        "telegramTitle":
            "Боттун талаабы кабул алынды. Авторизация жүргүзүлүп жатат.",
        "telegramTitle2":
            "Merlin бир нече минутту телеграмма билдирүү жөнөтөт.",
        "enter-call-code":
            "Төмөндө кирише турган номердин сонун 4 цифрасын киргизиңиз.",
        "enter-message-code": "Текстик билдирүүдөгү кодуну киргизиңиз",
        "code": "тастыктоо коду:",
        "confirm": "тастыктоо",
        "request": "талап...",
        "enter": "киргизиңиз",
        "loading": "жүктөө...",
        "requestError":
            "Чакыруу талаабын жөнөтүүдөгү ката, бирдем артка аракет кылыңыз.",
        "phoneError":
            "Телефон номерин комтун мамлекет форматында түзүк. Оруссия үчүн мисал: +79174246633",
        "codeExpired":
            "Сиз 60 секунданычы 4 цифрасын киргизген жоксуз. Тастыктооны кайра жүргүзүү үчүн процедурасын баштоо.",
        "codeError": "Цифралар туура эмес, аракеттер жок: {attempts} из 3",
    },

    "PhoneForm": {
        "back": "Арка",
        "another-phone": "Башка телефон номерин энгизиңиз",
        "prev-step": "Артка баруу",
        "auth": "Авторизация",
        "confirm-phone":
            "Сиздин негизги адам экендигиңизге ишенч жататабыз. Телефон номериңизди энгизиңиз:",
        "retry-after":
            "Сиз жакында чакырууга ырдайсыз, бул жаналыкты {{smsTimerCountdown}} секундадан кийин кайра ырдаса болот.",
        "not-found": "Эч нерсе табылган жок",
        "country-code": "Мамлекет коду",
        "phone": "Телефон номери",
        "agree_1": "Телефон номериңизди энгизүүнү кубанып, ",
        "agree_and": "жана",
        "agree_2": "колдонуу шарттарына",
        "agree_3": "жеке данные жөнündө саясатка чакырдыңызды билдиресиз.",
        "telegram":
            "Каттоонуңузду аяктатуу үчүн, байланышыңызды бөлүшүңүз - Telegram'га кирүүгө болбосо, алгачкы жолу калганда колдонулат.",
        "call": "Чакыруу келип жатат",
        "message": "Кабарлама келип жатат",
        "blocked":
            "Сиздин номериңиз жакынча блоктолду, сиз бул процедураны {{smsTimerCountdown}} секундача жатактуу менен баштай алат.",
        "retry-error":
            "Егер сизге эч кандай байланышуу орнотулбайт же тексттик кабарлама албайт болсо, сиз кодду {{smsTimerCountdown}} секундан кийин кайра суралбайсыз.",
        "telegramTitle":
            "Botтун отуусун алдык. Авторизация жылдамдыкпен аткарылат.",
        "telegramTitle2":
            "Merlin сизге минутаныч ичинде Telegram кабарламасын жөнөтөт.",
        "enter-call-code":
            "Чакыруудан келип чыгкан сонун 4 нөмөрүн астында жазыңыз.",
        "enter-message-code": "Тексттик кабарламадагы коду жазыңыз",
        "code": "тастыктама коду:",
        "confirm": "тастыкта",
        "request": "сурамжы...",
        "enter": "кириңиз",
        "loading": "жүктөлүүдө...",
        "requestError":
            "Чакыруу берүү сурамжысын жөнөтүүдө ката, бир нече секундалар ичинде кайра сынап көрүңүз.",
        "phoneError":
            "Телефон номериңизди эңгизүүнү эл аралык форматта туура килдире түрдө. Мисалы, Россия үчүн: +79174246633",
        "codeExpired":
            "Сиз 60 секунда ичинде ахиридагы төрт нөмөрдү энгизүүгө убакытыңызды аштыңыз. Тастыктама процедурасын кайра жатактап көрүңүз.",
        "codeError":
            "Туура эмес нөмөр, бир да аракет жок: {attempts} жетишинен 3",
    },

    "choose-want": {
        "choose-1-1": "Акча",
        "choose-1-2": "иккирмчилик",
        "choose-1-3": "өз-үйүлөж алуу",
        "choose-2-1": "Энергия",
        "choose-2-2": "мотивация",
        "choose-2-3": "макул",
        "choose-3-1": "Байланыш",
        "choose-3-2": "гено",
        "choose-3-3": "искермелер",
        "choose-4-1": "Ишенуу",
        "choose-4-2": "жеке оценка",
        "choose-4-3": "тинчтүүлүк",
    },
    "CelebrityProgram": {
        "compare":
            "Чечүүлүк сылауучу менен компанизацияңызды текшериңиз, эгер сиз жогорку чатка барсаңыз, жеке түрдө учураша аласыз",
        "desc": "Бул программасы кешелерге жана чечүүлүк көркөмдөйүүлөрдө:",
        "get-know": "Билип алуу",
        "collaborate": "Бирге работа жасоо",
        "talk": "Сүйлөшүү",
        "date": "Суугуу болуу",
        "all": "Программадагы бардык чечүүлүк көркөндөйүүлөр",
    },
    "MessagesHistory": {
        "accessError": "Сиз бул колдонуучуга жаза албайсыз. Эгер бул ката деп ойлосоңуз, колдоо бөлүмүнө кайрылыңыз.",
        "no-messages": "Билдирүүлөр жок",
        
    },
    "MessageHistoryHead": {
        "new-chat": "Жаңы чат",
        "history": "Тарых",
        "delete-chat": "Чатты өчүрүү",
        "disable-notifications": "Билдирүүлөрдү өчүрүү",
        "enable-notifications": "Билдирүүлөрдү иштетүү",
        "attempts-left": "{0} сураныш калды"
    },
    "ChatMessage": {
        "myMessageTitle": "сиз",
        "upscale": "Олошоолук",
        "generated-by-stable-diffusion":
            "Stable Diffusion аркылуу жасалган изображение",
        "generated-by-midjorney": "Midjorney аркылуу жасалган изображение",
        "optimization-midjorney": "Изображение оптимизациясын жасоо",
        "new-chat": "Жаңы чат",
        "history": "Чат тизмеси",
        "seconds": " сек.",
        "generated-by-midjorney-error": "Сүрөттү жаратуу мүмкүн эмес болду. Ойдой болбогуңуз, биз мушкулдуу туура эмес изображение жаратылганын билибиз жана жакын арада шешебиз. Бир аздан кийин аракет кылып көрүңүз",
        "midjorney-parameters-error": "Белгисиз параметрлери берилип келди:",
        "midjourney-queue": "Күтүүдөs, сиз {0} жаратып берүүгө чат кигиздикидесиз",
        "midjorney-stress-error": "Серверге мулкудуу жүк коюлган. Бир мүнөттөн кийин аракет кылып көрүңүз",
        "midjorney-moderator-error": "Урматтуу туулуу, изображение өтүл ээсиң бузуолор. Азыра камтуучу сүрөттү не жарататын:\n- Бирини кыяландыруу же кысыктуруу мумкандыктары.\n- Уруппуну күйсүз сөздөр же зоруку.\n- Бирини жайсыз жагынанай кийинки тамгасыз бир сүрөтгө айналдыруу.\n- Башка маданияттарда бузучу эч нерсе деп саналышы мумкун.",
        "midjorney-unknown-error": "Ойой, кыягына ээсиң сүрөт жаратууга алдаа чыкты.\n\nКөпкүйлүк каталардын мисалдары:\n1️⃣ Параметрди --version 5.2 дегендей --style: raw менен гана колдонуу. \n2️⃣ Берилген параметрлерди туура берүү. -ar, 21:9, -style, raw, -q2, -seed, 302280510 деп айтылган параметрлер шүбөделдеш көрүнбөйт. \n3️⃣ Берилген к taraf боюнча сүрөттүн форматы туура эмес эч каш. Мисалы, окшокош форматында болуу керек: Ш:В, мисалы, 16:9, 'da' дегендей эмес.\n\nБулар менен алданып калып жаткан мадалыктарыңыз. Көрдүм суранышыңызды текшерип, спам кылып көрүңүз.",
        "midjorney-single-image-error": "Сүрөттөгү берилген параметрлерди колдонуу мүмкүн эмес, тексттинде суранышты кошуп, аракетти кайра кылыңыз",
        "gpt-error-try-again": "Бир нерсе туура болду эмес. Кайра аракет кылып көрүңүз",
        "download": "Жүктөө",  
    },
    "AddMessageForm": {
        "inputPlaceholder": "Билдирүүнү киргизиңиз...",
    },
    "Chat": {
        "loading": "Диалогду жүктөө...",
        "write": "чаптап алып...",
        "online": "Интернетте",
        "offline": "Онлайн болгон",
        "delete-chat": "Чатты өчүрүү",
        "disable-notifications": "Билдирүүлөрдү өчүрүү",
        "enable-notifications": "Билдирүүлөрдү иштетүү",
        "partner": "Сиз бизнес предложение жасадыңыз",
        "partner-for-you": "Сизге бизнес предложение жеткен",
        "target": "Сиз мааксатты жабууну сунуштадыңыз",
        "target-for-you": "Сизден мааксатты жабуу сунушталды",
        "AIPlaceholder": "Басууда изображениетти жаратар сызбаны киргизиңиз",
        "edit": "Оңдоо",
        "copy": "Көчүрүү",
        "delete": "Өчүрүү",
        "read": "Окулуу",
        "notRead": "Окулбаган",
        "empty-error": "Билдирүү бош болбош керек",
        "length-error-min": "Билдирүү {min} белгиден аз болбош керек",
        "length-error-max": "Билдирүүнүн узундугу {max} белгиден ашпайт",
        "target-message": "Сиз мааксатты жабууну текшерүүсүз",
        "partner-message": "Сиз бизнес предложениени түйүүсүз",
        "stable-diffusion-message":
            "Stable Diffusion аркылуу изображениетти жаратуу режими",
        "midjorney-message": "Midjorney аркылуу изображениетти жаратуу режими",
        "editing": "Оңдоо",
        "confirmTitle": "Аракеттин ишенчен берүү",
        "confirmDeleteMessageText": "Билдирүүнү чындыгында өчүрөк чыгамы?",
        "confirmDeleteAllMessagesText":
            "Бардык жазишууларды чындыгында өчүрөк чыгамы?",
        "manyMessage5seconds":
            "Кыска убакытта көп билдирүү, 5 секунда күтүңүз.",
        "manyMessage60seconds":
            "Мүнөттө 30 билдирүүдөн ашпайт, мүмкүн болгонча 60 секунда күтүңүз.",
        "userDisable": "Колдонуучу билдирүүлөрдү алуу айкырыйтүүгө катышты.",
        "sendError": "Билдирүү жөнөтүүдө алып келиштик туут, кайралап көрүңүз.",
        "gpt-message-to-long": "Бек узун кабар",
    },

    "Dialogs": {
        "search": "Достуктарды издөө",
        "open": "Чатты ачуу",
        "nothing": "Сизде активдүү чат жок",
        "image": "Сүрөт",
        "new-chat": "Жаңы чат",
        "delete": {
            "title": "Өчүрүүнү тастыктоо",
            "desc": "Чатты өчүргөндөн кийин, ага жаткан бардык билдирүүлөр өчүрүлөт",
            "btn": "Чатты өчүрүү"
        },
        "attempts-left": "{0} сурамжы калды"
    },
    "BlockedUI": {
        "title": "Merlin AI сиздин таланттарыңызды аныктайт",
        "description": "Сайттын бардык функцияларына ирешүү үчүн фотосурөттөрүңүздү жүктөө",
        "upload": "Фотосурөттөрдү жүктөө"
    },
    "Messages": {
        "loading": "Чат жүктөлүүдө...",
        "chooseAI": "Колдонгуңуз келген нейрон тармагын тандаңыз",
        "chooseChat": "Билдирүүлөрүн көрүү үчүн чат тандаңыз",
    },
    "ChatMenu": {
        "title": "Меню",
        "stableDiffusionTitle": "Stable Diffusion",
        "stableDiffusionDescription":
            "Сүзмө болуп өз-өзү боюнча фотореалисттик сүрөттөр жаратыңыз",
        "midjorneyTitle": "Midjorney",
        "midjorneyDescription":
            "Сүзмө болуп өз-өзү боюнча фотореалисттик сүрөттөр жаратыңыз",
        "enable": "Иштетүү",
        "disable": "Өчүрүү",
    },
    "PayoutModal": {
        "balance": "Сиздин баланстыгыз:",
        "title": "Фонддарды чыгаруу:",
        "sum": "Чыгарманын суммасы:",
        "sumRequiredError": "Чыгарманын суммасын көрсөтүңүз",
        "sumMinError":
            "Кечиресиз, биз арызды кабыл алалаймайбыз. Чыгаруу үчүн минималдык сумма {min} сом. толук ишке ашырыныздар.",
        "sumMaxError": "Чыгарманын максималдык суммасы {max}",
        "success":
            "Фонддарды чыгаруу боюнча арызы менен кайрылды! Мениджер жакында сизге байланышат.",
        "newFormat":
            "Биз жазылуу форматына өттүк, сиз учурдагы баланстынзы достуктар менен илип-талашуу же көндүлөр мундай башка нерселер үчүн сарфтап коюшуңуз мүмкүн. Жана баарын колдоноо үчүн бир не бир нечече бөлүмгө жазылыңыз",
        "deposits": "Толуктоолор",
        "payments": "Сатып алуулар",
        "bonuses": "Бонуссалар",

        "table": {
            "date": "Дата",
            "sum": "Сумма",
            "description": "Сүрөттөмө",
            "remainder": "Калган",
            "loading": "Жүктөлүүдө...",
            "noMore": "Эч кандай жазуулар жок",
            "no": "Транзакциялар табылган жок",
        },
    },
    "purchaseDescriptions": {
        "category": {
            "deposit": "Эсепке толуктоо",
            "analyse_item_unlock": "Сатып алынган сүрөттөменин категориясы",
            "bonus_analyse_item_unlock":
                "Биреу сиздин категориялык сүрөттөмө элементиңизди ачты",
            "referral_reward": "Реферал программасынан соолук",
            "merlinapp_referral_created":
                "Мобилдик колдонмога рефералдык сыйлоону",
            "person_compare": "Сиз текшерген",
            "bonus_person_compare": "Сизди биреу текшерди",
            "person_compare_photos": "Сүрөттөрдүн анализи",
            "person_indicator": "Индикатор ачылды:",
            "bonus_person_indicator": "Сиздин индикаторуңузду биреу ачты:",
            "phone_confirm": "Телефонду тастыктоо бонусу",
            "bonus_coach_partner": "Тренер Партнер программасы бонусу",
            "payout": "Чыгаруу",
            "refund": "Акы кайтаруу",
        },

        "subcategory": {
            "professionaltype": "профессионалдык түр",
            "neurotype": "нейротүр",
            "leadertype": "лидердик",
            "character": "өзгөчөлүк",
            "relations": "байланыш менен жана жамаат",
            "motivation": "мотивация жана энергия",
            "selfAssessmen": "өз игилеги жана жакыншык",
            "professional": "профессия жана өз-жат бузуш",
            "compatibility": "ырастоочулук",
            "similarity": "өзгөчөлуш",
            "business": "бизнес ортактыкка ырастоочулук",
            "interaction_business": "бизнес мамилелесиштегиси түр",
            "interaction_interpersonal":
                "кишилер арасындагы мамилелесиштегиси түр",
            "mma_champion": "чемпиондук потенциал",
            "mma_potential": "көрөш спорту менен шугулашуу потенциалы",
            "aggression_common": "жалпы агрессия",
            "aggression_physical": "желдик агрессия",
            "aggression_emotional": "емоционалдык агрессия",
            "aggression_social": "социальдык агрессия",
            "aggression_intellectual": "интеллектуалдык агрессия",
        },
    },

    "serviceTypes": {
        "COMPATIBILITY": "Байлыкка сыенейтинин абданлыгы",
        "COMPATIBILITY_PLAN": "Байлык жана жамаат",
        "SIMILARITY": "Белгилердин окшошу",
        "SIMILARITY_PLAN": "Өздүк баа жана ишемчилик",
        "BUSINESS": "Бизнес сыяктуулук",
        "BUSINESS_PLAN": "Касип жана максат",
        "INTERACTION_BUSINESS": "Бизнес байланыш",
        "INTERACTION_BUSINESS_MY": "Мен бизнесди кандай аткарам",
        "INTERACTION_BUSINESS_OTHER": "Ал бизнесте кандай жаткан",
        "INTERACTION_BUSINESS_PLAN": "Касип жана максат",
        "INTERACTION_INTERPERSONAL": "Адам менен байланыш",
        "INTERACTION_INTERPERSONAL_MY": "Мен адамдар менен кандай кайрешам",
        "INTERACTION_INTERPERSONAL_OTHER": "Ал адамдарга кандай жаткан",
        "INTERACTION_INTERPERSONAL_PLAN": "Байлык жана жамаат",
        "AGGRESSION_COMMON": "Жалпы агрессия",
        "AGGRESSION_COMMON_PLAN": "Байлык жана жамаат",
        "AGGRESSION_PHYSICAL": "Физикалык агрессия",
        "AGGRESSION_PHYSICAL_PLAN": "Касип жана максат",
        "AGGRESSION_EMOTIONAL": "Эмоционалдык агрессия",
        "AGGRESSION_EMOTIONAL_PLAN": "Касип жана максат",
        "AGGRESSION_SOCIAL": "Социальдык агрессия",
        "AGGRESSION_SOCIAL_PLAN": "Касип жана максат",
        "AGGRESSION_INTELLECTUAL": "Интеллектуальдык агрессия",
        "AGGRESSION_INTELLECTUAL_PLAN": "Байлык жана жамаат",
        "INTELLIGENCE_LEVEL": "Интеллектдин деңгээли",
    },

    "buyActions": {
        "compareUnlimited": "Салыштыруулардын саны чексиз",
        "compareLimit": "Калган салыштыруулар: {limit}",
        "indicatorUnlimited": "Тексерүүлөрдүн саны чексиз",
        "indicatorLimit": "Калган талпактар: {limit}",
        "compareCelebrity": "күндүзүү",
        "compareUser": "колдонуучу тарабынан",
        "indicatorCelebrity": "күндүзүүлөр",
        "indicatorUser": "колдонуучу",
        "compareConfirmTitle": "Салыштырууну тастыктаңыз",
        "compareConfirmText": `Сиз чын эле категорияда "{activeType}" {userType} {name} менен себебисиңизди салыштырңыз келеби?`,
        "compareConfirmBtn": "Сатып алууну тастыктаңыз",
        "indicatorConfirmTitle": "Сатып алууну тастыктаңыз",
        "indicatorConfirmText":
            "Сиз чын эле категорияны ачкыңыз келеби “{activeType}” {userType} {name}?",
        "indicatorConfirmBtn": "Сатып алууну тастыктаңыз",
    },
    "compare-result-dialog": {
        "more": "Адам жөнүндө көбүрөк билүү",
    },
    "ConfirmDialog": {
        "price": "Баасы:",
        "timer": "Жазуу болгон колдонуучуга текшерүү алдында:",
        "cancel": "Жокко чыгаруу",
        "apply": "Тастыктаңыз",
    },

    "LegalLinksMobile": {
        "referral": "Рефералдык программа",
        "legal-title": "Юридикалык маалымат",
        "requisites": "Байланышуулар жана маалыматтар",
        "about": "Компания жөнүндө",
        "payments": "Кызматка төлөмдөр",
        "term-of-use": "Колдонуушунун Келишими",
        "privacy-policy": "Жашыруунулук Саясаты",
        "payout": "Бонус программа",
        "support": "Колдоо бөлүмүне жазуу",
        "logout": "Чыгуу",
    },
    "AnotherPerson": {
        "rating": "Сиздин бааңыз",
        "person-rate": "Адамды баа берүү",
        "compare-with-me": "Өзүң менен салыштыруу",
        "more": "Дагы өтүш",
        "aggression": "Агрессиянын дөзүмө",
    },
    "AnotherPersonActions": {
        "sendMessage": "БИЛДИРҮҮ ЖӨНӨТҮҮ",
        "sendTarget": "МАКСАТТЫ ЖАБУУ ТЕКЛИП ЭТҮҮ",
        "sendBusiness": "БИЗНЕС ТЕКПӨЧҮЛҮК",
        "targetConfirmTitle": "эскертүү",
        "targetConfirmText":
            "Бул аракетти колдонуу үчүн, алгач аны досулош тизмесине кошуу керек.",
        "businessConfirmText":
            '"Касиет жана Максат" категориясына жазылуу үчүн адамга бизнес тектештеме сунуу керек.',
        "add": "Досулош тизмесине кошуу",
        "subscribe": "жазылуу",
    },
    "AnotherPersonInfo": {
        "getType": "Менин психо түрүмдү аныкта",
        "typeHidden": "Шахсий түр өшөн ошол эле ачыкталган жок",
        "web": "Веб-сайт",
        "webUndefined": "Көрсөтүлгөн жок",
        "selfEsteem": "Өз баарымын кошуп жатканы",
        "selfEsteemUndefined": "Көрсөтүлгөн жок",
        "occupation": "Статус",
        "occupationUndefined": "Көрсөтүлгөн жок",
        "personalInfo": "Жеке маалымат",
        "age_1": "жыл",
        "age_2": "жыл",
        "age_3": "жыл",
        "height": "см",
        "weight": "кг",
        "noChildren": "Бала жок",
        "children_1": "бала",
        "children_2": "бала",
        "children_3": "бала",
    },

    "AnotherPersonBusinessCard": {
        "merlin": "Merlindan",
        "aboutMe": "{name} өзү тууралуу",
        "blocked": "Бөгөттөлгөн",
        "unlock": "Бөгөттөн алуу",
        "more": "Дагы окуу",
        "mrln_strengths": "Күчтүүлүктөр",
        "mrln_talents": "Талант",
        "mrln_self_esteem": "Атайын сүйүктүктү арттырат",
        "mrln_motivation": "Мотивацияны арттырат",
        "mrln_tasks": "Жакында жетишкен задачалар",
        "mrln_activities": "Ар кандай сферага жакын",
        "self_esteem": "Атайын сүйүктүктү арттырат",
        "user_like": "Эмни жакшы көрөөр",
        "user_good": "Эмне жакшы мүмкүн",
        "user_usually": "Болушу менен эмне жасай аласыз",
        "user_work": "Бул жуумчакта ишке киргизүүгө каалайт",
        "user_goal": "Акчаны жаңыртууда каалап жатат",
        "user_skill": "Маши жоопкерчиликке ашуу каалайт",
        "career": "ТЕКЪЕРМЕ",
        "education": "БИЛИМ",
        "self_esteemUndefined": "Эчи жок",
        "user_likeUndefined": "Эчи жок",
        "user_goodUndefined": "Эчи жок",
        "user_usuallyUndefined": "Эчи жок",
        "user_workUndefined": "Эч ким эмес",
        "user_goalUndefined": "Сааты 5 доллар",
        "user_skillUndefined": "туустук кулуунга",
        "fromNow": "азыргы учурга дейин",
        "categoryProfession": "Профессия жана дестинация",
        "categorySelfEsteem": "өзүн сүйүү жана ишенч",
        "categoryMotivation": "Мотивация жана эффективдик",
        "needSubscribe": "Абоненттик талап кылынат",
        "needSubscribeText":
            'Бул озуну бөгөттөн алуу үчүн "{category}" категориясинан абонент болуу талап кылынат.',
        "subscribe": "Абонент болуу",
    },
    "AvailableSocial": {
        "notSelected": "ЭЧИ ЭМЕС НУСКАЛАШТЫРИЛГАН",
    },
    "AvatarGallery": {
        "retry": "Кайра аракет кылуу",
        "new": "Жаңы аватар жүктөө",
    },
    "ChangeName": {
        "placeholder": "Атыңызды жана фамилияңызды киргизиниз",
        "error": "Ат жана фамилия кеминде демек 2 тамгадан турат",
        "saving": "Сактоо...",
        "save": "Сактоо",
    },

    "ChangePhotos": {
        "another": "Башкасын жүктөө",
        "anotherAnfasDrag": "<br/>Фотосуну толук бет боюнча жерге апарыңыз",
        "anotherProfileDrag": "<br/> Фотосуну профилге апарыңыз",
        "there": "бул жерде",
        "didntFit": "Фото тагылды",
        "limit": "<br/> Бүгүнкү жүктөө чеги толду",
        "example": "Мисал, референциялык фото",
        "anfas": {
            "title":
                "Сиздин фотолоруңузду тек нейрондук тармактагы алгоритм көрөт. Фотолор үчүнчү тараптар үчүн жеткиликтүү эмес.",
            "desktopText":
                "Фотографияларыңызды толук бет боюнча жерге апарыңыз.",
            "mobileText": "Фотосуну толук бет боюнча жүктөҩүңүз.",
            "dragText": "Фотосуну толук бет боюнча жерге апарыныз",
        },
        "profile": {
            "desktopText": "Профиль фотосуңузду жерге апарыныз",
            "mobileText": "Профиль фотосуну жүктөҩүңүз",
            "dragText": "Профиль фотосу жерге апарыныз",
        },
        "avatar": {
            "title": "Аватардыңызды жүктөө, бүгүн ар жакындап келгени көрөт.",
            "text": "Аватардыңызды жүктөҩүңүз",
            "dragText": "Аватардыңызды жерге апарыныз.",
            "help": "Сизге табышмай турган фото жүктөөнү сунуштайм. Бул, башка колдонуучулар көрүшү керек фото. Би мен силерге бала-башкалардын, ирилүү жакындап фотолор менен жардам бералмайм.",
        },
    },

    "CompareWithPhoto": {
        "back": "Шахсий сипаттамага баруу",
        "another-type": "Башка салыштыруу түрүн тандоо",
        "choose-in-list": "Достордогу тизмеден тандоо",
        "searchFriends": "Досторду издөө",
        "noFriends":
            "Азырынча достор жок. Досторду чакырып, алар катышса, сиз бонус аласыз",
        "invite":
            "Кечиресиз, бул адам азырынча сиздин достор тизмеңизде жок. Аларга чакырып, алар катышса, сиз бонус аласыз",
        "loading": "Жүктөп жатат...",
        "no": "Башка эч ким табылган жок :)",
        "noFriendsShort": "Сизде достор жок",
        "upload-friend-photo": "Достуңуздун сүрөтүн жүктөө",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Сүрөт жүктөлдү",
        "choose-main": "Негизги кылуу үчүн",
        "confirmDeleteTitle": "Өчүрүүнү тастыктоо",
        "confirmDeleteText": "Чын эле сүрөттү өчүрүүнү каалайсызбы?",
        "confirmDeleteBtn": "Өчүрүү",
    },
    "PersonCompareMobile": {
        "compare": {
            "myProfile": "Досторуңуз менен салыштырып көрүңүз",
            "withUser": "Өзүңүздү <span>{user}</span> менен салыштырыңыз",
        },
        "indicator": {
            "myProfile": "Өзүңүз тууралуу көбрөөк билиңиз",
            "withUser": "Көбрөөк билиңиз:<br/> {user}",
        },
    },

    "PersonDescription": {
        "back": "Артка",
        "choose-type": "Сизге кызыктуу баагын тандаңыз",
        "need-subscribe": "Жазылуу талап кылынат",
        "need-subscribe-text":
            "Тандалган болузун сүрөттөөңүз үчүн абонементке жазылуу керек",
        "btn": "Жазылыңыз",
    },
    "PersonInfo": {
        "age": "Жашы",
        "age_1": "жаштап",
        "age_2": "жаштап",
        "age_3": "жаштап",
        "no": "Көрсөтүлгөн жок",
        "nothing": "Эч нерсе",
        "children": "Бала менен",
        "children_1": "бала",
        "children_2": "бала",
        "children_3": "бала",
        "height": "Өсөү",
        "weight": "Салма",
        "subscriptions": "Жазылышы",
        "photo-error":
            "Сизде бирнече жеке фото болса, бизнес-карта жана тартыптыр эмес болот",
    },
    "PersonMobileBox": {
        "history": "Тарых",
    },
    "PersonMobileBusinessCard": {
        "merlin": "Мерлинден",
        "user": "{user} -дан",
        "career": "Карьера",
        "education": "Билим",
    },
    "PersonMobileBusinessCardPreview": {
        "title": "Башкаларга насыл бизнес-картамы корот <br/> көрүнөт",
        "send": "МЕНИН БААРДЫК САЙЛАМАМА ЖІБЕРҮҮ",
    },
    "PersonMobileHeader": {
        "noName": "Аты жазылган жок",
        "changeName": "Атын өзгөртүү",
        "my-type": "Менин психо-түрүмдү аныктаңыз",
        "type-not-available": "Шахсий түрү ачык эмес",
    },
    "PersonalRecommendations": {
        "title": "Жеке сунуштар",
    },
    "PersonalRecommendationsDialog": {
        "title": "Сизге кеңештелген жеке сунуштар",
        "canOpen": "Сиз ача аласыз",
        "number": "Кеңеш №",
        "openAfter": "Сиз ача аласыз: ",
    },

    "PhotoExamples": {
        "title": "Жыйынтык фотосуроо жана түз электрондук шилтеме мисалдары",
        "text": "Сурат стандартка жакын болсо, нейрондук тармак сизден толуктуктуу маалымат берет. <br/> Сураттар гана сизге арналып болот.",
        "anfas": "Бут түз көрүнүштөгү сүрөт",
        "profile": "Профиль сүрөтү",
        "btn": "Сүрөттөрүңүздү жүктөңүз",
    },
    "AboutSettingsForm": {
        "user_like": "Сиз эмнени жакшы коюшу керек?",
        "user_good": "Сиз эмне жакшысыз?",
        "user_usually": "Сиз кандай адатта эмне жасайсыз?",
        "user_work": "Сиз болуп иштөө кааласыз?",
        "user_goal": "Сиз канча жеңмөкчү кааласыз?",
        "user_skill": "Сиз эмне үйрөнүүнү кааласыз?",
        "saved": "Сакталды",
    },
    "CareerSettingsForm": {
        "company-name": "Компаниянын аты",
        "search-title": "Бөлүк эң бирде менени макулдашып калдыңыз:",
        "company-name-error": "Компаниянын атын жазыңыз",
        "company-position": "Позиция",
        "company-position-error": "Позицияны жазыңыз",
        "company-start": "Ишти баштоо",
        "company-start-error": "Баштапкыч күндү жазыңыз",
        "company-end": "Ишти жактыруу",
        "company-end-now":
            "Агар азыркы учурда компанияда иштесеңиз, бул поляны бош койуп койңуз",
        "saved": "Сакталды",
        "save": "Сактоо",
        "andContinue": " жана улантуу",
        "delete": "Жобогу жок кылыңыз",
        "add": "Жобо кошуу",
    },

    "EducationSettingsForm": {
        "country": "Мамлекет:",
        "selectCountry": "Мамлекет тандоо",
        "alreadySelectCountry": "Оченткен мамлекети тандаңыз",
        "alreadySelectCity": "Оченткен шаары тандаңыз",
        "city": "Шаар: ",
        "searchCity": "Шаардан издөө",
        "no-cities": "Сиздин сураманыз боюнча шаар табылгандай жок",
        "education-place": "Мектептин аты",
        "education-place-nothing": "Сураманыздын боюнча мектеп табылгандай жок",
        "education-palce-error": "Мектептин атын киргизиңиз",
        "education-position": "Мамандык",
        "education-position-error": "Окуу бөлүмүнүздү киргизиңиз",
        "education-start": "Окууга баштаган жылы",
        "education-start-error": "Окууга баштаган жылды киргизиңиз",
        "education-end": "Окууну аяктаган жылы",
        "education-end-now":
            "Эгер сиз өз мектебиңизде иштей жатсаңыз, бул тармағы бош калсын",
        "saved": "Сактоо",
        "save": "Сактоо",
        "andContinue": " жана улантуу",
        "delete": "Окуу жайын өчүрүү",
        "add": "Окуу жайы көшүү",
    },
    "Settings": {
        "information": "Негизги",
        "added-info": "Маалымат",
        "about": "Кызыктуулуктар",
        "career": "Карьера",
        "education": "Билим",
        "photos": "Сүрөттөр",
        "name": "Аталышы",
        "person-name": "Адамдын атын киргизиңиз",
        "name-error": "Аталышы 60 белгиден кыска болушу керек",
        "address": "Баракчанын адреси",
        "address-error":
            'Баракча адреси аныктама буп, гана латин белгилер, сан жана "_" жана "-" белгилерин учурдай кылуу мүмкүн, кеминде 3, эң көп 30 белги болушу керек',
        "address-length-error": "Адрес {min} белгиден узун болуу мүмкүн эмес",
        "birthday": "Туулган күнү",
        "birthday-error": "Туулган күнү тандоо",
        "marital": "Уй-бүлө дарыгы",
        "maritalList": [
            "Бүк",
            "Үйлөнгөн",
            "Үйлөнбөгөн",
            "Үйлөнгөн",
            "Ажырашкан",
        ],

        "select": "Тизмеден тандоо",
        "children": "Балдар",
        "children-error": "Бутун санды жазыңыз же талааны бош койуңуз",
        "height": "Өсүш",
        "maxHeightError": "Уктуу рүчүм 200 га чейин",
        "maxWeightError": "Уктуу рүчүм - 200",
        "heightUnit": "см",
        "weight": "Салма",
        "weightUnit": "кг",
        "height-error": "Өсүшүңүздү жазыңыз",
        "weight-error": "Салмаңызды жазыңыз",
        "gender": "Жынысы",
        "genderList": [
            {
                "id": "М",
                "name": "Эркек",
            },
            {
                "id": "F",
                "name": "Аял",
            },
        ],
        "eye-color": "Көз түсү",
        "saved": "Сактаган",
        "delete-account": "Аккаунту жок кылуу",
        "exit": "Чыгуу",
        "nameTimeError": "Аты бир күндө бир жолу чалгылган",
        "successPhotoTitle": "Фотосурөттөр жүктөлдү",
        "successFotoDescription": "Азыр сайттын бардык функцияларын пайдалана аласыз"
    },
    "InfoSettingsForm": {
        "web": "Вебсайт",
        "selfEsteem": "Сыятым берген нерсе кандай?",
        "address-incorrect": "Туура ссылкани кирпти: https://merlinface.com",
        "self-esteem-error":
            "50 белгиден артык эмес, сиз {{ selfEsteem.length }} жаздыңыз",
        "social-status": "Сыятымдарыңыз:",
        "socialList": [
            "Мектеп окуучу",
            "Студент",
            "Ишсиз",
            "Өздүк иши",
            "Компаниянын ишчиси",
            "Компаниянын (компаниясынын) ээси",
        ],

        "country": "Мамлекет:",
        "city": "Шаар:",
        "selectCountry": "Мамлекетти тандаңыз",
        "searchCities": "Шаарларды издөө",
        "saved": "Сакталган",
    },
    "SocialRating": {
        "start": "Кантип{suffix}",
        "end": "И",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "Сунушталган адамдардын анализи аткарылды. Бул 24 саатка чейин созгучу болушу мүмкүн.",
        "category_1": "касип&nbsp;мақсат",
        "category_2": "Байлык&nbsp;бала-чеке",
        "category_3": "Мотивация&nbsp;эффективдик",
        "category_4": "өзүнүн сезимдери&nbsp;ырастычү жолу.",
    },

    "Person": {
        "you": "Сиз",
        "user": "User",
        "compare": {
            "fromMe": "Досторуңуздун менен салыштырыңыз",
            "fromUnnamedUser":
                "Озунузду <span>аталбаган бир киши</span> менен салыштырыңз",
            "fromNamedUser": "Озунузду <span>{user}</span> менен салыштырыңз",
        },
        "indicator": {
            "fromMe": "Озунуздун жөнүндө көбөрөк билүү",
            "fromUnnamedUser":
                "<span>Аталбаган Колдонуучудун</span> жөнүндө көбөрөк билүү",
            "fromNamedUser": "<span>{user}</span> жөнүндө көбөрөк билүү",
        },
        "aggression": {
            "fromMe": "Озунуздун агрессия деңгейин аниңдаңыз",
            "fromUnnamedUser":
                "Агрессия Денгейи: <span>Аталбаган Колдонуучу</span>",
            "fromNamedUser": "Агрессия деңгейи: <span>{user}</span>",
        },
    },

    "PersonList": {
        "celebrities": "Атакуулар",
        "fans": "Жолдошуулар",
        "friends": "Бардык досуулар",
        "friends-requests": "Досуу болуу сурамжылары",
        "select-all": "Ар бир досуларыңызды тандаңыз",
        "no-with-params":
            "Көрсөтүлгөн параметрлер боюнча атакуулар табылган жок",
        "no-friends": "Ушулууга чейин сиздин досуңуз жок.",
        "no-requests": "Силерге досуу болуу өтүнчө сурамжылар жок.",
        "no-celebrities":
            "Көрсөтүлгөн параметрлер боюнча атакуулар табылган жок",
        "search": {
            "celebrity": "Атакууларды издөө",
            "friends": "Досуулар боюнча издөө",
            "requests": "Досуу болуу сурамжысын издөө",
            "users": "Адамдар боюнча издөө",
        },
    },
    "PersonShortCard": {
        "typeLocked": "Шахс болуу түрү өзгөртүлүү жок",
        "sendMessage": "Билдирүү жүгүртүү",
        "compare": "Салыштырма",
        "hide": "жашырүү",
        "removeFromFriends": "Досулардан алып салуу",
        "acceptRequest": "Кошуу",
        "declineRequest": "Таштап кетүү",
        "confirmDeleteFriendTitle": "Аракетти тастыктап кошуу",
        "confirmDeleteFriendText": `"{user}" дегенди досулардан таштайынбы?`,
        "confirmDeleteFriendBtn": "Өчүрүү",
    },
    "Promocode": {
        "btn": "Менде промо-код бар",
        "enter": "Промо-коду киргизүү",
    },
    "RecommendedPeople": {
        "no-persons": "Мерлин сизге адамдарды дарактан жете алган жок",
        "tabs": {
            "partner": "Бизнес таанышуу",
            "love": "Мамиле",
            "coming": "Жакында",
        },
        "type-undefined": "Аныктама жок",
        "toProfile": "ПРОФИЛГЕ",
        "age_1": "жыл",
        "age_2": "жыл",
        "age_3": "жыл",
        "status": "Социалдык статус",
        "no-status": "Статус жок",
        "no-goal": "Көрсөтүлгөн жок",
        "city": "Шаар",
        "no-city": "Көрсөтүлгөн жок",
        "noAge": "Жашы көрсөтүлгөн жок",
        "money-target": "Менин денсөөлүү максадым",
        "spheres": "Кызмат көрсөтүү аймагы",
        "no-spheres": "Көрсөтүлгөн жок",
        "next": "Кийинки",
        "loading": "Жүктөө...",
        "category_1": "Профессия жана &nbsp;цель",
        "category_2": "Байланыштар &&nbsp;семья",
        "category_3": "Мотивация &&nbsp;эффективность",
        "category_4": "Оз самым уверенность &&nbsp;үндүлүүлүк",
        "next-recommendation": "Кийинки адам",
    },

    "SubscriptionsCategoriesItem": {
        "active": "абонемент активдуу",
    },
    "SubscriptionsContent": {
        "active": "Абонемент {date} гача активдуу",
        "category_1": "Профессия жана Назначение, БЕСПЛАТНО",
        "category_2": "Байлык & Ойлогуу, БЕСПЛАТНО",
        "category_3": "Мотивациялуу & Перформанс, БЕСПЛАТНО",
        "category_4": "Ырастоочу & Озуну таноо, БЕСПЛАТНО",
        "cancelTitle": "абонементтен чыгуу.",
        "cancelText":
            'Сиз {period} абонементтен "{category}" категорияны жабудуну тастыктаймызбы?',
        "cancelBtn": "абонементтен чыгуу",
        "mounthly": "ай боюнча",
        "annual": "жылдык",
    },
    "SubscriptionsTableForm": {
        "payBtn": "Төлөмгө өтүү",
        "withBalance": "Төлөм сайттагы баланстан чыгарылат",
    },
    "SubscriptionsTableInfo": {
        "discount": "Сиздин арзандаштыруу: {discount}&nbsp;₽",
        "chooseAnnual":
            "Жылдык абонементти тандап <br/> {discount}&nbsp;₽ сактоо",
        "active": "Активдүү абонемент",
    },
    "Subscriptions": {
        "title": "Сизге кызыктуу абонемент жасагылдыгын тандап алыңыз",
        "canceled": "Абонемент ийгиликтүү токтотулду!",
        "succeeded": "Ийгиликтүү абонеле!",
        "choosePlan": "Планды тандап алыңыз:",
    },
    "about": {
        "title": "merlinface.com долбооруна кош келипсиз!",
        "text-1": "Жалпы жазылуулар",
        "text-2":
            "Биздин команда жеңил сеттер менен, сизге текст жана графикалык диагностикалык баалоолор жана ошондой эле бир нече ыкма индивидуализациялган сунуштарды алат, ошондо эле көрсөтүүчүлөргө ортундаган.",

        "text-3":
            "Ошондой эле, квалификациялуу адистер Колдонуучулардын диагностикасы боюнча иштешет: нейропсихологдор, когнитивдик жүрүш-терапевттер жана башка тармактардагы адистер.",
        "text-4": "Сиз үчүн пайдалуу маалымат!",
        "vk": "Биздин VKontakte группасы:",
    },
    "cookie": {
        "title": "Cookie саясаты",
        "text-1":
            "Cookies - бул сервери жана колдонуучунун компьютеринде сакталган тексттин кичи биримдери. Кукислер көпчүлүк функцияларды аткарат, мисалы, Колдонуучунын жараткан орнотууларды сактоо, укук бергенден кийин барак беттер арасында өтүүгө уруксат берүү жана сайт менен жумушу жалпыда жөндөө кылуу.",
        "text-2": "Биз cookies колдонобуз:",
        "text-3":
            "1. Кимдиктер - cookies сиздин урумчаны жана иш-чараларыңызды аныктай алат, ар баракка бетке өткөндө аларды ырастоо учурда жана эч качан сурамайт",
        "text-4":
            "2. Статистика - cookies белгиленген барактарды колдоо жөнүндөгү маалыматтарды топтоо мүмкүндүк берет.",
        "text-5":
            "Сиздин браузериңиз cookies кабул алууну токтотууга мүмкүн, бирок бул платформаны колдонуу мүмкүнчүлүгүнү өтүк камтыгат.",
    },

    "payments": {
        "title": "Төлөм жана буйрутманы алуу",
        "text-1": "Банк карточкасы менен төлөө",
        "text-2":
            "Robokassa иштеп чыгаруу борбору - бул VISA жана MasterCard-дын официалдуу кызмат көрсөтүүчүсү - сайтта төлөмдөрдү кабылдайт.",
        "text-3":
            "Онлайн төлөө үчүн, ыңгайсыз МИР, Visa, Visa Electron, MasterCard жана Maestro банк карточкаларын колдонууга болот. 3D-Secure-ге абонент болсоңуз рет, сыро-сызгычты болуу макулдоого дайын бириктирильген купчилоо менен төлөө амалга ашырылып жатат.",
        "text-4":
            "Төлөм данныхысынын киргизүү жана иштеп чыгаруу иштеп чыгаруу борборунун жанында орын алып барат. Төлөм данныхдары купчилоолучу банкка кут болгон аркылуу шифрленген формада таштылат. Эч кайсы бири, сатуучуну катталганы менен, клиенттерден киргизген банк карточкасынын реквизиттерине албайт, бул анын акчасын жана жеке данныхдарын толук калптўө камсыздагын куллуктайт.",
        "text-5":
            "Ийгиликтүү төлөмдөн кийин, төлөвчүгө электрондук чек кагызды жөнөтүп, онун төлөмдү тастыктоочу жана унукуттуу белгичини алсыз болуп жаткан.",
        "text-6":
            "Акчаны кайрык бериш жаагында, акча ошол эле карта гана кайра берилет, кайра жөнөткөн төлөм амалга ашырылган.",
        "text-7":
            "Төлөмө байланыштуу бардык суроолорду 24/7 кызмат көрсөтүүчү Robokassa колдоноочуларына +7 499 110 57 25 номер боюнча же support@robokassa.ru адресине жазып жөнөтёжүнүзгө болот.",
        "text-8": "Yandex.Money, Webmoney, Qiwi менен төлөө",
        "text-9":
            "Буйрутмаңызды merlinface.com сайтында тура жаттаган түрдө, Yandex.Money, WebMoney, QIWI электрондук кошөмөлдөр аркылуу төлөп бере аласыз. Төлөөнүн камсыздатуусун Robokassa иштеп чыгаруу борбору камтамасыздагыларын куллуктоо менен алып берет.",

        "text-10":
            "Акчаны кайтаруу жайгашуу болгон жагдайда, акча төлөмдөү болгон электрондук өртөгө кайтарылат.",
        "text-11":
            "Эгер сизде электрондук өртөк менен төлөө боюнча суроолар бар болсо, сиз Robokassa компаниясынын кургакча колдоо кызматына телефон менен +7 499 110 57 25 номери боюнча кайрылыша аласыз",
        "text-12": "Сатыла турган маалымат кызматтар тизмеси:",
        "text-13":
            '1. "Касиет жана өз-өзүнү өз саноосу" кызматы. Колдонуучунун эсеп-түшүнүктөрү жана уга касиеттерге тийиштуу төмөндөмөлөрдүн сунуштоо. Баасы 150 - 500 сомдан.',
        "text-14":
            '2. "Өз саноосу" кызматы. Колдонуучунун эсеп-түшүнүктөрү жана өз саноосу темасы боюнча төмөндөмөлөрдүн сунуштоо. Баасы 150 - 200 сомдан.',
        "text-15":
            '3. "Мотивация жана энергия" кызматы. Колдонуучунун эсеп-түшүнүктөрү жана өз саноосу темасы боюнча төмөндөмөлөрдүн сунуштоо. Баасы 150 - 500 сомдан.',
        "text-16":
            '4. "Байланыш жана жамаат" кызматы. Колдонуучунун эсеп-түшүнүктөрү жана башка адамдар менен байланыш жөнүндө төмөндөмөлөрдүн сунуштоо. Баасы 100 - 400 сомдан.',
        "text-17":
            '5. "Байланыштагы коопсуздук" кызматы, пайыз менен деңгээлди көрсөтөт. Баасы 50 сом.',
        "text-18":
            '6. "Белгилердиң охшошлугу" кызматы, пайыз менен деңгээлди көрсөтөт. Баасы 25 сом.',
        "text-19":
            '7. "Бизнестеги коопсуздук" кызматы, пайыз менен деңгээлди көрсөтөт. Баасы 100 сом.',
        "text-20":
            '8. "Бизнестеги мүмкүнчүлүктөр" кызматы, пайыз менен деңгээлди көрсөтөт. Баасы 50 сом.',

        "text-21":
            '9. "Адамдар менен кандай байланышады" кызматы дөрөв түшүндөт нархы 50 сом.',
        "text-22":
            '10. "Көбөлөк көрсөткүчтөргө мүмкүндүк" кызматы дөрөв түшүндөт эчкил көрсөтүү, баасы 50 сом.',
        "text-23":
            '11. "Чемпион мүмкүндүгү" кызматы дөрөв түшүндөт нархы 50 сом.',
        "text-24":
            "Платформанын ар бир пайдалануучусу менен салыштырып же сүрөттөгү блогун ачканда биржолуку сатып алуулардын суммасы системанын ичинен чыгарылышы.",
        "text-25":
            "Өткөн төлөмдөн кийин, кызмат автоматтык түрдө пайдалануучунун баракчасында merlinface.com сайтында ачылышы. Кызмат мөүнөттө берилет.",
        "text-26":
            'Фонддарды кайтаруу Ресей Федерациясынын 07.02.1992-ж. № 2300-I Закону "Сармакташтыктардын Укуктарын Коргоо" бойунча регулирлелет.',
    },

    "privacy-policy": {
        "title": "",

        "text-1": "Жалпы талаптар",
        "text-2":
            '"Merlin AI" ЖДШ (алдынарсыз - Долбоор) тарабынан жеке маалыматтардын ишелдөө процесси жана жеке маалыматтардын калыпкастыгын кепилдөөчү чараларды аныктаган жеке маалыматтар жөнүндө Закондук 27.07.2006. № 152-ФЗ жарарынын талаптарына даярдоодо, конфиденциалдык сактоо жөнүндө бул китепча түзүлдү.',
        "text-3":
            "1.1. Долбоор адамдын жана граждандыккуу укуктарын, айрымдук жана граждандык укуктарын жеке маалыматтарды иштеп чыгаргандыгы учурда, так жежеке турмушу менен байланыштуу укуктарын караалаган негизги максаты жана бул маалыматтарды иштеп чыгуунун шартын камтыйт.",
        "text-4":
            '1.2. Бул Долбоордун жеке маалыматты иштеп чыккан полисясы ("Полися") баары маалыматкактан колонот https://merlinface.com веб-сайтында жана Мерлин дейттин мобилдик колдонмосу жөнүндө.',
        "text-5": "2. Полисте колдонулган негизги поняциялар",
        "text-6":
            "Жеке маалыматтарды блоктоо - жеке маалыматтарды иштеп чыгуу (маалыматты аныктоо мүмкүн болгондогу чыгармалар болгондо жеке маалыматтарды иштеп чыгуунун убактай тоолуктанып болгондогу)",
        "text-7":
            "Вебсайт - маалыматтарына байланыштуу графикалык жана маалыматтык материалдардын жоролмасы, абдан компютер программасы жана мердөөнөрдөрү, https://merlinface.com вебсайтты колдонуучуларга Internet аркылуу жеткиликтүү кылуу учун; ондон иретте, Merlin Dating мобилдик колдонмо.",
        "text-8":
            "Жеке маалымат аяктоо системасы - маалыматты иштеп чыккан жеке маалыматтардын базасында жатышат жеке маалыматты өзгөчө борбордук жана техникдик средаларды колдонуп алууга мүмкүнчүлүк берген маалымат жиынтыгы;",
        "text-9":
            "Жеке маалыматтардын деперсонализациясы - иш-ара менен дайындаган дайындыктар, жеке маалыматтын уюмдуу колдонуучуга же башка жеке маалыматты менен дайындыктын айтылышы мүмкүн эмес;",

        "text-10":
            "Жеке маалыматтарды иштөө - жеке маалыматтар менен автомат жана автоматтама, кылыса, жазуу, системдөө, жигерүү, сактоо, түзөтүү (жаңылоо, өзгөртүү), чыгаруу, колдонуу, акырма (таркытуу, берүү, доступтук), эсептешүү, блоктуруу, өчүрүү, жеке маалыматтардын жок кылышы жана, болгон аракет (операция) же аракеттер топтому (операциялар);",
        "text-11":
            "Башкаруу - каноний түзүлүштө же жеке жарандай, жеке маалыматтарды бөлиндүрүп, иштөп жана (же) иштөбөгөн кызматкер, ошондой эле иштөп пайда болгон жеке маалыматтарды иштөөнүн маалыматы, иштөөнүн маалыматтары менен жеке маалыматтар болуп саналат;",
        "text-12":
            "Жеке маалымат - https://merlinface.com сайтынын жана Merlin Dating мобилдик колдонмасынын колдонуучусу жана колдонуучу менен түз жана түз байланыштуу маалымат;",
        "text-13":
            "Колдонуучу - https://merlinface.com сайтына жана Merlin Dating мобилдик колдонманы келүүчү;",
        "text-14":
            "Жеке маалыматтарды берүү - жеке маалыматтарды берүүү менен чекимдүүлүү адамга же адамдарга коюуңуздун аракеттери;",
        "text-15":
            "Жеке маалыматтарды таркытуу - жеке маалыматтарды таркытууга багытталган аракет жолу (жеке маалыматтарды акырма) жана жеке маалыматтардан чындыганыр көп санзаргыч адамдар менен тааныштыруу, ошондой эле медиада жеке маалыматтарды жарутуу, маалымат жана телекомуникация желелерине жайгаштыруу же башка бир түрдө жеке маалыматтарды берүү;",
        "text-16":
            "Жеке маалыматтарды чек ара transfer кылуу - жеке маалыматтарды чет элге, чет элгедеги укуктук түзмөгө, четэлдеги жеке түрдөгү жана чет өлкөдөгү каноний түрдөгү колдонуучуга transferлаш;",
        "text-17":
            "Жеке маалыматтарды нөлөрүү - жеке маалыматтарды кайра калыптар менен ичиндеги маалыматтарды кайра калыптар жана (жана) жеке маалыматтарды калыптаны медиадагы жироктушу жана жеке маалыматтарды жок кылууга багытталган аракет.",
        "text-18":
            "Төлөм - башкаруучу ишчи биле бир милитесиндеги олчов менен берген иш, пайызнын колдонуучунун администрация алдына төлөңүздүргөн эксклюзивтүү болгон лицензия;",
        "text-19":
            "3. Администрация колуптануу менен төмөнкү Жеке маалыматтарын иштей алышат:",
        "text-20": "3.1. Фамилиясы, аты, атасынын аты;",
        "text-21": "3.2. Email дареги;",
        "text-22": "3.3. Телефон номерлери;",
        "text-23": "3.4. Жылы, айы, датасы жана туулган жери;",
        "text-24": "3.5. Фотолор;",
        "text-25":
            "3.6. Билим жөнүндө маалымат, мамлекеттик билим жайында жеке толук маалымат, профессия, мамлекеттик белги;",
        "text-26":
            "3.7. Сайт интернет статистикалык кызматтарыны колдонуп (Яндекс Метрика жана Google Analytics ж.б.) колдонуучунун жеңил маалыматтарын (cookies киргизүү) жина жана иштейт.",
        "text-27":
            "3.8. Сайт оңой же колдонуучу катышымы менен кошумча маалыматты топтоп жана жина алуу мүмкүн, ошондо адистердеги IP-дарек, браузердин версиясы, өлкө, тил, суроо оруну, колдонуучунун сайтка кирген баракчасынын дареги ж.б. техникалык маалымат.",
        "text-28":
            "3.9. Сайттын кызматтарын колдонуу үчүн колдонуучудан 3-түрдүү кызматтардын (коопсуз файл жайгашкыруу, байталамчы маалыматтар, социалдык тармактар ж.б.) акаунтторуна киришке рүксат сурап болот. Бул жагында сайт 3-түрдүүлөрдөн кошумча жеке маалыматты алуу мүмкүн болушат жана ошондо алат тугел жынысы, жайгашып жаткан жери, кездеги сүрөт (avatar) ж.б. Эгерде 3-түрдүү кызмат берүүчү аркылуу жеткиликтүү болгон баардык маалыматты иштеп, сактап жатат Колдонуу шарты жана купуялыкты коргоо саясатына ыйгарсыз.",
        "text-29": "4. Жеке Маалыматтарды иштеп чыгуунун максаттары",

        "text-30":
            "4.1. Колдонуучунун Жеке маалыматтарын иштөө максаты - Колдонуучунун Эсеби менен Жеке кабинетти түзүү жана Сайт менен Жумуш мобилдик колдонмосу менен иштөө; Колдонуучуга электрондук дарек же билдирүүлөр жиберүү; колдонуучуларды кызмат көрсөтүү жана техникалык өмүчө берүү; Колдонуучуга сайт жана колдонмода жаткан кызматтарга, маалыматка жана / же материалдарга иришкендиги берүү.",
        "text-31":
            "4.2. Ошондой эле, Башкаруу Колдонуучуга жаңы буюмдар жана кызматтар, ар кандай жатуу тооктар жана башка иш-чаралар тууралуу билдирүү жиберүүгө укук болуп саналады. Колдонуучу дайымдык жаңы буюмдар, кызматтар жана ар кандай жоопкерчилик тууралуу билдирүүлөргө чыгарып келишет, эгер дайымдыкпа info@merlinface.com деген электрондук дарекке «Жаңы буюмдар жана кызматтар жана артыкчылык үнөмдөр тууралуу билдирүү жиберилген» деген белги менен кат жиберет.",
        "text-32":
            "4.3. Колдонуучу сайтпен кандай аракет кылганына байланыштуу, унун буюмдары жана кызматтары жөнүндө жеке маркетингдик билдирүүхөнө жана сайт менен аракетти ишкерүүлөргө тууралуу маалыматка чабыштыруу мүмкүн, аттестук катары, социалдык тармактардагы жарандамаларды көрсөткөн жарандамаларды көргөнгө.",
        "text-33":
            "4.4. Интернет статистикалык кызматтары аркылуу жиналган Колдонуучунун анонимдетилген маалыматтары сайттагы Колдонуучулардын аракеттөрүнө байланыштуу маалымат жинаууга, сайттын сапасын жана мазмунун тиешелүүгө колдонулат.",
        "text-34":
            "4.5. Колдонуучу менинAlpari жасоондогонда, калыпка эмелге алуучу аракеттерди тізмесі: катталуу жана жазылуу убактысы, жазылуу жана башка түрдөгү кызматтарды сатып алуу убактысы, хат жиберүү убактысы, профиль баракчасын өчүрүү убактысы.",
        "text-35": "5. Жеке маалыматтарды иштөөгө салымдык негиз",
        "text-36":
            "5.1. Башкаруу Колдонуучунун Жеке маалыматтарын тек Колдонуучу үлгүтүп жана / же жекелайген колдонмолор аркылуу жөнөткөндөгүнде иштөө кылат. Өзүнүн жеке маалыматтарын Сайттагы ар бир формага толтура жана / же Башкарууга жөнөтө түрдө, Колдонуучу ошондой негизде Бул көрсөтмөгө укуксыздык берет.",
        "text-37":
            "5.2. Башкаруу Колдонуучудан камтылган жеке маалыматтарын колдонуучунун браузердеги орнотууларында болууга укук берет (браузер кукилери жана JavaScript технологиясыны колдонуу болуп экен).",
        "text-38":
            "5.3. Колдонуучу сайтка кошулуу жана колдоо кызматтарын колдонууга байланышты кызматтарды колдонуучу жеке маалыматтарын тийгендер.",
        "text-39":
            "6. Жеке маалыматтарды жигерүү, сактоо, бөлүшүү жана башка маалымат иштөө болуп саналат.",

        "text-40":
            "6.1. Иштеген администрациянын иштеген жеке маалыматтын көгөзалүүсү үчүн зарыл, уюмду жана техникалык чөп-чөлөктөрдүн колдонуусу аркылы акысылууда камсыз көгөзалүү негизи боюнча талапка дайындысына жетишген камсыз көгөзалуу берилет.",
        "text-41":
            "6.2. Администрация Колдонуучунун жеке маалыматтарын эркин же токтолган ирешүүдөн, токтолгандан же өзгөртүлгөндөн, токтондогдон, көчүрүлгөндөн, ашыктангылуусу же башка иребес көйдөрдөн коргошун үчүн бардык зарыл техникалык жана уюмду камсыздоолорду көздөйт. Колдонуучунун жеке маалыматы жогоктуу же акыска чыгарылганда, Администрация Колдонуучуга ошонун жеке маалыматын жогоктуу же акыска чыгаруу тууралуу билдирет.",
        "text-42":
            "6.3. Жеке маалымат жана башка маалыматты учурда алуу үчүн Колдонуучудан өчүрүүчүлөр суратып берет, мисалы, төлөм аткаруу үчүн, же Сайтка чогултуштуу өчүрүүчү кызматтарын колдонуу менен көп ырастоолорду кошуу. Колдонуучу ошондай жеке маалыматтарын жана башка маалыматты көз бурак кабыл алат. Өчүрүүчүлөрдөн талап кылынган бардык жеке маалыматтар Колдонуудун Пайдалануу Келишими жана бул өчүрүүчүлөрдүн Көз карашы саясатына дайындоо жана сактоодо болушу керек.",
        "text-43":
            "6.4. Администрация Жеке маалыматтарды Администрация атына кызматтарды жетиштүү үчүн көндөткөндөргөн көндөткө больбусун берет. Мисалы, Администрация абданын өзү боюнча Клиенттин көмөк көрсөтүү, өчүрүүчү ресурстарда жарандыктарды башкаруу, Администрация атына маркетинг жана башка билдирүүлөрдү жөнөтүү, же маалыматты сактоодо жардамкерүү үчүн өчүрүүчүлөрдү колдонууга болушу мүмкүн. Ошондой түрдө кылыкта Колдонуучулардын Жеке маалыматтарын жарандык максаттар үчүн колдонуу тиешелүү эмес.",
        "text-44":
            "6.5. Колдонуусунун Жеке маалыматында катасыздыктар болгон сез алардыогучу жана камсыздалуудо жеки тараптан колдонууну ойдот. болушу мүмкүн «Жеке маалыматтарды жаңылоо» деген тема менен Администрациянын электрондук почтасына law@merlinface.com билдирүү аркылы.",
        "text-45":
            "6.6. Жеке маалыматтын иштөө мөөнөтү чексиз. Колдонуучулар Жеке маалыматтарынын иштөөгө рысылы тартууну кайра көздөй алат, «Жеке маалыматтардын даярдоонун боюнча рысылын чыгаруу» деп белгиленген Администрациянын law@merlinface.com электрондук почтасына билдирүү аркылы. Техникалык себептерден долай, маалымат тез болбосо көп бир кечиктүүни ошуру алат. Администрациянын жакшылыктан кызматкер жана алдалганчылыктан камсыздоо үчүн зарым маалыматты алдыңкы тутумга сактоогу мүмкүн.",
        "text-46":
            "6.7. Пайдалануучу тарапынан жүктөлүп алынган өчүрүүчүлөрдүн фотосурөттөрү менен дата жарандыласууну менен Жеке маалыматтын возуу уюмду кылоо үчүн колдонуу жок.",
        "text-47":
            "6.8. Сайт Жеке маалыматтын оператору, бул экисте жана Колдонуудун информациясын жүктөп берүү үчүн Пайдалануучудан эгинче алган маалымат боюнча гана.",

        "text-48":
            "6.9. Колдонуучунун маалыматы жана жүктөлгөн сүрөттөрдү көлдөнүү сүрөттү Серверге өткөрүү аркылуу аткарылат. Мерлин алгоритмы беттин чызып, ыз-көрөктөрүнүн параметрлерин жана илмегин аныктоо. Колдонуучунун сүрөттөрү Серверде сакталбайт жана Кызмат жана/же өчүрүүчүлөр тарабынан колдонулбайт. Беттин ыз-көрөктөрүнүн кыймыл жана бийиктик параметрлери үчүнчү тарабына берилбеген Серверде сакталат.",
        "text-49":
            "6.10 Кызмат беттин ыз-көрөктөрүнүн кыймыл жана илмеги боюнча маалыматты жигердейт жана колдонот:",
        "text-50":
            "Мерлин алгоритмындагы автордун методикасы боюнча эки адамдын ыз-көрөктөрүнүн параметрлерин салыштыруу, өчүштүгү, ошол эле индикаторлору аныктоо;",
        "text-51":
            "Мерлин алгоритмына киргизген автордун методикасы боюнча Колдонуучуга өчүштүгү шахсиятты тандоо;",
        "text-52":
            "Кызматтын дерек камтыймасындагы ыз-көрөк параметрлери боюнча шахсиятты издөө;",
        "text-53":
            "Сайт жана Программанын болущу Колдонуучулары үчүн өчүштүгү жетишкинчиликтин результаттарын сактоо.",
        "text-54": "7. Төлөө саясаты",
        "text-55":
            "7.1. Колдонуучу Сайттын кызматтарын колдонуучу абонементи боюнча төлөө Visa, MasterCard, Maestro, MIR, Yandex.Money, Webmoney, Qiwi, ApplePay, GooglePay, PayPal кадаар негизделген, бирок чектелбеген төлөм жүйөлөрүн колдонуу аркылуу аткаралат. Бул төлөм жүйөлөрү алардын Колдонуучулар жөнүндөгү келишими жана Купуя саясатына ылайык финанс маалыматын жайгаштырат жана сактоот.",

        "text-56":
            "7.2. Башкаруу картанын бардык маалыматтарын сактабайт жана төлөмдөрдү иштебейт, төлөм системасынын ийгиликтүү төлөм жөнүндө кабар алады гана.",
        "text-57":
            "7.3. Башкаруу колдонуучунун жасаган төлөмгө байланыштуу өзгө маалыматты топтой алышы мүмкүн, ошондуктан ташкары, муамеленин номери, муамеленин убакыты, төлөм үчүн колдонулган картанын түрү жана мөөнөтү, картанын номеринин соңку төрт цифрасы, картанын ээсинин аты, картанын төлөөлдүгү өлкө жана шаар.",
        "text-58": "8. Жеке маалыматтын эркак-терек берилиши",
        "text-59":
            "8.1. Жеке маалыматтын терек-өнүгүш берилишин баштоодон мурун, Башкаруу экинчи-өнүгүш өлкөгө Жеке маалыматтын берүүлөрүнүнүн укуктарын так, ишенчтүү коргоо болуп саналат.",
        "text-60":
            "8.2. Жеке маалыматтын терек-өнүгүш беруусу бул укуктарды толук жерине кеспейткин өлкөлөрдү өткөрүү мүмкүн, эгерде жеке маалыматтын субъекти кагыйдасынын жеке маалыматынын терек-өнүгүш берүүсүнө жазаа рухсат берсе жана / же келишимди аткаруу, кайсынын жеке маалыматтын субъекти жалгыз бир тарап.",
        "text-62": "9. Тараптардын жоопкерчилиги.",
        "text-63":
            '9.1. Эгерде Колдонуучу үчүнчү тараппын жеке маалыматы талашса, Колдонуучу жеке маалыматты коргогу мезгилге каралган тиешелүү чыгармаларга (Жалпы Маалыматтын Коргоодо Кураал, GDPR), Федералдык Законда № 152-ФЗ 27.07.2006 ж. "Жеке маалымат жөнүндө" жана башка закондор жана нормалар, ошондуктан ташкары, тиешелүү жеткирүүлөрдү алуу, Колдонуучунун сайтында анык документтерди жана маалыматты жайгаштыруу үчүн бардык жоопкерчиликке ээ болот.',
        "text-64": "",
        "text-65": "а) тиешелуу юрюк органдыктарына жеткиликтүү болгон;",
        "text-66":
            "б) балалык менен Башкаруу аркылуу өткөннөрдөн кийин үчүнчү жакындан ачыкталган;",
        "text-67": "c) алар жоголгон же ашыкталганга чейин ажыратылган;",
        "text-68":
            "д) Башкарууга тапшырылуусунан мурун учурда жеткиликтүү жакындан алынган;",
        "text-69": "е) колдонуучунун жазасы менен ачыкталган;",
        "text-70": "f) жанжалдуу болгон жагынан ачыкталган;",
        "text-71":
            "г) үчүнчү тараптын укуктарын бузгон жана/же Колдонуучунун Келишимин бузгон тууралуу салттамага колдонуучуга ачыкталган.",
        "text-72": "10. Аяктоо шарттары",
        "text-73":
            "10.1. Колдонуучу жеке маалыматтын иштөө боюнча айкындатууларды электрондук почта аркылуу Administrationган жакта жеткен болот:",
        "text-74":
            "10.2. Бул документ Administration жеке маалыматтын иштөө саясатында болушу мүмкүнчүлүктөрдү акс этип берет. Саясат жаңы версия алыстык берилип турганга чейин мүнөттөкту болот.",
        "text-75":
            "10.3. Саясаттын акырындагы версиясы бул жерде эл аралыктан жана жаксыз жетелиштерда ашык: https://merlinface.com/docs/privacy-policy",
    },

    "subscriptions": {
        "choose-type": "Сизди интереслаткан жазылуу категориясын тандагыла",
        "cancel": "Жазылуу ийгиликтүү жарыяланды!",
        "success": "Жазылуу ийгиликтүү!",
        "choose-plan": "Планды тандагыла:",
        "junior":
            "junior жазылуу 4 категориядагы Gold даражаны кошо: <br/>Касиет жана өзүн ыкмалдоо, Өзүндүк жана Оозунуу, Мотивация жана Энергия, Мундуздуу жана ой-аш. ",
        "before-charges": "чыгымдарга чеиин",
        "to-support": "колдоого обуруч",
        "left": "калды",
        "status": "Жазылуу статусу",
        "failed-to-renew": "жазылууну жаныткызууда ийгиликсиз болду",
        "auto-renew-active": "авто-жанылашуу активдүү",
        "active-to": "белгиленген мөөнөтке чеиин актив",
    },
    "RetakeOnboarding": {
        "DEFAULT": "Менин психотипимди аныктоо",
        "PROFESSION_ORIENTATION": "Профессионалдык түрлендирүүнө өткөрүү",
    },
    "ConnectSocial": {
        "title": "Социалдык тармактарга кошуучу",
        "enabled": "Байланыштырилды",
        "enable": "Байланыштыруу",
        "enterEmail": "Сиздин электрондук почтаңызды киргизиңиз",
        "codeSend":
            "Код сиздин электрондук почтаңызга жөнөтүлдү. Аны бул жерге киргизиңиз.",
        "youGetCode":
            "Сиз электрондук почтаңызга ырастоо кодунун жиберуусун аласыз",
        "retryEmail":
            "Себеби болгонда электрондук почта келбей түшсө болбосо, кодуну жаны жөнөтө аласыз <span> секундада {таймер}...</span>",
        "codeError": "Код дал келбейт",
        "retrySend": "КОДДУ ЖАНЫ ЖӨНӨТҮҮ",
    },

    "FriendActions": {
        "requestSend": "Дос нукта жиберилди",
        "cancel": "Бекитүү",
        "incomingRequest": "Келген дос нуктасы, кабыл аласызбы?",
        "accept": "Кабылдоо",
        "decline": "Четке кагуу",
        "add": "Кошуу",
        "sendMessage": "Жазуу жолдошу",
        "addToFriends": "Дос катары кошуу",
        "partnerRequestSend": "Сериктештикке өтүнүччү кат жиберилди!",
        "makePartner": "Сериктештик кылуу",
    },
    "RecommendedPeopleCtrl": {
        "title": "Адамдардын сунуштары",
    },
    "AppLoading": {
        "title": "Интернет байланышын текшериңиз <br/> жана бетти жаны жүктөө",
    },
    "ChooseFriendPhotos": {
        "analyze": "Талдоо",
    },
    "ServiceCollapse": {
        "openNow": "Азыр ачуу",
        "confirmTitle": "Сунуштардын ачулушу",
        "confirmText":
            "Сунуш азыр эч качы ачуу үчүн, сиздерге PLATINUM тарифине жазылуу керек.",
        "confirmBtn": "Жазылуу",
    },
    "LandingHeader": {
        "advantages": "Сиз эмнени аласыз",
        "steps": "Бул эмне иштейт",
        "mission": "Биздин миссия",
        "enter": "Кирүү",
    },

    "LandingBanner": {
        "title":
            "<span>Каспий маселеденин текшерүүсү</span><br/> Нейрондук тармақ 3 професцияны тандайды<br/>сизге тиешелүү",
        "description": "Жасалма интеллект дүйнөсүнө кириңиз жана Merlin AI менен жөндөмүңүздү колдонуңуз",
        "list": ["Сиздин кувваттуу жана зайырлуу жагынарларын талдао алгоритмы", "Мамлекеттин жана байланыш учурдарын тандоо жаттыгууда кеңеш берет", "Жеке рекомендацияларды алуу, сүрөтлөрдү генерациялоо, байланышып жана өүренүү"],
        "btn": "Мамлекетти билиңиз",
    },
    "LandingAbout": {
        "title": "<span>Merlinface —</span> Жасалма йөнөмдөөчүлөрдүн сунуштары болуп сунушталган, искусствендик интеллект салмагынын сунуштуу алгоритмы",
        "list": [{
            "1": "Сүрөтүңөздү жүктөп, биздин нейрондук тармагы алар үчүн тиешелүү профессияларды тандалайт",
            "2": "AI менен чатта адисте болуу"
        }, {
            "3": "Чындыктуу милдеттер жана долбоорлор",
            "4": "Биздин нейрондук тармак менен байланышып, курстан тапшыруу жасаңыз жана нейрондук тарма операторунун жетишкендиктерин өсүндүрүңүз"
        }, {
            "5": "Чатта чындыктуу долбоорлорго берилген билимдерди колдонуу",
            "6": "Бүгүндөгү карьерага даярдык: биздин AI сизди жетишүүгө рекомендацияларды жекелей даярдайт",
            "7": "IT-га ийгиликтүү баштоого рекомендациялар"
        }]
    },
    "LandingAdvantages": {
        "title": "Сиз не аласыз?",
        "showVideo": "Видеону көрүү",
        "list": [
            {
                "id": 1,
                "active": true,
                "code": "профессионалдык",
                "title": "Профессионалдык түрөлүш",
                "description":
                    "Биздин берилиш базасында 1000ден ашык түрдөө профессиялар баар. Merlin AI сизге жетишебилецегиңиз 3 профессияны тандашта жардам берет.",
            },
            {
                "id": 2,
                "active": false,
                "code": "psychotype",
                "title": "Психологиялык типтөш",
                "description":
                    "Сиз бардык күчтүү жана зайылуу жактарыңызды, жана аларды қалай өнүргүзүү тууралуу тизмени аласыз.",
            },

            {
                "id": 3,
                "active": false,
                "code": "person-description",
                "title": "Сенденин табигатың жана дем алуу түрүңдөгү сүрөтү",
                "description":
                    "Биздин сайттын үчүнчү колдонуучусу любимый нерсесин табат.",
            },
            {
                "id": 5,
                "active": false,
                "code": "recommendations",
                "title": "Жеке оңойлуулар",
                "description":
                    "Merlin AI сиз үчүн жакшы болууга багытталган жөнөкөй 150 жеке оңойлууну даярдады.",
            },
            {
                "id": 6,
                "active": false,
                "code": "person-recommendations",
                "title": "Баскадан платформа мүчөлөрүнүн өндөрүүлөрү",
                "description":
                    "Merlin AI жогорку бизнес уюмдуулугу болгон адамдарды же мотиваторлорду регуляртай тандай жатат, ошондо бизнесдик бирдикчи, дост ошондо жакындык үчүн болбогон адамдарды таба аласыз.",
            },

            {
                "id": 7,
                "active": false,
                "code": "messages",
                "title": "Сунуштуктук жоболор боюнча окууну сунуштоо",
                "description": "Сиз бизнестибиздин жолунашырышы, байланышты же мотивация учурдагы колдонуучулар менен тактил эй бере аласыз"
            },
            {
                "id": 8,
                "active": false,
                "code": "celebrity",
                "title": "Көп мындыктар базасына кириш",
                "description": "Сиз алар менен жеке чараалар болуп, иштеэл баштоо жана старлы боюнча статистика айыратын болушу мүмкүн."
            },
        ],
    },
    "LandingMission": {
        "title": "Биздин миссия",
        "description":
            "Ыкмалдарын аныкташ учун жана жумушторгон жетишкендери менен эмес, алардын резюменен байланыштуу жумуш табуу үчүн курал түзөбүз.",
        "memberCount": "Идентдик буга чаар алынган",
        "btn": "Кошулуңуз",
    },
    "LandingSteps": {
        "title": "Бул кандай иштейт?",
        "steps": [
            {
                "number": 1,
                "title": 'Төмөндөк "Башта" баскычын басыңыз',
                "description": "Тестке башта",
            },
            {
                "number": 2,
                "title": "Эки кыска психологиялык тесттер",
                "description": "Сизге тиешелүү аланга аныктай алуу үчүн",
            },
            {
                "number": 3,
                "title":
                    "Нейрондук тармак сизге сунуштоолоо профессияларға добуш бериңиз",
                "description":
                    "Ошондук басын былайганы жана сиз жок карашыныздарын таштаңыз.",
            },
            {
                "number": 4,
                "title": "Натыйжаларды алыңыз",
                "description":
                    "3 профессия, 3 ишкана алан. Бул профессиялардын сыпаттамасы. Успехке жетүүгө кандай кеңештөр. Аларга өз потенциалыңызды жана не мотивациялыктыгызды билип чыгуу мүмкүнчүлүгү.",
            },
        ],

        "btn": "Башталуу",
    },
    "LandingReviews": {
        "title": "Алар Мерлинбет жөнүндө эмнени айтат:",
    },
    "MerlinLimit": {
        "title": "Бүгүнкү сүрөштүү суроо чектелди - 5/5",
        "desc": "Merlin AI менен байланышты улантуу үчүн төлөнгөн тарифке өтүшү керек",
        "btn": "Тарифтерди кароо",
    },
    "SubscriptionsData": {
        "PROFESSION": {
            "TITLES": [
                {
                    "id": "PROF_OPTION_1",
                    "name": "Өздүк үчүн жетишген сипаттамаларыңыз",
                },
                {
                    "id": "PROF_OPTION_2",
                    "name": "Жеке тавсиялар жетишүү үчүн",
                },
                {
                    "id": "PROF_OPTION_3",
                    "name": [
                        "Жеткиликтүү көрсөткүчтөр:",
                        "Иш жөнүндөгү келешим",
                        "Иш байланышма",
                        "Физикалык агрессия",
                        "Эмоционалдык агрессия",
                    ],
                },
                {
                    "id": "PROF_OPTION_4",
                    "name": "Танымалдык бизнестин теңдөштүү тест",
                },
                {
                    "id": "PROF_OPTION_5",
                    "name": "Бизнес ортогон Мерлинбет менен жүйүү",
                },
            ],

            "FREE": [
                {
                    "id": "PROF_OPTION_1",
                    "value": "30% ачык",
                },
                {
                    "id": "PROF_OPTION_2",
                    "value": "апта башына 1",
                },
                {
                    "id": "PROF_OPTION_3",
                    "value": "күнү боюнча 1 белгиленген",
                },
                {
                    "id": "PROF_OPTION_4",
                    "value": "—",
                },
                {
                    "id": "PROF_OPTION_5",
                    "value": [
                        "апта башына 1",
                        "мен үчүн 1 тандоо жасап берилди",
                        "1 гет түш",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "PROF_OPTION_1",
                    "value": "толук ачык",
                },
                {
                    "id": "PROF_OPTION_2",
                    "value": "үч күнү 1",
                },
                {
                    "id": "PROF_OPTION_3",
                    "value": "саат боюнча 1 белгиленген",
                },
                {
                    "id": "PROF_OPTION_4",
                    "value": "айына 1",
                },
                {
                    "id": "PROF_OPTION_5",
                    "value": [
                        "үч күнү 1",
                        "мен үчүн 1 тандоо жасап берилди",
                        "3 гет түш",
                    ],
                },
                {
                    "id": "PROF_OPTION_6",
                    "value": "апта башына 1",
                },
            ],

            "PLATINUM": [
                {
                    "id": "PROF_OPTION_1",
                    "value": "толук ачык",
                },
                {
                    "id": "PROF_OPTION_2",
                    "value": ["күнү башына 1"],
                },
                {
                    "id": "PROF_OPTION_3",
                    "value": "чексиз",
                },
                {
                    "id": "PROF_OPTION_4",
                    "value": "апта башына 1",
                },
                {
                    "id": "PROF_OPTION_5",
                    "value": [
                        "күнү башына 1",
                        "1 танданып алынат",
                        "5 сизге колдонулат",
                    ],
                },
                {
                    "id": "PROF_OPTION_6",
                    "value": "апта башына 1",
                },
            ],
        },

        "SELF_ESTEEM": {
            "TITLES": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "name": "Бирдикке текшермелер оз-инанын сыйоосун көтөрүү үчүн",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "name": "Жеке сунуштарды оз-инанын сыйооо көтөрүү үчүн",
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "name": [
                        "Жеткиликтүү индикаторлор",
                        "Үлгүлөрдүн пайда болушу",
                        "Ишки мамиле",
                        "Адам менен байланышым",
                    ],
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "name": "Опытын алмаштыруу үчүн адамдардын тандоосу",
                },
            ],
            "FREE": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "value": "30% ачык",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "value": "1 жумада бир",
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "value": "Көрсөтүлгөндөн бир күнүн",
                },
                {
                    "id": "ESTEEM_OPTION_4",
                    "value": "—",
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "value": [
                        "1 жумада бир",
                        "1 менен алынат",
                        "бир менен алыңыз",
                    ],
                },
            ],

            "GOLD": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "value": "толук ачуулуу",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "value": "3 күнүгө бир",
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "value": "саатына берилишке айкытуучу бир",
                },
                {
                    "id": "ESTEEM_OPTION_4",
                    "value": "айына бир",
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "value": [
                        "3 күнүгө бир",
                        "маңдай менен бир танданып алуу",
                        "3-гө чейсеңиз аласыз",
                    ],
                },
                {
                    "id": "ESTEEM_OPTION_6",
                    "value": "аптасына бир",
                },
            ],
            "PLATINUM": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "value": "толук ачуулуу",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "value": ["күнүнө бир"],
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "value": "чегене баайтуу",
                },
                {
                    "id": "ESTEEM_OPTION_4",
                    "value": "аптасына бир",
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "value": [
                        "күнүнө бир",
                        "маңдай менен бир танданып алуу",
                        "5-гө чейсеңиз барабыз",
                    ],
                },
                {
                    "id": "ESTEEM_OPTION_6",
                    "value": "аптасына бир",
                },
            ],
        },

        "MOTIVATION": {
            "TITLES": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "name": "Жеке сүрөттөрүңүздү мотивациялоо үчүн",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "name": "Мотивацияны арттыруу үчүн жеке сунуштар",
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "name": "Мотиватор тамашабыларды тандоо",
                },
            ],
            "FREE": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "value": "30% ачык",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "value": "апта сайын 1",
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "value": [
                        "ай сайын 1",
                        "Мен үчүн тандалган 1",
                        "Менге тийген 1",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "value": "толук ачык",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "value": "үч күн сайын 1",
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "value": [
                        "үч күн сайын 1",
                        "Мен үчүн тандалган 1",
                        "сизге тийген 1",
                    ],
                },
            ],
            "PLATINUM": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "value": "толук ачык",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "value": ["күнү сайын 1"],
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "value": [
                        "күнү сайын 1",
                        "Мен үчүн тандалган",
                        "сизге тийген",
                    ],
                },
            ],
        },

        "RELATIONS": {
            "TITLES": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "name": "Сиздин мөнөттөгү үлгүлөрүңүз",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "name": "Ар бир жакшыртуу мүнөт үчүн жеке сунуштар",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "name": [
                        "Жеткиликтүү көрсөткүчтөр:",
                        "Мүнөттүү ырастоочулук",
                        "Адамдар менен байланыш",
                        "Жалпы агрессия",
                        "Физикалык агрессия",
                        "Эмоциялык агрессия",
                        "Социалдык агрессия",
                    ],
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "name": "Атакуулук Мүнөттүүлүктүн Салыштырмалуу Чыгармасы",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "name": "Мүнөттүүлүк үчүн ортактардын тандоосу",
                },
            ],
            "FREE": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "value": "30% ачык",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "value": "1 жумада бир",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "value": "1 көрсөтүлгөндөгү бир күн",
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "value": "—",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "value": [
                        "1 жумада бир",
                        "1 менен танданыш",
                        "1 гана алуу",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "value": "толук ачык",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "value": "1 үч күндө",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "value": "1 көрсөтүлгөндөгү бир саат",
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "value": "1 айда бир",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "value": ["1 үч күндө", "1 менен танданыш", "3 гана алуу"],
                },
                {
                    "id": "RELATIONS_OPTION_6",
                    "value": "1 жумада бир",
                },
            ],

            "PLATINUM": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "value": "толук ачык",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "value": "1 күнү бир",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "value": "чексиз",
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "value": "1 жумада бир",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "value": ["1 күнү бир", "1 менен танданыш", "5 гана алуу"],
                },
                {
                    "id": "RELATIONS_OPTION_6",
                    "value": "1 жумада бир",
                },
            ],
        },
    },

    "TariffJunior": {
        "title-1": "JUNIOR активдиктe",
        "title-2": "Junior",
        "desc": "Junior абонементи 4 категорияда Gold пландын жана барча жакшылыктарын камтыйт: <br/>Касип жана өздүк сезим, өз-өзүн барабарлык, мотивация жана энергия, муноттар жана семья",
        "cancelSubscription": "Абонементти жок кылуу",
        "confirmation": {
            "title": "Абонементтан чыгуу",
            "desc": "Сиздик Жүниор абонементти жок көргөндүгүнүздү тастыктайсызбы? Бардык жакшылыктар төлөнгөн мөөнөттүгү талап кылынганга чейин жеткиликтүү болот",
            "btn": "Абонементтен чыгуу",
        },
    },

    "SubscriptionPeriodLabel": {
        "MONTH": "айына",
        "ANNUAL": "жылына",
    },
    "SubscriptionPeriodOptions": {
        "MONTH": "айлык",
        "ANNUAL": "жылык",
    },
    "Tips": {
        "close": "Ыстыкты жабуу",
        "prev": "Артка",
        "next": "Кийинки",
        "disable": "Башкарууларды өчүрүү",
        "categories": {
            "PROFESSIONAL": "Касип жана максат",
            "RELATIONS": "Байлыктар жана семья",
            "MOTIVATION": "Мотивация жана сапаттуулук",
            "SELFASSESSMENT": "Өзүнүн үнөмдөгү жана ишенүү",
        },

        "list": [
            {
                "name": "auth",
                "element": ".auth-form",
                "title": "Телефон номерин колдонуу менен катталуу",
                "subtitle":
                    "Бул сиз гана маалыматка иштетүүнү баштапташ үчүн керек. Жана сизге сыр сөз түзүүгө мүмкүн эмес.",
                "timeout": 2000,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },

            {
                "name": "avatar",
                "element": "[data-tip='avatar']",
                "title": "Аватарыңызды орнотуңуз",
                "subtitle":
                    "Аватар жок болсо, алгоритм башка сайт колдонуучуларына сизди сунуштай албайт.",
                "timeout": 2000,
                "next": "recommendations",
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "recommendations",
                "element": "[data-tip='recommendations']",
                "title": "",
                "subtitle":
                    "Бул жерде алардын ар турдугу жардам берет жаткан сунуштарыны табасыз",
                "timeout": 2000,
                "next": "people-recommendations",
                "prev": "avatar",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "people-recommendations",
                "element": "[data-tip='people-recommendations']",
                "title": "",
                "subtitle":
                    "Бул жерде алардын ар турдугу категорияларда сизге сунушталган адамдарды табасыз",
                "timeout": 2000,
                "next": "издөө",
                "prev": "recommendations",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "sphere",
                "element": "[data-tip='sphere']",
                "title": "Адамдарын чышкан көпү болгон дөрт башкы көз карты",
                "subtitle":
                    "Мен таштапки фотограафияларыңызды анализдалдым жана жеке сипаттаманы жасадым. Кызыктырган категориядагы переписканы басып катар.",
                "timeout": 200,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },

            {
                "name": "inside-sphere",
                "element": "[data-tip='sphere']",
                "title": "Ачык жана жабык сфера категориялары",
                "subtitle":
                    "Окуу үчүн ачылгандар сары түс менен көрсөтүлгөн. Караңгыларды кызыкчылык категорияларына жазылуу менен ачса болот.",
                "timeout": 200,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "search",
                "element": "[data-tip='search']",
                "title": "",
                "subtitle":
                    "Издөө терезеси аркылуу каалаган орусиялык атактууларды жана адамдарды аты же фамилиясы боюнча таба аласыз.",
                "timeout": 2000,
                "next": "notifications",
                "prev": "people-recommendations",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "notifications",
                "element": "[data-tip='notifications']",
                "title": "Коңгуроо - эскертме баскычый",
                "subtitle":
                    "Ал социалдык тармактагы активдүү аракеттер үчүн ар кандай бонустар жөнүндө билдирүүлөрдү, кимдир бирөө сиз менен шайкештигин текшерген болсо, билдирүүлөрдү көрсөтөт.",
                "timeout": 2000,
                "next": "subscriptions",
                "prev": "search",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "subscriptions",
                "element": "[data-tip='subscriptions']",
                "title": "",
                "subtitle":
                    "Бул жерде сиз мүмкүн болгон жазылуулардын тизмесин жана активдүү жазылууларыңызды таба аласыз.",
                "timeout": 2000,
                "next": false,
                "prev": "notifications",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "multi-compare",
                "element": "[data-tip='multi-compare']",
                "title": "Категорияларды тандаңыз",
                "subtitle":
                    "Сиз досторуңуз менен салыштыргыңыз келген бир нече категорияны тандай аласыз. Сиз PLATINUM тарифин иштеткен салыштыруулар гана жеткиликтүү.",
                "timeout": 2000,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": false,
            },
            {
                "name": "choose-avatar",
                "element": "[data-tip='choose-avatar']",
                "title": "Аватарыңызды коюңуз",
                "subtitle":
                    "Ошондо мен сени башка адамдарга сунуштай алам. Мен сени суроту жок жарнамалай албайм.",
                "timeout": 200,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": false,
            },
            {
                "name": "recommendations-modal",
                "element": "[data-tip='recommendations-modal']",
                "title": "Жеке сунуштар",
                "subtitle":
                    "Эсептегичтин мөөнөтү аяктагандан кийин сизде бир сунуш ачылат. Кийинки сунуштун эсептегичи сиз акыркы жеткиликтүүсүн окумайынча иштебейт.",
                "timeout": 500,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
        ],
    },

    "WelcomeLayout": {
        "ctrl": {
            "step-1": "Сороолуу менен текшерүү",
            "step-2": "Сүрөттөгү текшерүү",
            "step-3": "Сүрөттөгү текшерүү",
            "step-4": "Песелер текшерүү",
        },
        "back-to-main": "Башкы баракке",
        "back-to-main-confirm-title": "Аракетти тастыктайсызбы?",
        "back-to-main-confirm-desc":
            "Чын эле башкы баракка кайрылгыңыз келеби? Бардык прогресс толугу менен жоолор.",
        "back-to-main-confirm-apply": "Башкы баракка",
    },

    "WelcomeWant": {
        "title": "Куттикуу, биринчи кадам алынды.",
        "description":
            "Менин атым Мерлин, мен сизге чындыктуу керек болгон затты алууга жардам берем. <br/>Чыгармакчы эмнени көрсөтүңүз:",
    },
    "WelcomeTest": {
        "title-1": "Тестти 15 минутка берилген",
        "title-2": "Биз сизге жараган 12 аймакты аныктадык!",
        "title-3":
            "СИЗ УЧАСТ! Жаңы билим берүү менен өзүңүздүн туура келгени 2 бос катар калды",
        "subtitle-1": "Азыр психологиялык тесттегем болууну",
        "subtitle-2": "Алдында карабыз. Башка бирге жооп берин",
        "subtitle-3":
            "Сиздин лика типин белгилөө үчүн соңку {count} суроо. <br/> Сизге даярдоо аларды белгилөө үчүн",
        "question-destination-1": "суроо",
        "question-destination-2": "суроо",
        "question-destination-3": "суроолор",
        "choose-title": "Сизди эң ийи сүрөткөн вариантты тандоо:",
        "next-question": "Келечектики суроо",
        "quote-1": "Ар суроо жогорку сизди табат",
        "quote-2":
            "Сизге кайрылоо этет жана баш тартпа. Башкача, өлгөн канчалыктары жакшы. (c) Альберт Камю",
        "quote-3":
            "Толук адам баарын өзүндө издейди, болмош адам - башкаларда. (c) Кофуций",
    },

    "WelcomePhotoTest": {
        "title": "Натижага 1 кадам қалды",
        "subtitle": "Сиздин күчтөрүңүздү аныкташ үчүн ПСИХОЛОГИЯЛЫК ТЕСТ",
        "choose-title": "Сизге кайсы бет үнөмдөр?",
        "next-btn": "Кийинки Сүрөттөр",
    },
    "WelcomeCheckPay": {
        "title-success": "Төлөм ийгиликтүү болду",
        "title-error": "Төлөмдө ката болду",
        "description-success": "Сиз 5 секундда кийинки кадамга кайрылатсиз...",
        "description-error": "Жалгызуу үчүн сиз төлөмдү жасамалысыз",
        "btn-success": "улантуу",
        "btn-error": "Кайрылыш",
    },
    "WelcomeAnalyze": {
        "title": "Жооптарды талдоо",
    },
    "WelcomePhotos": {
        "title": "СИЗДИН НАТИЖАЛАРЫҢЫЗДЫ ЖАКШИЛАНДИРУУ",
        "subtitle":
            "Сиздин жооптарыңыз нейрондук тармактама алгоритминен талдоолога табылады.<br/> Мамлекеттик вазифаларды да туура тандоо үчүн, сүрөттөрүңүздү жүктөө.",
        "upload-photos-title":
            "Абдан туура иш тандоосу үчүн сүрөттөрүңүздү жүктөө",
        "next-btn": "Кийинки",
        "skip-btn": "Өткөрүү",
    },

    "WelcomeAnalyzeReady": {
        "title": "Мениң 170 тыюугасыз касыпкерчиликтерди алып салгандым болду.",
        "subtitle":
            "10 минут ичинде сиз үчүн эң жакшы касыпкерчиликтерди тандайбыз.",
        "desc": "Сизге ылайык эмес болгон салмактарга убакты кыйнарыңыз.<br/> Тест ишкени жолун тездетет.<br/> 90% адамдар тестти өткөндөр өздөрүнү жакшы билген иштерин табууга жетишкендер.",
        "quote": "Бардык нускаларды толук алыш керек. (c) Аристарх Ромашин",
        "start-btn": "Баштоо",
    },
    "WelcomeProfessionVote": {
        "title-1": "",
        "title-2": "Болгонду! {count} {decl} 1150 ичинде ыза болду",
        "title-3": "Болжолуу болду! Биз жакындайбыз",
        "title-4": "Жаңы касыпкерчиликке басыңыздар мен биз үйлөшөбүз",
        "subtitle-1":
            "Булюм менен сиздин өзүңүздү эң көп кабылга алаткан салмак көрсөтүлгөн.<br/>Мен {count} {decl} қойдум 1150 ичинде. Кайгы бирин тандабыңар.<br/> <br/>Сары бөлүктөгү бөлүктө басыңыз",
        "subtitle-2": "Сары бөлүкү бөлүктө басыңыз",
        "subtitle-3": "{count} {decl} ыза қалды 1150 ичинде",
        "subtitle-4": "{count} {decl} ыза қалды 1150 ичинде",
        "profession-declination-1": "касыпкерчилик",
        "profession-declination-2": "касыпкерчиликтер",
        "profession-declination-3": "касыпкерчилик",
    },

    "ProfessionVotePopup": {
        "help-1":
            "Өткөр. Сиздин жоопуңузду эстедим. Азыр кийинки бир нече касиптерди толтуруңуз жана Эсептөө баскычын басыңыз.",
        "help-2":
            "Эгерде сиз касиптин эмне экенин билбесеңиз, жакшы аталышына басыңыз.",
        "help-3":
            "Бул жерде сизге бул иш менен кызыктуу болушуңуз үчүн боладыгы жана сиздин кайсы качанлыктарыңыздын сизге көмөк кылушу мүмкүн экени жазылган.",
        "help-4":
            "Бул сиздин иш-карында жакшы болушуңуз мүмкүн болгон касиптин мисалы. Ошондой эле сизге кандай кызыктуу экенин баа алыңыз. <br/><br/>Ойлогон чүүдөй баалагылы, мен сиздин жообуңузду эң жакшы касип тандаш үчүн колдону алам. <br/><br/>Birinchi kasip üchün döbölöb chygyryňyz.",
        "error": "Кийинки касиптер үчүн добуш бериңиз:",
        "next-btn": "Улантуу",
        "calc-btn": "Эсептөө",
    },

    "OnboardDescriptionPopupRadio": {
        "list": [
            {
                "id": 1,
                "text": "Эч качан да интерес жок",
                "showSub": false,
            },
            {
                "id": 2,
                "text": "Али эч качан аракаткан жокмун, бирок мен менил интересуушамын",
            },
            {
                "id": 3,
                "text": "Мен азыр эч нерсе учуп жатамын",
            },
            {
                "id": 4,
                "text": "Мен акчалабактым, ал эмне эч кылмадым",
            },
            {
                "id": 5,
                "text": "Мен булду активдуу аткарып жатамын жана мен жакшы жатамын",
            },
            {
                "id": 6,
                "text": "Бул жерге артык бир жогорку тажрыйбага эйе мен экспертим",
            },
        ],
    },

    "WelcomeProfPay": {
        "title-1": "1150 касиеттин ичинде, 3 сизге тийген келген",
        "title-2": "Төлөмдү тандоо",
        "desc-title":
            "{count} адам уктуу касиетин табып, олардын доходуну ошондой ачыктады",
        "desc": "87% издөө этүүчүлөр бул издөөнү уктуу касиеттерди төмөр алгычтарына сунуштайт <br/>Бул издөө эми 38 жемкилдик компаниянын чорго директорлору жатканында колдонулат <br/><br/>Касиеттин ийгиликтүү жогоргой алуу үчүн жеке кеңеш алыңыз",
        "open-btn": "499 сомго ачыңыз",
        "motivation":
            "Сиз алгачы тоо болуп 15 минута ылдап бердингиз, акыркы кол берген чакырдаңыз!",
        "recreate-text": {
            "btn": "Тестти кайта көүгү",
            "confirm-title": "Тестти кайта иштейсизби?",
            "confirm-desc":
                "Тестимде сиз дагы издөөчү менен тескел береткен тендерди кайратой эле болсо, болдура кайтадан <br/>Акыр кеткенизге бактык кызыктуу: отуулган тесттин прогресси мүндөмүлүк өчүрүлөт.",
            "confirm-btn": "Тестти кайта көүгү",
        },
        "partners": {
            "title": "Биздин бузуучулардын майылыктары менен бекитилген:",
            "list": [
                "Ресей Федерациясынын Билим Министрлиги",
                "Инновациялык Терекке Катышуу Фонду",
                "B20 Индонезия 2022",
                "Skolkovo",
                "Merlin AI - Telegramдын официалдык бузуучусу",
            ],
        },
        "paymentMethods": {
            "all": "Бардык төлөм ыкмалдарын көрсөтүү",
            "phone": {
                "title": "1. Телефон номери аркылуу",
                "copy": "көчүрүү",
                "copied": "көчүрүлгөн",
                "copy-number": "Номерни көчүрүү",
            },
            "link": {
                "title": "2. Силтеме боюнча",
                "btn": "Силтемеге өтүү",
            },
            "kassa": {
                "title": "3. Robokassa аркылуу",
                "btn": "Төлөү",
            },
            "qr": {
                "title": "4. QR коду аркылуу",
                "btn": "Тандоо",
            },
        },
        "after-pay":
            "Төлөбүздөн кийин, тандик белгичекчет, биз төлөмдү текшере алабыз",
        "after-pay-label": "499 сом төлөндү",
        "after-pay-btn": "Улантуу",
    },

    "WelcomeProfessions": {
        "title": "Биз сиздин болшык эң асыра чыгардык<br/>",
        "title-1": "Жакшы!<br/>биз сизге эң келген касиптерди табдык",
        "title-2": "Жакшы!<br/>Азыр",
        "title-3": "Жакшы!<br/>Азыр",
        "subtitle-1":
            "Сизди кызыктырган касиптердин бирин <b class='gold'>дагы</b> башкаларгана чыгарып алыңыз",
        "subtitle-2":
            "Байланыштыгы көчө-деги салбырдыгы эң жакшы<br><br>кайсы дагы бир графика түзүмөнө басып, сизге келген касиптерге толук көрүңүз",
        "title-helper": "Аламдын касиптер",
        "these": "Булар",
        "merlin-help":
            "Мен уларды изилесиңизге жардам менен болушатын. Merlinface дагы жакшы адамдар бар алар бычак доңгосудай. Мен силерди таништырам. <br/><br/>Бирок менен азыр эң карашаа...",
        "more-btn": "Өренүү",
        "next-btn": "Түшөө",
    },
    "WelcomeBusinessCardInfo": {
        "info-title": "Озуңуздун жөн гана информациясын жазыңыз",
        "edu-title": "Билим таргаагыңыздын маалыматын тоолуңуз",
        "career-title": "Иштеңиздин маалыматын түзүңүз",
        "subtitle":
            "Бул маалымат адамдардын сиздин страницаңызды коргонгондогунда көрөт. Бул бизнес ортогонузда болушу мүмкүн",
        "all-fields-error": "Бардык талааларды толтуруу талап кылынат",
        "edu-fields-error": "Эсебеле болгон жерди косуу тууралуушу эмел болот",
        "career-fields-error":
            "Азыркы эмгекчи иштеген жериңизди кошуу талап кылынат",
    },
    "WelcomeBusinessCardPay": {
        "loading": "Жүктөлүү...",
    },
    "WelcomeStart": {
        "title": "Профилиңизди чондоп толтуруңуз!",
        "subtitle":
            "Маалымат эше чынылуу болсо, натежа дагы эше дөңгөлөй болот",
    },
    "WelcomePersonInfo": {
        "title": "Очень жөнөкөй, ",
        "subtitle": "Өзүңүз жөнүндө бир аз маалыматты толтуруңуз:",
        "age": "жаш",
        " age-placeholder ": "Сизге канча жаш кетип калды?",
        "age-error": "14 жана 99 ортосу жашты киргизиңиз",
        "choose-country": "Жакты тандоо",
        "choose-city": "Шаарды тандоо",
        "country-placeholder": "Жакты издөө",
        "city-placeholder": "Шаарды издөө",
        "city-no-options": "Тандоолгон жакка карата шаардын атын киргизиңиз",
        "social-title": "Сиздин социальдык статусуңуз:",
        " social-error ": "Социальдык статусту тандоо",
        "next-btn": "Улантуу",
    },
    "WelcomeTestComplete": {
        "title": "Мен сиздин психотипиңизди белгилеменим",
        "your-type": "Сиздин психотипиңиз menen биздин сайтта {count} {dec}",
        "your-partners":
            "{count} {decl} сизге иште биргоо боюнча жогорку далелдер менен.",
        "celebrity":
            "{count} сүрөттөгү жана ийгиликтүү болгон адамдар сиздин personality типиңизге ийгиликтүү.",
        "next-btn": "Улантуу",
        "typeDescriptions": {
            "Медиатор":
                "Сиздин табигий кызматтарыңыз сизди учурду таттуу чөпчөкчү кылатат. Сиз талаштарды чөзгөн жана адамдар менен орточо түшүүлөрдү таба аласыз. Башкалардын керек демдерин угуу жана тушунуу кабилияти сизди ар бир жигитдин кымбаты жасайт. Сиздин камтыйышларга берилүү жана миргилоого үнөм сиздин жакшы жана өндүрүштүү байланышты жаратат.",
            "Коммуникатор":
                "Сизде байланыштып турууку бир талант бар. Сиздин кызматтарыңыз көмөкчү болуп, туура байланыштык, өз пикердериңизди жана идеяларды айтканыңдай жергиликтүү адамдардын көз карашына үйрөндүрүү кызматкерлериге чет болот. Байланышты жөнөкөй жана кайрыктуу кылатканыңыз сизге касиеттүү ыйыкты түзүүгө жардам берет.",
            "Туташтыргыч":
                "Сиз жакшы байланыштыруучу болуп эсептелесиз, адамдарды жана идеяларды байланыштыратасыз. Социалдык теңгелүү кызматкарларыңыз жана башкаларга берилген камтыйышыңыз сизге касиеттүү тармактардын орталыгында болууга мүмкүндүк берет. Өргөнчөлүк кызматтарыңыз сизге ийгиликтүү командаларды жана долбоорлорду жаратууга колдоо берет.",
            "Гедонист":
                "Сиз касиеттүү жашоону жана накыды табасыз. Сиздин кызматтарыңыз сизге жумушуңузду жакшы коргон жана жагымдуу натижалар гана эмес, бирок рахаттык, аккорду жана жумуш процессин азыраак таттуу жана табышмалуу кылуунун жолдорун табуу мүмкүндүүгүн көрсөтөт.",
            "Дипломаттык баскынчы":
                "Сизде байланыштып жана адамдарды таасирлөөгө көз жеткирүү уюмдагы бир талант бар. Сиздин талантыңыз комфорттуу бардык мүмкүн болгуча ортолуктарда подрядчик жана ишти жүздө көтөрүүгө, сизди касиеттүү колдообщуп кылатат. Силер туура жетгенде жана оң көрүнүш менен сунуштуу жана жетөдөй жылдырактарды колдонуу көмөкчү болуп, эң кыйын чыгымдарга жакшуу кызмат алат.",
            "Лоббист":
                "Сизде, баскаларга үнөм болуунун жолдорун тапууга жана иш жүздө көтөргөн уюмдарды колдоо кылууга уюм болгон бактылуу сууктуулук жана таасирлөө кызматтарыңыз бар. Силердеги кызматтар сизге байланыштыруу жана жандарыңызга таасирлөөгө мүмкүнчүлүк берет. Сиз жөнгө салыштырууну, стратегияларды жана лоббистдик интереслерди жаратууга кабыл аласыз. Сизге өздүк инициативалыгыңыз жана өргөнчөлүктөгү көмөкчү болгон уюм аркалы болууч адамдардын ыйгармасын колдоо берет, жаталган ортолуктарда ийгиликтүү ишке киреше турууга жардам берет.",
            "Саясатчы":
                "Сиздин жетекчилик жана ырааттуулук көрсөтүүгө укук берет, сиз политикалашкан жерде ийгиликтүү болушуңузга мүмкүн. Сиз маанилүү чечимдер кабыл алууга, конфликттарды шешүүгө жөндөгүн түрдө башка пикирге ээ болгон адамдар менен орточо тилге табууга укук берет. Сиздин активдик жашоо тартиби, өзгөрүүгө койгон сүйүүңүз жана башкаларды өз пикерлериңизге катыштыруу көнөгүүңүз сизди ийгиликтүү политикацияды кылат. Сиздин социолюбивость жана өзүңүздү бою ар бир колуңузду жетиштүргөн үчүн жол ашат.",
            "Материалист":
                "Сиз материалдык өшөл баагылат жана натиже үчүн иштөөгө турган болушуңузга укук берет. Сиздин прагматизмдигиңиз жана максаттуулуктыңыз сизге ар бир учурда ырааттуу болууга жардам берет. Сиз ресурстарды баштап көрүүгө, планировкаларды жана пландаштырган иштериңизди максаттуулуктан тигерек жүз аткарууга укук берет. Сиздин организациондук, оптимизациялык, чечимдик көрөшүүчү жана чатка Түрдөгүтөштүгүңүз сизди ар бир команда же долбоор үчүн жетиштүүсүз кылат.",
            "Консервативдик":
                "Сиздин таланттарыңыз жана жетиштүүшүлүгүңүз сизди рахаттакыла консерватордык мкада катыштырууга укук берет. Сиз стабилдетти, тартыбы жана салт-туустаны баагылат жана бул маанилдендерди ар бир көндүмчөлүк талаада айкындыктан тигерек жетиштүрө аласыз. Сизде организациялоо жана системалаштыруу көнөкчүлүгү бар, бул эле меле андык тиеткендикке көңүл бөлбөсүү мүмкүн, сиздин тынчтыкта жана ырааттуу даражада максаттарды жетиштирген ырааттуулуктарыңыз бекем жана стабилдуу структура төрөт.",
            "Социализатор":
                "Сиздин адамдарды тартуу жана күчтүү социалдык байланыштарды түзүү жөндөмүңүз сизди идеалдуу социализатор кылат. Сиздин баарлашуу жана социалдык көндүмдөрүңүз ишиңиздин натыйжалуулугун бир топ жогорулаткан тармактарды түзүүгө жана колдоого жардам берет.",
            "Колдонуучу":
                "Сиз идеалдык түрдө компромисс куралуучуга жарактуу. Сиздин көндүмчөлүк таланттарыңыз жана көндүмчөлүк жиктөөгө активдик жетиштүүшүлүгүңүз сиздин садык жана ишенчтүү болушуңузга болушуңузга мүмкүн. Сиздин акы иштеүүнүз, өткүрчүлүктүүнүз жана команда кызматындагы жетиштүүшүлүгүңүз сизди ар бир долбоорга зарыл катышуучусу кылып кылат.",
            "Сактоочу":
                "Башкаларды камкор болууга жана тартыбы  коргоо көңүл бөлөүүүңүз сизди идеалдык сакчы кылып кылат. Сиздин көндүмчөлүк таланттарыңыз баалы болууга жана ресурстарды сакташка укук берет, бул сизин жакшылык жана элдик жарыктык аркылуу стабилдет жана ишенчтүүлүк бергенин жетиштирет. ",
            "Массалык оюн-зоок":
                "Сиздин таланттарыңыз жана ишенчтүүлүгү сизди үлгүлөккү добуш берүүчүнүн ролуна кеширген. Сизде булла адамдарды өзүңүзгө жакындатуу, организациялоо жана алып жүргөн идеялар менен кызыктуу эмес. Деңгээлдерди өзгөртүүгө жана жогорку мотивация даражасы менен чечимдерге чабылышууга жардам берет. Сиздин энергиядыңыз жана көңүл ачуулугуңуз сизди любимый команданын мүшөсү кылат. ",
            "Уюштуруучу":
                "Сиздин табиғий ташкелчи жана планировка кылуу таланттарыңыз бар. Сураныш жана ресурс менеджменти үчүн көнөлөжөт кылуу көз караштырып, адамдарды жана тапшырмаларды жакшылаштырып берет. Сиз ысыктоо жатуу эмес, балкы иш-чараларды алкак кылып жакшы ташкел кылууну билесиз. Сиздин башкы тутум жана команда духуну сактоо кызматы сизге жакын ар бир долбоорда маанилүү элемент кылып келет.",
            "Оптимист":
                "Сиздин оптимизм жана омурга келген көз боюнча көркөмдүк материалдык саясатта өзүңөздүң алкагында болуу мүмкүнчүлүгү бар. Сиз ыргылышты бир өзгөбөлүктө көрүңүз жана башкаларга жарым-жылдам көңкө көрүү күндөгү боюнча көргүч акчалаштыруу сизге чечүүчүлүктермен байып чыгууда жаткызуу жолдору табууга мүмкүнчүлүк берет. Сиздин энтузиазм жана берекет сизге команда энергиясын көпөйтүүгө мүмкүнчүлүк берет, жалпы көрөшүн арттырат.",
            "Активист":
                "Сиздин энергияңыз, аракетке кирешүүдүүрүңүздүн жана адамдарга таасир өркүндөтүү способу, ага бир жакшы активисттин ийгилик болып берет. Сиз нече гана жаткызылып, процессге активдүү катышып болгон жоопкерчилигиге ээ, мүлк тандырып кирешүүгө кирешелебиз. Сураныш жана ташкел жетүүлөрүңүз сизге ойлоруңузду ийгиликтүү танытат жана жактыйтууларыңызды жетишкендикке жетишет. Сиждин энтузиазм жана өзүңөздүң ішенбештини көркөмүү, ийгиликтүү жүктөөлөрдү жетишүүгө мүмкүнчүлүк берет.",
            "Романтикалык":
                "Сиздин сезимдик жана дүйнөлүк көз көргөнүңүз кырда ырымдуган кылып берет. Адатта көрүлө түрдө көркүмдүлүктү көрүү жана өз ишине майдаштыруу пайызбериңиз бар. Ойлонтуу жана башкатарды да оюу үчүн күчтүү амал болуунуз жаңы идей жана көз караштырыштын ачкычы болушу мүмкүн.",
            "Меценалар":
                "Сиз шөндө көрк, уюм жана креативдик идеяларды колдоо жана развивировкасын берүү үчүн табиғий көз көрүүгүнү толук эч кач нускада актив жетек беретесиз. Башкаларга жардам берүү, алардын жаратуу болмогонуну коштуруу жана санатқа гылым берүү сизге ийгиликтүү көркөм материалдык саяси аровда жатышууга мүмкүнчүлүк берет.",
            "Кыялкеч":
                "Сиздин идеяларды жаратуунуздагы ийгилик, эмоцияларды кооп жуңкой алуугуз, үлкүн сүрөттү көрүүнүз сизди толук албана dreamer кылат. Сиз бүт көрүүдөн каткы кылмаз уктура интбайдын. Сугущу ийгиликчиликтин мен баяндыктан айратын үлгүлөр мен жаңы болмолочтолор көрсөтчү, сиз хайаңырко барса тунук алалай.",
            "Ой лидери":
                "Сиздин идеяларды жаратуунуз жана ишке ашырууңуз, айрыктык башкаларды көргөзүп чангызып сыялоого ийгиликчил жана айрандуу йаңгач лидер болугуз. Сиз жалбырак коюлган жолдон өткөргө көз ачып, жаңы өкмөттөр жолго салышак жана ошондой эле командаңа колдоо бересиз. Сиздин алды баратуунуз, баяндык эмгек мен жаңылыкты издөө бекитимчи күчү болуп калышат, кимдерде сизди колдоо кылат.",
            "Сүрөт жаратуучу":
                "Сиздин баяндык көрүүңүз мен стилдик ийгиликчиликчиликчилигиңиз кишилерге таасир этүүчү сүрөттөр кылат жаткан сүрөттер жаратып, алдында сактап калууга мүмкүнчүлүк берет. Сиздинде бар мыйзамчылыктарга жана даяр жол гөрүмдө, баяндык баяндыктарды ойнойтууга жардам берет. Сиздин сезимчиликчиликчилигиңиз, жаңы тууралууларга беймолоо өтүү жана сүрөт стилдик баяндыктырууга болгон даярдыкчылыкчилигиңиз сизди ар бир топтун кыскасыз бөлүгү.",
            "Идеаник":
                "Сиздик идеяларды жаратууга жана өнүк дамытууга болгон жардамчы беймолоо баяндыкчы эркин айлантуучу кылат. Сиздин шарттарды шечүү приоритеттере, курап берүү жана элдик болуп кетүү жана айратындуу ойного наразылыктарды көргөндө сизге бышымбай мааниви мүмкүнчүлүктер жаратуу мүмкүндүктөрүн табууга бологон жүрүкчүлүк менен бирге атайын көргөзүүчү ойлор белгилөө. Сиздин идеяларга башкаларды чангызып, өзгөртүүнү аштырып көргөзөт.",
            "Демилгечи":
                "Сиздин энергияңыз жана өзгөртүүгө болжол болгон ийгиликчиликчилик сизди чоң болгон ишке колдоочу кылат. Сиздинде жаңы жоболорго баштууга, алкарыда айрымдар салууга жана алгачкы максат боюнча адамдарды башкарууга орнотууга мүмкүнчүлүк таасир ийгиликчилигиндей айратында болуп калат. Сиздин алкактарга каршы жаңы моделдерге ачыктык үчүн баштабызга жетүүчү ийгиликчиликчилигиңиз сизди кыймыл кылып калышат жоболо даярдыкчылык, кызыктуу денеелерге ылайыкчан берген.",
            "Моралист":
                "Сизде жогорку этикалык аноолардан ве чыныгы справедливость каабайдан каамдуушулук бар, бул сизди идеалдуу моралист кылып алыштырады. Адам табигатынан, кадимки маанилерден ве бутундугу дунядагы нысандарды сырткы кылуугуздун тушунукушу. Сиздин командада же организациядагы болошуз ажыратынсыздык.",
            "Мастермин":
                "Башкаларды шыктандыруу жана мотивациялоо жөндөмүңүз, ошондой эле башкаларга болгон эмпатик мамилеңиз сизди идеалдуу шыктандыруучу адам кылат. Сиздин терең сезимталдыгыңыз жана адам табиятын түшүнүү сизге адамдарга таасир этүүгө жана алардын потенциалын ишке ашырууга жардам берет. Сиздин эмпатия жана кунт коюп угуу жөндөмү сизди кандайдыр бир командада же уюмда ажырагыс кылат.",
            "Мугалим":
                "Окуу деген болощугуздан жана адам табигатынан чогултуугу ачык маш тушуналуун, сизди идеалдуу билим бергенич кылып алыштырады. Согуштун эгиминин ордуна чейилуу болчу талаларды иштеп чыгып, кызыктуураган кондо чечкене, так эмес, жардамы менен адамдардын ичиндеги когусуздугуну кашкара алууга укукту болосуз. Окуулууларыңызга байланышуу, текшере болущуу жана сезгиричулукту кошушугуз достук алууга жана ишенч берууге жардам берет.",
            "Эмоционалдуу лидер":
                "Сиздин эмоциялык сезгиштиктуугуз, ажыратын окуя беруугуз жана жакынылык мамилелерди куруу способу, сизди эмоциялык лидер болуп соонала тышка чыгарады. Касынаа алган адамдардын эмоцияларын тутушугуз жана олардын кайра тигилетинен тардыруугуз команданың ошентип жана чостугу наркын ылайыкка чыгарышы мумкун.",
            "Энтузиаст":
                "Сиздин энергияңыз, ачыкчылыктуугуз жана оптимизмдуугуз жакыныңыздагы адамдарды баалагып жана бааландыруу иштеп чыга алыштырууга жардам берет. Сиз эмне ишке алдын алып болсоңуздар, ошолорго ойооп, толчобусчу эмоцияларды жыйнатасыз. Абдан трыйдан жана болошуку мүмкүн болууга боюн кырып беруу способуугуз сизди негизинде керек кылып алыштырады.",
            "Насаатчы":
                "Башкалардын керектиилери менен кол-колча жана адамды көйгөшү менен колдоо менен, сизди идеалдуу ментор кылып алыштырат. Сиздин терпимостугуз, эмпатия чувстволуугуз жана билимдерин жана тажиребин бөлүшүү способуңуз жана башкаларды целлерине точтуп материалдык жаттымдарды болууга жалбыраган.",
            "Сүрөтчү":
                "Сиздин и новаторство, эмоциялык ифоде жане дүйнөгө өзгөчүлүктүү башкара жаткан түрдө, сизди идеалдуу сөзчү кылып алыштырат. Жатышып келүү менен чыгармалар тузуу, аларды башкалар менен бөлүшүү способуңуз, өзүңүздү байланышмалар менен эмоциялыктык озгочо жана ылайыкуу болушу материалдарды болууга жол берет.",
            "Устат":
                "Сиздин толуктауу ориентацияңыз, практикалык ойлоо жана мушкелдерди шешүү көндөгү жылдам ишчинин даяр. Сиз бардык деталдарды текшересиз, иште эң жогорку сапатты жеткөзгөндө негизделесиз. Колдук сумдукта иштешингиз, жакшылоолор жасоо жана талааларды дайындоо бир көп профессияларда өте қолдонуулу.",
            "Оптимизатор":
                'Тез эффективдет, практикалык жана акылдуу чечимдер табуу үчүн  юртөкчөлүгүңүз сизди идеалдуу оптимизаторго айландырабыз. Сиз чилдетиш процессинин жетишкенин жөндөөсү аркылуу, иштеги жумушту колойлоо жана эффективдытууну тыя аласыз. "Чоң сүрөт" көрүү, муракат системаларын талдаш жана ишкердешинин оптимизациясын үтүүңүз эң негизги активиңиз.',
            "Техника":
                "Техникалык баалуулук, аналитикалык ойлоо өздөгү жана мурдак системаларды түшүнүү үчүн көзүңүз сизди идеалдуу техникчи кылабыз. Сиз технология менен иштөшүнүн талашып үкгөн, түшүндүргөн өзүңүз жана бул практикалык мушкелдерди шешүү үчүн колдоно аласыз. Тезден үйрөнүү жана жаңы технологияларга адаптациялануу  үчүн үстүн өстүкчүлүк болуп эсептелет.",
            "Уста": "Сиздин колдук жетенчилиги, практикалык жана мушкелдерди шешүүгө креативдүү жаактуу идеалдуу мастерди кыла алат. Сиз өз колуңуз менен түзүүгө, талааны дайындоого жана жакшылоого мүмкүнчүлүгү бар. Сиздин детальга берилген кызыктуулук, материалдар менен жана аспаптар менен иштеш үчүн уник мүмкүнчүлүк, жана жаңы жана пайдалуу бир нерсе даярдоо үчүн сындыга игилик кызыктуу жана суранчаңыз.",
            "Бекем баскынчы":
                'Сиздин үлүп жатканыз, тез шешимдер кабылдоо жана кошумча көзөлбөл байланыштуулук сизди идеалдуу "жылдуу басүйгүч" кылабыз. Сиз башкалардын долбоорун көрүшүндө мүмкүнчүлүк көрө аласыз, жана сиз өчүрүүлөр олгон кездерге белек жаттабыз. Сиз көмөкчүлөрүңүздө мааксаттарды жеткөзүү үчүн үйрөнүп казылган алуу менен кошулуп өткөрүлгөн терең ыраатыбыз жана ризико.',
            "Лидер":
                "Сиздин батырлык, өзүңүзгө ишенүү жана адамдарды мобилдөргөн үчүн способдуйлугуңуз сизди идеалдуу башчыга айландырабыз. Сиз өз шешимдериңизге өнө сөрөг жашарга болот жана өз башчылыкчылыгыңыздын өсөт гидроэнергиясындан сунуш кылышты. Сиздин табии башчылыгыңыз, батырлык жана мушкелдүү жайгашканда жүгүрткү операция үчүн кызмат кылыш үчүн кабилет сиздин эң негизги сапатыңыз.",
            "Легионер":
                "Сиздин байыкчылык, команда жакшы ойноо умуттарыңыз жана физикалык жакшы аянтуулук теңче менен, алар сиздин уулуча жана максаттарыңызды алдын алуу, машыктан чыккан жатуундук сыяктуу жана чынайы жолун тутууга жардам бермейт алар, ал бирок алар менен чатышуусуз бололуйт. Сиздин эч канчалык кыйын жааткан жерден чыгарса боштаткан адисте колдонуучу боло албайсыз.",
            "Инспектор":
                "Сиздин анализ жана детализация, тартыпташ жана системалаштырыш үчүн рахат, адисте жатуундук жана учун болгон Иш алдынын көзө малдоолору, тизме жана процедураларды иш учун заманга айыктанычы көрүнүштөрүңүздөн кайрылууну бермейди. Маалый бирим.",
            "Контроллер":
                "Сиздин жакшы калкым болгон жатайындылыгым, адисте детаилге ракат жана толуктуута белгиленген болчу болууну жаткызгыч жана нысан бирим ишты пайдалуу алдын каамалдоолору, алар жатайында толуктуулук жана азаматтык ишчи бөлүмдөрүн өтө айгылы.",
            "Стабилизатор":
                "Алар жатайында сиздин уулучулук, ишин жатту, жөнөкөй алдынын жатайындан адисте колдонуучу боло албайсыз, ал бирок алар менен таап бололуу терек белгиленгенчи натыйжа менен алыс келүү, өзгөрүүгө болот жана бери алыс келүүнү сактоо кызмат көргөсүн жүргүзөт кечууга керектүү бир жер.",
            "Инженер":
                "Сиздин системалык жана аналитикалык оюндоо умуттарың, жаратуу белгиленген жумуш алдынын болгонжолу жана машыкта толуктуулук, алар менен чатышуусуз бололуйт. Сиз тизмелик жана көп деталдагы ишке бололуйт, техника жана бери алыс келүүнү өзгөртүүгө оюндоо үчүн.",
            "Менеджер":
                "Сиздин терезелеген, пландоогон жана менен башкычы жакшы болуу жапарысына карата, сиз топ жана ресурстарышты превосходно жүргүзөт, максаттары croimages коммуникацияда кызмат көргөсүн жүргүзөт керектүү бир жер.",
            "Практик":
                "Сиздин жумушка тегерелүүдүгүз, чынындаа болуп жаткан мүмкүнчүлүктөргө көз салуу жана бир түрдөгү жана санаалыштыгы болгон натижелер сизди чындыктуу жетекчи кылышат. Ойдорулуп алууга, эмгекчил шагылдуулук кылууга, жана реализмдик иштөө - булар сиздин асаа активдериңиз.",
            "Аткаруучу":
                "Пландарды ишке ашыруудагы көн эмгекчил несиеңиз ве тегерек көрүүңүз сизди абдан жакшы ойдоочу кылышат. Сизде ойдорулганды кеңешке ашуу жана бир түрдөгү натижелердин кабылыш ишиндугу бактылуу талант бар, бул катармыш бир борбор организация отунда мүнөттүү.",
            "Администратор":
                "Сиздин организациялык көрбөгөлдөрүңүздүн, байлыкпайтты эчкемдүүлүктөгү жатуулугу жана бир нече ыкмага салууну координаторлоо көрбөгөлдөрү, сизди мүктүк администратор кылат. Курама менен козмо башташ, порядок кайта камтыш жана эффективдүү процесстерди кепсус, сиздин казакчы иликсеги ситоүденин алтындасы.",
            "Урматтуу гриз":
                'Штратегиялык ойлоо кылууга көзүгө бурулган кабылышыңыз, талдоо жатуундагы жатуулуктарыңыз жана бардык мүмкүнчүлүк баалажаттарын санап чыгуу көрбөгөлдөрү  "боз кардинал" мага индеме боло турат. Сиз көлөр карачаларды ишке ашуу мүмкүнчүлүгүнө ээ болуудагы, кол койуучу чакырды сөздөн көркөмдүү, жана байа чеңей турганда иштөө мүмкүнчүлүгүнө ээ.',
            "Синоптик":
                "Сиздин байлыкка созно берүүдөгү жатуулуктарыңыз, аналитикалык ойлоо жана буудардын алдын ала башташуудагы көрбөгөлдөрү сизди мүктүк прогнозор кылат. Билимдерди талдоо, байы карашатты коруу жана алдын ала болушу алдын ала бүлөгү болушат, стратегиялык пландоонын ийгилигиничи ак.",
            "Скептик":
                "Тыйын ойлоо, талдоо жана скецтик болууга сык ой менече болуунуз сизди жакты мощный скецтик кылат. Сиздер акыл-каза боютулича расмияттандыктан шайланган көз карааларды жана ойдорулуштор, аларды бир нече жактан талдоо жана анын бүтөмдөгү казыналарын бастап алууга көмөтчү болушча.",
            "Сынчы":
                "Сиздин акыл айкындыкта эмес эле критикалык баа алынуу жана талдоонун талдаа кылууга, ошентип, сапатты жана дүйнөлүгүнү каалагандыгы, сиздини идеалдуук критикакы кылат. Сиздин кемчиликтерди табуу жана өнүгүүлөрдү текшерүүгө болугуңуздун жардамы менен постоянное преследование совершенствования жана развития.",
            "Инноватор энтузиаст":
                "Сиздин таянкуу энергиялуугу, жаңы нерселерди каалагандыгы жана өзгөчөлүктүү энтузиазмдуу уюштуруу, сиздини өзгөчөлүктүү инноватор энтузиасткага айырдат. Сиз үчүн дайым жаңы идеяларды издөө, эксперименттелерди ачыкка чыгаруу жана жаңы жана бузулма эмелгө киргүү үчүн риске кирүү мүмкүн. Бул сага катышуудан чыккан жана стандарттук чегелерди чыпасы жетүү үчүн кабилет берет. Сиз үчүн катышкан командаларда кызыктуу жана жаңы кылык алууга кайрылат.",
            "Изилдөөчү":
                "Сиздин кызыктуулугу, өзүңөрө талаастык кызыктуулук жана талдоонун чоңоруп талаа кылууга кабилети, сиздини чындыктуу исследователь кылат. Билимдик таятууга болгон түшүнүкчү жана жакшы суроолорду суроо үчүн болгон кабилети, сизди окшоогандон баштап төмөнгө карак. Сиз байланышты табууга болгон кабилети, башкаларды табууда кыйыкат, иш жол жана стратегияларды иштетүүгө мүмкүнчүлүктү берет. Сиздин исследователь кылуугу жана үндүүктү түшүп барууга кызыктуу, сиздин развитие жана инновациялардыгы негиздөйт.",
            "Генератор":
                "Сиздин creative кызыктуулугу, идеяларды тез иштетүү жана үндүүктүү жана прогрессиялууга болгон ырастоолуу, сиздини өтө маанилүү генератордуу кылат. Сиздин идеяларыңыз часто өзгөруү учур берет, жана ырастоону creative кылуу драйвы сизге командага инновациялык маданиятты коргоо үчүн жардам берет. Сиздин уникалдуу кабилети, башкаларды өтөбөй көрүлгөн мүмкүнчүлүктөрдү табуу, сиздин идеяларды генерациялоо ашы болуу, бизнестеги же долбоорлордогу өсүү жана развитиеди ырастарак болушу мүмкүн болбот.",
            "Аналитик":
                "Analyzing and breaking things down makes you an indispensable analyst. You always see how each element affects the big picture, and your ability to objectively assess information and data allows you to offer insightful and informed insights. Your analytical skills help organisations make data-driven decisions and develop effective strategies.",
            "Системаизатор":
                "Сиздеги жактары, структура жана систематизациялуу, сизди тамаша системалагычты кылат. Маалыматты тартиби боюнча жыйндоо, байланышты курдуу жана ачык жана логикалык системаларды түзүүгө болгон кабилети сизге буйуктуктун ичиндеги система болууга мүмкүнчүлүк берет. Бул уюмдарди жакшылаштыруу, эффективдүүлүгүн арттыруу жана өнүгүүгө жардам берет.",
            "Перфекционист":
                "Тез ыйгылчылыгына, жетиштүүлүгүнө жана деталга көңүл бурушуна арналган сыйлыгын сенде толук юмшактуу кылды. Ишти, мүмкүн болгонча жакшы тамгаланбай тура, сен иши жок кылып аласың. Жогорку стандарттарың жана сапатты издөөнүөнү чөкөлкө салмайын эле болбогон иште мүмкүн болгонча жогорку сапатты иште сезимделик нөтижелерге айканат.",
            "Инноватор":
                "Талдоону жана оптимизациялоону сапатты кылатуу мүмкүнчүлүгү тагдыр бекчи болуңузга жардамчы болур. Бирдеме катары бир жакты бирде жетиштүүрүң болбойт жатат. Сиздин оптимизациялоону жана П сложнецату жеңилдетүү үстүнчүлүгү күчтөө жана сапатты жогорултуу организацияларга жардшы болур.",
            "Ишкер инноватор":
                "Чоң сүрөттү, бирдеме катары барак ойдонбосторду табуу жана жаңы идеяларды кабул алуу системасы сизге иштейт берүүчү инновационду болуу үчүн табышка иштеш. Сиз өп азыктуу эмес жана размер үчүн ырастоо жана күч бардык иштеги ойлору акысын реал жакта көрсөтүү. Сиздин бизнес менен элеандаштырууга орду системасы колдоого алгуу кызмат көрсөтүп бергенде жана инновациялоо бирдеме ишки жана проект ачуу жөнүндө өстүрүүчү болур.",
            "Алгоритмист":
                "Аймакты иштеп чыгаруу, алгоритмдерди жасоо жана билимди органзациялоо кесенегиз көп биринчи дайым үчүн сизге алгоритмдик кылып берер. Сенин дайымдарды жана структура жасоо процессеге дайым келтирди, ошондуктан организациялар дагы иштөө үчүн азыктуу жана мааналы болбогон. Сиздин алгоритмге ойлогдогуу ойлоо жарым-жарым продуктивди жана иштин сапатын көтөрөт.",
            "Интегратор":
                "Адамдарды, идеаларды жана ресурстарды бириктеген мүмкүнчүлүгү бул процесси бириктиргичи тийгиз болур. Сиз ар бир элементтерин бириктип жатат бирдеме катары ар-биринин чыгындарын жана башкадан алынган өзгөрүштөрдү көрө аласыз, ошондуктан үй передачи таанып жана ичиндеги группаны толук ишке киргизүү үчүн жатар. Сиздин бириктируучу материал бир биринен табыштуунун организациясына жардамчы болур.",
            "Экспериментатор":
                "Сиз сынап көрүү үчүн керек талапка жана жакка тизме бирдеме жанында жатасыз, суроору жана жүйрүмдөрдү текшерүү үчүн бирдеме катары иштөө чорчгонос, ошондуктан иштөөр эксперименттин мүмкүнчүлүгүн өткөргөн болур. Сиз акысын жогоргу бекчиги, жана уларды үйрөнүү жол болгон экспериментчи болбосуңузга уруксат берет. Сиздин ыйгылчылык азыктуу тозоолууга рүүктө болур жана унутпа жана үйлөр өзгөртүү жүйрүмү үчүн жуунтыксыстыгѳргѳн ючүн рүүктө болур.",
        },
    },
    "WelcomeSubscribe": {
        "title":
            "Мени текшерерсин, өстүк ийгиликтни жеткүзгөндө жасалган алаңдарга жана акча табууга мадар берем",
        "subtitle":
            "Профилге өтүүдөн мурун - азыр ушул жерде сага өтө келечек тургандык бар<br/><br/> Ичинде кандай эле менен",
        "pay": "Барыда толук ирешүүгө жетиш {price} тынайт, айына",
        "to-profile": "Профилге өтүү",
        "list": [
            {
                "id": 1,
                "icon": "people-recommendations",
                "text": "Жол-жободо сиз менен бириккен бизнестеги 5 киши текшерилет.",
            },
            {
                "id": 2,
                "icon": "text-recommendations",
                "text": "Сиздин психотипиңизди тереңдетип, анын декодулатууга жетишкиңиз",
            },
            {
                "id": 3,
                "icon": "brain",
                "text": "Сиздин шамдырагыңыздын жана тартышыңыздын сүрөттөн тушкөрүүгө жетишкиңиз",
            },
            {
                "id": 4,
                "icon": "environment",
                "text": "Бул бизнесте юпама комплексиңизди оңдоого 10-дан ашуун текшерүү болушу мөмкүн",
            },
            {
                "id": 5,
                "icon": "stars",
                "text": "Сиздин өзүңүздүн катапшасына ирешүңүз жана оларды мешексиз текстетүүлөрү, башкача иште катышуу болушу же олардын характерин билүүгө мөмкүнчүлүк берилет.",
            },
            {
                "id": 6,
                "icon": "photo",
                "text": "Сайттагы же ашуулук сайтта бардык адамдарды бизнестеги жойгуштуулукке текшерүүңүзгө мөмкүнчүлүк берилет.",
            },
        ],
    },
};
